import React, { useEffect, useState } from "react";
import {
  Typography,
  Container,
  Paper,
  Box,
  Divider,
  IconButton,
  Stack,
} from "@mui/material";
import "../../Css/checkoutnew.style.css";
import OrderSummary from "./OrderSummary";
import PlaceIcon from "@mui/icons-material/Place";
import PaymentIcon from "@mui/icons-material/Payment";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import eventEmitter from "../PartSearchTest/eventEmmiter";

import ShippingAdderss from "./ShippingAdderss";
import PaymentOptions from "./PaymentOptions";
import ShippingMethod from "./ShippingMethod";
import {
  UserInfo,
  UserInfoERR,
  UserlocationInfo,
  Shippingmethod,
} from "../../Types/MicrositeType";
import Loader from "../Loader";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { UpdateTmpTable, getCadenceAPIToken } from "./SubmitOrder";
import { useSelector } from "react-redux";

export default function CheckoutNew() {
  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);
  const CaptureCaptchaValue = (state: any) => state.CAPTURECAPTCHAVALUE;  
  const CaptchaValue = useSelector(CaptureCaptchaValue);

  const [currentTab, setcurrentTab] = useState("ADDRESS");

  //  GENERAL CHECKOUT
  const [Shipinfo, setShipInfo] = useState<UserInfo>({
    Firstname: "",
    Lastname: "",
    AddressLine1: "",
    Aptsuite: "",
    Zip: "",
    State: "",
    Country: "",
    City: "",
    Email: "",
    Phone: "",
    StateId: 0,
    CountryId: 0,
    CityId: 0,
    ZipId: 0,
  });

  const [CountryList, setCountryList] = useState<[]>([]);
  const [StateList, setStateList] = useState<[]>([]);
  const [CityList, setCityList] = useState<[]>([]);

  const [CountryBillList, setCountryBillList] = useState<[]>([]);
  const [StateBillList, setStateBillList] = useState<[]>([]);
  const [CityBillList, setCityBillList] = useState<[]>([]);
  const [ShipinfoLocationDetails, setShipinfoLocationDetails] =
    useState<UserlocationInfo>({
      Zip: "",
      State: "",
      Country: "",
      City: "",
      StateId: 0,
      CountryId: 0,
      CityId: 0,
    });
  const [load, setload] = useState<boolean>(false);
  // const [Emailme, setEmailme] = useState<boolean>(false);
  const [disabled, setdisabled] = useState<boolean>(false);
  const [Shippingdisabled, setShippingdisabled] = useState<boolean>(false);
  //const [emaildisabled, setemaildisabled] = useState<boolean>(false);
  // const navigate = useNavigate();

  //  SHIPPING METHOD
  const [shippingType, setShippingType] = useState<{
    id: string;
    res: string;
    response: Shippingmethod; // Provide the appropriate type here
  }>({
    id: "",
    res: "",
    response: {
      carrierLogo: "",
      carrier_code: "",
      checked: "",
      commonCarrierName: "",
      deliveryDate: "",
      deliveryDay: "",
      delivery_days: "",
      estimated_delivery_date: "",
      handlingCharge: "",
      handlingType: "",
      lastPickup: "",
      list_rate: "",
      override_amount: "",
      priority: "",
      rate: "",
      retail_rate: "",
      returnLabel: "",
      service_type: "",
      shippingPolicyName: "",
      shippingTypeCommonCarrierId: "",
      shippingTypeDesc: "",
      shippingTypeId: "",
      shipping_amount: "",
    },
  });
  const [isDeliveryallChecked, setIsDeliveryallChecked] = useState(true);

  // UPDATE BILLING INFO FOR EXISTING USER CASE

  const [Billinfo, setBillInfo] = useState<UserInfo>({
    Firstname: Shipinfo.Firstname,
    Lastname: Shipinfo.Lastname,
    AddressLine1: Shipinfo.AddressLine1,
    Aptsuite: Shipinfo.Aptsuite,
    Zip: Shipinfo.Zip,
    State: Shipinfo.State,
    Country: Shipinfo.Country,
    City: Shipinfo.City,
    Email: Shipinfo.Email,
    Phone: Shipinfo.Phone,
    StateId: Shipinfo.StateId,
    CountryId: Shipinfo.CountryId,
    CityId: Shipinfo.CityId,
    ZipId: Shipinfo.ZipId,
  });

  const [cpToken, setCPToken] = useState("");

  const [fieldErrors, setFieldErrors] = useState<UserInfoERR>({
    Firstname: "",
    Lastname: "",
    AddressLine1: "",
    Aptsuite: "",
    Zip: "",
    State: "",
    Country: "",
    City: "",
    Email: "",
    Phone: "",
  });
  const [error, setError] = useState(false);

  useEffect(() => {
    eventEmitter.on("triggerTabChange", HandleChange);
    // eventEmitter.on("triggersearchfilter",  TriggerAttribute);
    return () => {
      // ResetreduxData()

      eventEmitter.off("triggerTabChange", HandleChange);
    };
  }, []);

  useEffect(() => {
    // console.log("Token Genertion");
    tokengen();
  }, []);

  async function tokengen() {
    var CadenceAPIToken = localStorage.getItem("authToken");
    // console.log(CadenceAPIToken, "Token Genertion0");
    if (
      CadenceAPIToken === "" ||
      CadenceAPIToken === null ||
      CadenceAPIToken === undefined
    ) {
      const fun = await getCadenceAPIToken(EnvData);
      // console.log(fun, "Token Genertion1");
    }
  }




  useEffect(() => {
    setBillInfo({
      Firstname: Shipinfo.Firstname,
      Lastname: Shipinfo.Lastname,
      AddressLine1: Shipinfo.AddressLine1,
      Aptsuite: Shipinfo.Aptsuite,
      Zip: Shipinfo.Zip,
      State: Shipinfo.State,
      Country: Shipinfo.Country,
      City: Shipinfo.City,
      Email: Shipinfo.Email,
      Phone: Shipinfo.Phone,
      StateId: Shipinfo.StateId,
      CountryId: Shipinfo.CountryId,
      CityId: Shipinfo.CityId,
      ZipId: Shipinfo.ZipId,
    });
    setCountryBillList(CountryList);
    setStateBillList(StateList);
    setCityBillList(CityList);
  }, [Shipinfo, CountryList, CityList]);

  const savedShipinfo = localStorage.getItem("ShippingInfo");
  const savedShippingType = localStorage.getItem("ShippingType");

  useEffect(() => {
    const Deliveryall = localStorage.getItem("DeliveryAll");

    const ParsedShipVal = JSON.parse(
      savedShipinfo ? savedShipinfo : JSON.stringify({})
    );

    if (
      ParsedShipVal.Firstname !== "" ||
      undefined ||
      (null && ParsedShipVal.Lastname !== "") ||
      undefined ||
      (null && ParsedShipVal.AddressLine1 !== "") ||
      undefined ||
      (null && ParsedShipVal.Zip !== "") ||
      undefined ||
      (null && ParsedShipVal.State !== "") ||
      undefined ||
      (null && ParsedShipVal.Country !== "") ||
      undefined ||
      (null && ParsedShipVal.City !== "") ||
      undefined ||
      (null && ParsedShipVal.Email !== "") ||
      undefined ||
      (null && ParsedShipVal.Phone !== "") ||
      undefined ||
      null
    ) {
      setShipInfo({
        Firstname: ParsedShipVal.Firstname,
        Lastname: ParsedShipVal.Lastname,
        AddressLine1: ParsedShipVal.AddressLine1,
        Aptsuite: ParsedShipVal.Aptsuite,
        Zip: ParsedShipVal.Zip,
        State: ParsedShipVal.State,
        Country: ParsedShipVal.Country,
        City: ParsedShipVal.City,
        Email: ParsedShipVal.Email,
        Phone: ParsedShipVal.PhoneNumber,
        StateId: ParsedShipVal.StateId,
        CountryId: ParsedShipVal.CountryId,
        CityId: ParsedShipVal.CityId,
        ZipId: ParsedShipVal.ZipId,
      });
      const savedEmail = ParsedShipVal;
      if (savedEmail.Email !== "") {
        //setemaildisabled(true);
      }
      if (savedShipinfo !== null && Customerndbranch) {
        setcurrentTab("METHOD");
      }

      setdisabled(true);
    }

    if (savedShippingType) {
      setShippingType(JSON.parse(savedShippingType));
    }

    if (Deliveryall == "true") {
      setIsDeliveryallChecked(true);
    } else {
      setIsDeliveryallChecked(false);
    }

    if (savedShipinfo && savedShippingType) {
      setcurrentTab("OPTIONS");
      setdisabled(true);
      setShippingdisabled(true);
      // setemaildisabled(true)
    }
  }, [savedShipinfo]);

  

  // useEffect(() => {
  //   // console.log(Billinfo ,"Bill Info")
  //   localStorage.setItem("BillingInfo", JSON.stringify(Billinfo));
  // }, [Billinfo]);

  const validateEmail = (value: string) => {
    const expression = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const result: boolean = expression.test(value);
    if (!result) {
      //   console.error("Please enter a valid email address");
      setError(true);
    } else {
      setError(false);
    }
  };
  const [toggle, setToggle] = useState(window.innerWidth > 1000);
  const handleDeliveryallCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    localStorage.setItem("DeliveryAll", String(event.target.checked));
    setIsDeliveryallChecked(event.target.checked);
  };

  const handleInputChange = (name: any, value: any) => {
    setShipInfo((prevValues: any) => ({
      ...prevValues,
      [name]: value,
    }));

    setFieldErrors((prevValues: UserInfoERR) => ({
      ...prevValues,
      [name]: "",
    }));

    if (name === "Email") {
      validateEmail(value);
    }
  };

  function HandleTopTab(data: string) {
    // console.log(savedShippingType, "saved shipping type");

    if (data === "ADDRESS" || data === "METHOD") {
      if (savedShipinfo && Customerndbranch) {
        setcurrentTab(data);
      }
    } else if (data === "OPTIONS" && savedShippingType) {
      // console.log(savedShippingType, "saved shipping type");
      setcurrentTab(data);
    }
  }
  const handleBillInputChange = (name: any, value: any) => {
    // console.log(name , value , " chamges")
    // setBillInfo((prevValues: any) => ({
    //   ...prevValues,
    //   [name]: value,
    // }));
    // // if (name === "AddressLine1") {
    // //   let address = value.split(",").map((part) => part.trim());
    // //   const [zip, country, city , state] = address;
    // //   setShipInfo((prevShipInfo) => ({
    // //     ...prevShipInfo,
    // //     State:state,
    // //     City:city,
    // //     Country: "",
    // //     Zip: zip,country
    // //   }));
    // // }
    // if (name === "Email") {
    //   validateEmail(value);
    // }
    // //  localStorage.setItem('ShippingInfo', JSON.stringify(Shipinfo));
    // localStorage.setItem("BillingInfo", JSON.stringify(Billinfo));
  };
  let Customerndbranch = localStorage.getItem("CartAndBramchId");
  let CustomerndbranchDate = localStorage.getItem("CartAndBramchDate");

   async function UpdateInfo(data?:string) {   
    setload(true); 
    let flag;
    if(data!=="")  {
       flag = await UpdateTmpTable(EnvData, "", data);
    }
    else {
      flag = await UpdateTmpTable(EnvData, "", CaptchaValue.CaptchaValue);
    }
    if(flag !==true) {
      setcurrentTab("ADDRESS");
      alert ("Invalid Shipping Address")

      localStorage.removeItem("CartAndBramchId");
        localStorage.removeItem("CartAndBramchDate");
    }
    setload(false);
    return flag;

   
  }

  const handleChange = (
    panel: string,
    event?: React.SyntheticEvent,
    isExpanded?: boolean
  ) => {
    if (currentTab === "ADDRESS") {
      setdisabled(true);
      UpdateInfo();
    
    }

    if (currentTab === "METHOD") {
      setShippingdisabled(true);
      
     UpdateInfo();
    
    }
    const myTimeout = setTimeout(() => {
      setcurrentTab(panel);
    }, 900);

    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      Firstname: "",
      Lastname: "",
      AddressLine1: "",
      Aptsuite: "",
      Zip: "",
      State: "",
      Country: "",
      City: "",
      Email: "",
      Phone: "",
    }));
    //}
  };

  function HandleChange(data: any) {
    if (data.eventName == "Addresss") {      
      setcurrentTab("ADDRESS");
      CaptchaValue.CaptchaValue!==""&&UpdateInfo();
      setdisabled(false);
    } else if (data.eventName=="Method") {
      setcurrentTab("METHOD");
      UpdateInfo(data.captchaValue);
    }
    else if (data.eventName == "Contact" && savedShipinfo) {
      setcurrentTab("ADDRESS");
      //setemaildisabled(false);
    }
  }

  function handleshippingtype(data: any) {
    // localStorage.setItem("ShippingType", JSON.stringify(data));
    setShippingType(data);
  }

  const HandleZip = (val: any) => {
    // console.log("handleZip")
  };
  
  if (load) {
    return <Loader />;
  }

  return (
    <div>
      <Paper elevation={0} sx={{ mt: 2, mb: 2, py: 3 }}>
        <Container>
          <Box className="checkout-container">
            <div className="checkout-ordersummary">
              <Box
                onClick={() => setToggle((toggleBefore) => !toggleBefore)}
                className="mobile-checkout-onclick"
              >
                <div className="product-right-border">
                  <Typography variant="h4">
                    ORDER SUMMARY
                    <Divider
                      sx={{ bgcolor: "primary.main" }}
                      className="title-divider"
                    />
                  </Typography>
                  <KeyboardArrowDownIcon />
                </div>
              </Box>
              <div
                style={{ display: `${toggle ? "block" : "none"}` }}
                className="mobile-checkout-summary"
              >
                <OrderSummary ordersummary={true} Tab={currentTab} />
              </div>
            </div>
            <div className="checkout-cart-details">
              <Box className="cart-details-box">
                <div className="product-right-border">
                  <Typography variant="h4" pb={1}>
                    YOUR CART DETAILS
                    <Divider
                      sx={{ bgcolor: "primary.main" }}
                      className="title-divider"
                    />
                  </Typography>
                </div>
                <Stack
                  sx={{ cursor: "pointer" }}
                  direction="row"
                  spacing={1}
                  className="cart-details-tab"
                >
                  <Box
                    className="cart-details-tab-box"
                    onClick={() => {
                      // setcurrentTab("ADDRESS");
                      HandleTopTab("ADDRESS");
                    }}
                  >
                    <IconButton
                      color={currentTab === "ADDRESS" ? "primary" : "default"}
                    >
                      <PlaceIcon />
                    </IconButton>{" "}
                    <span>SHIPPING ADDRESS</span>
                  </Box>
                  <IconButton className="cart-details-tab-icons">
                    {" "}
                    /{" "}
                  </IconButton>
                  <Box
                    className="cart-details-tab-box"
                    onClick={() => {
                      HandleTopTab("METHOD");
                    }}
                  >
                    <IconButton
                      color={currentTab === "METHOD" ? "primary" : "default"}
                    >
                      <LocalShippingIcon />
                    </IconButton>{" "}
                    <span>SHIPPING METHOD</span>
                  </Box>
                  <IconButton className="cart-details-tab-icons">
                    {" "}
                    /{" "}
                  </IconButton>
                  <Box
                    className="cart-details-tab-box"
                    onClick={() => {
                      HandleTopTab("OPTIONS");
                    }}
                  >
                    <IconButton
                      color={currentTab === "OPTIONS" ? "primary" : "default"}
                    >
                      <PaymentIcon />
                    </IconButton>{" "}
                    <span>PAYMENT OPTIONS</span>
                  </Box>
                </Stack>
              </Box>

              {currentTab === "ADDRESS" && (
                <ShippingAdderss
                  statevalue={Shipinfo}
                  change={handleInputChange}
                  err={fieldErrors}
                  nxtTab={handleChange}
                  emaildisable={false}
                  disable={disabled}
                  HandleZip={HandleZip}
                  citylist={setCityList}
                  statelist={setStateList}
                  countrylist={setCountryList}
                  city={CityList}
                  state={StateList}
                  country={CountryList}
                />
              )}

              {currentTab === "METHOD" && (
                <ShippingMethod
                  statevalue={shippingType}
                  nxtTab={handleChange}
                  handleshpping={handleshippingtype}
                  disable={false}
                  handledeliveryall={handleDeliveryallCheckboxChange}
                  deliveralldata={isDeliveryallChecked}
                  handlechange={HandleChange}
                />
              )}
              {currentTab === "OPTIONS" && (
                <PaymentOptions
                  statevalue={Billinfo}
                  change={handleBillInputChange}
                  err={fieldErrors}
                  nxtTab={handleChange}
                  handlechange={HandleChange}
                  ContactInfo={Shipinfo.Email}
                  ShipAddress={Shipinfo.AddressLine1}
                  ShipMethod={shippingType.res}
                  citylist={setCityBillList}
                  statelist={setStateBillList}
                  countrylist={setCountryBillList}
                  city={CityBillList}
                  state={StateBillList}
                  country={CountryBillList}
                />
              )}
            </div>
          </Box>
        </Container>
      </Paper>
    </div>
  );
}
