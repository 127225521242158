import breadcrumbsbanner from "../../Images/centralturbos/breadcrumbs-brochures-banner.png"
import brochures1 from "../../Images/centralturbos/brochures1.png";
import brochures2 from "../../Images/centralturbos/brochures2.png";
import brochures3 from "../../Images/centralturbos/brochures3.png";
import {
  Avatar,
  Typography,
  Container,
  Paper,
  Grid,
  Box,
  Divider,
} from "@mui/material";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

import { useTestimonialResults } from "../../APIHooks/useGetData";
import { useEffect, useState } from "react";
import { GetData, Get_Data } from "../../API/GetDataFromApi";
import Loader from "../../Components/Loader";
import { useSelector } from "react-redux";
import TechBulletinsjpg from "../../Images/techbulletin-jpg-file.svg";
import TechBulletinspdf from "../../Images/techbulletin-pdf-file.svg";
import TechBulletinsdoc from "../../Images/techbulletin-doc-file.svg";
import TechBulletinstxt from "../../Images/techbulletin-txt-file.svg";
import TechBulletinsppt from "../../Images/techbulletin-ppt-file.svg";
import TechBulletinsvideo from "../../Images/techbulletin-video.svg";
import TechBulletinswebsite from "../../Images/techbulletin-link-file.svg";
import TechBulletinsxls from "../../Images/techbulletin-xls-file.svg";
import TechBulletinszip from "../../Images/techbulletin-zip-file.svg";
import TechBulletinshtml from "../../Images/techbulletin-html-file.svg";


export default function CentralTurbosBrochures() {

  interface IgetDataTechBulletins {
    fileNameText: string;
    fileNameNavigateUrl: string;
    imageUrl: string;
    videoUrl: string;
    fileNameToolTip: string;
    iconURL : string;
  }

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setsearchText] = useState("");
  const [data, setDetailsItems] = useState<IgetDataTechBulletins[]>([]);
  let resultshowornot = 1;

  let url =
    EnvData?.REACT_APP_APIURL + "/microsite-api-v1/get-techbulletins";
  if (searchText !== "") {
    url = url + "?Filename=" + searchText;
  }

  const FindTechBulletins = async () => {
    setLoading(true);
    Get_Data(url, "")
      .then((results?) => {
        resultshowornot = 1;
        if (results.data.techBulletinsDtl == null) {
          alert("No Records Found");
          resultshowornot = 0;
          setDetailsItems([]);
        } else {
        // console.log(results.data.techBulletinsDtl.length, "Total Rows Count");
          setDetailsItems(results.data.techBulletinsDtl);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    setLoading(false);
  };

  const ResetFilter = async () => {
    setLoading(true);   
    setsearchText(""); 
    resultshowornot = 0;
    setDetailsItems([]);        
    setLoading(false);
  };

  return(
    <div>
      <Paper elevation={0} sx={{ mt: 0,mb:1, p: 7,  backgroundImage: `url(${breadcrumbsbanner})`, }} className="central-turbos-breadcrumbs-banner">
          <Container>
              <Box sx={{px:5}}>
                  <Typography className="central-turbos-breadcrumbs-title">Brochures</Typography>
                  {/* <Typography className="central-turbos-breadcrumbs-sub-title">subhead</Typography> */}
              </Box>
          </Container>
      </Paper>
      <Paper elevation={0} sx={{mb: 2, py:10}} className="central-turbos-brochures-paper">
          <Container>
            <Grid container spacing={2} alignContent="center">
            {data &&
                        data.map((elem, index) => (
                <Grid item xs={12} md={4}>
                    <Box mt={2}>
                    {elem.iconURL != "" ? (
                                    <>
                                      <img
                                          src={elem.iconURL}
                                          loading="lazy"
                                        />
                                    </>
                                    ) : elem.imageUrl == "images/doc_pdf.png" ? (
                                      <>
                                        <img
                                          src={TechBulletinspdf}
                                          loading="lazy"
                                        />
                                      </>
                                    ) : elem.imageUrl == "images/doc_jpg.png" ? (
                                      <>
                                        <img
                                          src={TechBulletinsjpg}
                                          loading="lazy"
                                        />
                                      </>
                                    ) : elem.imageUrl == "images/doc_doc.png" ? (
                                      <>
                                        <img
                                          src={TechBulletinsdoc}
                                          loading="lazy"
                                        />
                                      </>
                                    ) : elem.imageUrl == "images/doc_pptx.png" ? (
                                      <>
                                        <img
                                          src={TechBulletinsppt}
                                          loading="lazy"
                                        />
                                      </>
                                    ) : elem.imageUrl == "images/doc_xls.png" ? (
                                      <>
                                        <img
                                          src={TechBulletinsxls}
                                          loading="lazy"
                                        />
                                      </>
                                    ) : elem.imageUrl == "images/doc_zip.png" ? (
                                      <>
                                        <img
                                          src={TechBulletinszip}
                                          loading="lazy"
                                        />
                                      </>
                                    ) : elem.imageUrl == "images/doc_html.png" ? (
                                      <>
                                        <img
                                          src={TechBulletinshtml}
                                          loading="lazy"
                                        />
                                      </>
                                    ) : elem.imageUrl ==
                                      "images/doc_website.png" ? (
                                      <>
                                        <img
                                          src={TechBulletinswebsite}
                                          loading="lazy"
                                        />
                                      </>
                                    ) : null}
                      <Typography sx={{mt:1}} variant="subtitle1"  onClick={() => {
                                      window.open(
                                        elem.fileNameNavigateUrl,
                                        "_blank"
                                      );
                                    }}>   {elem.fileNameText}</Typography>
                    </Box>
                </Grid>

                        ))}
              
            </Grid>
          </Container>
        </Paper>
    </div>
  )
}


