import {
  Typography,
  Paper,
  Avatar,
  Grid,
  Stack,
  Button,
  Box,
  Divider,
} from "@mui/material";
import { Container } from "@mui/system";
import PlaceIcon from "@mui/icons-material/Place";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import TextField from "@mui/material/TextField";
import React, { useState, useEffect } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

// import { useContactResults, useGetData } from "../APIHooks/useGetData";
import "../Css/contact.style.css";
import GMap from "../Components/GMap";
import {
  GetData,
  GetauthHeader,
  GeteMailRes,
  Get_Data,
} from "../API/GetDataFromApi";
import {
  IgetContactShoworHide,
  IgetContactInfo,
  IgetOpenHours,
  IgetWHDetails,
} from "../Types/MicrositeType";
import formatPhoneNumber from "../API/GetMicrositeSettings";
import Loader from "../Components/Loader";
import { CheckValidEmailAddress } from "../API/GetMicrositeSettings";
import { useSelector } from "react-redux";

// import locatioimg from "../Images/location.jpg";
// import { Key } from "@mui/icons-material";

const containerStyle = {
  width: "100%",
  height: "100%",
};
export function ContactUs() {
  const [checkVal, setCheckVal] = useState("0");
  const [error, setError] = useState<string | null>(null);
  const [nameVal, setnameVal] = useState("");
  const [emailVal, setemailVal] = useState("");
  const [subjectVal, setsubjectVal] = useState("");
  const [msgVal, setmsgVal] = useState("");

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);
  const authHeader = GetauthHeader();

  const [data, setData1] = useState<IgetContactShoworHide[]>([]);
  const [data1, setData2] = useState<IgetContactInfo[]>([]);
  //const [data2, setDat3] = useState<IgetOpenHours[]>([]);
  const [data3, setData4] = useState<IgetWHDetails[]>([]);

  const [latVal, setLatVal] = useState(0);
  const [langVal, setLangVal] = useState(0);
  const [whAddress, setWhAddress] = useState("");
  const [whStateCityCountry, setWhStateCityCountry] = useState("");
  const [whPhoneNo, setWhPhoneNo] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  // let mailfromaddress = "",
  //   mailtoaddress = "",
  //   mailsubject = "",
  //   mailbodydata = "";

  const setValue_Name = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setnameVal(event.target.value);
  };
  const setValue_Email = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setemailVal(event.target.value);
    validateEmail();
  };
  const setValue_Subject = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setsubjectVal(event.target.value);
  };
  const setValue_Msg = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setmsgVal(event.target.value);
  };
  const validateEmail = () => {
    const result: boolean = CheckValidEmailAddress(emailVal);
    if (!result) {
      setError("Please enter a valid email address");
    } else {
      setError("");
    }
  };
  const Sendmessage = async () => {
    let err_msg = "";
    let success = 0;
    let response: any = null;
    if (nameVal === null || nameVal === "") {
      err_msg = "Enter Name";
    }
    if (subjectVal === null || subjectVal === "") {
      if (err_msg !== "") {
        err_msg = err_msg + "\n";
      }
      err_msg = err_msg + "Enter Subject";
    }
    if (msgVal === null || msgVal === "") {
      if (err_msg !== "") {
        err_msg = err_msg + "\n";
      }
      err_msg = err_msg + "Enter Message";
    }
    if (error !== null && error !== "") {
      if (err_msg !== "") {
        err_msg = err_msg + "\n";
      }
      err_msg = err_msg + error.toString();
    } else if (error === null) {
      if (err_msg !== "") {
        err_msg = err_msg + "\n";
      }
      err_msg = err_msg + "Enter Email Address";
    }
    // console.log(err_msg);
    if (err_msg !== "") {
      alert(err_msg);
    } else {
      var raw = {
        Name: nameVal,
        Email: emailVal,
        Subject: subjectVal,
        Message: msgVal,
      };
      await GeteMailRes(
        EnvData?.REACT_APP_APIURL + "/api/Email/send-email",
        JSON.stringify(raw)
      )
        .then((response) => {
          return response;
        })
        .then((results) => {
          success = 1;
          alert("Thanks for your message!  We’ll be in touch soon.");
          //console.log(results);
        })
        .catch((error) => {
          // console.log("Error in " + error);
        });
      if (success === 1) {
        setnameVal("");
        setemailVal("");
        setsubjectVal("");
        setmsgVal("");
        validateEmail();
      }
    }
  };

  useEffect(() => {
    (async () => {
      // console.log("start - contact page");
      setLoading(true);
      Get_Data(
        EnvData?.REACT_APP_APIURL + "/microsite-api-v1/get-contact",
        ""
      )
        .then((results?) => {
          // console.log(results.data);
          setData1(results.data.micrositeContact_ShoworNotResponse);
          setData2(results.data.micrositeContact_CompanyResponse);
          //setDat3(results.data.micrositeContact_OperationalHoursResponse);
          setData4(results.data.micrositeContact_AddressResponse);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          //   localStorage.removeItem("user");
        });
    })();
  }, [checkVal]);
  let warehouseContact = false;
  let MainContact = false;
  let MainContactMap = false;
  let MainContactMap_new = false;
  let DropALine = false;
  let ShowOperationalHrs = false;
  let MainContact_md = 4,
    DropALine_md = 7,
    ShowOperationalHrs_md = 3;
  let lat = 0,
    lang = 0;
  let wh_address = "",
    wh_statecitycountry = "",
    wh_phoneno = "",
    totalRows = "";

  {
    data &&
      data.map(
        (elem, index) => (
          (warehouseContact = elem.warehouseContact),
          (MainContact = elem.mainContact),
          (MainContactMap_new = elem.mainContactMap),
          (DropALine = elem.dropALine),
          (ShowOperationalHrs = elem.showOperationalHrs)
        )
      );
  }

  {
    if (data3.length > 0) {
      // console.log(data3, "WH");
      lat = Number(data3[0].latitude);
      lang = Number(data3[0].longitude);
      wh_address =
        data3[0].warehouseAddress1 + ", " + data3[0].warehouseAddress2;
      wh_statecitycountry =
        data3[0].stateCode +
        ", " +
        data3[0].cityName +
        ", " +
        data3[0].countryName;
      wh_phoneno = data3[0].phonePrefix + '' + data3[0].warehousePhone;
      totalRows = data3[0].totalRows;
      // console.log("total rows -", totalRows);
    }
  }

  if (ShowOperationalHrs === false) {
    MainContact_md = 6;
    DropALine_md = 6;
  }

  const updateValue = (target: any) => {
    let latlang = target.target.value;
    lat = 0;
    lang = 0;
    let addresswh = "",
      statecitycountrywh = "";
    setLatVal(0);
    setLangVal(0);
    setWhAddress("");
    setWhStateCityCountry("");
    setWhPhoneNo("");
    latlang.split("###").map((item: any, index: number) => {
      if (index === 0) {
        setLatVal(Number(item));
      } else if (index === 1) {
        setLangVal(Number(item));
      } else if (index === 2) {
        addresswh = item;
      } else if (index === 3) {
        addresswh = addresswh + ", " + item;
        setWhAddress(addresswh);
      } else if (index === 4) {
        statecitycountrywh = item;
      } else if (index === 5) {
        statecitycountrywh = statecitycountrywh + ", " + item;
      } else if (index === 6) {
        statecitycountrywh = statecitycountrywh + ", " + item;
        setWhStateCityCountry(statecitycountrywh);
      } else if (index === 7) {
        setWhPhoneNo(item);
      }
    });
  };

  return (
    <>
      {loading !== true ? (
        <>
          <div>
            <Paper elevation={0} sx={{ mt: 2, mb: 2, py: 3 }}>
              <Container>
                <Grid container spacing={2}>
                  {MainContact ? (
                    <>
                      <Grid item xs={12} sm={6} md={MainContact_md}>
                        <div className="product-right-border">
                          <Typography variant="h4" sx={{ pb: 2 }}>
                            CONTACT US
                            <Divider
                              sx={{ bgcolor: "primary.main" }}
                              className="title-divider"
                            />
                          </Typography>
                        </div>
                        {data1 &&
                          data1.map((elem, index) => (
                            <div className="contact-info" key={index}>
                              <Typography variant="h5" sx={{ mb: 2 }}>
                                {elem.companyName}
                              </Typography>
                              {/* <Stack
                                className="contact-info-list"
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Button
                                  color="primary"
                                  variant="contained"
                                  className="contact-info-icons"
                                  href={elem.locationUrl}
                                  target="_blank"
                                >
                                  <PlaceIcon />
                                </Button>
                                <Typography
                                  component="a"
                                  href={elem.locationUrl}
                                  target="_blank"
                                  sx={{
                                    color: "primary.light",
                                    textDecoration: "none",
                                  }}
                                >
                                  {elem.address}
                                </Typography>
                              </Stack> */}
                              {elem.email != "" ? (
                                <Stack
                                  className="contact-info-list"
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    className="contact-info-icons"
                                    component="a"
                                    href={"mailto:" + elem.email}
                                  >
                                    <MailIcon />
                                  </Button>
                                  <Typography
                                    component="a"
                                    href={"mailto:" + elem.email}
                                    sx={{
                                      color: "primary.light",
                                      textDecoration: "none",
                                    }}
                                  >
                                    {elem.email}
                                  </Typography>
                                </Stack>
                              ) : null}
                              {elem.phoneNo != "" ? (
                                <Stack
                                  className="contact-info-list"
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    className="contact-info-icons"
                                    component="a"
                                    href={"tel:" + elem.phonePrefix + '' + formatPhoneNumber(elem.phoneNo)}
                                  >
                                    <PhoneIcon />
                                  </Button>
                                  <Typography
                                    component="a"
                                    href={"tel:" + elem.phonePrefix + '' + formatPhoneNumber(elem.phoneNo)}
                                    sx={{
                                      color: "primary.light",
                                      textDecoration: "none",
                                    }}
                                  >
                                    {elem.phonePrefix + '' + formatPhoneNumber(elem.phoneNo)}
                                  </Typography>
                                </Stack>
                              ) : null}

                              {elem.workhrs_Content1 != "" ? (
                                <>
                                  <Box mt={1}>
                                    <Typography variant="subtitle2" mb={1}>
                                      OPERATIONAL HOURS
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      sx={{ marginBottom: "3px" }}
                                    >
                                      {elem.workhrs_Content1}
                                    </Typography>
                                    {elem.workhrs_Content2 != "" ? (
                                      <>
                                        <Typography
                                          variant="body1"
                                          sx={{ marginBottom: "3px" }}
                                        >
                                          {elem.workhrs_Content2}
                                        </Typography>
                                      </>
                                    ) : null}
                                    {elem.workhrs_Content3 != "" ? (
                                      <>
                                        <Typography
                                          variant="body1"
                                          sx={{ marginBottom: "3px" }}
                                        >
                                          {elem.workhrs_Content3}
                                        </Typography>
                                      </>
                                    ) : null}
                                    {elem.workhrs_Content4 != "" ? (
                                      <>
                                        <Typography
                                          variant="body1"
                                          sx={{ marginBottom: "3px" }}
                                        >
                                          {elem.workhrs_Content4}
                                        </Typography>
                                      </>
                                    ) : null}
                                    {elem.workhrs_Content5 != "" ? (
                                      <>
                                        <Typography
                                          variant="body1"
                                          sx={{ marginBottom: "3px" }}
                                        >
                                          {elem.workhrs_Content5}
                                        </Typography>
                                      </>
                                    ) : null}
                                    {elem.workhrs_Content6 != "" ? (
                                      <>
                                        <Typography
                                          variant="body1"
                                          sx={{ marginBottom: "3px" }}
                                        >
                                          {elem.workhrs_Content6}
                                        </Typography>
                                      </>
                                    ) : null}
                                    {elem.workhrs_Content7 != "" ? (
                                      <>
                                        <Typography
                                          variant="body1"
                                          sx={{ marginBottom: "3px" }}
                                        >
                                          {elem.workhrs_Content7}
                                        </Typography>
                                      </>
                                    ) : null}
                                  </Box>
                                </>
                              ) : null}
                            </div>
                          ))}
                      </Grid>
                    </>
                  ) : null}

                  <Grid item xs={12} className="other-location-mobile">
                    {MainContactMap_new ? (
                      <>
                        <div className="contact-info">
                          <div className="product-right-border">
                            <Typography variant="h4" sx={{ pb: 2 }}>
                              LOCATION
                              <Divider
                                sx={{ bgcolor: "primary.main" }}
                                className="title-divider"
                              />
                            </Typography>
                          </div>
                          <Grid container spacing={2}>
                            {data3 &&
                              data3.map((elem, index) => (
                                <React.Fragment key={index}>
                                  <Grid item xs={12} sm={6} md={MainContact_md}>
                                    <Typography variant="h5" sx={{ mb: 2 }}>
                                      {elem.warehouseName}
                                    </Typography>
                                    <Stack
                                      className="contact-info-list"
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        className="contact-info-icons"
                                        href={elem.locationURL}
                                        target="_blank"
                                      >
                                        <PlaceIcon />
                                      </Button>
                                      <Typography
                                        component="a"
                                        href={elem.locationURL}
                                        target="_blank"
                                        sx={{
                                          color: "primary.light",
                                          textDecoration: "none",
                                        }}
                                      >
                                        {elem.address}
                                      </Typography>
                                    </Stack>
                                    {elem.email != "" ? (
                                      <Stack
                                        className="contact-info-list"
                                        direction="row"
                                        alignItems="center"
                                        spacing={2}
                                      >
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="contact-info-icons"
                                          component="a"
                                          href={"mailto:" + elem.email}
                                        >
                                          <MailIcon />
                                        </Button>
                                        <Typography
                                          component="a"
                                          href={"mailto:" + elem.email}
                                          sx={{
                                            color: "primary.light",
                                            textDecoration: "none",
                                          }}
                                        >
                                          {elem.email}
                                        </Typography>
                                      </Stack>
                                    ) : null}
                                    {elem.warehousePhone != "" ? (
                                      <Stack
                                        className="contact-info-list"
                                        direction="row"
                                        alignItems="center"
                                        spacing={2}
                                      >
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="contact-info-icons"
                                          component="a"
                                          href={"tel:" + elem.phonePrefix + '' + formatPhoneNumber(elem.warehousePhone)}
                                        >
                                          <PhoneIcon />
                                        </Button>
                                        <Typography
                                          component="a"
                                          href={"tel:" + elem.phonePrefix + '' + formatPhoneNumber(elem.warehousePhone)}
                                          sx={{
                                            color: "primary.light",
                                            textDecoration: "none",
                                          }}
                                        >
                                          {elem.phonePrefix + '' + formatPhoneNumber(
                                            elem.warehousePhone
                                          )}
                                        </Typography>
                                      </Stack>
                                    ) : null}
                                  </Grid>
                                </React.Fragment>
                              ))}
                          </Grid>
                        </div>
                      </>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12} md={7}>
                    {DropALine ? (
                      <>
                        <div className="product-right-border">
                          <Typography variant="h4" sx={{ pb: 2 }}>
                            SEND US A NOTE
                            <Divider
                              sx={{ bgcolor: "primary.main" }}
                              className="title-divider"
                            />
                          </Typography>
                        </div>
                        <div>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                            sx={{ mb: 2 }}
                            className="contact-mobile-stack"
                          >
                            <Grid item xs={12} sm={12} md={6}>
                              <TextField
                                fullWidth
                                placeholder="Name"
                                variant="standard"
                                label="Name"
                                value={nameVal}
                                onChange={(event) => setValue_Name(event)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                              <TextField
                                fullWidth
                                placeholder="Email"
                                variant="standard"
                                label="Email"
                                value={emailVal}
                                onChange={(event) => setValue_Email(event)}
                              />
                            </Grid>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={3}
                            sx={{ mb: 2 }}
                          >
                            <TextField
                              fullWidth
                              placeholder="Subject"
                              variant="standard"
                              label="Subject"
                              value={subjectVal}
                              onChange={(event) => setValue_Subject(event)}
                            />
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={3}
                            sx={{ mb: 2 }}
                            className="contact-your-msg"
                          >
                            <TextField
                              fullWidth
                              placeholder="Your Message"
                              multiline
                              variant="standard"
                              label="Your Message"
                              rows={3}
                              value={msgVal}
                              onChange={(event) => setValue_Msg(event)}
                            />
                          </Stack>
                          <Button
                            variant="contained"
                            sx={{ float: "right" }}
                            onClick={() => Sendmessage()}
                          >
                            Send Message
                          </Button>
                        </div>
                      </>
                    ) : null}
                  </Grid>
                  {MainContactMap ? (
                    <select onChange={updateValue}>
                      {data3.map((item) => (
                        <option
                          key={item.warehouseId}
                          value={
                            String(item.latitude) +
                            "###" +
                            String(item.longitude) +
                            "###" +
                            String(item.warehouseAddress1) +
                            "###" +
                            String(item.warehouseAddress2) +
                            "###" +
                            String(item.cityName) +
                            "###" +
                            String(item.stateCode) +
                            "###" +
                            String(item.countryName)
                          }
                        >
                          {item.warehouseAddress1 +
                            " " +
                            item.warehouseAddress2 +
                            " " +
                            item.cityName +
                            " " +
                            item.countyName +
                            " " +
                            item.stateCode +
                            " " +
                            item.zipCode +
                            " " +
                            item.countryName}
                        </option>
                      ))}
                    </select>
                  ) : null}
                  <Grid item md={12}>
                    {MainContactMap ? (
                      <>
                        {MainContactMap && latVal !== 0 ? (
                          <div style={{ height: "400px", width: "100%" }}>
                            {latVal !== 0 && langVal !== 0 ? (
                              <GMap
                                lat={latVal}
                                lng={langVal}
                                addr={whAddress}
                                stcty={whStateCityCountry}
                                phno={whPhoneNo}
                              />
                            ) : null}
                          </div>
                        ) : (
                          <div style={{ height: "400px", width: "100%" }}>
                            {lat !== 0 && lang !== 0 ? (
                              <GMap
                                lat={lat}
                                lng={lang}
                                addr={wh_address}
                                stcty={wh_statecitycountry}
                                phno={wh_phoneno}
                              />
                            ) : null}
                          </div>
                        )}
                      </>
                    ) : null}
                  </Grid>
                </Grid>
              </Container>

              {data[0]?.warehouseContact == true ? (
                <>
                  {/* Single Location  start */}
                  {totalRows == "" || totalRows == "1" ? (
                    <Container>
                      <div className="other-location-desktop">
                        {MainContactMap_new ? (
                          <>
                            <div className="contact-info">
                              <div className="product-right-border">
                                <Typography variant="h4" sx={{ pb: 2 }}>
                                  LOCATION
                                  <Divider
                                    sx={{ bgcolor: "primary.main" }}
                                    className="title-divider"
                                  />
                                </Typography>
                              </div>
                              <Grid container spacing={2}>
                                {data3 &&
                                  data3.map((elem, index) => (
                                    <React.Fragment key={index}>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={4}
                                        md={4}
                                        key={index}
                                      >
                                        <Box className="location-image-sec">
                                          <img src={elem.imgURL} alt="" />
                                        </Box>
                                      </Grid>
                                      <Grid item xs={12} sm={4} md={4}>
                                        <Typography variant="h5" sx={{ mb: 2 }}>
                                          {elem.warehouseName}
                                        </Typography>
                                        <Stack
                                          className="contact-info-list"
                                          direction="row"
                                          alignItems="center"
                                          spacing={2}
                                        >
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            className="contact-info-icons"
                                            href={elem.locationURL}
                                            target="_blank"
                                          >
                                            <PlaceIcon />
                                          </Button>
                                          <Typography
                                            component="a"
                                            href={elem.locationURL}
                                            target="_blank"
                                            sx={{
                                              color: "primary.light",
                                              textDecoration: "none",
                                            }}
                                          >
                                            {elem.address}
                                          </Typography>
                                        </Stack>
                                        {elem.email != "" ? (
                                          <Stack
                                            className="contact-info-list"
                                            direction="row"
                                            alignItems="center"
                                            spacing={2}
                                          >
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="contact-info-icons"
                                              component="a"
                                              href={"mailto:" + elem.email}
                                            >
                                              <MailIcon />
                                            </Button>
                                            <Typography
                                              component="a"
                                              href={"mailto:" + elem.email}
                                              sx={{
                                                color: "primary.light",
                                                textDecoration: "none",
                                              }}
                                            >
                                              {elem.email}
                                            </Typography>
                                          </Stack>
                                        ) : null}
                                        {elem.warehousePhone != "" ? (
                                        <Stack
                                          className="contact-info-list"
                                          direction="row"
                                          alignItems="center"
                                          spacing={2}
                                        >
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            className="contact-info-icons"
                                            component="a"
                                            href={"tel:" + elem.phonePrefix + '' + formatPhoneNumber(elem.warehousePhone)}
                                          >
                                            <PhoneIcon />
                                          </Button>
                                          <Typography
                                            component="a"
                                            href={"tel:" + elem.phonePrefix + '' + formatPhoneNumber(elem.warehousePhone)}
                                            sx={{
                                              color: "primary.light",
                                              textDecoration: "none",
                                            }}
                                          >
                                            {elem.phonePrefix + '' + formatPhoneNumber(
                                              elem.warehousePhone
                                            )}
                                          </Typography>
                                        </Stack>
                                        ) : null}
                                      </Grid>
                                      {elem.workhrs_Content1 != "" ? (
                                        <Grid item xs={12} sm={4} md={4}>
                                          <Box mt={1}>
                                            <Typography
                                              variant="subtitle2"
                                              mb={1}
                                            >
                                              OPERATIONAL HOURS
                                            </Typography>
                                            <Typography
                                              variant="body1"
                                              sx={{ marginBottom: "3px" }}
                                            >
                                              {elem.workhrs_Content1}
                                            </Typography>
                                            {elem.workhrs_Content2 != "" ? (
                                              <>
                                                <Typography
                                                  variant="body1"
                                                  sx={{ marginBottom: "3px" }}
                                                >
                                                  {elem.workhrs_Content2}
                                                </Typography>
                                              </>
                                            ) : null}
                                            {elem.workhrs_Content3 != "" ? (
                                              <>
                                                <Typography
                                                  variant="body1"
                                                  sx={{ marginBottom: "3px" }}
                                                >
                                                  {elem.workhrs_Content3}
                                                </Typography>
                                              </>
                                            ) : null}
                                            {elem.workhrs_Content4 != "" ? (
                                              <>
                                                <Typography
                                                  variant="body1"
                                                  sx={{ marginBottom: "3px" }}
                                                >
                                                  {elem.workhrs_Content4}
                                                </Typography>
                                              </>
                                            ) : null}
                                            {elem.workhrs_Content5 != "" ? (
                                              <>
                                                <Typography
                                                  variant="body1"
                                                  sx={{ marginBottom: "3px" }}
                                                >
                                                  {elem.workhrs_Content5}
                                                </Typography>
                                              </>
                                            ) : null}
                                            {elem.workhrs_Content6 != "" ? (
                                              <>
                                                <Typography
                                                  variant="body1"
                                                  sx={{ marginBottom: "3px" }}
                                                >
                                                  {elem.workhrs_Content6}
                                                </Typography>
                                              </>
                                            ) : null}
                                            {elem.workhrs_Content7 != "" ? (
                                              <>
                                                <Typography
                                                  variant="body1"
                                                  sx={{ marginBottom: "3px" }}
                                                >
                                                  {elem.workhrs_Content7}
                                                </Typography>
                                              </>
                                            ) : null}
                                          </Box>
                                        </Grid>
                                      ) : null}
                                    </React.Fragment>
                                  ))}
                              </Grid>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </Container>
                  ) : null}

                  {totalRows != "" && totalRows != "1" ? (
                    <Container>
                      <div className="other-location-desktop">
                        {MainContactMap_new ? (
                          <div className="contact-info">
                            <div className="product-right-border">
                              <Typography variant="h4" sx={{ pb: 2 }}>
                                LOCATION
                                <Divider
                                  sx={{ bgcolor: "primary.main" }}
                                  className="title-divider"
                                />
                              </Typography>
                            </div>
                            <Grid container spacing={2}>
                              {data3 &&
                                data3.map((elem, index) => (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={4}
                                      md={MainContact_md}
                                      key={index}
                                    >
                                      <Box
                                        className="location-image-sec"
                                        mb={1}
                                      >
                                        <img src={elem.imgURL} alt="" />
                                      </Box>
                                      <Typography variant="h5" sx={{ mb: 2 }}>
                                        {elem.warehouseName}
                                      </Typography>
                                      <Stack
                                        className="contact-info-list"
                                        direction="row"
                                        alignItems="center"
                                        spacing={2}
                                      >
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="contact-info-icons"
                                          href={elem.locationURL}
                                          target="_blank"
                                        >
                                          <PlaceIcon />
                                        </Button>
                                        <Typography
                                          component="a"
                                          href={elem.locationURL}
                                          target="_blank"
                                          sx={{
                                            color: "primary.light",
                                            textDecoration: "none",
                                          }}
                                        >
                                          {elem.address}
                                        </Typography>
                                      </Stack>
                                      {elem.email != "" ? (
                                        <Stack
                                          className="contact-info-list"
                                          direction="row"
                                          alignItems="center"
                                          spacing={2}
                                        >
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            className="contact-info-icons"
                                            component="a"
                                            href={"mailto:" + elem.email}
                                          >
                                            <MailIcon />
                                          </Button>
                                          <Typography
                                            component="a"
                                            href={"mailto:" + elem.email}
                                            sx={{
                                              color: "primary.light",
                                              textDecoration: "none",
                                            }}
                                          >
                                            {elem.email}
                                          </Typography>
                                        </Stack>
                                      ) : null}
                                      {elem.warehousePhone != "" ? (
                                      <Stack
                                        className="contact-info-list"
                                        direction="row"
                                        alignItems="center"
                                        spacing={2}
                                      >
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="contact-info-icons"
                                          component="a"
                                          href={"tel:" + elem.phonePrefix + '' + formatPhoneNumber(elem.warehousePhone)}
                                        >
                                          <PhoneIcon />
                                        </Button>
                                        <Typography
                                          component="a"
                                          href={"tel:" + elem.phonePrefix + '' + formatPhoneNumber(elem.warehousePhone)}
                                          sx={{
                                            color: "primary.light",
                                            textDecoration: "none",
                                          }}
                                        >
                                          {elem.phonePrefix + '' + formatPhoneNumber(
                                            elem.warehousePhone
                                          )}
                                        </Typography>
                                      </Stack>
                                      ):null}
                                      {elem.workhrs_Content1 != "" ? (
                                        <>
                                          <Box mt={1}>
                                            <Typography
                                              variant="subtitle2"
                                              mb={1}
                                            >
                                              OPERATIONAL HOURS
                                            </Typography>
                                            <Typography
                                              variant="body1"
                                              sx={{ marginBottom: "3px" }}
                                            >
                                              {elem.workhrs_Content1}
                                            </Typography>
                                            {elem.workhrs_Content2 != "" ? (
                                              <>
                                                <Typography
                                                  variant="body1"
                                                  sx={{ marginBottom: "3px" }}
                                                >
                                                  {elem.workhrs_Content2}
                                                </Typography>
                                              </>
                                            ) : null}
                                            {elem.workhrs_Content3 != "" ? (
                                              <>
                                                <Typography
                                                  variant="body1"
                                                  sx={{ marginBottom: "3px" }}
                                                >
                                                  {elem.workhrs_Content3}
                                                </Typography>
                                              </>
                                            ) : null}
                                            {elem.workhrs_Content4 != "" ? (
                                              <>
                                                <Typography
                                                  variant="body1"
                                                  sx={{ marginBottom: "3px" }}
                                                >
                                                  {elem.workhrs_Content4}
                                                </Typography>
                                              </>
                                            ) : null}
                                            {elem.workhrs_Content5 != "" ? (
                                              <>
                                                <Typography
                                                  variant="body1"
                                                  sx={{ marginBottom: "3px" }}
                                                >
                                                  {elem.workhrs_Content5}
                                                </Typography>
                                              </>
                                            ) : null}
                                            {elem.workhrs_Content6 != "" ? (
                                              <>
                                                <Typography
                                                  variant="body1"
                                                  sx={{ marginBottom: "3px" }}
                                                >
                                                  {elem.workhrs_Content6}
                                                </Typography>
                                              </>
                                            ) : null}
                                            {elem.workhrs_Content7 != "" ? (
                                              <>
                                                <Typography
                                                  variant="body1"
                                                  sx={{ marginBottom: "3px" }}
                                                >
                                                  {elem.workhrs_Content7}
                                                </Typography>
                                              </>
                                            ) : null}
                                          </Box>
                                        </>
                                      ) : null}
                                    </Grid>
                                  </>
                                ))}
                            </Grid>
                          </div>
                        ) : null}
                      </div>
                    </Container>
                  ) : null}
                </>
              ) : (
                <></>
              )}
            </Paper>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
