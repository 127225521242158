import { Box, Container, Menu, Paper, Button, Typography, MenuItem, Toolbar,useMediaQuery, Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import InputIcon from "@mui/icons-material/Input";
import phone from "../../Images/phone-receiver-white.png";
import phoneblock from "../../Images/phone-receiver.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { CartCount } from "../Cart/CartCount";
import './header.style.css';
import {
  IMicrositeProps,
  IgetProductDetails,
  IcustomMenu,
} from "../../Types/MicrositeType";
import logo from "../../Images/centralturbos/central-turbo-white.png"
import logoblock from "../../Images/centralturbos/central-turbo-block.png"

import { useSelector } from "react-redux";
import PartSearchExtender from "./PartSearchExtender";
import { Get_Data } from "../../API/GetDataFromApi";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Fade from "@mui/material/Fade";
import formatPhoneNumber, { clearLocalstorageValues } from "../../API/GetMicrositeSettings";
import { CentralTurbosLeftMenu } from "./CentralTurbosLeftMenu";

const CustomMenu = (props: {
  _Dealerlocation: any;
  _menuName: string;
  _subMenuArray: any;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        component="a"
        className="linkstyle"
        sx={{
          color: "primary.light",
          ":hover": {
            color: "primary.main",
          },
        }}
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onMouseOver={handleClick}
      >
        {props._menuName}
      </Button>

      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <Box>
          {props._subMenuArray.map((res: any, index: number) => {
            const string = `/CustomPage/${res.customSubMenuId}?`;
            return (
              <Box key={index} className="custom-menu-box">
                <Button
                  to={string}
                  component={Link}
                  onClick={handleClose}
                  className="custom-dropdown-menu"
                  sx={{
                    color: "primary.light",
                    ":hover": {
                      color: "primary.main",
                    },
                  }}
                >
                  {res.customSubMenuName}{" "}
                </Button>
              </Box>
            );
          })}
        </Box>

        {/* {props._subMenuArray.map((type: any) => (
          str_url = `/Products/${type.customSubMenuId}?`
          return(
            <Grid item md={3}>
          <Button
            to={str_url}
            component={Link}
            onClick={handleClose}
            className="menumega-list"
          >
            {type.customSubMenuName}{" "}
          </Button>
          </Grid>
          );
          //<MenuItem onClick={handleClose}>{type.customSubMenuName}</MenuItem>
        ))} */}
      </Menu>
    </div>
  );
};


export default function CentralTurbosHeader(props: { _settings: IMicrositeProps, _contact?: string }) {

  const [menuData, setMenudata] = useState<IcustomMenu[]>([]);
  const [productInfo, setproductInfo] = useState<IgetProductDetails[]>([]);
  const [anchorpopupEl, setanchorpopupEl] = useState<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorHeaderMenuEl, setanchorHeaderMenuEl] = useState<null | HTMLElement>(null);


  const openHeaderMenu = Boolean(anchorHeaderMenuEl);

  const open = Boolean(anchorEl);

  let tollfreenumber: string;
  let showtollfree: boolean = true;
  let showfreeshopping: boolean = true;
  let freeshippingvalue: string = "";
  

  tollfreenumber =
  props._settings.phonePrefix +
  "" +
  formatPhoneNumber(props._settings.tollFreeNumber);
showtollfree = props._settings.tollFree;
showfreeshopping = props._settings.freeshipping;
freeshippingvalue = props._settings.freeshippingValue;

 
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const productmenuClickpopup = (event: React.MouseEvent<HTMLElement>) => {
    setanchorpopupEl(event.currentTarget);
  };
  const openpopup = Boolean(anchorpopupEl);
  const productmenuClosepopup = () => {
    setanchorpopupEl(null);
  };
  
  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);



  const headerDropdownMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setanchorHeaderMenuEl(event.currentTarget);
  };
  const headerDropdownMenuClose = () => {
    setanchorHeaderMenuEl(null);
  };

  useEffect(()=>{
    (async () => {
      Get_Data(EnvData?.REACT_APP_APIURL + "/microsite-api-v1/get-top-products")
        .then((results) => {
          setproductInfo(results.data);
        })
        .catch((error) => {
          console.log("Error in " + error);
        });
    })();
  },[])

  let showCart: boolean = true;
  let _cartCount: number = 0;
  let _logo: string = "";
  let _showSearch: boolean = true;
  let _showAboutus: boolean = true;
  let _showContactus: boolean = true;
  let _showtestmonial: boolean = true;
  let _showProduct: boolean = true;
  let _showServices: boolean = true;
  let _dealerlocation: string = "";
  let _showTechBulletins: boolean = true;

  const catalogueWithPricing = props._settings.catalogueWithPricing;
  showCart = props._settings.shoppingcart;
  _cartCount = props._settings.cartCount;
  _logo = props._settings.logo;
  _showSearch = props._settings.partsSearchMenu;
  _showAboutus = props._settings.aboutUs;
  _showtestmonial = props._settings.testimonial;
  _showProduct = props._settings.topProducts;
  _showServices = props._settings.services;
  _dealerlocation = props._settings.dealerLocation;
  _showContactus = props._settings.showinFooter;
  _showTechBulletins = props._settings.showTechBulletins;

  let totalMenuCount = 7;
  let MaximumLength = 105;

  if (props._settings.topProducts) {
    totalMenuCount += 11;
  }
  if (props._settings.services) {
    totalMenuCount += 11;
  }
  if (props._settings.showinFooter) {
    totalMenuCount += 13;
  }
  if (props._settings.showTechBulletins) {
    totalMenuCount += 17;
  }
  if (props._settings.dealerLocation) {
    totalMenuCount += 18;
  }

  if (props._settings.partsSearchMenu) {
    totalMenuCount += 14;
  }
  if (props._settings.testimonial) {
    totalMenuCount += 15;
  }
  if (props._settings.aboutUs) {
    totalMenuCount += 11;
  }
  if (EnvData?.REACT_APP_STATICPAGE == "Yes") {
    totalMenuCount += 20;
  }

  const isScreenBelow900 = useMediaQuery("(max-width: 950px)");

  const navigate = useNavigate();
  function NavHome() {
    navigate("/");
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }

  const CustomerId =
    localStorage.getItem("CustomerId") === undefined ||
    localStorage.getItem("CustomerId") === null ||
    localStorage.getItem("CustomerId") === ""
      ? "0"
      : localStorage.getItem("CustomerId");

  const logout = () => {
    setAnchorEl(null);
    localStorage.removeItem("userName");
    localStorage.removeItem("authToken");
    clearLocalstorageValues();
    navigate("/");
    window.location.reload();
  };

  const handleButtonClick = () => {
    // Redirect to the 'About' component when the button is clicked
    navigate("/Login");
  };

  const location = useLocation();
  const isRootPath = location.pathname === '/';
  const ProductPath = location.pathname.includes('/Products');
  const PartsearchPath = location.pathname =='/PartSearch';
  const AboutUsPath = location.pathname =='/AboutUs';
  const ContactPath = location.pathname =='/ContactUs';
  const ReqCatPath = location.pathname =='/RequestCatalog';
  const FeaturePath = location.pathname =='/FeaturesBenefits';
  const Resources = location.pathname.includes('/Warranties');
  //const Resources = location.pathname.includes('/Brochures');


  return(
    <div>
      <Box  className={isRootPath == true ? "central-turbos-header" : "central-turbos-bg-header"}>
        <Container maxWidth="lg">
          <Paper elevation={0} className="top-bar-sec">
           
            <div className="top-bar-right-sec">
              <div className="header-search-bar">
                  <PartSearchExtender _fromPage={""} />
                </div>
                {showtollfree == true ? (
              <Typography variant="body2" sx={{ ml: 3 }}>
               {isRootPath == true ? (
                  <>
                  <img src={phone}  alt="phone" loading="lazy" style={{ width: "20px" }} />
                  </> ) : <>
                  <img src={phoneblock} alt="phone" loading="lazy"  style={{ width: "20px" }}/>
                  </>}
              </Typography>
            ) : null}
                 {showtollfree == true ? (
              <Button
                href="tel:1(203)408-1666" className="central-turbos-header-phone"
              >
                {tollfreenumber}
              </Button>
            ) : null}
              
              {props._settings.loginOption === "InternalCadenceLogin" ? (
              <Button
                href={EnvData?.REACT_APP_CADENCELOGINURL}
                variant="text"
                className="central-turbos-header-login"
                startIcon={<InputIcon sx={{ fontSize: "10px" }} />}
              >
                Login
              </Button>
            ) : (props._settings.loginOption === "B2CShoppingCartLogin" ||
                (props._settings.loginOption === "CatalogSite" &&
                  props._settings.catalogueWithPricing === true)) &&
              localStorage.getItem("userName") ? (
              <>
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  className="central-turbos-header-login"
                  startIcon={<AccountCircleIcon sx={{ fontSize: "10px" }} />}
                >
                  {localStorage.getItem("userName")}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={logout}>Logout</MenuItem>
                  <MenuItem onClick={() => navigate("/OrderHistory")}>
                    Order History
                  </MenuItem>
                </Menu>
              </>
            ) : props._settings.loginOption == "B2CShoppingCartLogin" ||
              (props._settings.loginOption === "CatalogSite" &&
                props._settings.catalogueWithPricing === true) ? (
              <Button
                onClick={handleButtonClick}
                variant="text"
                className="central-turbos-header-login"
                startIcon={<InputIcon sx={{ fontSize: "10px" }} />}
              >
                Login
              </Button>
            ) : null}

              
                {/* <Button
                  href=""
                  variant="text"
                  sx={{ color: "primary.contrastText" }}
                  startIcon={<InputIcon sx={{ fontSize: "10px" }} />}
                >
                  Login
                </Button>
            
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    startIcon={<AccountCircleIcon sx={{ fontSize: "10px" }} />}
                  >
                    Admin
                  </Button> */}
                  {showCart === true ||
            (CustomerId !== "0" && catalogueWithPricing === true) ? (  <CartCount _cartcount={_cartCount} />   ) : null}

                 
                  {/* <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem >Logout</MenuItem>
                    <MenuItem >
                      Order History
                    </MenuItem>
                  </Menu> */}
            
            </div>
          </Paper>
          <Toolbar className="main-menu-toolbar">
              <a className="header_logo_centralturbos"  onClick={NavHome}>
              {isRootPath == true ? (
                  <>
                 <img src={logo} alt="logo" />
                  </> ) : <>
                  <img src={logoblock} alt="logo" />
                  </>}
                
              </a>
             
              <Box className="main-menu-right">
                <div className="centralturbos-mobile-hamber-menu">
                  <CentralTurbosLeftMenu _settings={props._settings} _custommenu={menuData} />
                </div>
                <Box className="nav-main-menu">
                  <Button
                    to="/"
                    component={Link}
                    className={isRootPath==true?"linkstyle active":"linkstyle"}
                   
                  >
                    Home
                  </Button>
                  {EnvData?.REACT_APP_STATICPAGE == "Yes" ? (
                    <Button
                      to="/FeaturesBenefits"
                      component={Link}
                      className={FeaturePath==true?"linkstyle active":"linkstyle"}
                      
                    >
                      Features-Benefits
                    </Button>
                  ) : null}
                  {_showProduct === true ? (
                    <div>
                      <Button
                        id="menumega-button"
                        className={ProductPath==true?"linkstyle active":"linkstyle"}
                        
                        aria-controls={open ? "menumega" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={productmenuClickpopup}
                      >
                        Products
                      </Button>
                      <Menu
                        id="menumega"
                        open={openpopup}
                        anchorEl={anchorpopupEl}
                        onClose={productmenuClosepopup}
                        MenuListProps={{
                          "aria-labelledby": "menumega-button",
                        }}
                        className="menumega"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Box
                          display="flex"
                          sx={{ padding: "10px" }}
                          className="menumega-box"
                        >
                          <Grid container spacing={2}>
                            {productInfo.map((res, index) => {
                              const productUrl = `/Products/${res.productName}?`;
                              return (
                                <Grid item md={3} key={index}>
                                  <Button
                                    to={productUrl}
                                    component={Link}
                                    onClick={productmenuClosepopup}
                                    className="menumega-list"
                                  >
                                    {res.productName}{" "}
                                  </Button>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Box>
                      </Menu>
                    </div>
                  ) : null} 


                  {_showSearch == true ? (
                    <Button
                      to="/PartSearch"
                      component={Link}
                      className={PartsearchPath==true?"linkstyle active":"linkstyle"}
                     
                    >
                      Part Search
                    </Button>
                  ) : null}

                  {_showAboutus == true ? (
                    <Button
                      to="/AboutUs"
                      className={AboutUsPath==true?"linkstyle active":"linkstyle"}
                      component={Link}
                     
                    >
                      About
                    </Button>
                  ) : null}
                

                  {_showContactus !== true ? (
                    <Button
                      to="/ContactUs"
                      component={Link}
                      className={ContactPath==true?"linkstyle active":"linkstyle"}
                     
                    >
                      Contact Us
                    </Button>
                  ) : null}

                    {EnvData?.REACT_APP_SHOWCATALOG == "Yes" ? (
                      <Button
                      to={"/RequestCatalog"}
                      component={Link}
                      className={ReqCatPath==true?"linkstyle active":"linkstyle"}
                     
                    >
                      Request Catalog
                    </Button>
                    ) : null}


                  <Button
                          id="dropdown-button"
                          aria-controls={open ? 'dropdown-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onMouseOver={headerDropdownMenuClick}
                          className={Resources==true?"linkstyle active":"linkstyle"}
                        >
                          Resources
                        </Button>
                        <Menu
                          id="dropdown-menu"
                          anchorEl={anchorHeaderMenuEl}
                          open={openHeaderMenu}
                          onClose={headerDropdownMenuClose}
                          //onMouseLeave={headerDropdownMenuClose}
                          TransitionComponent={Fade}
                          MenuListProps={{
                            'aria-labelledby': 'dropdown-button',
                          }}
                          className="central-turbo-dropdown-menu"
                        >
                          <Button to="/Warranties"
                              component={Link}
                              
                            onClick={headerDropdownMenuClose}>Forms + Warranties</Button>
                            {_showTechBulletins === true ? (
                          <Button to="/Brochures"
                          
                              component={Link} onClick={headerDropdownMenuClose}>Brochures</Button>
                            ):null }
                          
                        </Menu>

                  {isScreenBelow900 == false
                    ? menuData.map((elem, index) => {
                        totalMenuCount += elem.customMenuName.length;

                        if (totalMenuCount > 110) {
                          return null;
                        } else {
                          return (
                            <div key={index}>
                              <CustomMenu
                                _Dealerlocation={_dealerlocation}
                                _menuName={elem.customMenuName}
                                _subMenuArray={elem.subMenus}
                              />
                            </div>
                          );
                        }
                      })
                    : null}
                </Box>
                <Button variant="outlined" sx={{ ml:2}} className="central-turbos-button-otlined" to="/PartSearch"
                              component={Link}>Shop Now</Button>
              </Box>
          </Toolbar>
        </Container>
      </Box>
    </div>
  )

}