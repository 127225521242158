import * as React from "react";
import {
  Box,
  Button,
  Typography,
  Paper,
  Divider,
  Container,
  Menu,
  MenuItem,
} from "@mui/material";
import InventoryIcon from "@mui/icons-material/Inventory";
import CampaignIcon from "@mui/icons-material/Campaign";
import { IMicrositeProps } from "../../Types/MicrositeType";
import phone from "../../Images/phone-receiver.png";
import formatPhoneNumber, {
  clearLocalstorageValues,
} from "../../API/GetMicrositeSettings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import InputIcon from "@mui/icons-material/Input";
import { useNavigate } from "react-router";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useSelector } from "react-redux";
import LanguageSwitcher from "../../Translate/Googletranslate";

export const TopHeader = (props: { _settings: IMicrositeProps }) => {
  //console.log(JSON.stringify(props._settings));
  let tollfreenumber: string;
  let showtollfree: boolean = true;
  let showfreeshopping: boolean = true;
  let freeshippingvalue: string = "";

  tollfreenumber =
    props._settings.phonePrefix +
    "" +
    formatPhoneNumber(props._settings.tollFreeNumber);
  showtollfree = props._settings.tollFree;
  showfreeshopping = props._settings.freeshipping;
  freeshippingvalue = props._settings.freeshippingValue;

  const navigate = useNavigate();

  const handleButtonClick = () => {
    // Redirect to the 'About' component when the button is clicked
    navigate("/Login");
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const logout = () => {
    setAnchorEl(null);
    localStorage.removeItem("userName");
    localStorage.removeItem("authToken");
    clearLocalstorageValues();
    navigate("/");
    window.location.reload();
  };

  //console.log(EnvData, "EnvData?.REACT_APP_SHOWMULTILANGUAGE")

  return (
    <Box>
      <Divider sx={{ bgcolor: "primary.main", padding: "2px" }} />
      <Container maxWidth="lg">
        <Paper elevation={0} className="top-bar-sec">
          {showfreeshopping == true ? (
            <Typography
              align="left"
              color="primary"
              variant="body2"
              className="freeshippingvalue"
              sx={{ fontWeight: 600 }}
            >
              <CampaignIcon sx={{ marginRight: "5px" }} /> {freeshippingvalue}
            </Typography>
          ) : null}
          <div className="top-bar-right-sec">
            {showtollfree == true ? (
              <Typography variant="body2" sx={{ ml: 3 }}>
                <img src={phone} alt="phone" style={{ width: "18px" }} />
              </Typography>
            ) : null}
            {showtollfree == true ? (
              <Button
                href="tel:1(203)408-1666"
                sx={{ fontSize: "18px", color: "#000", padding: "2px 8px" }}
              >
                {tollfreenumber}
              </Button>
            ) : null}

            {props._settings.loginOption === "InternalCadenceLogin" ? (
              <Button
                href={EnvData?.REACT_APP_CADENCELOGINURL}
                variant="text"
                sx={{ color: "primary.light" }}
                startIcon={<InputIcon sx={{ fontSize: "10px" }} />}
              >
                Login
              </Button>
            ) : (props._settings.loginOption === "B2CShoppingCartLogin" ||
                (props._settings.loginOption === "CatalogSite" &&
                  props._settings.catalogueWithPricing === true)) &&
              localStorage.getItem("userName") ? (
              <>
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  startIcon={<AccountCircleIcon sx={{ fontSize: "10px" }} />}
                >
                  {localStorage.getItem("userName")}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={logout}>Logout</MenuItem>
                  <MenuItem onClick={() => navigate("/OrderHistory")}>
                    Order History
                  </MenuItem>
                </Menu>
              </>
            ) : props._settings.loginOption == "B2CShoppingCartLogin" ||
              (props._settings.loginOption === "CatalogSite" &&
                props._settings.catalogueWithPricing === true) ? (
              <Button
                onClick={handleButtonClick}
                variant="text"
                sx={{ color: "primary.light" }}
                startIcon={<InputIcon sx={{ fontSize: "10px" }} />}
              >
                Login
              </Button>
            ) : null}
            {/* {(props._settings.loginOption == "B2CShoppingCartLogin" ||
              (props._settings.loginOption === "CatalogSite" &&
                props._settings.catalogueWithPricing === true)) &&
            localStorage.getItem("userName") ? (
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={logout}>Logout</MenuItem>
                <MenuItem onClick={() => navigate("/OrderHistory")}>
                  Order History
                </MenuItem>
              </Menu>
            ) : null} */}
            {/* <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleClose}>Logout</MenuItem>
          </Menu> */}

            {EnvData?.REACT_APP_SHOWMULTILANGUAGE == "YES" ? (
              <LanguageSwitcher />
            ) : (
              <></>
            )}
            {/* <LanguageSwitcher/> */}
          </div>
        </Paper>
      </Container>
    </Box>
  );
};
