import {
  Avatar,
  Typography,
  Container,
  Paper,
  Grid,
  Box,
  Divider,
} from "@mui/material";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

import { useTestimonialResults } from "../APIHooks/useGetData";
import { useEffect, useState } from "react";
import { GetData, Get_Data } from "../API/GetDataFromApi";
import Loader from "../Components/Loader";
import { useSelector } from "react-redux";

export default function Testimonial() {
  const defaultSettingsComapny: IgetDataTestimonial_Header = {
    micrositeSEOh1tag: "",
    micrositeSEOh2tag: "",
  };

  const [data, setDetailsItems] = useState<IgetDataTestimonial[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [data1, setHeaderItems] = useState<IgetDataTestimonial_Header>(
    defaultSettingsComapny
  );
  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);


  interface IgetDataTestimonial {
    comments: string;
    testimonialName: string;
    testimonialAlttext: string;
    testimonialImageFileName: string;
    companyName: string;
    imageUrl: string;
  }
  interface IgetDataTestimonial_Header {
    micrositeSEOh1tag: string;
    micrositeSEOh2tag: string;
  }

  var raw = JSON.stringify({
    action: "ms_TestimonialPage",
    parray: [
      "MicrositeName",
      EnvData?.REACT_APP_MICROSITENAME,
      "Userid",
      "1",
    ],
  });

  // const [data, data1] = useTestimonialResults<
  //   IgetDataTestimonial[],
  //   IgetDataTestimonial_Header[]
  // >(
  //   process.env.REACT_APP_APIURL + "/api/Microsite/getDatas_readOnly",
  //   requestOptions,
  //   [],
  //   []
  // );
  //console.log(JSON.stringify(data1))

  useEffect(() => {
    (async () => {
      setLoading(true);
      Get_Data(
        EnvData?.REACT_APP_APIURL +
          "/microsite-api-v1/get-testimonials-info",
        raw
      )
        .then((results?) => {
          // console.log(results, "reults for testimonial  ");
          setDetailsItems(results.data.testimonialsInfo);
          setHeaderItems(results.data.seoInfo);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          //   localStorage.removeItem("user");
        });
    })();
  }, []);
  return (
    <>
      {loading !== true ? (
        <>
          {" "}
          <Box sx={{ pt: 4, pb: 10 }}>
            <Container maxWidth={"lg"}>
              {data1 && (
                <div>
                  <Typography variant="h1" align="center">
                    {data1.micrositeSEOh1tag}
                  </Typography>
                  <Typography
                    variant="body1"
                    pb={3}
                    align="center"
                    sx={{ fontSize: "17px" }}
                  >
                    {data1.micrositeSEOh2tag}
                  </Typography>
                </div>
              )}
              <div className="product-right-border">
                <Typography variant="h4" pb={2} color="#000">
                  TESTIMONIALS
                  <Divider
                    sx={{ bgcolor: "primary.main" }}
                    className="title-divider"
                  />
                </Typography>
              </div>
              <div>
                <Grid container spacing={2}>
                  {data &&
                    data.map((elem, index) => (
                      <Grid item xs={12} md={6} key={index}>
                        <Paper
                          variant="outlined"
                          sx={{ mb: 2, height: "100%" }}
                        >
                          <Box style={{ height: "100%" }} sx={{ p: 4 }}>
                            <Box sx={{ display: "flex" }}>
                              <Typography component="div" color="primary">
                                <Avatar
                                  alt={elem.testimonialAlttext}
                                  sx={{
                                    margin: "0px 20px 0px 0",
                                    marginLeft: "auto",
                                    width: 56,
                                    height: 56,
                                  }}
                                  src={elem.imageUrl}
                                />
                              </Typography>
                              <div style={{ display: "inline-block" }}>
                                <Typography component="h6" color="primary">
                                  {elem.testimonialName}
                                </Typography>
                                <Typography
                                  component="span"
                                  color="custom"
                                  variant="body2"
                                >
                                  {elem.companyName}
                                </Typography>
                              </div>
                              <FormatQuoteIcon
                                sx={{ fontSize: 60, marginLeft: "auto" }}
                                color="primary"
                              />
                            </Box>
                            <Typography>{elem.comments}</Typography>
                          </Box>
                        </Paper>
                      </Grid>
                    ))}
                </Grid>
              </div>
            </Container>
          </Box>
        </>
      ) : (
        <>
          <Loader />
        </>
      )}
    </>
  );
}
