import {
  Typography,
  Container,
  Paper,
  Box,
  Divider,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useParams,
} from "react-router-dom";

import { ProductList } from "../Components/Product/ProductList";
import ProductGrid from "../Components/Product/ProductGrid";
import { useDispatch, useSelector } from "react-redux";
import { Get_Data } from "../API/GetDataFromApi";
import {
  IFeatureproductProps,
  IPartsearchCategoryProps,
  IPartsearchAttributeTypeProps,
  IPartsearchAttributeValueProps,
  IPartsearchApplicationValueProps,
  IProductProps,
} from "../Types/MicrositeType";
import { addprevPart, clearPrevPart } from "../Redux/Counterslice";

export const OurProducts = () => {
  const [featureparts, setFeatureparts] = useState<IFeatureproductProps[]>([]);

  const { partid } = useParams();
  const { flag } = useParams();
  const selectData = (state: any) => state.PART_DETAILS_INFO;
  const data = useSelector(selectData);

  // console.log(data.prevPart , "from View more pros")
  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  useEffect(() => {
    if (partid && flag) {
      const CustomerId =
        localStorage.getItem("CustomerId") === undefined ||
        localStorage.getItem("CustomerId") === null ||
        localStorage.getItem("CustomerId") === ""
          ? "0"
          : localStorage.getItem("CustomerId");
      let URL = `${
        EnvData?.REACT_APP_APIURL
      }/microsite-api-v1/get-product-viewmore?partId=${partid}&flag=${flag.replace(
        "AlternativeProducts",
        "Alternative"
      )}&customerId=${CustomerId}`;

      // microsite-api-v1/get-product-viewmore?partId=113105&flag=Accessories
      Get_Data(URL)
        .then((results) => {
          //   setMenudata(results.data);
          // console.log(results, "from ", flag);
          setFeatureparts(results.data);
        })
        .catch((error) => {
          console.log("Error in " + error);
        });
    }
  }, [partid || flag]);
  return (
    <div>
      <Paper elevation={0} sx={{ mt: 2, mb: 1, py: 3 }}>
        <Container>
          <Box sx={{ mb: 1 }}>
            <div className="product-right-border-featureParts">
              <Typography variant="h4" pb={2}>
                {flag == "AlternativeProducts" ? "Alternative  Products" : flag}
                <Divider
                  sx={{ bgcolor: "primary.main" }}
                  className="title-divider"
                />
              </Typography>

              <Button
                component={Link}
                to={data.prevPart}
                className="back-button"
                variant="outlined"
                color="primary"
                // onClick={()=> dispatch(clearPDInfo())}
              >
                Back to Main Page
              </Button>
            </div>
          </Box>
          {/* <ProductList count={0} _product={featureparts} /> */}
          {featureparts.length !== 0 ? (
            <ProductGrid _product={featureparts} />
          ) : (
            <></>
          )}
        </Container>
      </Paper>
    </div>
  );
};
