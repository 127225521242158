import {
  Typography,
  Paper,
  Avatar,
  Grid,
  Stack,
  Button,
  Divider,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Box,
  FormHelperText,
} from "@mui/material";
import React, { useRef } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import Radio from "@mui/material/Radio";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
//import Typography from "@mui/material/Typography";
//import Button from "@mui/material/Button";
//import { Box, Stack } from "@mui/system";
import Pay from "../../Images/pay.png";
import paypall from "../../Images/paypall.png";
import { useEffect, useState } from "react";
import { GetAuthHeaderToken, Get_Data } from "../../API/GetDataFromApi";
import { ICardType, ICardPointe, IsavedCard } from "../../Types/MicrositeType";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import axios, { AxiosRequestConfig } from "axios";
import CheckIcon from "@mui/icons-material/Check";
import { getCadenceAPIToken } from "./SubmitOrder";
import { AnyARecord } from "dns";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";
import { TriggerPaypalGenerated, addSubtotal } from "../../Redux/Counterslice";
import {
  loadCaptchaEnginge,
  validateCaptcha,
} from "../Captcha/react-simple-captcha";
import CachedIcon from "@mui/icons-material/Cached";
import {
  clearLocalstorageValues,
  restrictedInputs,
} from "../../API/GetMicrositeSettings";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  // '&:not(:last-child)': {
  //   borderBottom: 0,
  // },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "transparent" : "transparent",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: "8px 0",
    marginLeft: theme.spacing(1),
    alignItems: "center",
  },
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function Payment({
  paySelectedValue,
  setPaySelectedValue,
  setAccountVal,
  setRoutingVal,
  setCPToken,
  setPaypal,
  setSCard,
  disable,
}: any) {
  const [payExpanded, setPayExpanded] = React.useState<string | false>(
    localStorage.getItem("PaypalToken") ? "panel2" : "panel1"
  );

  if (payExpanded === "panel2") {
    setPaySelectedValue("PayPal");
  }
  const [paymenttypechange, setPaymenttypechange] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const setValue_Account = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAccountVal(event.target.value);
  };
  const setValue_Routing = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRoutingVal(event.target.value);
  };
  const handleChange =
    (panel: string) =>
    (event: React.SyntheticEvent, newpayExpanded: boolean) => {
      setPayExpanded(newpayExpanded ? panel : panel);
      setPaymenttypechange(paymenttypechange === false ? true : false);
    };

  const checkBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaySelectedValue(event.target.value);
  };

  let ach_allowed = false;
  const [cardtype, setCardType] = useState<ICardType[]>([]);
  const [cardpointe, setCardPointe] = useState<ICardPointe[]>([]);
  const [paypalshow, setPaypalShow] = useState(false);
  const [showPayViaTerms, setShowPayViaTerms] = useState(false);
  const [payViaTermDesc, setPayViaTermDesc] = useState("");
  const [cpiframeurl, setCPIframeURL] = useState("");
  const [paypalClientId, setPaypalClientId] = useState("");
  const [savedCard, setSavedCard] = useState<IsavedCard[]>([]);
  const navigate = useNavigate();
  const CaptureCaptchaValue = (state: any) => state.CAPTURECAPTCHAVALUE;
  const CaptchaValue = useSelector(CaptureCaptchaValue);
  const [showiframe, setShowiframe] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [showloader, setShowloader] = useState(false);
  //const [orderID, setOrderID] = useState(false);

  const $ref = useRef(null);
  const [Captcha, setCaptcha] = useState("");
  const [captchaErr, setCaptchaErr] = useState("");

  const CustomerId =
    localStorage.getItem("CustomerId") === undefined ||
    localStorage.getItem("CustomerId") === null ||
    localStorage.getItem("CustomerId") === ""
      ? "0"
      : localStorage.getItem("CustomerId");

  var raw = JSON.stringify({
    parray: ["CustomerId", "0", "BranchId", "0", "UserId", "1"],
  });

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);
  const dispatch = useDispatch();
  useEffect(() => {}, [disable]);

  // useEffect(() => {
  //   console.log("HERE" ,CaptchaValue.CaptchaValue)

  //   if(CaptchaValue.CaptchaValue !== null && CaptchaValue.CaptchaValue !== undefined && CaptchaValue.CaptchaValue !== ""){
  //     loadCaptchaEnginge("6");
  //     if(localStorage.getItem("userName")){
  //       setShowiframe(true);
  //       console.log("HERE")
  //     } else {
  //       fetchCaptchaInfo();
  //       console.log("HERE")
  //     }
  //   } else if(localStorage.getItem("userName")){
  //     setShowiframe(true);
  //     console.log("HERE")
  //   } else {
  //     setShowiframe(false);
  //     // loadCaptchaEnginge("6");

  //     fetchCaptchaInfo()
  //     console.log("HERE")
  //   }
  // },
  // [])

  const fetchCaptchaInfo = async () => {
    try {
      const URL = `${
        EnvData?.REACT_APP_APIURL
      }/microsite-api-v1/get-captcha-value?CustomerAndBranchId=${
        localStorage.getItem("CartAndBramchId") || "0"
      }`;
      Get_Data(URL)
        .then((response: any) => {
          if (response?.data?.captchaValue !== "") {
            if (response?.data?.captchaValue === CaptchaValue.CaptchaValue) {
              setShowiframe(true);
            } else {
              loadCaptchaEnginge(6);
              setShowiframe(false);
            }
          }
        })
        .catch((error) => {});
    } finally {
    }
  };

  // useEffect(() => {
  //   loadCaptchaEnginge("6");
  //   //alert(CaptchaValue.CaptchaValue);
  // }, [payExpanded]);

  useEffect(() => {
    if (payExpanded === "panel0" || initialLoad === true) {
      (async () => {
        setShowloader(true);
        const headers: any = await GetAuthHeaderToken();
        if (headers === "Token Expired") {
          navigate("/login");
          return false;
        }
        setInitialLoad(false);
        axios
          .get(
            EnvData?.REACT_APP_APIURL + "/checkout-api-v1/get-payment-info?CustomerId="+CustomerId,
            {
              headers: headers,
            }
          )
          .then((results) => {
            console.log(results, "payment resonse");
            setCardPointe(results.data.cardPointeResponse);
            setCardType(results.data.cardTypeResponse);
            setPaypalShow(results.data.paypalAllowed);
            setCPIframeURL(results.data.cardpointeIframeURL);
            setPaypalClientId(results.data.cardpointePaypalClientID);
            setSavedCard(results.data.savedCardResponse);
            setShowPayViaTerms(results.data.showPayViaTerms);
            setPayViaTermDesc(results.data.termDescription);

            // console.log(results.data.savedCardResponse, "savedCardClick");
            if (results.data.savedCardResponse.length > 0) {
              localStorage.setItem(
                "cpToken",
                results.data.savedCardResponse[0].profileid
              );
              localStorage.setItem(
                "cpexpiry",
                results.data.savedCardResponse[0].expiry
              );

              setPayExpanded("panel0");
              // console.log(
              //   results.data.savedCardResponse[0].profileid,
              //   "savedCardClick123"
              // );
            }

            if (
              CaptchaValue.CaptchaValue !== null &&
              CaptchaValue.CaptchaValue !== undefined &&
              CaptchaValue.CaptchaValue !== ""
            ) {
              // loadCaptchaEnginge("6");
              if (localStorage.getItem("userName")) {
                setShowiframe(true);
              } else {
                fetchCaptchaInfo();
              }
            } else if (localStorage.getItem("userName")) {
              setShowiframe(true);
            } else {
              setShowiframe(false);
              // loadCaptchaEnginge("6");

              fetchCaptchaInfo();
            }
            setShowloader(false);
          })
          .catch((error) => {
            console.log(error, "payment resonse error");
            return "Error";
            setShowloader(false);
          });
      })();
    }
  }, [paymenttypechange]);

  {
    cardpointe && cardpointe.map((elem1) => (ach_allowed = elem1.achAllowed));
  }

  // Check that window exists before accessing it

  if (typeof window !== "undefined") {
    window.addEventListener(
      "message",
      function (event) {
        if (
          event.origin === "https://fts-uat.cardconnect.com" ||
          event.origin === "https://fts.cardconnect.com"
        ) {
          // console.log(event.origin, "origin");
          // console.log(paySelectedValue, "CreditCard paySelectedValue");
          // console.log(event.data, "event data");
          var token = JSON.parse(event.data);
          if (
            payExpanded === "panel1" &&
            token.message != "" &&
            token.message != undefined
          ) {
            localStorage.setItem("cpToken", token.message);
            // console.log(token.message, "cpToken");
            setCPToken(token.message);
            localStorage.removeItem("PaypalToken");
            localStorage.removeItem("cpexpiry");
            // console.log("token clicked");
          } else {
            setCPToken("");
            localStorage.removeItem("cpToken");
            // console.log("token clicked not");
          }
        }
      },
      false
    );
  }

  //Paypal related start
  const createOrder = (data: any, actions: any) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Auto Parts",
            amount: {
              currency_code: "USD",
              value: 100,
            },
          },
        ],
      })
      .then((orderID: any) => {
        //setOrderID(orderID);
        // localStorage.setItem("PaypalToken", orderID);
        // alert(orderID);
        // localStorage.setItem("PaypalToken", orderID);
        // setPaypal(orderID);
        return orderID;
      });
  };

  // check Approval
  const onApprove = (data: any, actions: any) => {
    setPaypal(data.orderID);
    localStorage.setItem("PaypalToken", data.orderID);
    localStorage.removeItem("cpToken");
    localStorage.removeItem("paypallookup");

    dispatch(TriggerPaypalGenerated(data.orderID));
    // fetchCartInfo();
    return data;
  };

  function enterCaptcha(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    let captchaVal = restrictedInputs(event.target.value);
    setCaptcha(captchaVal.toString());
  }

  const CaptchaValidate = () => {
    if (validateCaptcha(Captcha) === true) {
      setShowiframe(true);
    } else {
      setShowiframe(false);
      if (Captcha !== null && Captcha !== undefined && Captcha !== "") {
        setCaptchaErr("Please enter valid captcha");
      } else {
        setCaptchaErr("Please enter captcha");
      }
    }
  };

  const reloadCaptcha = () => {
    loadCaptchaEnginge("6");
  };

  //saved Card start
  const [activeIndex, setActiveIndex] = useState(0);
  const savedCardClick = (parameter: any, index: number) => {
    // console.log("savedCardClick res", parameter);
    setCPToken(parameter.profileid);
    localStorage.setItem("cpToken", parameter.profileid);
    setActiveIndex(index);
  };
  //saved Card End

  if (showloader)
    return (
      <div>
        {" "}
        <Loader />
      </div>
    );

  return (
    <div>
      {cpiframeurl && cpiframeurl !== undefined && cpiframeurl !== "" ? (
        <>
      {
      savedCard &&
      savedCard.length > 0 ? (
        <Accordion
          expanded={payExpanded === "panel0"}
          onChange={handleChange("panel0")}
        >
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography variant="subtitle2">
              {" "}
              <Radio
                checked={payExpanded === "panel0"}
                onChange={checkBox}
                value="Saved Card"
                name="radio-buttons"
                inputProps={{ "aria-label": "D" }}
              />
              Saved Cards
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{ mb: 2 }}
            >
              <div className="">
                {savedCard &&
                  savedCard.map((elem, index) => (
                    <Box
                      key={index}
                      className={
                        "payment-savedcard-box " +
                        `${activeIndex === index ? "active" : ""}`
                      }
                      onClick={() => savedCardClick(elem, index)}
                    >
                      <div className="payment-savedcard-box-data">
                        <div className="payment-savedcard-icons">
                          <CheckIcon sx={{ fontSize: "18px" }} />
                        </div>
                        <div className="payment-savedcard-data">
                          <img src={elem.imgUrl} alt={elem.profileid} />
                          <span>Card ending in….</span>
                          <strong>{elem.cardNumber}</strong>
                        </div>
                      </div>
                    </Box>
                  ))}
              </div>
            </Stack>
          </AccordionDetails>
        </Accordion>
      ) : null}
      
        <Accordion
          expanded={payExpanded === "panel1"}
          onChange={handleChange("panel1")}
          disabled={disable === "" || disable === "credit" ? false : true}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            className="card-images-mobile"
          >
            <Typography variant="subtitle2">
              <Radio
                checked={payExpanded === "panel1" || disable === "credit"}
                onChange={checkBox}
                value="CreditCard"
                name="radio-buttons"
                inputProps={{ "aria-label": "A" }}
              />
              Credit Card
            </Typography>
            <div className="card-images">
              {cardtype &&
                cardtype.map((elem, index) => (
                  <img
                    src={elem.filePath}
                    alt={elem.cardPointRefCardType}
                    key={index}
                  />
                ))}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {showiframe}
            {showiframe === true ? (
              <>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  Credit card option
                </Typography>
                <iframe
                  src={cpiframeurl}
                  height="210"
                  className="credit-card-iframe"
                ></iframe>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={12} md={12}>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      For your security and ours, please enter the captcha below
                      before proceeding.
                    </Typography>
                    <Stack>
                      <div className="ReloadCaptcha">
                        <canvas
                          id="canv"
                          width="100"
                          height="15"
                          ref={$ref}
                        ></canvas>
                        <Button onClick={reloadCaptcha} variant="text">
                          <CachedIcon />
                        </Button>
                        <div className="EnterAboveText">
                          <TextField
                            id="user_captcha_input"
                            label="Enter Captcha"
                            variant="outlined"
                            onChange={(e) => {
                              enterCaptcha(e);
                            }}
                            onBlur={CaptchaValidate}
                          />
                        </div>
                      </div>
                    </Stack>
                    {captchaErr !== "" && (
                      <FormHelperText style={{ color: "red" }}>
                        {captchaErr}
                      </FormHelperText>
                    )}
                  </Box>
                </Grid>
              </>
            )}
          </AccordionDetails>
        </Accordion>

        </>
      ) : <Typography>Credit Card Payment Settings Not Available</Typography>}

      
      {paypalshow ? (
        <Accordion
          expanded={payExpanded === "panel2"}
          onChange={handleChange("panel2")}
          disabled={disable === "" || disable === "Paypal" ? false : true}
        >
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography variant="subtitle2">
              {" "}
              <Radio
                checked={payExpanded === "panel2" || disable === "Paypal"}
                onChange={checkBox}
                value="PayPal"
                name="radio-buttons"
                inputProps={{ "aria-label": "B" }}
              />{" "}
              PayPal
            </Typography>
            <div className="paypall-image">
              <img src={paypall} alt="pay" />
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {localStorage.getItem("PaypalToken") ? (
              <>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <img src={Pay} alt="pay" />
                  <p>
                    You are already logged-in via this email ID:{" "}
                    <b> {localStorage.getItem("EmailInfo")}</b>
                  </p>
                </Stack>
              </>
            ) : (
              <>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <img src={Pay} alt="pay" />
                  <p>
                    You will be redirected to PayPal page to complete your
                    Purchase Securely.
                  </p>
                </Stack>

                <PayPalScriptProvider
                  options={{
                    clientId: paypalClientId,
                    currency: "USD",
                    intent: "authorize",
                    "disable-funding": "credit,card",
                    commit: false,
                  }}
                >
                  <PayPalButtons
                    style={{ layout: "vertical" }}
                    createOrder={createOrder}
                    onApprove={onApprove}
                    //onError={onError}
                  />
                </PayPalScriptProvider>
              </>
            )}
          </AccordionDetails>
        </Accordion>
      ) : null}
      {/* {ach_allowed ? (
        <Accordion
          expanded={payExpanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography variant="subtitle2">
              {" "}
              <Radio
                checked={payExpanded === "panel3"}
                onChange={checkBox}
                value="ACH"
                name="radio-buttons"
                inputProps={{ "aria-label": "C" }}
              />
              ACH
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{ mb: 2 }}
            >
              <Grid item xs={12} sm={12} md={3}>
                <label>Account #</label>
              </Grid>
              <Grid item xs={12} sm={12} md={9}>
                <TextField
                  variant="outlined"
                  onChange={(event) => setValue_Account(event)}
                />
              </Grid>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{ mb: 2 }}
            >
              <Grid item xs={12} sm={12} md={3}>
                <label>Routing #</label>
              </Grid>
              <Grid item xs={12} sm={12} md={9}>
                <TextField
                  variant="outlined"
                  onChange={(event) => setValue_Routing(event)}
                />
              </Grid>
            </Stack>
          </AccordionDetails>
        </Accordion>
      ) : null} */}
      {showPayViaTerms && CustomerId !== "0" ? (
        <Accordion
          expanded={payExpanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
            <Typography variant="subtitle2">
              {" "}
              <Radio
                checked={payExpanded === "panel4"}
                onChange={checkBox}
                value="PayViaTerms"
                name="radio-buttons"
                inputProps={{ "aria-label": "C" }}
              />
              Pay Via Terms
            </Typography>
            <div className="card-images">{payViaTermDesc}</div>
          </AccordionSummary>
        </Accordion>
      ) : null}
    </div>
  );
}
