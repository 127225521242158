import * as React from "react";
import {
  Button,
  Box,
  Grid,
  Container,
  Paper,
  Typography,
  Avatar,
  IconButton,
  Link,
} from "@mui/material";
import BannerSlider from "../Components/Header/StaticBannerSlider";
import DescriptionIcon from "@mui/icons-material/Description";
import ecatalog from "../staticimages/e-catalog.jpg";
import distributorportal from "../staticimages/distributor-portal.jpg";
import representative from "../staticimages/representative.jpg";
import map from "../staticimages/from-pins-map.jpg";
import catalog from "../staticimages/catalog.jpg";

import truck from "../staticimages/side_view_truck.png";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailIcon from "@mui/icons-material/Mail";
import {  useNavigate } from "react-router-dom";
import Banner1 from "../staticimages/banner.jpg";
import logo from "../staticimages/torqstop.png";

import "../Css/static.css";
import { IBannerProps, IMicrositeProps } from "../Types/MicrositeType";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { GetDataMultiResults } from "../API/GetDataFromApi";
import ProductLoader from "../Components/ProductLoader";

export default function StaticHome(props: { _settings: IMicrositeProps, _contact?: string }) {
  const [banner, setBanner] = useState<IBannerProps[]>([]);
  const [Loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  let Loginaddress=  EnvData?.REACT_APP_CADENCELOGINURL

  function NavigateTo(path:string) {
    navigate(path);
  }
  useEffect(() => {
    (async () => {
      setLoading(true)
      GetDataMultiResults(
        EnvData?.REACT_APP_APIURL + "/microsite-api-v1/get-index"
      ).then((results?) => {
      
        setBanner(results.data.micrositeIndex_BannerResponse);
        // console.log(results.data.micrositeIndex_BannerResponse)
        setLoading(false)
      });
    })();
  }, []);
  return (
    <>
       {
      !Loading ? <div>
      <BannerSlider  bannerName={banner}/>
      {/* <img src={Banner1} alt="banner-logo" /> */}
      {/* <Box
              className="torqstop-banner-slider-container"
              sx={{ backgroundImage: `url(${Banner1})` , backgroundRepeat:"no-repeat"  , backgroundPosition:"center" , backgroundSize:"cover"}}
            >
              <Container sx={{ position: "relative" }}>
                <Box className="torqstop-banner-slider-content">
                  <img src={logo} alt="banner-logo" />
                  <Typography variant="h6" color="primary.contrastText" ml={2}>
                    {" "}
                    Protect your investment and keep your feet <br /> Running
                    Strong While staying on budget
                  </Typography>
                </Box>
              </Container>
            </Box> */}
      <Box className="torqstop-about-section">
        <Container>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "400", fontSize: "22px" }}
          >
            With nearly 100 years experience, OPC Parts produces
            OE-quality, 100% NEW Light, Medium and Heavy-Duty Commercial Vehicle
            brakes components under the TorqStop<sup>TM</sup> brand or under
            your own Private Label. OPC Parts believes in building longstanding,
            mutually benefical relationships. Customer service is paramount. Our
            Products are high-quality, competitively priced, and readily
            avaliable to keep your equipment running and safe.
          </Typography>
        </Container>
      </Box>

      <Box className="torqstop-service-section" sx={{ backgroundColor: "#010001" }}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs>
              <Box className="torqstop-service-box" onClick={()=>{
                NavigateTo('CustomPage/2')
              }}>
                <div className="torqstop-service-box-img" >
                  <img src={catalog} alt="image" />
                  <Avatar
                    sx={{ bgcolor: "primary.main" }}
                    className="torqstop-service-box-icon"
                  >
                    <DescriptionIcon />
                  </Avatar>
                </div>
                <div className="torqstop-service-box-content">
                  <Typography color="primary.contrastText" variant="subtitle1">
                    Catalogs and Literature
                  </Typography>
                </div>
              </Box>
            </Grid>
            <Grid item xs>
              <Box className="torqstop-service-box" >
                <a href={ Loginaddress }>
                <div className="torqstop-service-box-img" >
                  <img src={distributorportal} alt="image" />
                  <Avatar
                    sx={{ bgcolor: "primary.main" }}
                    className="torqstop-service-box-icon"
                  >
                    <DescriptionIcon />
                  </Avatar>
                </div>
                <div className="torqstop-service-box-content">
                  <Typography color="primary.contrastText" variant="subtitle1">
                    Distributor Portal
                  </Typography>
                </div>
                </a>
               
              </Box>
            </Grid>
            <Grid item xs>
              <Box className="torqstop-service-box" onClick={()=>{
                NavigateTo('/PartSearch') 
              }}> 
                <div className="torqstop-service-box-img">
                  <img src={ecatalog} alt="image" />
                  <Avatar
                    sx={{ bgcolor: "primary.main" }}
                    className="torqstop-service-box-icon"
                  >
                    <DescriptionIcon />
                  </Avatar>
                </div>
                <div className="torqstop-service-box-content">
                  <Typography color="primary.contrastText" variant="subtitle1">
                    E-CATALOG
                  </Typography>
                </div>
              </Box>
            </Grid>
            <Grid item xs>
              <Box className="torqstop-service-box" onClick={()=>{
                NavigateTo('/TerritoryRepresentative')
              }}>
                <div className="torqstop-service-box-img">
                  <img src={representative} alt="image" />
                  <Avatar
                    sx={{ bgcolor: "primary.main" }}
                    className="torqstop-service-box-icon"
                  >
                    <DescriptionIcon />
                  </Avatar>
                </div>
                <div className="torqstop-service-box-content">
                  <Typography color="primary.contrastText" variant="subtitle1" >
                    Contact your Territory Representative
                  </Typography>
                </div>
              </Box>
            </Grid>
            <Grid item xs>
              <Box className="torqstop-service-box" onClick={()=>{
                NavigateTo('/TerritoryRepresentative')
              }}>
                <div className="torqstop-service-box-img">
                  <img src={map} alt="image" />
                  <Avatar
                    sx={{ bgcolor: "primary.main" }}
                    className="torqstop-service-box-icon"
                  >
                    <DescriptionIcon />
                  </Avatar>
                </div>
                <div className="torqstop-service-box-content">
                  <Typography color="primary.contrastText" variant="subtitle1">
                    Find A Distributor
                  </Typography>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className="torqstop-news-letter-sec" sx={{ background: "#e37b2e" }}>
        <div className="torqstop-truck-img">
          <img src={truck} alt="truck" />
        </div>
        <Container>
          <Typography variant="h3" color="primary.contrastText">
            SIGN Up For Our Newsletter
          </Typography>
        </Container>
      </Box>
      <Box className="torqstop-contact-sec" sx={{ background: "#000" }}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <Box className="torqstop-contact-box">
                <IconButton className="torqstop-contact-circle">
                  <LocalPhoneIcon />
                </IconButton>

                <Link className="torqstop-contact-text" href="tel:1-888-996-6896">
                  1-888-996-6896 (Toll-Free)
                </Link>
                <Link className="torqstop-contact-text" href="tel:1-980-987-7272">1-980-987-7272 (Local)</Link>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box className="torqstop-contact-box">
                <IconButton className="torqstop-contact-circle">
                  <MailIcon />
                </IconButton>

                <Link href="mailto:admin@opcparts.com" className="torqstop-contact-text">admin@opcparts.com</Link>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div> : (<>
      <ProductLoader />
    </>)
    }
    </>
 
    
  );
}
