import { Box, Button, Container, Divider, FormControl, Grid, Paper, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { GeteMailRes } from "../API/GetDataFromApi";
import { useSelector } from "react-redux";
import { CheckValidEmailAddress } from "../API/GetMicrositeSettings";
  export function RequestCatalog() {
    let env = (state: any) => state.ENV_SETTINGS;
    const EnvData = useSelector(env);
    const [name, setName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [PhoneNumberErr, setPhoneNumbererr] = useState("");
    const [NameErr, setNameErr] = useState("");
    const [EmailErr, setEmailErr] = useState("");
    const [CompanyNameErr, setCompanyNameErr] = useState("");

    const handleNameChange = (event:React.ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
    };

    const handleCompanyNameChange = (event:React.ChangeEvent<HTMLInputElement>) => {
      setCompanyName(event.target.value);
    };
  
    const handleEmailChange = async (event:React.ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
    };
  
    const handleInputnumberChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const value = event.target.value.replace(/[^0-9()-]/g, "");
  
      if (value.length == 10 && value.length <= 11) {
        setPhoneNumber(value);
        setPhoneNumbererr("");
      } else if (value.length === 0) {
        setPhoneNumber("");

      } else {
        setPhoneNumber(value);
      }
    };

    async function handleSubmit() {
        let success = 0;
        var raw = {
            Name: name,
            Email: email,
            CompanyName: companyName,
            PhoneNumber: phoneNumber,
          };
          if(NameErr === '' && PhoneNumberErr === ''&& EmailErr ==='' && CompanyNameErr === '')
          {
            await GeteMailRes(
              EnvData?.REACT_APP_APIURL + "/api/Email/send-catalog-email",
              JSON.stringify(raw)
            )
            .then((response) => {
              return response;
            })
            .then((results) => {
              success = 1;
              alert("Thanks for your message!  We’ll be in touch soon.");
            })
            .catch((error) => {
              // console.log("Error in " + error);
            });
            if(success===1)
            {
              setName("")
              setCompanyName("")
              setEmail("")
              setPhoneNumber("")
            }
          }
          else{

          }
      };
    return(
      <div>
        <Paper elevation={0} sx={{mt:2, mb:1, py:3}}>
        <Container>
            <Typography variant="h1" align="center">
                Download Catalog
                </Typography>
              <Typography variant="h2" pb={3} align="center">
                   Please fill out the form below to receive our full catalog
              </Typography>
          <Grid container>
              <Grid item md={6}>
                  <Box>
                  <div className="product-right-border">
                      <Typography variant="h4" sx={{ pb: 2 }}>
                      Request Catalog
                        <Divider
                          sx={{ bgcolor: "primary.main" }}
                          className="title-divider"
                        />
                      </Typography>
                    </div>
                    <FormControl sx={{ width: "100%", mb:2}} className="select-mui-custom" >
                      <TextField  
                        id="demo-simple-select"            
                        label="Name"
                        name="Name"
                        variant="standard"
                        value={name}
                        onChange={handleNameChange}
                        onBlur={() => {
                          if (name == "" || name == undefined || name == null) {
                            setNameErr("This Field Is Required.");
                          } else {
                            setNameErr("");
                          }                          
                        }}
                        error={
                          NameErr !== "" &&
                          NameErr !== undefined &&
                          NameErr !== null
                        }
                        helperText={NameErr}
                      />
                      </FormControl>
                      <FormControl sx={{ width: "100%", mb:2}} className="select-mui-custom" >
                      <TextField  
                        id="demo-simple-select"            
                        label="Company Name"
                        name="Company Name"
                        variant="standard"
                        value={companyName}
                        onChange={handleCompanyNameChange}
                        onBlur={() => {
                          if (companyName == "" || companyName == undefined || companyName == null) {
                            setCompanyNameErr("This Field Is Required.");
                          } else {
                            setCompanyNameErr("");
                          }
                        }}
                        error={CompanyNameErr !== ""}
                        helperText={CompanyNameErr}
                      />
                      </FormControl>
                      <FormControl sx={{ width: "100%", mb:2}} className="select-mui-custom" >
                      <TextField  
                        id="demo-simple-select"            
                        label="E-mail"
                        name="E-mail"
                        variant="standard"
                        value={email}
                        onChange={handleEmailChange}
                        onBlur={async () => {
                          if (email == "" || email == undefined || name == null) {
                            setEmailErr("This Field Is Required.");
                          } else {
                            const result: boolean = await CheckValidEmailAddress(email);
                            if (!result) {
                              setEmailErr("Please enter a valid email address");
                            } else {
                              setEmailErr("");
                            }
                          } 
                          
                        }}
                        error={EmailErr !== ""}
                        helperText={EmailErr}
                      />
                      </FormControl>
                      <FormControl sx={{ width: "100%", mb:2}} className="select-mui-custom" >
                      <TextField  
                        id="demo-simple-select"            
                        label="Phone Number"
                        name="Phone Number"
                        inputProps={{
                          pattern: "[0-9]*",
                          minLength: 10,
                          maxLength: 11,
                        }}
                        variant="standard"
                        value={phoneNumber}                        
                        onChange={(e: any) => {
                          handleInputnumberChange(e);
                        }}
                        onBlur={(e) => {
                          const inputElement = e.target as HTMLInputElement;
                          const value = inputElement.value.replace(/[^0-9()-]/g, "");
                          if (phoneNumber.length == 0) {
                            setPhoneNumbererr("");
                          } else if (phoneNumber.length < 10) {
                            setPhoneNumbererr("Phone number should have 10  digits.");
                          } else if (phoneNumber.length > 9) {
                            setPhoneNumbererr("");
                          }
                        }}
                        error={PhoneNumberErr !== ""}
                        helperText={PhoneNumberErr}
                      />
                      </FormControl>
                      <Button variant="contained" sx={{float:'right'}} onClick={handleSubmit}>
                        Submit
                      </Button>
                  </Box>
              </Grid>
          </Grid>
          </Container>
          </Paper>
      </div>
    )
  }