import {
  Grid,
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Button,
} from "@mui/material";
import axios from "axios";
import { escape, stringify } from "querystring";
import React, { useEffect, useState } from "react";
import { Get_Data } from "../../API/GetDataFromApi";
import eventEmitter from "../PartSearchTest/eventEmmiter";
import { useSelector } from "react-redux";

export type ICountryProps = {
  countryId: number;
  country: string;
};
export type IStateProps = {
  stateId: number;
  state: string;
};
export type ICityProps = {
  cityId: number;
  city: string;
};
export type IZipProps = {
  zipId: number;
  zip: string;
};
const BillingAddressFilling = (props: {
  // mode: string;
  // mail: string;
  // EmailValidation: any;
}) => {
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [appSuite, setappSuite] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [extenderData, setExtenderData] = useState<[]>([]);
  const [countryData, setcountryData] = useState<ICountryProps[]>([]);
  const [stateData, setStateData] = useState<IStateProps[]>([]);
  const [citydata, setcitydata] = useState<ICityProps[]>([]);
  const [extenderDefaultValue, setextenderDefaultValue] = useState("");
  const [selectedZip, setselectedZip] = useState("");
  const [selectedCountry, setselectedCountry] = useState(0);
  const [selectedState, setselectedState] = useState(0);
  const [selectedCity, setselectedCity] = useState(0);
  const [ZipId, setZipId] = useState(0);
  const [selectedCountryValue, setselectedCountryValue] = useState("");
  const [selectedStateValue, setselectedStateValue] = useState("");
  const [selectedCityValue, setselectedCityValue] = useState("");
  const [defaultCity, setdefaultCity] = useState("");
  const [zipList, setzipList] = useState<any>([]);

  const [firstNameErr, setfirstNameErr] = useState("");
  const [lastNameErr, setlastNameErr] = useState("");
  const [extenderDefaultValueErr, setextenderDefaultValueErr] = useState("");
  const [selectedZipErr, setselectedZipErr] = useState("");
  const [PhoneNumberErr, setPhoneNumbererr] = useState("");
  const [selectedCountryerr, setselectedCountryerr] = useState("");
  const [selectedStateErr, setselectedStateErr] = useState("");
  const [selectedCityErr, setselectedCityErr] = useState("");
  const [EmailErr, setEmailErr] = useState("");

  const storedShippingInfoString = localStorage.getItem("BillingInfo");

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  function HandleTabChange() {
    if (storedShippingInfoString) {
      const storedShippingInfo = JSON.parse(storedShippingInfoString);
      // setfirstName(storedShippingInfo.Firstname);
      // setlastName(storedShippingInfo.Lastname);
      // setPhoneNumber(storedShippingInfo.PhoneNumber);
    }
    if (firstName == "") {
      setfirstNameErr("This Field Is Required.");
    } else {
      setfirstNameErr("");
    }
    if (lastName == "") {
      setlastNameErr("This Field Is Required.");
    } else {
      setlastNameErr("");
    }
    if (extenderDefaultValue == "") {
      setextenderDefaultValueErr("This Field Is Required.");
    } else {
      setextenderDefaultValueErr("");
    }

    if (selectedZip == "") {
      setselectedZipErr("This Field Is Required.");
    } else {
      setselectedZipErr("");
    }
    // if (PhoneNumber == "" ) {
    //   setPhoneNumbererr("This Field Is Required.");
    // } else if (PhoneNumber?.length>9) {
    //   setPhoneNumbererr("");
    // }

    if (PhoneNumber?.length == 0) {
      setPhoneNumbererr("");
    } else if (PhoneNumber?.length < 10) {
      setPhoneNumbererr("Phone number should have 10  digits.");
    } else if (PhoneNumber?.length > 9) {
      setPhoneNumbererr("");
    }

    if (selectedCountry == 0) {
      setselectedCountryerr("This Field Is Required.");
    } else {
      setselectedCountryerr("");
    }
    if (selectedState == 0) {
      setselectedStateErr("This Field Is Required.");
    } else {
      setselectedStateErr("");
    }
    if (selectedCity == 0) {
      setselectedCityErr("This Field Is Required.");
    } else {
      setselectedCityErr("");
    }
    // if (props.mail == "") {
    //   setEmailErr("This Field is Required");
    //   props.EmailValidation("This Field is Required");
    // } else {
    //   setEmailErr("");
    //   props.EmailValidation("");
    // }

    if (
      (firstName !== "" &&
        lastName !== "" &&
        extenderDefaultValue !== "" &&
        selectedZip !== "" &&
        PhoneNumber?.length == 0) ||
      (PhoneNumber?.length > 9 &&
        selectedCountry !== 0 &&
        selectedState !== 0 &&
        selectedCity !== 0)
      // props.mail !== ""
    ) {
      let ShippingInfo = {
        Firstname: firstName,
        Lastname: lastName,
        AddressLine1: extenderDefaultValue,
        // selectedZip: selectedZip,
        PhoneNumber: PhoneNumber,
        CountryId: selectedCountry,
        StateId: selectedState,
        CityId: selectedCity,
        // Email: props.mail,
        Country: selectedCountryValue,
        State: selectedStateValue,
        City: selectedCityValue,
        Aptsuite: "",
        Zip: selectedZip,
        ZipId: ZipId,
      };

      const ShippingInfoString = JSON.stringify(ShippingInfo);
      localStorage.setItem("BillingInfo", ShippingInfoString);
      // localStorage.setItem("BillEmailInfo" ,props.mail )
      // eventEmitter.emit("triggerTabChange", "Method");
    } else {
    }
  }
  useEffect(() => {
    HandleTabChange();
  }, [
    firstName,
    lastName,
    appSuite,
    PhoneNumber,
    extenderDefaultValue,
    selectedCountry,
    selectedState,
    selectedCity,
    ZipId,
    selectedZip,
  ]);
  useEffect(() => {
    if (localStorage.getItem("BillDefaultAddress") != "") {
      setextenderDefaultValue(localStorage.getItem("BillDefaultAddress")!);
    }
    if (localStorage.getItem("BilldefaultZip") != "") {
      setselectedZip(localStorage.getItem("BilldefaultZip")!);
    }
    if (localStorage.getItem("BilldefaultCity") != "") {
      setdefaultCity(localStorage.getItem("BilldefaultCity")!);
    }
    loadCountry(
      getUrl("Country", localStorage.getItem("BilldefaultZip")!),
      localStorage.getItem("BilldefaultZip")!
    );
    if (storedShippingInfoString) {
      const storedShippingInfo = JSON.parse(storedShippingInfoString);
      setfirstName(storedShippingInfo.Firstname);
      setlastName(storedShippingInfo.Lastname);
      setPhoneNumber(storedShippingInfo.PhoneNumber);
    }
    // loadAddressDropdownData(
    //   getUrl("Country", localStorage.getItem("defaultZip")!),
    //   "Country",
    //   localStorage.getItem("defaultZip")!
    // );
  }, []);

  const [zipinputValue, setzipInputValue] = useState("");
  const [countryfocus, setcountryfocus] = useState(false);
  const ziponblur = () => {
    loadZip();
  };
  function loadZip() {
    if (
      zipinputValue !== null &&
      zipinputValue !== "" &&
      zipinputValue !== undefined
    ) {
      var newvalue: any = {
        label: zipinputValue,
        value: zipinputValue,
      };
      setselectedZip(zipinputValue);
      localStorage.removeItem("BilldefaultCountry");
      localStorage.removeItem("BilldefaultState");
      localStorage.removeItem("BilldefaultCity");

      setselectedCountry(0);
      setselectedCity(0);
      setselectedState(0);

      setTimeout(() => {
        getZipAutoComplete(newvalue, "Zip");
      }, 500);
    }
  }

  const LoadZipExtender = (event: any, val: string) => {
    let URL = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/get-zipcode?zipCode=${val}`;
    Get_Data(URL)
      .then((res) => {
        //   setMenudata(results.data);

        const transferdata = res.data.map((item: any) => ({
          label: item.zipCode,
          value: item.zipCode,
        }));
        setzipList(transferdata);
      })
      .catch((error) => {
        console.log("Error in " + error);
      });
  };

  const loadExtender = (event: any, txtValue: string) => {
    let eventName: string = "";
    if (txtValue !== "") {
      if (event !== null) {
        eventName = event._reactName;
      }
      //txtValue = txtValue.replace(",  ", " ");
      txtValue = txtValue.replace("  ", " ");
      txtValue = txtValue.replace("-", "");
      txtValue = txtValue.replace("_", "");
      // txtValue = txtValue.toLowerCase().replace("p.o.box", "");
      // txtValue = txtValue.toLowerCase().replace("po box", "");
      // txtValue = txtValue.toLowerCase().replace("p o box", "");
      // txtValue = txtValue.toLowerCase().replace("pobox", "");
      // txtValue = txtValue.toLowerCase().replace("p.o box", "");
      // txtValue = txtValue.toLowerCase().replace("p.o box", "");
      localStorage.setItem("BillDefaultAddress", txtValue);

      setextenderDefaultValue(txtValue);

      getAddress(txtValue);
      if (eventName === "onClick") {
        if (txtValue.indexOf(",")) {
          let txtArray = txtValue.split(",");
          localStorage.setItem("BillDefaultAddress", txtArray[0]);
          setextenderDefaultValue(txtArray[0]);
        } else {
          // localStorage.setItem("BillDefaultAddress", txtValue);
          setextenderDefaultValue(txtValue);
          localStorage.setItem("DefaultAddress", txtValue);
          localStorage.setItem("BillDefaultAddress", txtValue);
          // setextenderDefaultValue(txtValue);
        }
      }
    }
  };

  const getAddress = (value: string) => {
    (async () => {
      axios
        .get(
          `https://atlas.microsoft.com/search/address/json?subscription-key=Ny-_UG1MGghhuU2D6ihr8GXBAM5QJp-QLSkFE7Dah6Q&api-version=1.0&limit=80&query=" + ${value}`
        )
        .then((results?) => {
          let transferdata = results?.data["results"]
            .map((item: any) => {
              const { address } = item;
              if (
                address &&
                address.postalCode &&
                address.countryCodeISO3 &&
                address.countrySubdivision &&
                address.countrySecondarySubdivision &&
                address.localName !== "" &&
                !address.postalCode.includes(",") &&
                address.freeformAddress
                  .toLowerCase()
                  .startsWith(value.toLowerCase()) &&
                address.countrySubdivision?.length <= 10 &&
                address.countryCodeISO3?.length <= 10
              ) {
                let ZipData = [
                  {
                    label: item.address.postalCode,
                    value: `${item.address.postalCode}`,
                  },
                ];

                setzipList(ZipData);

                return {
                  label: item.address.freeformAddress,
                  city: item.address.localName,
                  Zip: item.address.postalCode,
                  country: item.address.countryCodeISO3,
                  state: item.address.countrySubdivision,
                };
              }
            })
            .filter(Boolean);

          setExtenderData(transferdata);
        });
    })();
  };
  function ChangeCountryData(CountryData: any) {
    setcountryData(CountryData);
  }
  function SelectCountry(SelectedData: any) {
    setselectedCountry(SelectedData.countryId);
    setselectedCountryValue(SelectedData.country);
    localStorage.setItem("BilldefaultCountry", SelectedData.country);
  }

  function ClearCountryData() {
    setcountryData([]);

    setselectedCountry(0);
    setselectedCountryValue("");
  }
  function ChangeStateData(StateData: any) {
    setStateData(StateData);
  }

  function SelectState(SelectedData: any) {
    setselectedState(SelectedData.stateId);
    setselectedStateValue(SelectedData.state);
    localStorage.setItem("BilldefaultState", SelectedData.state);
  }

  function ClearStateData() {
    setStateData([]);
    setselectedState(0);
    setselectedStateValue("");
  }

  function ChangeCityData(CityData: any) {
    setcitydata(CityData);
  }

  function SelectCity(SelectedData: any) {
    setselectedCity(SelectedData.cityId);
    setselectedCityValue(SelectedData.city);
    setZipId(SelectedData.zipId);
    localStorage.setItem("BilldefaultCity", SelectedData.city);
  }

  function ClearCityData() {
    setcitydata([]);
    setselectedCity(0);
    setselectedCityValue("");
    setZipId(0);
    // change("ZipId", "");
  }

  const getZipAutoComplete = (inputValue: any, event: any) => {
    if (inputValue != undefined && inputValue != "") {
      localStorage.setItem("BilldefaultZip", inputValue.label);

      setselectedZip(inputValue.label);

      // setdefaultCity(inputValue.city);
      loadCountry(
        getUrl("Country", inputValue.label),

        inputValue.label
      );

      // loadAddressDropdownData(
      //   getUrl("Country", inputValue.Zip),
      //   "Country",
      //   inputValue.Zip
      // );
    } else {
      localStorage.removeItem("BilldefaultZip");
      // localStorage.removeItem("defaultCity");
      ClearCountryData();
      ClearStateData();
      ClearCityData();
    }
  };

  const getZipcode = (event: any, inputValue: any) => {
    if (inputValue != undefined && inputValue != "") {
      localStorage.setItem("BilldefaultZip", inputValue.Zip);
      localStorage.setItem("BilldefaultCity", inputValue.city);
      setselectedZip(inputValue.Zip);
      setdefaultCity(inputValue.city);
      loadCountry(
        getUrl("Country", inputValue.Zip),

        inputValue.Zip
      );
      // loadAddressDropdownData(
      //   getUrl("Country", inputValue.Zip),
      //   "Country",
      //   inputValue.Zip
      // );
    }
  };

  function SelectMultiCity(res: any) {
    for (var i = 0; i < res.data?.length; i++) {
      if (
        res.data[i].city.toUpperCase() ===
        localStorage.getItem("BilldefaultCity")?.toUpperCase()
      ) {
        SelectCity(res.data[i]);
      }
    }
  }
  const getUrl = (
    flag: string,
    zipcode?: string,
    countryId?: number,
    stateId?: number
  ) => {
    let apiUrl = "";
    if (zipcode === null) {
      zipcode = selectedZip;
    }
    if (countryId === null || countryId === 0) {
      countryId = Number(selectedCountry);
    }
    if (stateId === null || stateId === 0) {
      stateId = Number(selectedState);
    }

    if (flag == "Country") {
      apiUrl = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/get-country?zipCode=${zipcode}`;
    } else if (flag == "State") {
      apiUrl = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/get-state?zipCode=${zipcode}&countryId=${countryId}`;
    } else if (flag == "City") {
      apiUrl = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/get-city?zipCode=${zipcode}&countryId=${countryId}&stateId=${stateId}`;
    }
    return apiUrl;
  };
  function SelectMultiState(res: any) {
    for (var i = 0; i < res.data?.length; i++) {
      if (
        res.data[i].state.toUpperCase() ===
        localStorage.getItem("BilldefaultState")?.toUpperCase()
      ) {
        // setselectedCity(res.data[i].cityId);
        SelectState(res.data[i]);

        loadCity(
          getUrl(
            "City",
            localStorage.getItem("BilldefaultZip")!,
            selectedCountry,
            res.data[i].stateId
          ),
          localStorage.getItem("BilldefaultZip")!,
          selectedCountry,
          res.data[i].stateId
        );

        // localStorage.setItem("defaultCity", res.data[i].city);
      }
    }
  }

  function SelectMultiCountry(res: any) {
    const defaultCountry = localStorage
      .getItem("BilldefaultCountry")
      ?.toUpperCase();

    for (var i = 0; i < res.data?.length; i++) {
      if (
        res.data[i].country.toUpperCase() ===
        localStorage.getItem("BilldefaultCountry")?.toUpperCase()
      ) {
        SelectCountry(res.data[i]);

        loadState(
          getUrl(
            "State",
            localStorage.getItem("BilldefaultZip")!,
            res.data[i].countryId
          ),
          localStorage.getItem("BilldefaultZip")!,
          res.data[i].countryId
        );
      } else {
      }
    }
  }

  const loadCountry = (apiUrl: string, zipCode: string) => {
    Get_Data(apiUrl)
      .then((res) => {
        setcountryData(res.data);
        if (res.data?.length === 1) {
          SelectCountry(res.data[0]);
          // setselectedCountry(res.data[0].countryId);
          // setselectedCountryValue(res.data[0].country);

          loadState(
            getUrl("State", zipCode, res.data[0].countryId),
            zipCode,
            res.data[0].countryId
          );
        } else {
          // setselectedCountry(0);
          // setselectedCountryValue("");
          SelectMultiCountry(res);
        }
        return {
          label: res.data,
          value: `${res.data} `,
        };
      })
      .catch((error) => {
        console.log("Error in " + error);
      });
  };
  const loadState = (apiUrl: string, zipCode: string, countryId: number) => {
    Get_Data(apiUrl)
      .then((res) => {
        setStateData(res.data);
        if (res.data?.length === 1) {
          SelectState(res.data[0]);
          // setselectedState(res.data[0].stateId);
          // setselectedStateValue(res.data[0].state)
          localStorage.setItem("BilldefaultState", res.data[0].state);
          loadCity(
            getUrl("City", zipCode, countryId, res.data[0].stateId),
            zipCode,
            countryId,
            res.data[0].stateId
          );
        } else {
          // setselectedState(0);
          SelectMultiState(res);
        }
        return {
          label: res.data,
          value: `${res.data} `,
        };
      })
      .catch((error) => {
        console.log("Error in " + error);
      });
  };
  const loadCity = (
    apiUrl: string,
    zipCode: string,
    countryId: number,
    cityId: number
  ) => {
    Get_Data(apiUrl)
      .then((res) => {
        setcitydata(res.data);
        if (res.data?.length === 1) {
          SelectCity(res.data[0]);
        } else {
          SelectMultiCity(res);
        }
        return {
          label: res.data,
          value: `${res.data} `,
        };
      })
      .catch((error) => {
        console.log("Error in " + error);
      });
  };
  const loadAddressDropdownData = (
    apiUrl: string,
    flag: string,
    zipCode?: string
  ) => {
    if (zipCode === undefined) {
      zipCode = localStorage.getItem("BilldefaultZip")!;
    }

    Get_Data(apiUrl)
      .then((res) => {
        if (flag == "Country") {
          setcountryData(res.data);
          if (res.data?.length === 1) {
            SelectCountry(res.data[0]);
            loadAddressDropdownData(
              getUrl("State", zipCode, res.data[0].countryId),
              "State"
            );
          } else {
            setselectedCountry(0);
          }
        } else if (flag == "State") {
          setStateData(res.data);
          if (res.data?.length === 1) {
            setselectedState(res.data[0].stateId);

            loadAddressDropdownData(
              getUrl("City", zipCode, selectedCountry, res.data[0].stateId),
              "City"
            );
          } else {
            setselectedState(0);
          }
        } else if (flag == "City") {
          setcitydata(res.data);
          if (res.data?.length === 1) {
            setselectedCity(res.data[0].cityId);
          } else {
            SelectMultiCity(res);
          }
        }
        return {
          label: res.data,
          value: `${res.data} `,
        };
      })
      .catch((error) => {
        console.log("Error in " + error);
      });
  };

  function handleLoadOptions(name: string) {
    let URL = "";
    if (name == "Country") {
      URL = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/get-country?zipCode=${selectedZip}`;
    } else if (name == "State") {
      URL = `${
        EnvData?.REACT_APP_APIURL
      }/microsite-api-v1/get-state?zipCode=${selectedZip}&countryId=${Number(
        selectedCountry
      )}`;
    } else if (name == "City") {
      URL = `${
        EnvData?.REACT_APP_APIURL
      }/microsite-api-v1/get-city?zipCode=${selectedZip}&countryId=${Number(
        selectedCountry
      )}&stateId=${Number(selectedState)}`;
    }

    Get_Data(URL)
      .then((res) => {
        if (name == "Country") {
          setcountryData(res.data);
        } else if (name == "State") {
          setStateData(res.data);
        } else if (name == "City") {
          setcitydata(res.data);
        }
        return {
          label: res.data,
          value: `${res.data} `,
        };
      })
      .catch((error) => {
        console.log("Error in " + error);
      });
  }

  const handleInputnumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value.replace(/[^0-9]/g, "");

    if (value?.length == 10 && value?.length <= 11) {
      setPhoneNumber(value);
      setPhoneNumbererr("");
    } else if (value?.length === 0) {
      setPhoneNumber("");
      // setPhoneNumbererr("This Field Is Required.");
    } else {
      setPhoneNumber(value);
      // setTimeout(()=>{
      //   setPhoneNumbererr("Phone number should have 10 to 11 digits.");
      // } ,500)
    }
  };
  const handleDropDown = (event?: any, newvalue?: any, name?: string) => {
    if (newvalue == "Country") {
      ChangeCountryData(event);
      SelectCountry(event);
      // CountryChange(event.countryId);
      // getUrl("State", selectedZip, event.countryId),
      loadState(
        getUrl("State", selectedZip, event.countryId),
        selectedZip,
        event.countryId
      );
    } else if (newvalue == "State") {
      ChangeStateData(event);
      SelectState(event);
      loadCity(
        getUrl("City", selectedZip, selectedCountry, event.stateId),
        selectedZip,
        selectedCountry,
        event.stateId
      );

      // StateChange(event.stateId);
    } else if (newvalue == "City") {
      ChangeCityData(event);
      SelectCity(event);
    } else if ((newvalue == "Zip" && event == null) || undefined || "") {
      setselectedZip("");
      // ClearCountryData()
      // ClearStateData()
      // citylist([]);
      setzipList([]);

      ClearCountryData();

      ClearStateData();

      ClearCityData();
    } else {
      setselectedZip(event?.value);

      ClearStateData();

      ClearCityData();

      ClearCountryData();
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            fullWidth
            label="First Name"
            name="Firstname"
            variant="standard"
            value={firstName}
            onChange={(e) => {
              setfirstName(e.target.value);
            }}
            onKeyUp={(e) => {
              const inputElement = e.target as HTMLInputElement;

              if (inputElement.value == "") {
                setfirstNameErr("This Field Is Required.");
              } else {
                setfirstNameErr("");
              }
            }}
            error={
              firstNameErr !== "" &&
              firstNameErr !== undefined &&
              firstNameErr !== null
            }
            helperText={firstNameErr}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            fullWidth
            label="Last Name"
            name="Lastname"
            variant="standard"
            value={lastName}
            error={lastNameErr !== ""}
            helperText={lastNameErr}
            onChange={(e) => {
              setlastName(e.target.value);
            }}
            onKeyUp={(e) => {
              const inputElement = e.target as HTMLInputElement;

              if (inputElement.value == "") {
                setlastNameErr("This Field Is Required.");
              } else {
                setlastNameErr("");
              }
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Autocomplete
            options={Array.isArray(extenderData) ? extenderData : []}
            disablePortal
            freeSolo
            fullWidth
            onKeyUp={(e) => {
              const inputElement = e.target as HTMLInputElement;
              if (extenderDefaultValue == "") {
                setextenderDefaultValueErr("This Field Is Required.");
              } else {
                setextenderDefaultValueErr("");
              }
            }} // error={extenderDefaultValueErr}
            // helperText={extenderDefaultValueErr}
            onInputChange={(event, txtValue) =>
              // txtValue?.length >= 3 &&
              loadExtender(event, txtValue)
            }
            onChange={getZipcode}
            // onChange={(event, newvalue) =>
            //   getZipAutoComplete(event)
            // }

            renderInput={(params) => (
              <TextField
                {...params}
                label="Address"
                name="AddressLine1"
                variant="standard"
                error={extenderDefaultValueErr !== ""}
                helperText={extenderDefaultValueErr}
              />
            )}
            value={extenderDefaultValue}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <TextField
            fullWidth
            label="Apt, Suite etc. (optional)"
            name="Aptsuite"
            variant="standard"
            value={appSuite}
            onChange={(e) => {
              setappSuite(e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={4}>
          {/* <Autocomplete
            options={[]}
            freeSolo
            disablePortal
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label="Zip Code"
                name="Zip"
                variant="standard"
              />
            )}
            value={selectedZip}
          /> */}

          <Autocomplete
            disablePortal
            options={Array.isArray(zipList) ? zipList : []}
            fullWidth
            value={selectedZip}
            inputValue={zipinputValue}
            onInputChange={(event, newvalue) => {
              setzipInputValue(newvalue);
              newvalue?.length >= 3 && LoadZipExtender(event, newvalue);
            }}
            onKeyUp={(e) => {
              const inputElement = e.target as HTMLInputElement;
              if (selectedZip == "") {
                setselectedZipErr("This Field Is Required.");
              } else {
                setselectedZipErr("");
              }
            }}
            onChange={(event, newvalue) => {
              getZipAutoComplete(newvalue, "Zip");
            }}
            onBlur={ziponblur}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Zip Code"
                // disabled={props.disable}
                name="Zip"
                variant="standard"
                value={selectedZip}
                error={selectedZipErr !== ""}
                helperText={selectedZipErr}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    loadZip();
                    e.preventDefault();
                    setcountryfocus(true);
                  }
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={4}>
          <FormControl variant="standard" sx={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-helper-label">
              Country
            </InputLabel>

            <Select
              fullWidth
              name="Country"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="standard"
              sx={{ width: "100%", maxHeight: "300px" }}
              label=" Country"
              value={selectedCountry || ""}
              // onFocus={() => handleLoadOptions("Country")}
              onClick={() => handleLoadOptions("Country")}
              autoFocus={countryfocus}
              onBlur={() => {
                if (selectedCountry == 0) {
                  setselectedCountryerr("This Field Is Required.");
                } else {
                  setselectedCountryerr("");
                }
                setcountryfocus(false);
              }}
              error={selectedCountryerr !== ""}
            >
              {Array.isArray(countryData) ? (
                countryData.map((res: ICountryProps, index: number) => (
                  <MenuItem
                    key={index}
                    value={res.countryId}
                    onClick={(e) => {
                      handleDropDown(res, "Country");
                    }}
                  >
                    {res.country}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">
                  <em>No countries available</em>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4} md={4}>
          <FormControl variant="standard" sx={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-helper-label">State</InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="standard"
              sx={{ width: "100%", maxHeight: "300px" }}
              label="State"
              name="State"
              value={selectedState || ""}
              onFocus={() => handleLoadOptions("State")}
              error={selectedStateErr !== ""}
              onBlur={() => {
                if (selectedState == 0) {
                  setselectedStateErr("This Field Is Required.");
                } else {
                  setselectedStateErr("");
                }
              }}
            >
              {Array.isArray(stateData) && stateData?.length > 0 ? (
                stateData.map((res: IStateProps, index: number) => (
                  <MenuItem
                    key={index}
                    value={res.stateId}
                    onClick={(e) => {
                      handleDropDown(res, "State");
                    }}
                  >
                    {res.state}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">
                  <em>No states available</em>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4} md={4}>
          <FormControl variant="standard" sx={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-helper-label">City</InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="standard"
              sx={{ width: "100%", maxHeight: "300px" }}
              label="City"
              name="City"
              value={selectedCity || ""}
              onFocus={() => handleLoadOptions("City")}
              error={selectedCityErr !== ""}
              onBlur={() => {
                if (selectedCity == 0) {
                  setselectedCityErr("This Field Is Required.");
                } else {
                  setselectedCityErr("");
                }
              }}
            >
              {Array.isArray(citydata) && citydata?.length > 0 ? (
                citydata.map((res: ICityProps, index: number) => (
                  <MenuItem
                    key={index}
                    value={res.cityId}
                    onClick={(e) => {
                      handleDropDown(res, "City");
                    }}
                  >
                    {res.city}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">
                  <em>No cities available</em>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <TextField
            fullWidth
            label="Phone #"
            name="Phone"
            variant="standard"
            value={PhoneNumber}
            inputProps={{
              pattern: "[0-9]*",
              minLength: 10,
              maxLength: 10,
            }}
            onChange={(e: any) => {
              handleInputnumberChange(e);
            }}
            onKeyUp={(e) => {
              const inputElement = e.target as HTMLInputElement;
              const value = inputElement.value.replace(/[^0-9()-]/g, "");
              // if (value== "") {
              //   setPhoneNumbererr("This Field Is Required.");
              // } else

              if (value?.length > 9 || value?.length == 0) {
                setPhoneNumbererr("");
              }
            }}
            error={PhoneNumberErr !== ""}
            helperText={PhoneNumberErr}
          />
        </Grid>
      </Grid>

      {/* <Button
        fullWidth
        variant="contained"
        // onClick={handlecontinueToShip}
        onClick={HandleTabChange}
        sx={{ mt: 3 }}
      >
        Continue To Shipping Options
      </Button> */}
    </>
  );
};
export default BillingAddressFilling;
