import { Box, Button, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Slider from "react-slick";
import Banner1 from "../../staticimages/banner.jpg";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logo from "../../staticimages/torqstop.png";
import { IBannerProps } from "../../Types/MicrositeType";
import { useSelector } from "react-redux";

function SampleNextArrow(props: any) {
  const { style, onClick } = props;
  return (
    <Button
      variant="contained"
      style={{
        ...style,
        display: "block",
        top: "50%",
        padding: "6px",
        position: "absolute",
        right: "10px",
        zIndex: "99",
        transform: "translate(0, -50%)",
        minWidth: "auto",
        lineHeight: 1,
        background: "rgb(64 64 64)",
        borderRadius: "50px",
      }}
      onClick={onClick}
    >
      <KeyboardArrowRightOutlinedIcon sx={{ lineHeight: 1 }} />
    </Button>
  );
}

function SamplePrevArrow(props: any) {
  const { style, onClick } = props;
  return (
    <Button
      variant="contained"
      style={{
        ...style,
        display: "block",
        top: "50%",
        padding: "6px",
        position: "absolute",
        left: "10px",
        zIndex: "99",
        transform: "translate(0, -50%)",
        minWidth: "auto",
        lineHeight: 1,
        background: "rgb(67 67 67)",
        borderRadius: "50px",
      }}
      onClick={onClick}
    >
      <KeyboardArrowLeftOutlinedIcon sx={{ lineHeight: 1 }} />
    </Button>
  );
}
const settings = {
  dots: false,
  fade: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 500,
  // lazyLoad: "progressive",
  autoplaySpeed: 5000,
  cssEase: "linear",
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};
export default function BannerSlider({bannerName}:any) {
  const showOnMobile = window.innerWidth < 769;
  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);
  let url =
  EnvData?.REACT_APP_CDNURL +
  "/Microsite/" +
  EnvData?.REACT_APP_MICROSITENAME +
  "/Banner/";

  return (
    <Box>
      <div>
        <Slider {...settings}>
          {bannerName.map((elem:IBannerProps, index:number)=>{
            const encodedUrl = url + elem.bannerName;
            return (
              <div className="torqstop-banner-slider-div">
              {/* <Box
                className="torqstop-banner-slider-container"
                sx={{ backgroundImage: `url('${encodedUrl}')` }}
              >
                <Container sx={{ position: "relative" }}>
                  <Box className="torqstop-banner-slider-content">
                  <a href={elem.url} key={index} target="_blank">
                  <img src={url + elem.bannerName} alt="Banner images" />
                </a>
                    <img src={logo} alt="banner-logo" />
                    <Typography variant="h6" color="primary.contrastText" ml={2}>
                      {" "}
                      Protect your investment and keep your feet <br /> Running
                      Strong While staying on budget
                    </Typography>
                  </Box>
                </Container>
              </Box> */}
 <div key={index}>
              {elem.url != "" ? (
                <a href={elem.url} key={index} target="_blank">
                  <img src={url + elem.bannerName} alt="Banner images" />
                </a>
              ) : (
                <img src={url + elem.bannerName} alt="Banner images" />
              )}
            </div>

             </div>
             
            )

          })}
          {/* <div className="torqstop-banner-slider-div">
            <Box
              className="torqstop-banner-slider-container"
              sx={{ backgroundImage: `url(${Banner1})` }}
            >
              <Container sx={{ position: "relative" }}>
                <Box className="torqstop-banner-slider-content">
                  <img src={logo} alt="banner-logo" />
                  <Typography variant="h6" color="primary.contrastText" ml={2}>
                    {" "}
                    Protect your investment and keep your feet <br /> Running
                    Strong While staying on budget
                  </Typography>
                </Box>
              </Container>
            </Box>
          </div> */}
          {/* <div className="banner-slider-div">
            <Box
              className="banner-slider-container"
              sx={{ backgroundImage: `url(${Banner1})` }}
            >
              <Container sx={{ position: "relative" }}>
                <Box className="banner-slider-content">
                  <img src={logo} alt="banner-logo" />
                  <Typography variant="h6" color="primary.contrastText" ml={2}>
                    {" "}
                    Protect your investment and keep your feet <br /> Running
                    Strong While staying on budget
                  </Typography>
                </Box>
              </Container>
            </Box>
          </div> */}
           {/* <div className="torqstop-banner-slider-div">
            <Box
              className="torqstop-banner-slider-container"
              sx={{ backgroundImage: `url(${Banner1})` }}
            >
              <Container sx={{ position: "relative" }}>
                <Box className="torqstop-banner-slider-content">
                  <img src={logo} alt="banner-logo" />
                  <Typography variant="h6" color="primary.contrastText" ml={2}>
                    {" "}
                    Protect your investment and keep your feet <br /> Running
                    Strong While staying on budget
                  </Typography>
                </Box>
              </Container>
            </Box>
          </div> */}
        </Slider>
      </div>
    </Box>
  );
}
