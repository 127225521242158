import React, { useEffect, useRef } from 'react';

const Warranty = () => {
  const scriptContainerRef = useRef<any>(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://form.jotform.com/jsform/230374477306154";
    script.async = true;

    if (scriptContainerRef.current) {
      scriptContainerRef.current.appendChild(script);
    }

    return () => {
      if (scriptContainerRef.current) {
        scriptContainerRef.current.removeChild(script);
      }
    };
  }, []);

  return (
    <div>
      <div ref={scriptContainerRef}></div>
      {/* Your other JSX elements here */}
    </div>
  );
};

export default Warranty;
