import React, { useState, useEffect } from "react";
import { GetData } from "../../API/GetDataFromApi";
import { useSelector } from "react-redux";
import { EnvironmentSettings } from "../../Types/MicrositeType";

const GridApifetch = (raw: any , EnvData:EnvironmentSettings): Promise<any> => {

  // let env = (state: any) => state.ENV_SETTINGS;
  // const EnvData = useSelector(env);

  return GetData(
    EnvData?.REACT_APP_APIURL +
      "/microsite-api-v1/get-part-search-grid-data",
    raw
  )
    .then((res: any) => {
      if (res.length == 0) {
        alert("No records found ");
        return res;
      } else {
        return res;
      }
    })

    .catch((err: any) => {
      return err;
    });
};

export default GridApifetch;
