import {
  Typography,
  Container,
  Paper,
  Box,
  Grid,
  Divider,
} from "@mui/material";

// import { useGetData } from "../APIHooks/useGetData";
import { useEffect, useState } from "react";
import { GetData, Get_Data } from "../API/GetDataFromApi";
import { IAboutUs } from "../Types/MicrositeType";
import Loader from "../Components/Loader";
// import { CollectionsOutlined, UndoRounded } from "@mui/icons-material";
import { useSelector } from "react-redux";



export const AboutUs = (props: { _seoTitle: string }) => {
  const [aboutus, setData] = useState<IAboutUs[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
 

  let ImgURL = "";
  let AboutusAltText = "";
  let Content = "";

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);


  useEffect(() => {

      (async () => {
       
        setLoading(true)
        
        var raw = JSON.stringify({
          parray: ["MicrositeId", EnvData?.REACT_APP_MICROSITEID],
        });
        Get_Data(EnvData?.REACT_APP_APIURL+"/microsite-api-v1/get-about-us", raw)
          .then((results?) => {
            // console.log(results ," res from About us")
            setData(results.data);
            setLoading(false);
          })
          .catch((err) => {
            console.error(err);
            //   localStorage.removeItem("user");
          });
      })();
    
  
  }, []);

  {
    aboutus &&
      aboutus.map(
        (elem1) => (
          (ImgURL = elem1.imgURL),
          (AboutusAltText = elem1.aboutusAltText),
          (Content = elem1.content)
        )
      );
  }

  const newStr = Content.replace(/(<([^>]+)>)/gi, "");

  //console.log("--end about us page--");
  return (
    <>
      {loading !== true ? (
        <>
          <div>
            <Paper elevation={0} sx={{ mt: 2, mb: 1, py: 3 }}>
              <Container>
                {/* <Typography variant="h1" align="center">
             H1 Tag Content
            </Typography>
          <Typography variant="body1" pb={3} align="center">
                H2 Tag Content
          </Typography> */}
                <Box sx={{ mb: 1 }}>
                  <div className="product-right-border">
                    <Typography variant="h4" pb={1}>
                      {props._seoTitle}
                      <Divider
                        sx={{ bgcolor: "primary.main" }}
                        className="title-divider"
                      />
                    </Typography>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Box className="about-img-sec" key="0">
                        <div className="about-img">
                          <img
                            src={ImgURL}
                            alt={AboutusAltText}
                            loading="lazy"
                          />
                          {/* <Box
                      className="about-img-layer"
                      sx={{ bgcolor: "primary.main" }}
                    ></Box> */}
                        </div>

                        <Typography
                          align="justify"
                          dangerouslySetInnerHTML={{
                            __html: Content,
                          }}
                        ></Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Container>
            </Paper>
          </div>
        </>
      ) : (
        <>
          <Loader />
        </>
      )}
    </>
  );
};
export default AboutUs;
