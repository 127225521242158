import { Box, Button, Grid, IconButton, Paper, Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Slider from "react-slick";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import category1 from "../../../Images/centralturbos/category1.png";
import category2 from "../../../Images/centralturbos/category2.png";
import category3 from "../../../Images/centralturbos/category3.png";
import category4 from "../../../Images/centralturbos/category4.png";
import "../../../Css/centralturbos.style.css";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';


import eventEmitter from "../../PartSearchTest/eventEmmiter";
import Description from "./../description";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import {
  addCategoryfilter,
  trigger,
  addCategorySelection,
} from "../../../Redux/Counterslice";
import { useEffect, useState } from "react";
import { GetData, GetDataMultiResults } from "../../../API/GetDataFromApi";
import { CATEGORY_FILTER, BRAND_FILTER } from "../../../Redux/ReduxConstants";
import { IProductProps } from "../../../Types/MicrositeType";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";








export default function CentralTurbosCategoryProducts(elem:any ,  props?:any) {
  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);
  let url =
    EnvData?.REACT_APP_CDNURL +
    "/Microsite/" +
    EnvData?.REACT_APP_MICROSITENAME +
    "/Category/ProductImage/";
    const dispatch = useDispatch();
 
  let partsearchlink = `/Products/${elem.elem.productName}?`;


  function handleCategory(res: any) {
    if (elem.props.partsearch == true) {
      let type = {
        type: CATEGORY_FILTER,
        categoryId: res.categoryId,
        categoryName: res.productName,
      };
      dispatch(addCategoryfilter(type));
      dispatch(addCategorySelection(false));
      eventEmitter.emit("triggerAutoSelectFilter", { type });
      
    }
  }
          return (
            <div>
              {
                elem.props.partsearch == true ? (
                  <div className="ct-categorybox"
                 
                  >
                  <div className="ct-category-inner">
                  <img
                   onClick={() => handleCategory(elem.elem)}
                              src={
                                elem.elem.productImage !== ""
                                  ? url + elem.elem.productImage
                                  : EnvData?.REACT_APP_NOIMAGEUR
                              }
                              alt="category1"
                              loading="lazy"
                            />
                    </div>
                    <Typography className="ct-categorytitle" variant="subtitle1">
                    {elem.elem.productName}
                    </Typography>
                  </div>
                ):(
                  <Link to={partsearchlink}>
 <div className="ct-categorybox" >
                  <div className="ct-category-inner">
                  <img
                              src={
                                elem.elem.productImage !== ""
                                  ? url + elem.elem.productImage
                                  : EnvData?.REACT_APP_NOIMAGEUR
                              }
                              alt="category1"
                              loading="lazy"
                            />
                    </div>
                    <Typography className="ct-categorytitle" variant="subtitle1">
                    {elem.elem.productName}
                    </Typography>
                  </div>
                  </Link>
                 
                )
              }

           
          </div>
          )

}



function SampleNextArrow(props: any) {
  const { style, onClick } = props;
  return (
   <Box className="slider-arrow-left" onClick={onClick}>
        <KeyboardArrowRightIcon />
    </Box>
  );
}

function SamplePrevArrow(props: any) {
  const { style, onClick } = props;
  return (
    <Box className="slider-arrow-right" onClick={onClick}>
       <KeyboardArrowLeftIcon />
    </Box>
  );
}


const settings = {
  dots: false,
  infinite: false,
  slidesToShow:4,
  slidesToScroll: 1,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  cssEase: "linear",
  className: "",
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

export function CentralTurbosCategorySlider(
  props: {
    count?: number;
    _product: IProductProps[];
    partsearch?: boolean;
    selectcat?: any;
  }
) {
  const [Brands, setData] = useState<IProductProps[]>([]);

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);
  let url =
    EnvData?.REACT_APP_CDNURL +
    "/Microsite/" +
    EnvData?.REACT_APP_MICROSITENAME +
    "/Category/ProductImage/";
  let run = true;

  if (props._product.length > 0) {
    run = false;
  }


  useEffect(() => {
    if (run == true) {
      (async () => {
        GetDataMultiResults(
          EnvData?.REACT_APP_APIURL + "/microsite-api-v1/get-top-products"
        )
          .then((results?) => {
            setData(results.Table);
          })
          .catch((err) => {
            console.error(err);
          });
      })();
    }
  }, []);
  var brand: IProductProps[] = [];
  if (props._product.length > 0) {
    brand = props._product;
  } else {
    brand = Brands;
  }


  return(
    <div>
      {props.partsearch == true && props._product.length > 0 ? (
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" className="central-turbos-title">Category Search</Typography>
        </Box>
      ) : (
        <>
          
        </>
      )}
      {
        props.partsearch ? (<>
         <Grid container spacing={2}>
         {brand?.map((elem, index) => {
          let partsearchlink = `/Products/${elem.productName}?`;
          return (
            <Grid item xs={12} sm={4} md={3} key={index}>
  <CentralTurbosCategoryProducts elem={elem} props={props}/>
              </Grid>
         
          )
          
        }
      )}
         </Grid>
        
        </>):(<>
         <Box>
          <Slider {...settings}>
          {brand?.map((elem, index) => {
          let partsearchlink = `/Products/${elem.productName}?`;
          return (
          //   <div>
          //   <div className="ct-categorybox">
          //     <div className="ct-category-inner">
          //     <img
          //                 src={
          //                   elem.productImage !== ""
          //                     ? url + elem.productImage
          //                     : EnvData?.REACT_APP_NOIMAGEUR
          //                 }
          //                 alt="category1"
          //                 loading="lazy"
          //               />
          //       </div>
          //       <Typography className="ct-categorytitle" variant="subtitle1">
          //       {elem.productName}
          //       </Typography>
          //     </div>
          // </div>
          <CentralTurbosCategoryProducts elem={elem} props={props.partsearch} />
          )
          
        }
      )}
             
             
            
          </Slider>
        </Box></>)
      }
       
    </div>
  )

}