import React , {useState , useEffect , useRef} from 'react';
import USAMap from "react-usa-map";
import {
    Typography,
    Container,
    Paper,
    Box,
    Grid,
    Divider,
    Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  IconButton,
  Link,
  } from "@mui/material";
import { orange } from '@mui/material/colors';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Get_Data } from '../API/GetDataFromApi';
import { useDispatch, useSelector } from "react-redux";


const Maps = () => {
    const [Data, setData] = useState([]);
    const [TerritoryData, SetTerritoryData] = useState([]);
    const [showPopup, setshowPopup] = useState(false);
    const prevSelectedStateRef = useRef(null);
 
      let env = (state) => state.ENV_SETTINGS;
      const EnvData = useSelector(env);

      useEffect(() => {
        Get_Data(
          EnvData?.REACT_APP_APIURL +
            "/microsite-api-v1/get-territoryinfo" 
        )
        .then((res)=>{
       
          SetTerritoryData(res?.data)
        })
   
      }, [])
    function handler(event) {
  
        const stateName = event.target.dataset.name;
        const filtered = TerritoryData.filter((item) => item.territory == stateName);  
        if (prevSelectedStateRef.current) {
          prevSelectedStateRef.current.style.fill = 'rgb(211, 211, 211)';
        }    
        if (event.target === prevSelectedStateRef.current) {
          prevSelectedStateRef.current = null;
          setData([]);
        } else {
          event.target.style.fill = "#f7931e";
          prevSelectedStateRef.current = event.target;
          setData(filtered);
          setshowPopup(true)
        }
      }
      
      const statesCustomConfig = () => {
        return {
          NJ: { fill: 'navy' }, // Customize New Jersey color to navy
          NY: { fill: '#navy' }, // Customize New York color to a custom color
          // Add more states and their custom colors as needed
        };
      };

      const closeLightbox = () => {
        setshowPopup(false);

      };
  return (
    <>
     
    <div className='Territory-Maps'>
    {/* <Paper elevation={0} sx={{ mt: 2, mb: 1, py: 3 }}></Paper>  */}
    <div  className="USA-Map">
    <USAMap onClick={handler} customize={statesCustomConfig}  />
    
    </div>

    <Dialog
            onClose={closeLightbox}
            open={showPopup}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            fullWidth={true}
            maxWidth="md"
            // sx={{  padding: 0 }}
          >
        
            <DialogContent  sx={{  padding: 0 }}>
            <IconButton
                className="brawerclosebutton"
                onClick={closeLightbox}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                 
                }}
              >

                <CloseRoundedIcon />

              
              </IconButton>
              <Box>
              {Data.length!==0 ?( <TableContainer className='Territory-TableContainer' >
      <Table sx={{  width: 'inherit' }} aria-label="simple table">
        <TableHead>
          <TableRow className='Territory-Tableheader'> 
            <TableCell className='Territory-Tableheader-cells'  style={{ width: '3%' }} >Territory</TableCell>
            <TableCell className='Territory-Tableheader-cells'  align="left">Principal</TableCell>
            <TableCell className='Territory-Tableheader-cells' align="left">Rep Group</TableCell>
            <TableCell className='Territory-Tableheader-cells' align="left" style={{ width: '150px' }} >Phone </TableCell>
            <TableCell className='Territory-Tableheader-cells' align="left"  >Email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Data.map((row) => (
            <TableRow
            key={row.territory}
            sx={{ '&:last-child td, &:last-child th': { border: 0 , padding:' 3% 1.8%'  } }}
          >
            <TableCell sx={{ fontSize:17  }} component="th" scope="row">
              {row.territory}
            </TableCell>
            <TableCell sx={{ fontSize:17 }} align="left">{row.agencyPrincipal}</TableCell>
            <TableCell sx={{ fontSize:17 }} align="left">{row.repGroup}</TableCell>
            <TableCell sx={{ fontSize:17 }} align="left">
              <Link href={"tel:" + row.principalPhone} style={{ textDecoration: 'none' , color:'black' }}>
                {row.principalPhone}
              </Link>
            </TableCell>
            <TableCell sx={{ fontSize:17 }} align="left">
              <Link href={"mailto:" + row.principalEmail} target={"_blank"} style={{ textDecoration: 'none' , color:'black' }}>
                {row.principalEmail}
              </Link>
            </TableCell>
          </TableRow>
          
          ))}
        </TableBody>
      </Table>
    </TableContainer>):null}
              </Box>
            </DialogContent>
          </Dialog>
    {/* {Data.length!==0 ?( <TableContainer component={Paper} sx={{ maxWidth: 850 ,width: 750 , height:110}}>
      <Table sx={{ maxWidth: 1000, width: 749 }} aria-label="simple table">
        <TableHead>
          <TableRow className='Territory-Tableheader'> 
            <TableCell className='Territory-Tableheader-cells'>Territory</TableCell>
            <TableCell className='Territory-Tableheader-cells' align="left">Principal</TableCell>
            <TableCell className='Territory-Tableheader-cells' align="left">Rep Group</TableCell>
            <TableCell className='Territory-Tableheader-cells' align="left">Phone </TableCell>
            <TableCell className='Territory-Tableheader-cells' align="left">Email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Data.map((row) => (
            <TableRow
            key={row.Territory}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.Territory}
            </TableCell>
            <TableCell align="left">{row.AgencyPrincipal}</TableCell>
            <TableCell align="left">{row.RepGroup}</TableCell>
            <TableCell align="left">
              <Link href={"tel:" + row.PrincipalPhone} style={{ textDecoration: 'none' , color:'black' }}>
                {row.PrincipalPhone}
              </Link>
            </TableCell>
            <TableCell align="left">
              <Link href={"mailto:" + row.PrincipalEmail} target={"_blank"} style={{ textDecoration: 'none' , color:'black' }}>
                {row.PrincipalEmail}
              </Link>
            </TableCell>
          </TableRow>
          
          ))}
        </TableBody>
      </Table>
    </TableContainer>):null} */}
   
    </div>
        
    </>
  )
}

export default Maps 