import React, { useEffect, useRef, useState } from "react";

import {
  Button,
  styled,
  MenuItem,
  Select,
  FormControl,
  TextField,
  Autocomplete,
  lighten,
  darken,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import FilterExtender from "./FilterExtender";
import { useDispatch, useSelector } from "react-redux";

import {
  EXTENDER_BRAND_FILTER,
  EXTENDER_CROSSREFERENCE_FILTER,
  DESCRIPTION_FILTER,
  BRAND_FILTER,
  CATEGORY_FILTER,
} from "../../Redux/ReduxConstants";
import {
  addExBrandfilter,
  addExCrossReffilter,
  addSearchtext,
  addVSyear,
  addVSmake,
  addVSmodel,
  addVSengine,
  addApplicationfilters,
  ClearApplicationfilters,
  AddAttributefilters,
  clearattributefilters,
  trigger,
  addDescriptionSelection,
  addBrandSelection,
  addCategorySelection,
  addCategoryfilter,
  clearCategorySelection,
  addBrandfilter,
  clearBrandSelection,
  clearDescriptionSelection,
  addDesfilter,
  addVehicleSearchFilterExists,
} from "../../Redux/Counterslice";
import { useResetGeneralSearch, useResetdata } from "../../Redux/Resetdata";
import FilterApiFetch from "./FilterApiFetch";
import { IPartsearchAttributeTypeProps } from "../../Types/MicrositeType";
import { AttributeFilter } from "../Partsearch/AttributeFilter";
import { Clear } from "@mui/icons-material";
import eventEmitter from "./eventEmmiter";
import { Get_Data } from "../../API/GetDataFromApi";

const GeneralSearch = ({ setTab, values }: any) => {
  const [AttributesFilters, setAttributesFilters] = useState<
    IPartsearchAttributeTypeProps[]
  >([]);
  const [Catopen, setCatOpen] = React.useState(false);
  const [Brandopen, setBrandOpen] = React.useState(false);
  const [Partdesopen, setPartdesOpen] = React.useState(false);
  const [options, setOptions] = React.useState<any>([]);
  const [flterraw, setflterraw] = useState<IPartsearchAttributeTypeProps[]>([]);
  const dispatch = useDispatch();
  const selectData = (state: any) => state;
  const data = useSelector(selectData);
  const [showattribute, setshowattribute] = useState<boolean>(false);
  const [Keyname, setKeyname] = useState("");
  // const [AttributeType, setAttributeType] = useState<string | undefined>("");
  const [SubAttributesFilters, setSubAttributesFilters] = useState<
    IPartsearchAttributeTypeProps[]
  >([]);
  const [SelectedCatValue, setSelectedCatValue] = useState<[]>([]);
  const [SelectedBrandValue, setSelectedBrandCatValue] = useState<[]>([]);
  const [SelectedDescValue, setSelectedDescCatValue] = useState<[]>([]);
  let AttributeType = "";

  const [Category, setCategory] = useState(null);
  const [Brand, setBrand] = useState(null);
  const [Description, setDescription] = useState(null);

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  const [state, setState] = useState({
    extBrandFilter: data.EXTENDER_BRAND_FILTER.brandId,
    extcompetitorID: data.EXTENDER_CROSSREFERENCE_FILTER.CompetitorId,
    extcompetitiorPartNumber:
      data.EXTENDER_CROSSREFERENCE_FILTER.CompetitorpartNumber,
    categoryFilter: data.CATEGORY_FILTER.categoryName,
    categoryId: data.CATEGORY_FILTER.categoryId,
    brandFilter: data.BRAND_FILTER.brandId,
    descriptionFilterr: data.DESCRIPTION_FILTER.descriptionId,
    YearFilter: data.YEAR_FILTER.YearName,
    makefilter: data.MAKE_FILTER.MakeName,
    ModelFilter: data.MODEL_FILTER.ModelName,
    EngineFilter: data.ENGINE_FILTER.EngineName,
    AttributeFiltervalus: data.ATTRIBUTE_FILTER_WITH_VALUE.items,
    ApplicationFiltervalus: data.APPLICATION_FILTER_WITH_VALUE.items,
    EnteredText: data.EXTENDER_TEXT_SEARCH.Text,
  });

  const { ResetreduxData } = useResetdata();

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      extBrandFilter: data.EXTENDER_BRAND_FILTER.brandId,
      extcompetitorID: data.EXTENDER_CROSSREFERENCE_FILTER.CompetitorId,
      extcompetitiorPartNumber:
        data.EXTENDER_CROSSREFERENCE_FILTER.CompetitorpartNumber,
      categoryFilter: data.CATEGORY_FILTER.categoryName,
      brandFilter: data.BRAND_FILTER.brandId,
      descriptionFilterr: data.DESCRIPTION_FILTER.descriptionId,
      YearFilter: data.YEAR_FILTER.YearName,
      makefilter: data.MAKE_FILTER.MakeName,
      ModelFilter: data.MODEL_FILTER.ModelName,
      EngineFilter: data.ENGINE_FILTER.EngineName,
      AttributeFiltervalus: data.ATTRIBUTE_FILTER_WITH_VALUE.items,
      ApplicationFiltervalus: data.APPLICATION_FILTER_WITH_VALUE.items,
      EnteredText: data.EXTENDER_TEXT_SEARCH.Text,
    }));

    if (data.CATEGORY_FILTER.categoryName == "") {
      setCategory(null);
    }

    if (data.BRAND_FILTER.brandId == "") {
      setBrand(null);
    }
    if (data.DESCRIPTION_FILTER.descriptionId == "") {
      setDescription(null);
    }
  }, [data]);

  useEffect(() => {
    // console.log("setBrand", data.BRAND_FILTER.brandId);
    if (state.descriptionFilterr !== "") {
      AttributeType = "Attribute Search";
      triggerGrid("Attribute");
    }
    // console.log("setBrand", data.BRAND_FILTER.brandId);
    // if (data.BRAND_FILTER.brandId !== "") {
    //   triggerGrid("BrandName");
    //   let brandf: any = {
    //     KeyId: data.BRAND_FILTER.brandId,
    //     KeyName: data.BRAND_FILTER.brandName,
    //   };
    //   console.log("setBrand f", brandf);

    //   setBrand(brandf);
    // }

    eventEmitter.on("triggerFunction", resetGeneralsearchState);
    // eventEmitter.on("triggersearchfilter",  TriggerAttribute);

    return () => {
      // ResetreduxData()
      eventEmitter.off("triggerFunction", resetGeneralsearchState);
    };
  }, []);

  useEffect(() => {
    eventEmitter.on("triggerAutoSelectFilter", (data: any) => {
      CheckAutoSelection("MicrositeCategory", data.type.categoryId);
      ShowGrid();
    });
    return () => {
      eventEmitter.off(
        "triggerAutoSelectFilter",
        CheckAutoSelection("MicrositeCategory", "")
      );
    };
  }, []);

  useEffect(() => {
    if (state.descriptionFilterr !== "") {
      //setAttributeType("Attribute Search");
      AttributeType = "Attribute Search";

      triggerGrid("Attribute");
    } else {
      setAttributesFilters([]);
    }

    // else {
    //   triggerGrid(Keyname);
    // }
  }, [
    state.AttributeFiltervalus,
    state.categoryFilter,
    state.brandFilter,
    state.descriptionFilterr,
  ]);

  const newFilteredArray = state.AttributeFiltervalus.filter(
    (value: any) => value.AttributeName !== Keyname
  );

  const destinationArray = newFilteredArray.map((obj: any) => ({
    attributeId: obj.Attributeid,
    attributeValue: obj.AttributeValue,
    fromValue: obj.Attributefrom,
    toValue: obj.Attributeto,
  }));

  const newFilteredAppArray = state.ApplicationFiltervalus.filter(
    (value: any) => value.ApplicationName !== Keyname
  );

  const appdesitinationarray = newFilteredAppArray.map((obj: any) => ({
    applicationId: obj.Applicationid,
    applicationValue: obj.ApplicationValue,
  }));

  function ShowGrid() {
    // dispatch(addApplicationfilters(appdesitinationarray))
    // dispatch(AddAttributefilters(destinationArray))
    if (state.descriptionFilterr !== "") {
      dispatch(addDescriptionSelection(false));
    }
    if (state.brandFilter !== "") {
      dispatch(addBrandSelection(false));
    }
    if (state.categoryFilter !== "") {
      dispatch(addCategorySelection(false));
    }

    setTimeout(() => dispatch(trigger()), 200);

    if (state.descriptionFilterr !== "") {
      //setAttributeType("Attribute Search");
      AttributeType = "Attribute Search";
      triggerGrid("Attribute");
    } else {
      triggerGrid(Keyname);
    }
  }

  // function TriggerAttribute ( ){

  //   console.log(" atrribte notdone")
  //   if (state.descriptionFilterr !== "") {
  //     //setAttributeType("Attribute Search");
  //     AttributeType = "Attribute Search";
  //     console.log(" atrribte done")
  //     triggerGrid("Attribute");
  //   } else {
  //     triggerGrid(Keyname);
  //   }
  // }

  function triggershowgrd() {
    ShowGrid();
  }

  function triggerGrid(keynme: string) {
    // if(keynme !=='Attribute' ){
    //   setAttributeType("");
    // }

    if (keynme != "") {
      setKeyname(keynme);
      let raw = JSON.stringify({
        keyName: keynme,
        sessionid: "",
        keyvalue:
          state.extcompetitorID == "" &&
          state.extcompetitiorPartNumber == "" &&
          state.extBrandFilter == ""
            ? state.EnteredText
            : "",
        brandId: state.extBrandFilter,
        competitorId: state.extcompetitorID,
        make: keynme?.toLowerCase() == "make" ? "" : state.makefilter,
        model: keynme?.toLowerCase() == "model" ? "" : state.ModelFilter,
        year: keynme?.toLowerCase() == "year" ? "" : state.YearFilter,
        engine: keynme?.toLowerCase() == "engine" ? "" : state.EngineFilter,
        category:
          keynme?.toLowerCase() == "MicrositeCategory"
            ? ""
            : state.categoryFilter,
        brandFilter:
          keynme?.toLowerCase() == "BrandName" ? "" : state.brandFilter,
        type:
          keynme?.toLowerCase() == "make" ||
          keynme?.toLowerCase() == "model" ||
          keynme?.toLowerCase() == "year" ||
          keynme?.toLowerCase() == "engine" ||
          keynme?.toLowerCase() == "MicrositeCategory" ||
          keynme?.toLowerCase() == "BrandName" ||
          keynme?.toLowerCase() == "PartDescription"
            ? ""
            : AttributeType,
        descriptions:
          keynme?.toLowerCase() == "PartDescription"
            ? ""
            : state.descriptionFilterr,
        competitorPartno: state.extcompetitiorPartNumber,
        attributeFilters: destinationArray,
        applicationFilters: appdesitinationarray,
      });
      (async () => {
        FilterApiFetch(raw, EnvData).then((res: any) => {
          if (res.length > 0) {
            if (keynme == "MicrositeCategory") {
              setSelectedCatValue(res);
            } else if (keynme == "BrandName") {
              setSelectedBrandCatValue(res);
            } else if (keynme == "PartDescription") {
              setSelectedDescCatValue(res);
            } else if (AttributeType == "Attribute Search") {
              setAttributesFilters(res);
            } else if (AttributeType == "Attribute") {
              setSubAttributesFilters(res);
            }
          } else {
            if (
              (keynme == "MicrositeCategory" ||
                keynme == "BrandName" ||
                keynme == "PartDescription") &&
              AttributeType !== "Attribute Search" &&
              AttributeType !== "Attribute"
            ) {
              let name =
                keynme == "MicrositeCategory"
                  ? "Category"
                  : keynme == "BrandName"
                  ? "Brand"
                  : keynme == "PartDescription"
                  ? "Description"
                  : "";
              alert(name + " Filters Not Available");
            }

            if (keynme == "MicrositeCategory") {
              setSelectedCatValue([]);
            } else if (keynme == "BrandName") {
              setSelectedBrandCatValue([]);
            } else if (keynme == "PartDescription") {
              setSelectedDescCatValue([]);
            } else if (AttributeType == "Attribute Search") {
              setAttributesFilters([]);
            } else if (AttributeType == "Attribute") {
              setSubAttributesFilters([]);
            }
          }
        });
      })();
    }
  }

  const loading = Catopen && SelectedCatValue.length === 0;
  const brandloading = Brandopen && SelectedBrandValue.length === 0;
  const partdescloading = Partdesopen && SelectedDescValue.length === 0;

  function CurrentData(newValue: any) {
    // setSelectedCatValue(newValue);

    if (
      Keyname === "MicrositeCategory" ||
      Keyname === "BrandName" ||
      Keyname === "PartDescription"
    ) {
      CheckAutoSelection(Keyname, newValue.keyId);
      DispatchFilters(Keyname, newValue, "Manualselect");
    } else {
      console.error("Key name is incorrect");
    }
    // if (Keyname == "MicrositeCategory") {
    //   let type = {
    //     type: CATEGORY_FILTER,
    //     categoryId: newValue.keyId,
    //     categoryName: newValue.keyName,
    //   };
    //   dispatch(addCategoryfilter(type));
    //   dispatch(clearCategorySelection());
    // } else if (Keyname == "BrandName") {
    //   let type = {
    //     type: BRAND_FILTER,
    //     brandId: newValue.keyId,
    //     brandName: newValue.keyName,
    //   };
    //   dispatch(addBrandfilter(type));
    //   dispatch(clearBrandSelection());
    // } else if (Keyname == "PartDescription") {
    //   let type = {
    //     type: DESCRIPTION_FILTER,
    //     descriptionId: newValue.keyId,
    //     desDescriptionName: newValue.keyName,
    //   };
    //   dispatch(addDesfilter(type));
    //   dispatch(clearDescriptionSelection());
    // } else {
    //   console.error("Key name is incorrect");
    // }
  }

  function DispatchFilters(Keyname: string, newValue: any, from: string) {
    if (Keyname == "MicrositeCategory") {
      let type = {
        type: CATEGORY_FILTER,
        categoryId: newValue.keyId,
        categoryName: newValue.keyName,
      };
      dispatch(addCategoryfilter(type));
      if (from === "AutoSelect") {
        dispatch(addCategorySelection(false));
      }
    } else if (Keyname == "BrandName") {
      let type = {
        type: BRAND_FILTER,
        brandId: newValue.keyId,
        brandName: newValue.keyName,
      };
      dispatch(addBrandfilter(type));
      if (from === "AutoSelect") {
        dispatch(addBrandSelection(false));
      }
    } else if (Keyname == "PartDescription") {
      let type = {
        type: DESCRIPTION_FILTER,
        descriptionId: newValue.keyId,
        desDescriptionName: newValue.keyName,
      };
      dispatch(addDesfilter(type));
      if (from === "AutoSelect") {
        dispatch(addDescriptionSelection(false));
      }
    }
  }

  function CheckAutoSelection(keyname: string, keyId: string) {
    if (
      state.extcompetitorID === "" &&
      state.extcompetitiorPartNumber == "" &&
      state.extBrandFilter === "" &&
      state.EnteredText === "" &&
      state.extBrandFilter === "" &&
      state.extcompetitorID === "" &&
      state.makefilter === "" &&
      state.ModelFilter === "" &&
      state.YearFilter === "" &&
      state.EngineFilter === "" &&
      (state.categoryFilter !== "" ||
        (state.brandFilter !== "" && state.descriptionFilterr !== ""))
    ) {
      let categoryId = "0";
      let brandId = "0";
      let descriptionId = "0";
      if (keyname === "MicrositeCategory") {
        categoryId = keyId;
      } else {
        categoryId = state.categoryId !== "" ? state.categoryId : "0";
      }

      if (keyname === "BrandName") {
        brandId = keyId;
      } else {
        brandId = state.brandFilter !== "" ? state.brandFilter : "0";
      }

      if (keyname === "PartDescription") {
        descriptionId = keyId;
      } else {
        descriptionId =
          state.descriptionFilterr !== "" ? state.descriptionFilterr : "0";
      }

      var URL =
        EnvData?.REACT_APP_APIURL +
        "/microsite-api-v1/get-part-search-auto-selection-filters?categoryId=" +
        categoryId +
        "&descriptionId=" +
        descriptionId +
        "&brandId=" +
        brandId;
      Get_Data(URL)
        .then((results?) => {
          // console.log(results.data, " get-part-search-auto-selection-filters");

          if (results.data.brandId !== 0) {
            let brandtype: any = {
              keyId: results.data.brandId,
              keyName: results.data.brandName,
            };
            setBrand(brandtype);
            DispatchFilters("BrandName", brandtype, "AutoSelect");
          }
          if (results.data.categoryId !== 0) {
            let categorytype: any = {
              keyId: results.data.categoryId,
              keyName: results.data.categoryName,
            };
            setCategory(categorytype);
            DispatchFilters("MicrositeCategory", categorytype, "AutoSelect");
          }
          if (results.data.descriptionId !== 0) {
            let descriptiontype: any = {
              keyId: results.data.descriptionId,
              keyName: results.data.descriptionName,
            };
            setDescription(descriptiontype);
            DispatchFilters("PartDescription", descriptiontype, "AutoSelect");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  function SelectAttribute(key: any, keytype: any) {
    //setAttributeType("Attribute");
    AttributeType = "Attribute";
    setKeyname(key);
    triggerGrid(key);
  }

  function Handleclick(keyname: string) {
    setKeyname(keyname);
    AttributeType = "";
  }

  function ResetData() {
    ResetreduxData();
    // let Inpval = "";
    // dispatch(addSearchtext(Inpval));
    resetGeneralsearchState();
    setTimeout(() => dispatch(trigger()), 200);
  }
  function resetGeneralsearchState() {
    setAttributesFilters([]);
    setSubAttributesFilters([]);

    setSelectedDescCatValue([]);
    setSelectedBrandCatValue([]);
    setSelectedCatValue([]);
    dispatch(ClearApplicationfilters([]));
    dispatch(clearattributefilters([]));
    dispatch(addVehicleSearchFilterExists(true));
    // triggerGrid();
    setDescription(null);
    setBrand(null);
    setCategory(null);
    setKeyname("");
    eventEmitter.emit("triggerresetCompetiter", {});
  }
  return (
    <>
      <Box sx={{ mb: 2, minWidth: "100%" }} className="tab-panel-formcontrol">
        {/* <FilterExtender _keyname={"MicrositeCategory"} value="" /> */}
        {data.EXTRA_FILTERS.CategorySelection == true ? (
          <Autocomplete
            id="asynchronous-demo"
            open={Catopen}
            onOpen={() => {
              setCatOpen(true);
            }}
            onClose={() => {
              setCatOpen(false);
            }}
            isOptionEqualToValue={(SelectedCatValue: any, value: any) =>
              SelectedCatValue.keyName === value.keyName
            }
            getOptionLabel={(SelectedCatValue: any) => SelectedCatValue.keyName}
            options={SelectedCatValue}
            loading={loading}
            disableClearable
            onChange={(event: any, newValue: any) => {
              setCategory(newValue);
              CurrentData(newValue);
            }}
            value={Category}
            renderInput={(params) => (
              <TextField
                onClick={() => triggerGrid("MicrositeCategory")}
                {...params}
                placeholder="Category"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        ) : (
          <div className="disabled-textbox">
            {data.CATEGORY_FILTER.categoryName}
          </div>
        )}
      </Box>

      <Box sx={{ mb: 2, minWidth: "100%" }} className="tab-panel-formcontrol">
        {/* <FilterExtender _keyname={"BrandName"} value="" /> */}
        {data.EXTRA_FILTERS.BrandSelection == true ? (
          <Autocomplete
            id="asynchronous-demo"
            open={Brandopen}
            onOpen={() => {
              setBrandOpen(true);
            }}
            onClose={() => {
              setBrandOpen(false);
            }}
            isOptionEqualToValue={(option: any, value: any) =>
              option.keyName === value.keyName
            }
            getOptionLabel={(option: any) => option.keyName}
            options={SelectedBrandValue}
            loading={brandloading}
            disableClearable
            onChange={(event: any, newValue: any) => {
              setBrand(newValue);
              CurrentData(newValue);
            }}
            value={Brand}
            renderInput={(params) => (
              <TextField
                onClick={() => triggerGrid("BrandName")}
                {...params}
                placeholder="Brand"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {brandloading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        ) : (
          <div className="disabled-textbox">{data.BRAND_FILTER.brandName}</div>
        )}
      </Box>
      <Box sx={{ mb: 2, minWidth: "100%" }} className="tab-panel-formcontrol">
        {/* <FilterExtender _keyname={"PartDescription"} value="" /> */}
        {data.EXTRA_FILTERS.DescriptionSelection == true ? (
          <Autocomplete
            id="asynchronous-demo"
            open={Partdesopen}
            onOpen={() => {
              setPartdesOpen(true);
            }}
            onClose={() => {
              setPartdesOpen(false);
            }}
            isOptionEqualToValue={(option: any, value: any) =>
              option.keyName === value.keyName
            }
            getOptionLabel={(option: any) => option.keyName}
            options={SelectedDescValue}
            loading={partdescloading}
            disableClearable
            onChange={(event: any, newValue: any) => {
              setDescription(newValue);
              CurrentData(newValue);
            }}
            value={Description}
            renderInput={(params) => (
              <TextField
                onClick={() => triggerGrid("PartDescription")}
                {...params}
                placeholder="Description"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {partdescloading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        ) : (
          <div className="disabled-textbox">
            {data.DESCRIPTION_FILTER.desDescriptionName}
          </div>
        )}
      </Box>
      <Button
        onClick={ShowGrid}
        variant="contained"
        color="primary"
        sx={{ mr: 2 }}
      >
        Search
      </Button>
      <Button variant="outlined" onClick={ResetData}>
        Reset
      </Button>

      {AttributesFilters.length > 0 ? (
        <AttributeFilter
          _attributes={AttributesFilters}
          attributeSub={SubAttributesFilters}
          selectattribute={SelectAttribute}
          trigg={triggershowgrd}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default GeneralSearch;
