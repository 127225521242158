import { Box, Container, Paper, Typography, Divider , Button } from "@mui/material";
import { useEffect, useState } from "react";

import { IProductProps } from "../../Types/MicrositeType";

import { Get_Data } from "../../API/GetDataFromApi";

import { Link } from "react-router-dom";
import { ProductList } from "./ProductList";
import { useSelector } from "react-redux";
import PartSearchExtender from "../Header/PartSearchExtender";
import breadcrumbsbanner from "../../Images/centralturbos/breadcrumbs-common-banner.png";

export const TopProducts = () => {
// console.log("start");
  const [product, setProduct] = useState<IProductProps[]>([]);

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);


  useEffect(() => {
    let URL = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/get-top-products`;
    Get_Data(URL)
      .then((results) => {
        //   setMenudata(results.data);
      // console.log(results, "from topproducts");
        setProduct(results.data);
      })
      .catch((error) => {
        console.log("Error in " + error);
      });
  }, []);
  return (
    <div>
      {EnvData.REACT_APP_SHOW_CENTRAL_TURBO == "true"  ? (
                <>
               
                <Paper elevation={0} sx={{ mt: 0, mb: 1, p: 7,  backgroundImage: `url(${breadcrumbsbanner})`, }} className="central-turbos-breadcrumbs-banner">
                    <Container>
                        <Box sx={{px:5}}>
                            <Typography className="central-turbos-breadcrumbs-title">Search By Category</Typography>
                        </Box>
                    </Container>
                </Paper> 
               </>
              ) : (
                <></>
              )}
      
      <Paper elevation={0} sx={{ mt: 2, mb: 1, py: 3 }}  className={
                              EnvData?.REACT_APP_SHOW_CENTRAL_TURBO === "true"
                                ? "ct-top-product"
                                : ""
                            }>
        <Container>
        <Box sx={{ mb: 1 }}>
          <div className={EnvData?.REACT_APP_SHOW_CENTRAL_TURBO === "true" ? "product-right-border-featureParts central-turbos-featureParts" : "product-right-border-featureParts"}>
              
              {EnvData.REACT_APP_SHOW_CENTRAL_TURBO == "true"  ? (
                <>
               
                <Typography variant="subtitle1" className="central-turbos-title" sx={{textAlign:"center"}}>Search By Category</Typography>
                </>
              ) : (
                <Typography variant="h4" sx={{ mb: 2 }}>
                {/* 
                {
                   EnvData?.REACT_APP_SHOW_CENTRAL_TURBO == "true"? "Search By Category":"Top Products"
                } */}
              Top Products
                <Divider
                  sx={{ bgcolor: "primary.main" }}
                  className="title-divider"
                />
              </Typography>
              )}
              <Button
                      component={Link}
                      to={'/'}
                      className="back-button"
                      variant="outlined"
                      color="primary" 
                      // onClick={()=> dispatch(clearPDInfo())}
                    >
                      Back to Main Page
                    </Button>
            </div>
           
          </Box>
          <ProductList count={7} _product={product} />
          {EnvData.REACT_APP_SHOW_CENTRAL_TURBO == "true"  ? (
                <>
                 <div className="central-turbos-part-extender-topproduct">
                    <Typography variant="subtitle1" className="central-turbos-title">Part Search</Typography>
                    <PartSearchExtender _fromPage={"PartSearch"} />
                  </div>
                </>
              ) : (<></>)}

        </Container>
      </Paper>
    </div>
  );
};
export default TopProducts;
