// LanguageSwitcher.js
import React, { useEffect } from 'react';
import {  useSelector } from "react-redux";

function LanguageSwitcher() {

  let env = (state) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);
  useEffect(() => {
    // Define the callback function for the Google Translate API
    window.googleTranslateElementInit = function () {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: 'en', // Default language (English)
          autoDisplay: false, // Do not display the widget automatically
          includedLanguages:  EnvData?.REACT_APP_MULTILANGUAGE,
          
        },
        'google_translate_element'
      );
    };

    // Load the Google Translate widget
    const script = document.createElement('script');
    script.src =
      'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div id="google_translate_element">

        
    </div>
  );
}

export default LanguageSwitcher;
