import { Box, Container, Grid, Paper, Typography } from "@mui/material";

import breadcrumbsbanner from "../../Images/centralturbos/breadcrumbs-banner.png"

import warrantiesicons1 from "../../Images/centralturbos/warranties-icons1.png";
import warrantiesicons2 from "../../Images/centralturbos/warranties-icons2.png";
import warrantiesicons3 from "../../Images/centralturbos/warranties-icons3.png";
import { useNavigate } from "react-router-dom";




export default function CentralTurbosWarranties() {
  const navigate = useNavigate();
  return(
    <div>
      <Paper elevation={0} sx={{ mt: 0, mb: 1, p: 7,  backgroundImage: `url(${breadcrumbsbanner})`, }} className="central-turbos-breadcrumbs-banner">
          <Container>
              <Box sx={{px:5}}>
                  <Typography className="central-turbos-breadcrumbs-title">Forms + Warranties</Typography>
                  {/* <Typography className="central-turbos-breadcrumbs-sub-title">subhead</Typography> */}
              </Box>
          </Container>
      </Paper>
      <Paper elevation={0} sx={{ mt: 1, mb: 1, py:3}}>
        <Container>
          <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                  <Box className="central-turbos-warranties-box"
                  onClick={()=>{
                    navigate('/WarrantyandForms');
                  }}
                  >
                    <img src={warrantiesicons3} alt="" />
                    <Typography>Warranty <br />
                      Claim Form</Typography>
                  </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                  
                  <Box className="central-turbos-warranties-box"
                    onClick={()=>{
                      navigate('/ReturnCore');
                    }}
                  >
                    <img src={warrantiesicons1} alt="" />
                    <Typography>Return & Core  <br />
                    Return Form</Typography>
                  </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                  <Box className="central-turbos-warranties-box" 
                    onClick={()=>{
                      navigate('/CreditApplication');
                    }}
                  >
                    <img src={warrantiesicons2} alt="" />
                    <Typography>Credit Application</Typography>
                  </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                  <Box className="central-turbos-warranties-box">
                    <img src={warrantiesicons3} alt="" />
                    <Typography>Credit Card <br />Authorization</Typography>
                  </Box>
              </Grid>
          </Grid>
        </Container>
      </Paper>
    </div>
  )

}