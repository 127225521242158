import {
  Box,
  Container,
  Paper,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";

import { IFeatureproductProps } from "../../Types/MicrositeType";

import { Get_Data } from "../../API/GetDataFromApi";
import ProductGrid from "./ProductGrid";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
export const FeatureParts = () => {
  const { FeatureParts } = useParams();
  const [featureparts, setFeatureparts] = useState<IFeatureproductProps[]>([]);
  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  useEffect(() => {
    const CustomerId =
      localStorage.getItem("CustomerId") === undefined ||
      localStorage.getItem("CustomerId") === null ||
      localStorage.getItem("CustomerId") === ""
        ? "0"
        : localStorage.getItem("CustomerId");

    let URL = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/get-feature-parts?customerid=${CustomerId}`;
    Get_Data(URL)
      .then((results) => {
        //   setMenudata(results.data);
        // console.log(results.data[0].seoTitle, "from featureparts");
        setFeatureparts(results.data);
      })
      .catch((error) => {
        console.log("Error in " + error);
      });
  }, []);
  return (
    <div>
      <Paper elevation={0} sx={{ mt: 2, mb: 1, py: 3 }}>
        <Container>
          <Box sx={{ mb: 1 }}>
            <div className="product-right-border-featureParts">
              <Typography variant="h4" sx={{ mb: 2 }}>
                {featureparts[0]?.seoTitle}
                <Divider
                  sx={{ bgcolor: "primary.main" }}
                  className="title-divider"
                />
              </Typography>
              <Button
                component={Link}
                to={"/"}
                className="back-button"
                variant="outlined"
                color="primary"
                // onClick={()=> dispatch(clearPDInfo())}
              >
                Back to Main Page
              </Button>
            </div>
          </Box>
          {/* <ProductList count={0} _product={featureparts} /> */}
          {featureparts.length !== 0 ? (
            <ProductGrid _product={featureparts} />
          ) : (
            <></>
          )}
        </Container>
      </Paper>
    </div>
  );
};
export default FeatureParts;
