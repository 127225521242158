import { useState, useEffect, useContext, useRef } from "react";
import {
  Typography,
  Container,
  Paper,
  Box,
  Grid,
  Divider,
  Stack,
  Button,
  Menu,
  Tooltip,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ProductSlider from "../Components/Product/ProductSlider";
import CentralTurbosProductSlider from "../Components/Product/CentralTurbosProductSlider";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import pdficon from "../Images/publi_pdf.png";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import IconButton from "@mui/material/IconButton";
import Package from "../Images/package.png";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "../Css/productdetails.style.css";
import Pagination from "@mui/material/Pagination";
import PartAccessoriess from "../Components/Product/PartAccessories";
import AlterProduct from "../Components/Product/AlterProduct";
import MetaTag from "../SEO/MetaTag";
import {
  ICart,
  ICartContextAction,
  // ICartContextActionTest,
} from "../Types/CartType";
import { CartContext } from "../Components/Cart/CartContext";
import {
  BrowserRouter as Router,
  // Route,
  Link,
  // useLocation,
  useSearchParams,
  useParams,
} from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Referencetable from "../Components/Product/Referencetable";
import { addPDInfo, clearPDInfo } from "../Redux/Counterslice";
import ProductLoader from "../Components/ProductLoader";

import {
  IApplicationProps,
  IPublicationProps,
  IProductAccesProps,
  IProductSpecficationProps,
  IPartinformationProps,
  IProductalternativeProps,
  IProductImageProps,
  ICrossRefProps,
  IProductBom,
  IProductWhereUsedProps,
  IProductDescription,
  IPackagingInfo,
  // IMicrositeProps,
} from "../Types/MicrositeType";
import { useDispatch, useSelector } from "react-redux";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Loader from "../Components/Loader";
import CloseIcon from "@mui/icons-material/Close";

import {
  GetData,
  GetADDToCartData,
  Get_Data,
} from "../API/GetDataFromApi";
import { addSearchtext } from "../Redux/Counterslice";
import { DebounceInput } from "react-debounce-input";
import eventEmitter from "../Components/PartSearchTest/eventEmmiter";

export default function ProductDetails() {
  const dispatch = useDispatch();
  dispatch(addSearchtext(""));
  const [searchparam] = useSearchParams();

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);
  const { partnum } = useParams();

  const { setcartItems } = useContext(CartContext) as ICartContextAction;
  const [stdsellingpack, setstdsellingpack] = useState(1);
  const [count, setCount] = useState(1);
  const [StartingStdCount, setStartingStdCount] = useState(1);
  // const [maintable, setDetailsItems] = useState<[]>([]);
  const [crossref, setCrossRefItems] = useState<ICrossRefProps[]>([]);
  const [productbom, setproductbom] = useState<IProductBom[]>([]);
  const [showornotsettings, setShowornotSettings] = useState<[]>([]);
  const [specification, setSpecification] = useState<
    IProductSpecficationProps[]
  >([]);
  const [packageinfo, setSpackageinfo] = useState<IPackagingInfo[]>([]);
  const [partdetails, setPartDetails] = useState<IPartinformationProps[]>([]);
  const [applicationinfo, setApplicationinfo] = useState<IApplicationProps[]>(
    []
  );
  const [partwhereused, setpartwhereused] = useState<IProductWhereUsedProps[]>(
    []
  );
  const [publications, setpublications] = useState<IPublicationProps[]>([]);
  const [partaccessories, setpartaccessories] = useState<IProductAccesProps[]>(
    []
  );
  const [alterproducts, setalterproducts] = useState<
    IProductalternativeProps[]
  >([]);

  const [productdescription, setproductdescription] = useState<
    IProductDescription[]
  >([]);
  // const [Bom, setBom] = useState<any>([]);
  //const [inputValue, setInputValue] = useState("1");
  const [Prop65, setProp65] = useState<string>("");
  const [images, setimages] = useState<IProductImageProps[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [parmms, setparms] = useState<string | undefined>("");
  const [loading, setLoading] = useState(true);
  const [CorePolicyShow, setCorePolicyShow] = useState(false);
  const [shippingPolicyShow, setShippingPolicyShow] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [backtomainopen, setbacktomainOpen] = useState(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();
  const [navtoPS, setnavtoPS] = useState(false);
  const [showLoader, setshowLoader] = useState<boolean>(false);

  const popperRef = useRef<HTMLDivElement>(null);
  // redux
  const selectData = (state: any) => state.MS_SETTINGS_DATA;
  const data = useSelector(selectData);

  const Pddata = (state: any) => state.PART_DETAILS_INFO;

  const productdetailsData = useSelector(Pddata);

  const [anchorContactAvailabilityEl, setanchorContactAvailabilityEl] =
    useState<null | HTMLElement>(null);

  const contactAvailabilityClick = (event: React.MouseEvent<HTMLElement>) => {
    setanchorContactAvailabilityEl(
      anchorContactAvailabilityEl ? null : event.currentTarget
    );
  };
  const openContact = Boolean(anchorContactAvailabilityEl);
  const CustomerId =
    localStorage.getItem("CustomerId") === undefined ||
    localStorage.getItem("CustomerId") === null ||
    localStorage.getItem("CustomerId") === ""
      ? "0"
      : localStorage.getItem("CustomerId");

  const handleClick =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

  useEffect(() => {
    if (partnum) {
      setparms(partnum);
    }
  }, [partnum]);

  useEffect(() => {
    if (productdetailsData.partnumber.length == 0) {
      setbacktomainOpen(false);
    }
    if (
      productdetailsData.partflag.length !== 0 &&
      productdetailsData.partflag[productdetailsData.partflag.length - 1] !==
        "PartSearch"
    ) {
      setbacktomainOpen(true);
    }
    if (
      productdetailsData.partflag[productdetailsData.partflag.length - 1] ==
      "PartSearch"
    ) {
      setnavtoPS(true);
    }

    if (parmms !== "") {
      (async () => {
        setshowLoader(true);
        GetData(
          EnvData?.REACT_APP_APIURL + "/microsite-api-v1/get-product-details",
          raw
        )
          .then((results?) => {
            setCrossRefItems(results.productCrossreferences);
            setSpecification(results.productSpecifications);
            setSpackageinfo(results.productPackagingInfo);
            setPartDetails(results.partInformation);
            setApplicationinfo(results.prodcutApplications);
            setpublications(results.productPublications);
            setpartaccessories(results.productAccessories);
            setalterproducts(results.productAlternatives);
            setimages(results.productImages);
            setproductbom(results.productBOM);
            setpartwhereused(results.productWhereused);
            setProp65(results.partInformation[0].prop65Image);
            setproductdescription(results.piesDescription);
            setLoading(false);
            setshowLoader(false);
            setCount(results.partInformation[0].stdSellingPack);
            setstdsellingpack(results.partInformation[0].stdSellingPack);
            setStartingStdCount(results.partInformation[0].stdSellingPack);
            setUnitPrice(results.partInformation[0].discountUnitPrice);
            setCorePrice(results.partInformation[0].discountCorePrice);
            setdiscPercentage(results.partInformation[0].discountPercentage);
            settotPrice(results.partInformation[0].totalPrice);
            setdisctotPrice(results.partInformation[0].discountTotalPrice);
          })
          .catch((err) => {
            console.error(err, "in product details");
          });
      })();
    }

    return () => {
      setnavtoPS(false);

      if (
        productdetailsData.partflag[productdetailsData.partflag.length - 1] ==
        "PartSearch"
      ) {
        dispatch(clearPDInfo());
      }
    };
  }, [parmms]);

  const handleSpecClose = () => {

    setCorePolicyShow(false);
  };

  const shippingPolicyClose = () => {

    setShippingPolicyShow(false);
  };

  let DimensionReference = "0";
  let WeightReference = "0";
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popperRef.current &&
        !popperRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function areAllValuesEmpty(obj: any) {
    let ans = false;
    let dim = false;

    const { dimension, weight, ...restOfObject } = obj;
    for (const key in restOfObject) {
      if (restOfObject[key] !== "") {
        ans = true;
      }
    }

    if (
      packageinfo[0].weight == WeightReference &&
      packageinfo[0].dimension == DimensionReference
    ) {
      dim = false;
    } else {
      dim = true;
    }
    if (ans == false && dim == false) {
      return false;
    } else {
      return true;
    }
  }

  // Add to cart

  const AddTocart = (
    partid: number,
    partnumber: string,
    description: string,
    price: number,
    url: string
  ) => {
    var cartName = localStorage.getItem("cartName");

    var cartInput = {
      partid: partid,
      cartName: cartName,
      qty: count,
      customerId: CustomerId,
    };
    setLoading(true);

    return GetADDToCartData(
      EnvData?.REACT_APP_APIURL + "/microsite-api-v1/add-to-cart",
      JSON.stringify(cartInput),
      cartInput
    )
      .then((results) => {
        const output_array = JSON.stringify(results).split("|");

        let cartid = output_array[1];
        if (cartid !== "") {
          const newCart: ICart = {
            Partnumber: partnumber,
            description: description,
            price: price,
            imgflag: true,
            imgUrl: url,
          };
          setcartItems(newCart);
        }
      })
      .catch((error) => {
        console.log("Error in " + error);
      })
      .finally(() => {
        setLoading(false); // Stop loading
        const myTimeout = setTimeout(() => {
          eventEmitter.emit("triggerOpenDraw", {});
        }, 1000);
      });
  };

  const rowsPerPage = 10; // Number of rows per page

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const currentPageData = applicationinfo?.slice(startIndex, endIndex);

  let cart = localStorage.getItem("cartName");

  let BOMTable = {
    Title: " Bill of material",
    Head_no: "Image",
    Head_name: "Part No",
    Head_type: "Descriptions",
    Head_noter: "Notes",
  };

  let whereusedTable = {
    Title: " Where Used",
    Head_no: "Main Part No",
    Head_SalesPart_no: "Sales Part No",
    Head_name: "Part Description",
  };
  let crossrefTable = {
    Title: " CROSS REFERENCES",
    Head_no: "Competitor Part Number",
    Head_name: "Competitor Name",
    Head_type: "Interchange Type",
    Head_noter: "Notes",
  };

  let ApplicationInfoTable = {
    Title: " APPLICATION_INFO",
    Head_no: "Year",
    Head_name: "Make",
    Head_type: "Model",
    Head_VechType: "Vehicle Type",
    Head_noter: "Notes",
  };

  let raw = JSON.stringify({
    partnumber: partnum,
    cartID: "0",
    CompetitorPartno: partnum,
    customerId: CustomerId,
  });
  let PartNumber = "",
    PartDescriptionName = "",
    salesPartNumber = "",
    PartNotes = "",
    corePolicy = "",
    PartId = 0,
    stdSellingPack = 0,
    stockInfo = "",
    shippingPolicy = "",
    freightDeliveryReq = true,
    showOutOfStock = false,
    showInventoryPartnumber = true,
    partStatus = "";

  const [unitPrice, setUnitPrice] = useState(0);
  const [corePrice, setCorePrice] = useState(0);
  const [discPercentage, setdiscPercentage] = useState(0);
  const [totPrice, settotPrice] = useState(0);
  const [disctotPrice, setdisctotPrice] = useState(0);

  {
    partdetails &&
      partdetails.map(
        (elem: any) => (
          (PartNumber = elem["partNumber"]),
          (PartDescriptionName = elem["micrositeTitle"]),
          (salesPartNumber = elem["salesPartNumber"]),
          (PartNotes = elem["notes"]),
          (PartId = Number(elem["partId"])),
          (corePolicy = elem["corePrivacyPolicy"]),
          // (discountPercentage = elem["discountPercentage"]),
          //(discountunitPrice = elem["discountUnitPrice"]),
          //(discountcorePrice = elem["discountCorePrice"]),
          //(totalPrice = elem["totalPrice"]),
          //(totalDiscount = elem["discountTotalPrice"]),
          (stdSellingPack = elem["stdSellingPack"]),
          (stockInfo = elem["stockInfo"]),
          (freightDeliveryReq = elem["freightDeliveryRequired"]),
          (shippingPolicy = elem["shippingPolicy"]),
          (showOutOfStock = elem["showOutOfStock"]),
          (showInventoryPartnumber = elem["showInventoryPartnumber"]),
          (partStatus = elem["partStatus"])
        )
      );
  }

  let crossref_show = false,
    crossref_count = 0;
  {
    showornotsettings &&
      showornotsettings.length > 0 &&
      showornotsettings !== null &&
      showornotsettings.map(
        (elem) => (crossref_show = elem["ShowCrossreference"])
      );
  }
  crossref_count = crossref?.length;

  let bom_show = false,
    bom_count = 0;
  {
    showornotsettings &&
      showornotsettings.length > 0 &&
      showornotsettings !== null &&
      showornotsettings.map(
        (elem) => (crossref_show = elem["ShowCrossreference"])
      );
  }
  bom_count = productbom?.length;

  const whereuse_count = partwhereused?.length;

  const App_Info_Count = applicationinfo?.length;

  let spechtml = "";
  let packaginghtml = "";
  var spec_firstrow = specification?.slice(0, 1);
  var Packaging_firstrow = packageinfo?.slice(0, 1);

  // const heightRegex = /Height/ /Width/;
  // {
  //   spec_firstrow &&
  //     spec_firstrow.map((elem) => {
  //       spechtml =
  //         "<table class='specification-table'><tbody><tr><th>Brand Name</th><td>" +
  //         elem["brandName"] +
  //         " </td><th>Weight</th><td>" +
  //         elem["weight"] +
  //         "</td></tr>" +
  //         "<tr><th>Condition</th><td>" +
  //         (elem["newReman"] === ""
  //           ? null
  //           : elem["newReman"] !== "N"
  //           ? "Reman"
  //           : "New") +
  //         (elem["warranty"] === null
  //           ? null
  //           : `</td><th>Warranty</th><td>${elem["warranty"]}</td></tr>`); // waarranty missing)
  //     });
  // }

  {
    spec_firstrow &&
      spec_firstrow.map((elem) => {
        spechtml =
          "<table class='specification-table'><tbody><tr><th>Brand Name</th><td>" +
          elem["brandName"] +
          " </td><th>Condition</th><td>" +
          (elem["newReman"] === ""
            ? null
            : elem["newReman"] !== "N"
            ? "Reman"
            : "New") +
          "</td></tr>" +
          (elem["warranty"] === null ||
          elem["warranty"] === "" ||
          elem["warranty"] === undefined
            ? ""
            : `<tr><th>Warranty</th><td>${elem["warranty"]}</td> <th>${elem["partSpecificationName"]}</th><td>${elem["partSpecificationValue"]}</td></tr>`);
      });
  }

  //   {
  //   spec_firstrow &&
  //     spec_firstrow.map((elem) => {
  //       spechtml =
  //         "<table class='specification-table'><tbody><tr><th>Brand Name</th><td>" +
  //         elem["brandName"] +
  //         " </td><th>Condition</th><td>" +
  //         (elem["newReman"] === ""
  //           ? null
  //           : elem["newReman"] !== "N"
  //           ? "Reman"
  //           : "New") +
  //         "</td></tr>";

  //       if (elem["warranty"] && elem["partSpecificationValue"]) {
  //         spechtml += `<tr><th>Warranty</th><td>${elem["warranty"]}</td> <th>${elem["partSpecificationName"]}</th><td>${elem["partSpecificationValue"]}</td></tr>`;
  //       }

  //       // Close the table
  //       spechtml += "</tbody></table>";
  //     });
  // }

  if (Packaging_firstrow && Packaging_firstrow.length > 0) {
    packaginghtml +=
      "<table class='specification-table packaging-info-table'><tbody>";

    Packaging_firstrow.forEach((elem) => {
      const isWeightZero = elem["weight"] == WeightReference;
      elem["weight"] !== "" && !isWeightZero ? (
        (packaginghtml +=
          "<tr><th>Weight</th><td>" +
          elem["weight"]
            .replace(/(\d+\.\d*?[1-9])0+(\s)/g, "$1$2")
            .replace(/\.0+(\s)/g, "$1") +
          "</td></tr>")
      ) : (
        <></>
      );

      const isHeightZero = elem["dimension"] == DimensionReference;

      elem["dimension"] !== "" && !isHeightZero ? (
        (packaginghtml +=
          "<tr><th>Dimension</th><td>" +
          elem["dimension"]
            .replace(/(\d+)\.0+\b/g, "$1")
            .replace(/(\d+\.\d*?[1-9])0+\b/g, "$1") +
          "</td></tr>")
      ) : (
        <></>
      );
      elem["barCode"] !== "" ? (
        (packaginghtml +=
          "<tr><th>Barcode</th><td>" + elem["barCode"] + "</td></tr>")
      ) : (
        <></>
      );
      elem["packageLevelGTIN"] !== "" ? (
        (packaginghtml +=
          "<tr><th>GTIN</th><td>" + elem["packageLevelGTIN"] + "</td></tr>")
      ) : (
        <></>
      );
      elem["electronicCode"] !== "" ? (
        (packaginghtml +=
          "<tr><th>Electronic Product Code</th><td>" +
          elem["electronicCode"] +
          "</td></tr>")
      ) : (
        <></>
      );
    });

    packaginghtml += "</tbody></table>";
  }

  {
    specification &&
      specification.length > 0 &&
      showornotsettings !== null &&
      specification.slice(1).map((elem, index) => {
        index % 2 === 1
          ? (spechtml =
              spechtml +
              "<th>" +
              elem["partSpecificationName"] +
              "</th>" +
              "<td>" +
              elem["partSpecificationValue"] +
              "</td>" +
              "</tr>")
          : (spechtml =
              spechtml +
              "<tr>" +
              "<th>" +
              elem["partSpecificationName"] +
              "</th>" +
              "<td>" +
              elem["partSpecificationValue"] +
              "</td>");
      });
  }
  // {
  //   packageinfo &&
  //     packageinfo.length > 0 &&
  //     showornotsettings !== null &&
  //     packageinfo.map((elem, index) => {
  //       index % 2 === 0
  //         ? (packaginghtml =
  //             packaginghtml +
  //             "<tr>" +
  //             "<th>" +
  //             elem["partSpecificationName"] +
  //             "</th>" +
  //             "<td>" +
  //             elem["partSpecificationValue"] +
  //             "</td>")
  //         : (packaginghtml =
  //             packaginghtml +
  //             "<th>" +
  //             elem["partSpecificationName"] +
  //             "</th>" +
  //             "<td>" +
  //             elem["partSpecificationValue"] +
  //             "</td>" +
  //             "</tr>");
  //     });
  // }

  let specrowcount = specification?.length;
  let PackInfocrowcount = packageinfo?.length;
  if (specrowcount % 2 !== 0) {
    spechtml = spechtml + "</tr>";
  }
  spechtml = spechtml + "</tbody></table>";

  if (PackInfocrowcount % 2 !== 0) {
    packaginghtml = packaginghtml + "</tr>";
  }
  packaginghtml = packaginghtml + "</tbody></table>";

  interface ChildrenProps {
    children: any;
  }
  function handleInputChange(e: any, action: string) {
    let qty = e.target.value;

    if (action === "minus") {
      qty = count - StartingStdCount;
    } else if (action === "plus") {
      qty = count + StartingStdCount;
    }

    if (parseInt(qty) === 0) {
      alert("Qty should be greaterthan zero");
      return false;
    }
    if (parseInt(qty) % stdsellingpack != 0) {
      e.target.value = count;
      alert(
        "Enter the Qty in multiples of StdSellingpack (" + stdsellingpack + ")"
      );
      return false;
    } else {
      setCount(parseInt(qty));

      setshowLoader(true);
      Get_Data(
        EnvData?.REACT_APP_APIURL +
          "/microsite-api-v1/get-price-info?qty=" +
          qty +
          "&partnumber=" +
          partnum +
          "&customerId=" +
          CustomerId
      )
        .then((results) => {
          if (action === "minus") {
            setCount(count - StartingStdCount);
          } else if (action === "plus") {
            setCount(count + StartingStdCount);
          }
          setUnitPrice(results.data.unitPrice);
          setCorePrice(results.partInformation[0].corePrice);
          setdiscPercentage(results.partInformation[0].savedPercentage);
          settotPrice(results.partInformation[0].originalPrice);
          setdisctotPrice(results.partInformation[0].extendTotal);
          setshowLoader(false);
        })
        .catch((Error) => {
          console.log(Error);
          setshowLoader(false);
        });
    }
  }

  const ReadMore = ({ children }: ChildrenProps) => {
    const text = Array.isArray(children) ? children.join("") : children;

    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    if (!children) {
      return null; // Return null or handle the empty case accordingly
    }
    return (
      <span className="read-more-text">
        {isReadMore ? text.slice(0, 250) : text}
        {text.length > 250 && (
          <span onClick={toggleReadMore} className="read-or-hide">
            {isReadMore ? "...Read more" : " Show less"}
          </span>
        )}
      </span>
    );
  };

  if (loading == true) {
    return <Loader />;
  }
  const backmenu = navtoPS
    ? `/PartSearch`
    : `/Category/${
        productdetailsData.partnumber[productdetailsData.partnumber.length - 1]
      }`;
  return (
    <>
      {showLoader == false ? (
        <div>
          <MetaTag
            _pagename={"ProductDetails"}
            _productsdetails={partdetails}
            _fetchdata={false}
          />
          <Paper elevation={0} sx={{ mt: 1, mb: 1, py: 1 }}>
            <Container>
              <Box className="breadcrumb-container">
                <Breadcrumbs
                  separator={
                    <NavigateNextIcon fontSize="small" color="primary" />
                  }
                  aria-label="breadcrumb"
                >
                  <Link to="/" color="inherit">
                    Home
                  </Link>

                  {productdetailsData.partflag.length > 0 ? (
                    <Link
                      to={backmenu}
                      color="inherit"
                      onClick={() => dispatch(clearPDInfo())}
                    >
                      {
                        productdetailsData.partflag[
                          productdetailsData.partflag.length - 1
                        ]
                      }
                    </Link>
                  ) : null}

                  <Typography color="inherit">{salesPartNumber}</Typography>
                </Breadcrumbs>

                {backtomainopen == true ? (
                  <Button
                    component={Link}
                    to={backmenu}
                    className="back-button"
                    variant="outlined"
                    color="primary"
                    onClick={() => dispatch(clearPDInfo())}
                  >
                    Back to Main Part
                  </Button>
                ) : (
                  <></>
                )}
              </Box>
              <div
                className={
                  EnvData?.REACT_APP_SHOW_CENTRAL_TURBO === "true"
                    ? "product-detail-main-container central-turbos-product-detail-main"
                    : "product-detail-main-container"
                }
              >
                <div className="product-detail-left">
                  <div className="product-detail-container">
                    <div className="product-detail-slider">
                      {EnvData?.REACT_APP_SHOW_CENTRAL_TURBO == "true" ? (
                        <>
                          <CentralTurbosProductSlider props={images} />
                        </>
                      ) : (
                        <>
                          {" "}
                          <ProductSlider props={images} />
                        </>
                      )}
                    </div>
                    <div className="product-detail-desc">
                      <div className="product-right-border">
                        <Typography
                          className="product-detail-desc-title"
                          variant="h1" component="h1"
                        >
                          <span className="product-detail-desc-part-no">
                            {salesPartNumber !== PartNumber &&
                            showInventoryPartnumber === true
                              ? PartNumber.toUpperCase() +
                                " / " +
                                salesPartNumber.toUpperCase()
                              : salesPartNumber.toUpperCase()}

                            <Divider
                              sx={{ bgcolor: "primary.main" }}
                              className="title-divider"
                            />
                          </span>
                          <span className="product-detail-desc-para">
                            {" "}
                            {PartDescriptionName}
                          </span>
                        </Typography>
                      </div>
                      {/* <Typography
                        className="product-detail-desc-para"
                        variant="h2"
                      >
                        {PartDescriptionName}
                      </Typography> */}
                      {data.loginOption == "InternalCadenceLogin" ? (
                        <div className="part-detials-login-button">
                          <Typography
                            variant="body2"
                            sx={{ mb: 1 }}
                            color="error"
                          >
                            Login to view pricing and availability
                          </Typography>
                          <Button
                            href={EnvData?.REACT_APP_CADENCELOGINURL}
                            variant="contained"
                            color="primary"
                          >
                            Login
                          </Button>
                          <Button
                            href={EnvData?.REACT_APP_CADENCENEWACCOUNTURL}
                            variant="contained"
                            sx={{ ml: 1 }}
                            className="signup-button"
                          >
                            Sign Up
                          </Button>
                        </div>
                      ) : null}

                      {/* <Typography variant="body2">
                                        <b>Description :</b> Alliant Power New G2.8 Injector Connector Fits Ford F Series Excursion Applications With 6.0L Power Stroke Engines Model Years 2003 Thru 2007, Fits Ford E Series Applications With 6.0L Power Stroke Engines Model Years 2004 Thru 2010,   
                                        <a className="view-more">View More</a>
                                        </Typography> */}
                      {/* <Stack
                          direction="row"
                          spacing={1}
                          mt={3} 
                          className="part-notes-box"
                        >*/}
                      {/* <div className="part-notes">
                                                    <b>Part Notes :</b> Part Notes will be displayed here
                                            </div> */}

                      {/*</Stack>*/}

                      {/* -------------------------------------------- Do not delete-------------------------------------------------------------------------- */}

                      {partStatus === "Discontinued" ||
                      partStatus === "Ref Number" ? (
                        <div className="stack-disprice-discountinued">
                          <Box className="discountinued-details">
                            <Typography
                              variant="body2"
                              sx={{
                                px: 1,
                                backgroundColor: "primary.main",
                                color: "primary.contrastText",
                              }}
                            >
                              {partStatus}
                            </Typography>
                          </Box>
                        </div>
                      ) : null}

                      {(data.shoppingcart === true ||
                        (CustomerId !== "0" &&
                          data.catalogueWithPricing === true)) &&
                      parseFloat(unitPrice.toFixed(2)) > 0 &&
                      partStatus === "Active" ? (
                        <>
                          {totPrice > 0 && disctotPrice < totPrice ? (
                            <Typography
                              className="price-original"
                              variant="body2"
                              mt={2}
                            >
                              MSRP : ${totPrice.toFixed(2)}
                            </Typography>
                          ) : null}
                          <Stack
                            direction="row"
                            spacing={1}
                            mt={1}
                            alignItems="center"
                            className="product-detail-price-stack"
                          >
                            <Typography
                              variant="body1"
                              fontSize={28}
                              className="total-price"
                            >
                              ${unitPrice.toFixed(2)}
                            </Typography>

                            {discPercentage > 0 ? (
                              <div className="price-save">
                                <Typography variant="body2" sx={{ px: 1 }}>
                                  {discPercentage}% OFF
                                </Typography>
                              </div>
                            ) : null}
                            {parseFloat(unitPrice.toFixed(2)) ===
                            0 ? null : stockInfo === "In Stock" ? (
                              <p className="ready-to-ship">
                                <CheckCircleIcon /> In Stock
                              </p>
                            ) : showOutOfStock ? (
                              <p className="out-of-stock">
                                <CancelSharpIcon sx={{ marginRight: "5px" }} />{" "}
                                Out of stock
                              </p>
                            ) : null}
                          </Stack>
                          {corePrice > 0 ? (
                            <Stack
                              direction="row"
                              spacing={1}
                              mt={2}
                              alignItems="center"
                              className="core-box"
                            >
                              <p className="core-deposit">
                                + Core Deposit : ${corePrice.toFixed(2)}
                              </p>
                              <p
                                className="core-policy"
                                onClick={() => {
                                  setCorePolicyShow(true);
                                }}
                              >
                                Core Return Policy
                              </p>
                            </Stack>
                          ) : null}
                          {corePrice > 0 ? (
                            <Typography
                              variant="h3" component="h3"
                              sx={{
                                mt: 1,
                                fontWeight: "500",
                                fontSize: "14px",
                                textTransform: "none",
                              }}
                            >
                              Total price w/core : $
                              {/* {(count * (unitPrice + corePrice)).toFixed(2)} */}

                              {
                               Number(unitPrice+corePrice).toFixed(2)
                              }
                            </Typography>
                          ) : (
                            <Typography
                              variant="h3" component="h3"
                              sx={{
                                mt: 1,
                                fontWeight: "500",
                                fontSize: "14px",
                              }}
                            >
                              {/* Total price : ${(count * unitPrice).toFixed(2)} */}
                              Total price : ${unitPrice.toFixed(2)}
                            </Typography>
                          )}

                          <Stack
                            direction="row"
                            spacing={1}
                            mt={3}
                            mb={1}
                            alignItems="center"
                            className="qty-button-div"
                          >
                            <Dialog
                              open={CorePolicyShow}
                              onClose={handleSpecClose}
                              fullWidth={true}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                              maxWidth="md"
                            >
                              <DialogTitle id="alert-dialog-title">
                                Core Return Policy
                                <IconButton
                                  aria-label="close"
                                  onClick={handleSpecClose}
                                  sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </DialogTitle>
                              <DialogContent>
                                <div>{corePolicy}</div>
                              </DialogContent>
                            </Dialog>

                            <div className="qty-box">
                              {/* <span className="qty-label">Qty: </span> */}
                              <Button
                                onClick={(e) => handleInputChange(e, "minus")}
                                disabled={count === StartingStdCount}
                              >
                                <RemoveIcon />
                              </Button>
                             

                              <DebounceInput
                                value={count}
                                sx={{ width: "14%" }}
                                variant="outlined"
                                className="debounce-input"
                                debounceTimeout={1500}
                                onChange={(e: any) => handleInputChange(e, "")}
                              />
                              <Button
                                onClick={(e) => handleInputChange(e, "plus")}
                              >
                                <AddIcon />
                              </Button>
                            </div>

                            {/* <div className="package-qty">
                            Package Qty : <b>{count}</b>
                          </div> */}
                            <Button
                              className="addtocart"
                              variant="contained"
                              color="primary"
                              endIcon={<ShoppingCartOutlinedIcon />}
                              onClick={() => {
                                AddTocart(
                                  PartId,
                                  PartNumber,
                                  PartDescriptionName,
                                  discPercentage,
                                  ""
                                );
                              }}
                            >
                              Add To Cart
                            </Button>
                          </Stack>
                          {/* <Typography
                              variant="h1"
                              sx={{ px: 1, textAlign: "center" }}
                            >
                              or
                            </Typography> */}
                          <Stack
                            className="buynow-div"
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography
                              variant="body2"
                              className="package-qty"
                              sx={{ my: 1, fontWeight: "500" }}
                            >
                              <img src={Package} alt="Package Qty" /> Package
                              Qty : {stdSellingPack}
                            </Typography>
                            {/* <Button
                              className="buynow"
                              endIcon={<KeyboardArrowRightIcon />}
                            >
                              Buy Now
                            </Button> */}
                          </Stack>
                          {freightDeliveryReq === true ? (
                            <Typography
                              variant="body2"
                              sx={{ mt: 1, fontWeight: "500" }}
                              className="not-qualify-shipping"
                            >
                              <InfoOutlinedIcon
                                sx={{
                                  fontSize: "16px",
                                  verticalAlign: "text-bottom",
                                }}
                              />{" "}
                              Does not qualify free shipping{" "}
                              <a
                                className="core-policy"
                                onClick={() => {
                                  setShippingPolicyShow(true);
                                }}
                              >
                                {" "}
                                Shipping Policy
                              </a>
                            </Typography>
                          ) : null}
                        </>
                      ) : parseFloat(unitPrice.toFixed(2)) === 0 &&
                        (data.shoppingcart === true ||
                          (CustomerId !== "0" &&
                            data.catalogueWithPricing === true)) ? (
                        <>
                          <br></br>
                          <div
                            className="price-contact"
                            onClick={contactAvailabilityClick}
                            aria-controls={
                              open
                                ? "contact-availability_ProductDetails"
                                : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                          >
                            <Button
                              variant="outlined"
                              color="primary"
                              endIcon={<KeyboardArrowDownIcon />}
                              sx={{ width: "100%" }}
                            >
                              Contact for Availability{" "}
                            </Button>
                            <Menu
                              id="contact-availability_ProductDetails"
                              elevation={2}
                              className="contact-availability-popup"
                              open={openContact}
                              anchorEl={anchorContactAvailabilityEl}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                            >
                              <div className="contact-availability_ProductDetails">
                                <Box
                                  sx={{ textAlign: "center", fontSize: "15px" }}
                                >
                                  Please Contact
                                  <Button
                                    color="secondary"
                                    sx={{ py: 0, fontSize: "15px" }}
                                  >
                                    {data.tollFreeNumber}
                                  </Button>{" "}
                                  or
                                  <Button
                                    color="secondary"
                                    sx={{ py: 0, fontSize: "15px" }}
                                  >
                                    {data.contactEmail}
                                  </Button>
                                  for Availability as the item may not be in
                                  stock.
                                </Box>
                              </div>
                            </Menu>
                          </div>
                        </>
                      ) : null}

                      <Dialog
                        open={shippingPolicyShow}
                        onClose={shippingPolicyClose}
                        fullWidth={true}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth="md"
                      >
                        <DialogTitle id="alert-dialog-title">
                          Shipping Policy
                          <IconButton
                            aria-label="close"
                            onClick={shippingPolicyClose}
                            sx={{
                              position: "absolute",
                              right: 8,
                              top: 8,
                              color: (theme) => theme.palette.grey[500],
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </DialogTitle>
                        <DialogContent>
                          <div>{shippingPolicy}</div>
                        </DialogContent>
                      </Dialog>

                      {/* -------------------------------------------- Do not delete-------------------------------------------------------------------------- */}
                    </div>
                  </div>
                  <div>
                    {PartNotes && PartNotes !== "" ? (
                      <div className="product-specification">
                        <div className="product-right-border">
                          <Typography variant="subtitle1" component="h6" sx={{ mb: 1 }}>
                            PART NOTES
                            <Divider
                              sx={{ bgcolor: "primary.main" }}
                              className="title-divider"
                            />
                          </Typography>
                        </div>
                        <div className="border-box-padding">
                          <Typography
                            variant="body2"
                            sx={{ mb: 1 }}
                            color="error"
                          >
                            {PartNotes}
                          </Typography>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div>
                    <div className="product-specification">
                      {productdescription ? (
                        <div className="product-right-border">
                          <Typography variant="subtitle1" component="h6" sx={{ mb: 1 }}>
                            DESCRIPTION
                            <Divider
                              sx={{ bgcolor: "primary.main" }}
                              className="title-divider"
                            />
                          </Typography>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div className="product-description-content">
                        {productdescription && productdescription.length > 0 ? (
                          <>
                            {productdescription[0].descriptionCode == "" ? (
                              <div
                                key={0}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    productdescription[0].description +
                                    "<br/><div style='clear:both;'></div>",
                                }}
                              ></div>
                            ) : (
                              <></>
                            )}

                            {productdescription.length > 1 ||
                            productdescription[0].descriptionCode !== "" ? (
                              <div className="application-name">
                                {" "}
                                Manufacturer Description:
                              </div>
                            ) : null}

                            {productdescription.map(
                              (res: IProductDescription, index: any) =>
                                res.descriptionCode !== "" && index == 1 ? (
                                  <div
                                    key={index}
                                    dangerouslySetInnerHTML={{
                                      __html: "" + res.description,
                                    }}
                                  ></div>
                                ) : res.descriptionCode !== "" ? (
                                  <div
                                    key={index}
                                    dangerouslySetInnerHTML={{
                                      __html: res.description,
                                    }}
                                  ></div>
                                ) : null
                            )}
                          </>
                        ) : (
                          <Typography variant="h4" component="h4" className="partnote-text">
                            {PartDescriptionName}
                          </Typography>
                        )}

                        <div>
                          <Box>
                            {Prop65 ? (
                              <Button
                                className="prop65"
                                onClick={handleClick("right")}
                                sx={{ mt: 1 }}
                              >
                                <WarningRoundedIcon
                                  sx={{
                                    color: "#817f7f",
                                    verticalAlign: "bottom",
                                  }}
                                />{" "}
                                <span className="view-more">Prop65</span>
                                <Popper
                                  open={open}
                                  anchorEl={anchorEl}
                                  placement={placement}
                                  transition
                                >
                                  {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                      <Paper
                                        ref={popperRef}
                                        className="prob65-popup-img"
                                      >
                                        <img
                                          src={Prop65}
                                          alt="Prop65"
                                          loading="lazy"
                                        />
                                      </Paper>
                                    </Fade>
                                  )}
                                </Popper>
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Box>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {specification?.length > 0 ? (
                      <div className="product-specification">
                        <div className="product-right-border">
                          <Typography variant="subtitle1" component="h6" sx={{ mb: 1 }}>
                            SPECIFICATIONS
                            <Divider
                              sx={{ bgcolor: "primary.main" }}
                              className="title-divider"
                            />
                          </Typography>
                        </div>
                        <div
                          className="table-div"
                          dangerouslySetInnerHTML={{ __html: spechtml }}
                        ></div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div>
                    {areAllValuesEmpty(packageinfo[0]) ? (
                      <div className="product-specification">
                        <div className="product-right-border">
                          <Typography variant="subtitle1" component="h6" sx={{ mb: 1 }}>
                            PACKAGING INFO
                            <Divider
                              sx={{ bgcolor: "primary.main" }}
                              className="title-divider"
                            />
                          </Typography>
                        </div>
                        <div
                          className="table-div"
                          dangerouslySetInnerHTML={{ __html: packaginghtml }}
                        ></div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    className={
                      EnvData?.REACT_APP_SHOW_CENTRAL_TURBO === "true"
                        ? "product-detail-right-mobile central-turbos-product-detail-right-mobile"
                        : "product-detail-right-mobile"
                    }
                  >
                    {partaccessories?.length > 0 ? (
                      <PartAccessoriess
                        props={partaccessories}
                        partid={partdetails}
                      />
                    ) : null}

                    {/* alternative product */}
                    {alterproducts?.length > 0 ? (
                      <AlterProduct
                        props={alterproducts}
                        partid={partdetails}
                      />
                    ) : null}
                  </div>
                  <div>
                    <div className="product-specification">
                      {publications?.length > 0 ? (
                        <div className="product-right-border">
                          <Typography variant="subtitle1" component="h6" sx={{ mb: 1 }}>
                            PUBLICATIONS
                            <Divider
                              sx={{ bgcolor: "primary.main" }}
                              className="title-divider"
                            />
                          </Typography>
                        </div>
                      ) : null}

                      <Grid container spacing={2}>
                        {publications &&
                          publications?.map((res: any, index: any) => (
                            <Grid item xs={12} md={3} key={index}>
                              <Box className="publications-box">
                                <div className="publications-img-box">
                                  <div className="publications-img">
                                    <img
                                      src={
                                        res.publicationIconUrl != ""
                                          ? res.publicationIconUrl
                                          : pdficon
                                      }
                                      alt="images"
                                      loading="lazy"
                                    />
                                  </div>
                                </div>
                                <Typography
                                  variant="body1"
                                  className="publications-text"
                                  sx={{ fontWeight: 400 }}
                                >
                                  <Tooltip title={res.displayName}>
                                    <a
                                      href={res.publicationUrl}
                                      download
                                      target="_blank"
                                    >
                                      {res.displayName.slice(0, 25) + "..."}
                                    </a>
                                  </Tooltip>
                                </Typography>
                                {/* <IconButton
                                  className="publications-button"
                                  sx={{ alignItems: "end", color: "primary.main" }}
                                >
                                  <a href={res.publicationUrl} download target="_blank">
                                    <CloudDownloadIcon sx={{color: "primary.main"}} />
                                  </a>
                                </IconButton> */}
                              </Box>
                            </Grid>
                          ))}
                      </Grid>
                    </div>
                  </div>
                  {applicationinfo?.length > 0 ? (
                    <div>
                      <div className="product-specification">
                        <div className="product-right-border">
                          <Typography variant="subtitle1" component="h6" sx={{ mb: 1 }}>
                            APPLICATION INFORMATION
                            <Divider
                              sx={{ bgcolor: "primary.main" }}
                              className="title-divider"
                            />
                          </Typography>
                        </div>

                        <div className="application-info">
                          <table className="cross-ref-table">
                            <tbody>
                              <tr>
                                <th>Make</th>
                                <th>Model</th>
                                <th>Year</th>
                                <th>Notes</th>
                              </tr>
                            </tbody>

                            <tbody>
                              {currentPageData?.map(
                                (row: any, rowIndex: any) => {
                                  //   // const serialNumber = startIndex + rowIndex + 1; ---commmented --
                                  return (
                                    <tr key={`row-${rowIndex}`}>
                                      {/* <td key={`row-${rowIndex}-col-1`}>{serialNumber}</td>  ---commmented --*/}
                                      <td key={`row-${rowIndex}-col-2`}>
                                        {row.make}
                                      </td>
                                      <td>{row.model}</td>
                                      <td>{row.yearRange}</td>
                                      <td>{row.notes}</td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                          {applicationinfo?.length >= 10 ? (
                            <div className="pagination-sec">
                              <Pagination
                                count={Math.ceil(
                                  applicationinfo?.length / rowsPerPage
                                )}
                                page={currentPage}
                                onChange={(event, value) =>
                                  setCurrentPage(value)
                                }
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        {/* <div className="cross-ref">
                            <>
                              <Referencetable
                                props={ApplicationInfoTable}
                                count={App_Info_Count}
                                data={applicationinfo}
                                table={"Application_info"}
                              />
                            </>
                          </div> */}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div>
                    {" "}
                    {productbom?.length > 0 ? (
                      <div className="product-specification">
                        <div className="product-right-border">
                          <Typography variant="subtitle1" component="h6" sx={{ mb: 1 }}>
                            Bill Of Material
                            <Divider
                              sx={{ bgcolor: "primary.main" }}
                              className="title-divider"
                            />
                          </Typography>
                        </div>

                        <div className="cross-ref">
                          <Referencetable
                            props={BOMTable}
                            count={bom_count}
                            data={productbom}
                            table={"productBOM"}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div>
                    {" "}
                    {partwhereused?.length > 0 ? (
                      <div className="product-specification">
                        <div className="product-right-border">
                          <Typography variant="subtitle1" component="h6" sx={{ mb: 1 }}>
                            Where Used
                            <Divider
                              sx={{ bgcolor: "primary.main" }}
                              className="title-divider"
                            />
                          </Typography>
                        </div>

                        <div className="cross-ref">
                          <Referencetable
                            props={whereusedTable}
                            count={whereuse_count}
                            data={partwhereused}
                            table={"productWhereused"}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div>
                    {crossref?.length > 0 &&
                    data.showCrossreferences === true ? (
                      <div className="product-specification">
                        <div className="product-right-border">
                          <Typography variant="subtitle1" component="h6" sx={{ mb: 1 }}>
                            CROSS REFERENCES
                            <Divider
                              sx={{ bgcolor: "primary.main" }}
                              className="title-divider"
                            />
                          </Typography>
                        </div>

                        <div className="cross-ref">
                          <Referencetable
                            props={crossrefTable}
                            count={crossref_count}
                            data={crossref}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div
                  className={
                    EnvData?.REACT_APP_SHOW_CENTRAL_TURBO === "true"
                      ? "product-detail-right central-turbos-product-detail-right"
                      : "product-detail-right"
                  }
                >
                  {partaccessories?.length > 0 ? (
                    <PartAccessoriess
                      props={partaccessories}
                      partid={partdetails}
                    />
                  ) : null}

                  {/* alternative product */}
                  {alterproducts?.length > 0 ? (
                    <AlterProduct props={alterproducts} partid={partdetails} />
                  ) : null}
                </div>
              </div>
            </Container>
          </Paper>
        </div>
      ) : (
        <ProductLoader />
      )}
    </>
  );
}
