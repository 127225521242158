import { Box, Button, Divider } from "@mui/material";
import React, { Component, useState } from "react";
import Slider from "react-slick";
import { Avatar, Typography, Container, Paper } from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { ITestimonialProps } from "../../Types/MicrositeType";
import { useSelector } from "react-redux";

function SampleNextArrow(props: any) {
  const { style, onClick } = props;
  return (
    <Button
      variant="contained"
      style={{
        ...style,
        display: "block",
        padding: "6px",
        top: "50%",
        position: "absolute",
        right: "10px",
        zIndex: "99",
        transform: "translate(0, -50%)",
        minWidth: "auto",
        lineHeight: 1,
      }}
      onClick={onClick}
    >
      <KeyboardArrowRightOutlinedIcon sx={{ lineHeight: 1 }} />
    </Button>
  );
}

function SamplePrevArrow(props: any) {
  const { style, onClick } = props;
  return (
    <Button
      variant="contained"
      style={{
        ...style,
        display: "block",
        padding: "6px",
        top: "50%",
        position: "absolute",
        left: "10px",
        zIndex: "99",
        transform: "translate(0, -50%)",
        minWidth: "auto",
        lineHeight: 1,
      }}
      onClick={onClick}
    >
      <KeyboardArrowLeftOutlinedIcon sx={{ lineHeight: 1 }} />
    </Button>
  );
}
const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  cssEase: "linear",
  className: "",
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

interface ChildrenProps {
  children: any;
}

interface ChildrenProps {
  children: any;
}

const ReadMore = ({ children }: ChildrenProps) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <span className="read-more-text">
      {isReadMore ? text?.slice(0, 250) : text}
      {text.length > 250 && (
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? "...Read more" : " Show less"}
        </span>
      )}
    </span>
  );
};

export const Testimonial = (props: {
  _testimonial: ITestimonialProps[];
  _seoTitle: string;
}) => {
  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  //console.log(props._testimonial ,"testimnial")
  let url =
    EnvData?.REACT_APP_CDNURL +
    "/Microsite/" +
    EnvData?.REACT_APP_MICROSITENAME +
    "/Testimonial/";

  var raw = JSON.stringify({
    micrositeName: EnvData?.REACT_APP_MICROSITENAME,
    cartName: "",
    assetstyle: "string",
  });

  //const [testimoniail] = useGetDatawithMultipleArray<ITestimonialProps[]>(process.env.REACT_APP_APIURL + '/api/Microsite/Getmicrositeindexdata', requestOptions, "Table7", []);

  return (
    <Box sx={{ pt: 6, pb: 6, backgroundColor: "#f6f6f6" }}>
      <Container maxWidth={"lg"}>
        <div className="product-right-border">
          <Typography variant="h4" mb={2} color="#000">
            {props._seoTitle}
            <Divider
              sx={{ bgcolor: "primary.main" }}
              className="title-divider"
            />
          </Typography>
        </div>
        <Slider {...settings}>
          {props._testimonial.map((elem, index) => (
            <div key={index} className="slider-div">
              <Container>
                <Paper sx={{ p: 4, mb: 2, minHeight: "155px" }}>
                  <Box sx={{ display: "flex" }}>
                    <Typography component="div" color="primary">
                      <Avatar
                        alt="Showky Kaldawy"
                        sx={{
                          margin: "0px 20px 0px 0",
                          marginLeft: "auto",
                          width: 56,
                          height: 56,
                        }}
                        src={url + elem.testimonialImageFileName}
                      />
                    </Typography>
                    <div style={{ display: "inline-block" }}>
                      <Typography component="h6" color="primary">
                        {elem.name}
                      </Typography>
                      <Typography
                        component="span"
                        color="custom"
                        variant="body2"
                      >
                        {elem.name}
                      </Typography>
                    </div>
                    <FormatQuoteIcon
                      sx={{ fontSize: 60, marginLeft: "auto" }}
                      color="primary"
                    />
                  </Box>
                  <Typography>
                    <ReadMore>{elem.comments}</ReadMore>
                  </Typography>
                </Paper>
              </Container>
            </div>
          ))}

          {/* {testimoniail.map((elem, index) => (
            <div key={index}>
              <Container >
                <Paper sx={{ p: 4, mb: 2 }}>
                  <Box sx={{ display: 'flex' }}>
                    <Typography component="div" color="primary">
                      <Avatar
                        alt="Showky Kaldawy"
                        sx={{ margin: "0px 20px 0px 0", marginLeft: 'auto', width: 56, height: 56 }}
                        src={url + elem.TestimonialImageFileName} />
                    </Typography>
                    <div style={{ display: 'inline-block' }}>
                      <Typography component="h6" color="primary">{elem.Name}</Typography>
                      <Typography component="span" color="custom" variant="body2">{elem.Name}</Typography>
                    </div>
                    <FormatQuoteIcon sx={{ fontSize: 60, marginLeft: 'auto' }} color='primary' />
                  </Box>
                  <Typography >{elem.Comments}</Typography>

                </Paper>
              </Container>
            </div>
          ))} */}
        </Slider>
      </Container>
    </Box>
  );
};
