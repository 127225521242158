import React, { memo, useEffect, useState } from "react";
import {
  Typography,
  Box,
  Divider,
  TextField,
  Button,
  Stack,
  Checkbox,
  FormControlLabel,
  IconButton,
  Radio,
} from "@mui/material";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import { styled } from "@mui/material/styles";
import Paypall from "../../Images/paypall.png";
// import Checkout_Info from "./Checkout_Info";
import axios, { AxiosRequestConfig } from "axios";
import { GetAuthHeaderToken, Get_Data } from "../../API/GetDataFromApi";
import { UpdateTab, InfoComponentProps } from "../../Types/MicrositeType";
import eventEmitter from "../PartSearchTest/eventEmmiter";
import AddressFilling from "./AddressFilling";
import { useNavigate } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { CheckValidEmailAddress } from "../../API/GetMicrositeSettings";
import { useSelector } from "react-redux";

const PaypalButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 16px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#fdc439",
  borderColor: "#fdc439",
  borderRadius: "4px",

  "&:hover": {
    backgroundColor: "#fdc439",
    borderColor: "#fdc439",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#fdc439",
    borderColor: "#fdc439",
  },
});
function ShippingAdderss({
  statevalue,
  change,
  err,
  nxtTab,
  emaildisable,
  disable,
  HandleZip,
  citylist,
  statelist,
  countrylist,
  city,
  state,
  country,
}: InfoComponentProps) {
  const storedEmailInfo = localStorage.getItem("EmailInfo");
  const initialEmail =
    storedEmailInfo !== null && storedEmailInfo !== "" ? storedEmailInfo : "";

  const [showshippingadderssPopup, setshowshippingadderssPopup] =
    useState(false);
  const [Email, setEmail] = useState<string>(initialEmail);
  const [PaypalToken, setPaypalToken] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");

  const [OtherBranch, setOtherBranch] = useState<[]>([]);
  const [selectedOtherBranch, setselectedOtherBranch] = useState<any>({});
  const [data, setData] = useState<any>(null);
  const [isLoading, setLoader] = useState(false);
  const [showPopup, setshowPopup] = useState(false);

  const [EmailErr, setEmailErr] = useState("");
  const [isChecked, setIsChecked] = useState(
    localStorage.getItem("NewsAndOffersText") === "true"
  );
  const [selectedValue, setSelectedValue] = React.useState("");

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    res: any
  ) => {
    setSelectedValue(event.target.value);
    setselectedOtherBranch(res);
  };

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  function saveOtherBranch() {
    localStorage.setItem("Otherbranch", JSON.stringify(selectedOtherBranch));
    localStorage.setItem("EmailInfo", selectedOtherBranch?.customerEmail);
    setEmail(selectedOtherBranch?.customerEmail);
    localStorage.setItem("Cstbrnid", selectedOtherBranch?.cstBrnId);
    setshowshippingadderssPopup(false);
  }

  const navigate = useNavigate();

  function SelectMultiCity(citydata: any) {
    for (var i = 0; i <= citydata.data.length; i++) {
      if (
        citydata.data[i].city ==
        statevalue.AddressLine1.label.split(",")[1].toUpperCase().trim()
      ) {
        ChangeCityData(citydata.data[i]);
      } else {
        console.error(citydata.data[i], "response from cityy");
      }
    }
  }
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const openShippingadderssPopup = () => {
    setshowshippingadderssPopup(true);
  };
  const closeShippingadderssPopup = () => {
    setshowshippingadderssPopup(false);
  };

  const storedEmail = localStorage.getItem("EmailInfo");

  useEffect(() => {
    if (storedEmail) {
      setEmail(storedEmail);
    }

    if (isChecked) {
      localStorage.setItem("NewsAndOffersText", "true");
    } else {
      localStorage.removeItem("NewsAndOffersText");
    }
  }, [isChecked]);

  useEffect(() => {
    let ExistingUser = 0;
    if (
      localStorage.getItem("UserId") !== undefined &&
      localStorage.getItem("UserId") !== null &&
      localStorage.getItem("UserId") !== ""
    ) {
      ExistingUser = 1;
    }

    const URL = `${
      EnvData?.REACT_APP_APIURL
    }/microsite-api-v1/get-view-cart-info?CartId=${
      localStorage.getItem("CartId") || "0"
    }&ExistingUser=${ExistingUser}`;

    Get_Data(URL)
      .then((response: any) => {
        setLoader(true);

        setData(response.data);
      })

      .then(() => {
        setLoader(false);
      });
  }, [localStorage.getItem("CartId")]);

  useEffect(() => {
    eventEmitter.on("triggerPaypalToken", ReceiverToken);
    // eventEmitter.on("triggersearchfilter",  TriggerAttribute);

    return () => {
      // ResetreduxData()
      eventEmitter.off("triggerPaypalToken", ReceiverToken);
    };
  }, []);

  function ChangeCityData(CityData: any) {
    change("City", CityData.city);
    change("CityId", CityData.cityId);
    change("ZipId", CityData.zipId);
  }

  function setEmailFromPayPal(res: string) {
    CheckEmail(res);
  }

  async function ReceiverToken(args: any) {
    let URL = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/get-address-from-paypal?paypalOrderId=${args.token}`;
    change("AddressLine1", "");

    Get_Data(URL).then((res: any) => {
      setEmail(res.data.email_Address);
    });
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      localStorage.setItem("NewsAndOffersText", "true");
    } else {
      localStorage.setItem("NewsAndOffersText", "false");
    }
  };

  function EmailValidation(args: string) {
    setEmailErr(args);
  }

  useEffect(() => {
    async function getMultipleBranch() {
      let URL = `${EnvData?.REACT_APP_APIURL}/checkout-api-v1/get-other-branches-from-contact`;
      const headers: any = await GetAuthHeaderToken();
      if (headers === "Token Expired") {
        navigate("/login");
        return false;
      }
      try {
        const response = await axios.get(URL, { headers: headers });
        // console.warn(response.data.otherBranchesResponse, "Here")
        setOtherBranch(response.data.otherBranchesResponse);
      } catch (error) {
        alert("Due To Technical Issue Not Able TO Get Other Branch Address");
      }
    }

    if (localStorage.getItem("CustomerInfo")) {
      getMultipleBranch();
      const Logincustomerdetails = localStorage.getItem("CustomerInfo");
      let loginemail = Logincustomerdetails
        ? JSON.parse(Logincustomerdetails)
        : null;

      setEmail(loginemail?.customerEmail);
    }
  }, [localStorage.getItem("CustomerInfo")]);

  const createOrder = (data: any, actions: any) => {
    navigate("/Checkout");
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Part",
            amount: {
              currency_code: "USD",
              value: 20,
            },
          },
        ],
      })

      .then((orderID: any) => {
        //setOrderID(orderID);

        return orderID;
      });
  };

  // check Approval
  const onApprove = (data: any, actions: any) => {
    localStorage.setItem("PaypalToken", data.orderID);

    eventEmitter.emit("triggerPaypalToken", { token: data.orderID });
    setPaypalToken(data.orderID);
    return actions.order.capture().then(function (details: any) {
      const { payer } = details;
    });
  };
  //capture likely error
  const onError = (data: any, actions: any) => {
    setErrorMessage("An Error occured with your payment ");
  };

  const redirecttoLogin = () => {
    navigate("/login");
  };

  function CheckEmail(mail: string) {
    setEmail(mail);

    if (localStorage.getItem("CustomerInfo")) {
    } else {
      const result = CheckValidEmailAddress(mail);
      if (result) {
        const MailURL = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/check-existing-user?Email=${mail}`;

        Get_Data(MailURL)
          .then((res) => {
            if (res.data.success == 0) {
            } else {
              setshowPopup(true);
            }
          })
          .catch((error) => {
            console.log("Error in " + error);
          });
      } else {
      }
    }
  }

  const closeLightbox = () => {
    setshowPopup(false);
  };

  return (
    <div>
      <Dialog
        onClose={closeLightbox}
        open={showPopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          Online Access Request
          <IconButton
            className="brawerclosebutton"
            onClick={closeLightbox}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
              <Typography variant="body2" sx={{ fontWeight: "500" }}>
                It looks like this email address has ordered from us in the
                past.
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
              <Typography variant="body2" sx={{ fontWeight: "500" }}>
                {" "}
                Would you like to login to your account or would you like to
                proceed with checking out as a guest?{" "}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
              <Typography variant="body2" sx={{ fontWeight: "500" }}>
                {" "}
                Note: If you choose to proceed as a guest your order will be
                created under a new user and cannot be merged with your previous
                user’s purchases.{" "}
              </Typography>
            </Stack>

            <Button
              variant="contained"
              sx={{ mt: 2, float: "right" }}
              color="primary"
              // onClick={submitForgotpass}
              onClick={() => navigate("/Login")}
            >
              Login
            </Button>
            <Button
              variant="contained"
              sx={{ mt: 2, float: "right", mr: 1 }}
              color="primary"
              // onClick={submitForgotpass}
              onClick={closeLightbox}
            >
              Check out as guest
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      {data?.paypalClientId ? (
        <>
          {
            <Box sx={{ mb: 2 }}>
              <div className="product-right-border">
                <Typography variant="h4" pb={1}>
                  EXPRESS CHECKOUT
                  <Divider
                    sx={{ bgcolor: "primary.main" }}
                    className="title-divider"
                  />
                </Typography>
              </div>
              <PayPalScriptProvider
                options={{
                  clientId: data?.paypalClientId,
                  currency: "USD",
                  intent: "authorize",
                  "disable-funding": "credit,card",
                  commit: false,
                }}
              >
                <PayPalButtons
                  style={{ layout: "vertical" }}
                  createOrder={createOrder}
                  onApprove={onApprove}
                />
              </PayPalScriptProvider>
              {/* <PaypalButton>
          {" "}
          <img src={Paypall} alt="paypall" />
        </PaypalButton> */}
              <Divider>- OR -</Divider>
            </Box>
          }
        </>
      ) : (
        <> </>
      )}

      <Box>
        <div className="product-right-border">
          <Typography variant="h4" pb={1}>
            GENERAL CHECKOUT
            <Divider
              sx={{ bgcolor: "primary.main" }}
              className="title-divider"
            />
          </Typography>
        </div>
      </Box>
      <Box sx={{ mb: 3 }}>
        <Typography
          variant="subtitle1"
          pb={1}
          mt={3}
          mb={1}
          sx={{ fontSize: "17px" }}
        >
          CONTACT
        </Typography>
        <TextField
          fullWidth
          name="Email"
          disabled={emaildisable}
          label="Email"
          variant="standard"
          value={Email}
          onBlur={(e) => {
            const result: boolean = CheckValidEmailAddress(e.target.value);
            if (!result) {
              EmailValidation("Please enter a valid email address");
            } else {
              setEmailErr("");
              EmailValidation("");
            }
          }}
          onChange={(e) => {
            CheckEmail(e.target.value);
            // setEmail(e.target.value)
          }}
          error={EmailErr !== ""}
          helperText={EmailErr}
        />
        <Stack direction="row" spacing={2} className="already-account-stack">
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={handleCheckboxChange}
                color="primary"
              />
            }
            label="Email me with News and Offers"
          />
          {localStorage.getItem("CustomerInfo") ? (
            <></>
          ) : (
            <>
              <Typography sx={{ marginLeft: "auto!important" }} variant="body2">
                Already have an account?{" "}
                <Button variant="text" onClick={redirecttoLogin}>
                  Login
                </Button>
              </Typography>
            </>
          )}
        </Stack>

        {localStorage.getItem("CustomerInfo") ? (
          <></>
        ) : (
          <>
            <Typography variant="body2" className="shipping-tax-text">
              Note : Your email address will be used to send tracking, or other
              order related information.
            </Typography>
          </>
        )}
      </Box>
      <Box>
        <Typography
          variant="subtitle1"
          pb={1}
          mt={3}
          mb={0}
          sx={{ fontSize: "17px" }}
        >
          SHIPPING ADDRESS
        </Typography>
        {localStorage.getItem("CustomerInfo") && OtherBranch?.length > 1 ? (
          <>
            {" "}
            <Button
              sx={{ mb: 3, fontSize: "16px" }}
              onClick={openShippingadderssPopup}
              startIcon={<AddLocationIcon />}
              variant="outlined"
            >
              Choose from existing shipping address(es)
            </Button>
          </>
        ) : (
          <></>
        )}
      </Box>

      <Dialog
        onClose={closeShippingadderssPopup}
        open={showshippingadderssPopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>
          Select Shipping Address
          <IconButton
            className="brawerclosebutton"
            onClick={closeShippingadderssPopup}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box>
            <div>
              <table className="cross-ref-table">
                <thead>
                  <tr>
                    <th>Select</th>
                    <th>Address</th>
                    <th>Email Id</th>
                    <th>Phone Number</th>
                  </tr>
                </thead>
                <tbody>
                  {OtherBranch?.map((res: any, id) => {
                    let address = `${res.shippingAddress1} , ${
                      res.shippingCityName
                    }, ${res.shippingStateName.split("|")[1]} , ${
                      res.shippingCountryName.split("|")[1]
                    } , ${res.shippingZipCode}`;
                    if (res?.cstBrnId == localStorage.getItem("Cstbrnid")) {
                      return null; // Skip this address
                    }

                    return (
                      <tr key={id}>
                        <td>
                          <Radio
                            checked={selectedValue === id.toString()}
                            onChange={(e) => handleChange(e, res)}
                            value={id}
                            name="radio-buttons"
                            inputProps={{ "aria-label": "A" }}
                          />
                        </td>
                        <td>{address}</td>

                        <td>{res?.customerEmail}</td>
                        <td>{res?.shippingPhone}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <Button
              variant="contained"
              sx={{ mt: 2, float: "right" }}
              color="primary"
              onClick={saveOtherBranch}
            >
              Use selected address above
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Box>
        <AddressFilling
          mail={Email}
          mode={"ShipInfo"}
          EmailValidation={EmailValidation}
          setEmailFromPayPal={setEmailFromPayPal}
          emailErr={EmailErr}
        />
      </Box>
    </div>
  );
}

export default memo(ShippingAdderss);
