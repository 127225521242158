import axios, { AxiosRequestConfig } from "axios";
import {
  GetAuthHeaderToken,
  GetData,
  GetauthHeader,
} from "../../API/GetDataFromApi";
import { PaymentOption, Shippingmethod } from "../../Types/MicrositeType";
import { EnvironmentSettings } from "../../Types/MicrositeType";
import { useSelector } from "react-redux";

export async function getCadenceAPIToken(
  _EnvData: EnvironmentSettings
): Promise<any> {
  let CadenceToken = localStorage.getItem("authToken");
  if (
    CadenceToken === null ||
    CadenceToken === undefined ||
    CadenceToken === ""
  ) {
    // console.log("SOCreation tttt121");

    var raw = JSON.stringify({
      userName: "",
      password: "",
      loginfrom: "CheckOut",
    });
    const requestOptions = {
      method: "POST",
      headers: GetauthHeader(),
      body: raw,
    };
    await axios
      .post(
        _EnvData?.REACT_APP_APIURL +
          "/authentication-api-v1/get-microsite-authentication-token",
        raw,
        { headers: requestOptions.headers }
      )
      .then((results) => {
        // console.log(
        //   "SOCreation Token Success",
        //   results.data["authenticationToken"]
        // );
        localStorage.setItem("authToken", results.data["authenticationToken"]);
        CadenceToken = results.data["authenticationToken"];
      })
      .catch((error) => {
        console.log("Error in " + error);
        localStorage.removeItem("userName");
        localStorage.removeItem("authToken");
        CadenceToken = "Error";
      });
  }
  return CadenceToken;
}
export async function OrderCreation(
  CadenceAPIToken: any,
  userName: string,
  password: string,
  _EnvData: EnvironmentSettings
): Promise<any> {
  const ShippingInfo: any = localStorage.getItem("ShippingInfo");
  const BillingInfo: any = localStorage.getItem("BillingInfo");
  const ShippingOption: any = localStorage.getItem("DeliveryOption");

  const ParsedShipVal = JSON.parse(
    ShippingInfo ? ShippingInfo : JSON.stringify({})
  );
  const ParsedBilling = JSON.parse(
    BillingInfo ? BillingInfo : JSON.stringify({})
  );
  const ParsedShippingOption = JSON.parse(
    ShippingOption ? ShippingOption : JSON.stringify({})
  );

  if (ShippingInfo === null) {
    alert("Invalid Shipping Address");
    return;
  }
  if (BillingInfo === null) {
    alert("Invalid Billing Address");
    return;
  }
  if (ShippingOption === null) {
    alert("Invalid ShippingOptions");
    return;
  }
  const PaymentInfo: any = localStorage.getItem("PaymentInfo");

  const ParsedPaymentInfo = JSON.parse(
    PaymentInfo ? PaymentInfo : JSON.stringify({})
  );

  var address1: any = ParsedBilling.AddressLine1;
  var shipaddress1: any = ParsedShipVal.AddressLine1;

  //||ParsedBilling.Phone===undefined || ParsedBilling.Phone===null
  var billphone = "";
  if (
    ParsedBilling.Phone === "" ||
    ParsedBilling.Phone === undefined ||
    ParsedBilling.Phone === null
  ) {
    billphone = "";
  } else {
    billphone = ParsedBilling.Phone;
  }

  var shipphone = "";
  if (
    ParsedShipVal.PhoneNumber === "" ||
    ParsedShipVal.PhoneNumber === undefined ||
    ParsedShipVal.PhoneNumber === null
  ) {
    shipphone = "";
  } else {
    shipphone = ParsedShipVal.PhoneNumber;
  }

  let PoNumber = "";
  if (
    localStorage.getItem("PONUMBER") === "" ||
    localStorage.getItem("PONUMBER") === undefined ||
    localStorage.getItem("PONUMBER") === null
  ) {
    PoNumber = localStorage.getItem("EmailInfo") || "";
  } else {
    PoNumber = localStorage.getItem("PONUMBER") || "";
  }

  let onHoldFlag = "0";
  if (
    localStorage.getItem("OnHoldFlag") !== "" ||
    localStorage.getItem("OnHoldFlag") !== undefined ||
    localStorage.getItem("OnHoldFlag") !== null
  ) {
    onHoldFlag = localStorage.getItem("OnHoldFlag") || "0";
  }

  let onHoldMsg = "";
  if (
    localStorage.getItem("OnHoldMsg") !== "" ||
    localStorage.getItem("OnHoldMsg") !== undefined ||
    localStorage.getItem("OnHoldMsg") !== null
  ) {
    onHoldMsg = localStorage.getItem("OnHoldMsg") || "";
  }
  let Customerndbranch = localStorage.getItem("CartAndBramchId");
  var shipstate = ParsedShipVal.State.split("|");
  var shipcountry = ParsedShipVal.Country.split("|");
  var raw = JSON.stringify({
    orderInfo: {
      poNumber: PoNumber,
      // customerCode: "",
      customerName: "",
      // branchCode: "",
      branchName: "",
      contactEmail: localStorage.getItem("EmailInfo"),
      cartId: localStorage.getItem("CartId"),
    },
    shipingInfo: {
      firstName: ParsedShipVal.Firstname,
      lastName: ParsedShipVal.Lastname,
      shippingAddress1:
        shipaddress1["value"] === undefined
          ? ParsedShipVal.AddressLine1
          : shipaddress1["value"],
      shippingAddress2: "",
      shippingAddress3: "",
      shippingAddress4: "",
      shippingZipCode: ParsedShipVal.Zip,
      shippingCountryName: shipcountry[0],
      shippingCountyName: "",
      shippingStateName: shipstate[0],
      shippingCityName: ParsedShipVal.City,
      shippingEmail: ParsedShipVal.Email,
      shippingPhone: shipphone,
    },
    billingInfo: {
      userName: userName,
      passWord: password,
      contactFirstName: ParsedBilling.Firstname,
      contactLastName: ParsedBilling.Lastname,
      billingPhone: billphone,
      billingAddress1:
        address1["value"] === undefined
          ? ParsedBilling.AddressLine1
          : address1["value"],
      billingAddress2: "",
      billingAddress3: "",
      billingAddress4: "",
      billingZipCode: "",
      billingCountryName: "",
      billingStateName: "",
      billingCountyName: "",
      billingCityName: "",
    },
    deliveryInfo: {
      shipComplete:
        ParsedShippingOption.deliverShipcomplete === true ? "YES" : "NO",
      shippingType: ParsedShippingOption.ShippingTypename,
      shippingCharge: ParsedShippingOption.ShippingTyperate,
      deliveryDate: ParsedShippingOption.deliveryDate,
    },
    paymentInfo: {
      paymentType: ParsedPaymentInfo.paymentType,
      transactionNumber: ParsedPaymentInfo.referenceNum,
      cardType: ParsedPaymentInfo.cardType,
      paymentGateway: ParsedPaymentInfo.paymentGateway,
      paymentRemarks: "Microsite Order",
      authorizationType: "", //PaymentInfo.AuthorizationType,
      paymentAmount: "0", //PaymentInfo.PaymentAmount,
      authCode: ParsedPaymentInfo.authCode,
      cardNumber: ParsedPaymentInfo.cardNumber,
      respCode: ParsedPaymentInfo.respCode,
      cardPointeProfile: ParsedPaymentInfo.cardPointeProfile,
      cardPointeToken: ParsedPaymentInfo.cardPointeToken,
      expiryDate: ParsedPaymentInfo.expiryDate,
    },
    orderItems: [
      {
        partNumber: "",
        orderQty: 0,
        unitPrice: 0,
        corePrice: 0,
        epa: 0,
        notes: "",
      },
    ],
    onHoldFlag: onHoldFlag,
    onHoldMsg: onHoldMsg,
    checkCaptcha:
      localStorage.getItem("userName") != null &&
      localStorage.getItem("userName") != undefined &&
      localStorage.getItem("userName") != ""
        ? "0"
        : "1",
    customerAndBranchId:
      Customerndbranch !== null && Customerndbranch !== undefined
        ? Customerndbranch
        : 0,
  });
  var Messsage = "";
  // console.log("SOCreation raw", raw);
  const headers: AxiosRequestConfig["headers"] = {
    Accept: "application/json",
    Authorization: "Bearer " + CadenceAPIToken,
    "Content-Type": "application/json",
  };
  // console.log("SOCreation header", headers);
  await axios
    .post(
      _EnvData?.REACT_APP_APIURL + "/sales-order-api-v1/create-sales-order",
      raw,
      { headers: headers }
    )
    .then((results) => {
      localStorage.setItem("ShoppingcartHdrId", results.data.salesOrderNumber);
      // console.log("SOCreation Success 12321", results.data);
      if (
        results.data.salesOrderNumber !== "" &&
        results.data.salesOrderNumber !== null &&
        results.data.salesOrderNumber !== undefined
      ) {
        Messsage = "Success";
      } else {
        Messsage = results.data.ErrorMessage;
      }
    })
    .catch((error) => {
      if (error.response) {
        // console.log("Unauthorized response:", error.response);
        Messsage = error.response.data;
      } else {
        console.error("Error:", error);
        Messsage = "Error";
      }
    });

  return Messsage;
}

export async function CardpointeAuth(
  subtotal: any,
  _EnvData: EnvironmentSettings
): Promise<any> {
  localStorage.removeItem("OnHoldFlag");
  localStorage.removeItem("OnHoldMsg");
  let Message = "";
  // console.log("SOCreation Cardpointe auth tiggered");
  let cartId = localStorage.getItem("CartId");
  let cpToken = localStorage.getItem("cpToken");
  let cpexpiry = localStorage.getItem("cpexpiry");
  let savedcard =
    cpexpiry !== "" && cpexpiry !== undefined && cpexpiry !== null
      ? true
      : false;
  const ShippingInfo: any = localStorage.getItem("BillingInfo");
  const ParsedShipVal = JSON.parse(
    ShippingInfo ? ShippingInfo : JSON.stringify({})
  );

  let stateValue = localStorage.getItem("defaultState");
  var state = stateValue ? stateValue.split("|") : "";
  var country = ParsedShipVal.Country.split("|");

  let CadenceToken = localStorage.getItem("authToken");
  const headers: AxiosRequestConfig["headers"] = {
    Accept: "application/json",
    Authorization: "Bearer " + CadenceToken,
    "Content-Type": "application/json",
  };

  cpexpiry = cpexpiry === null ? "1" : cpexpiry;

  // console.log("SOCreation Cardpoiteheader", headers);
  // console.log("SOCreation ParsedShipVal", ParsedShipVal);
  // console.log("SOCreation Firstname", ParsedShipVal.Firstname);
  // console.log("SOCreation AddressLine1", ParsedShipVal.AddressLine1);
  // console.log("SOCreation cartId", cartId);
  // console.log("SOCreation cpToken", cpToken);
  // console.log("SOCreation cpToken", savedcard);
  // console.log("SOCreation cpToken", cpexpiry);

  // console.log(
  //   "ardpointe-paymen raw",
  //   "/checkout-api-v1/cardpointe-payment?cartId=" +
  //     cartId +
  //     "&cpToken=" +
  //     cpToken +
  //     "&amount=" +
  //     subtotal.toString().replace(".", "") +
  //     "&billingFirstName=" +
  //     ParsedShipVal.Firstname +
  //     "&address=" +
  //     ParsedShipVal.AddressLine1 +
  //     "&city=" +
  //     ParsedShipVal.City +
  //     "&state=" +
  //     state[1].trim() +
  //     "&country=" +
  //     country[1].trim() +
  //     "&zipcode=" +
  //     ParsedShipVal.Zip +
  //     "&savedCard=" +
  //     savedcard +
  //     "&Expiry=" +
  //     cpexpiry
  // );
  await axios
    .get(
      _EnvData?.REACT_APP_APIURL +
        "/checkout-api-v1/cardpointe-payment?cartId=" +
        cartId +
        "&cpToken=" +
        cpToken +
        "&amount=" +
        Math.round(subtotal * 100) +
        "&billingFirstName=" +
        ParsedShipVal.Firstname +
        "&address=" +
        ParsedShipVal.AddressLine1 +
        "&city=" +
        ParsedShipVal.City +
        "&state=" +
        state[1].trim() +
        "&country=" +
        country[1].trim() +
        "&zipcode=" +
        ParsedShipVal.Zip +
        "&savedCard=" +
        savedcard +
        "&Expiry=" +
        cpexpiry +
        "&frommicrosite=1",
      { headers: headers }
    )
    .then((results) => {
      // console.log("SOCreation Cardpointe Payment", results);
      // const parsePayment = JSON.parse(
      //   results.data ? results.data : JSON.stringify({})
      // );
      // console.log("SOCreation Cardpointe Payment 33", results.data);
      var data: PaymentOption = {
        paymentType: "CreditCard",
        referenceNum: results.data.pnref,
        paymentGateway: "CardPointe",
        cardType: results.data.cardType,
        respCode: results.data.respCode,
        cardPointeProfile: results.data.cardPointe_Profileid,
        cardPointeToken: results.data.cardPointe_Tokenid,
        cardNumber: results.data.acct,
        authCode: results.data.authCode,
        expiryDate: results.data.expDate,
        errorMsg: results.data.errorMsg,
      };
      localStorage.setItem("OnHoldFlag", results.data.onHoldFlag);
      localStorage.setItem("OnHoldMsg", results.data.onHoldMsg);
      localStorage.setItem("PaymentInfo", JSON.stringify(data));
      Message =
        results.data.errorMsg === "" ? "Success" : results.data.errorMsg;
    })
    .catch((error) => {
      if (error.response) {
        if (
          error.response.data
            .toString()
            .includes("System.UnauthorizedAccessException: Invalid Request")
        ) {
          Message = "Invalid Request";
        } else {
          Message = error.response.data;
        }
        // console.log("Unauthorized response:", error.response);
      } else {
        console.error("Error:", error);
        Message = "Error";
      }
    });

  return Message;
}

// export async const PaypalAuth=(subtotal: any): Promise<any> => {

export const PaypalAuth = async (
  subtotal: any,
  _EnvData: EnvironmentSettings
): Promise<any> => {
  localStorage.removeItem("OnHoldFlag");
  localStorage.removeItem("OnHoldMsg");
  //alert("From Paypal");
  var Message = "";
  let cartId = localStorage.getItem("CartId");
  let PaypalToken = localStorage.getItem("PaypalToken");
  const ShippingInfo: any = localStorage.getItem("ShippingInfo");
  const ParsedShipVal = JSON.parse(
    ShippingInfo ? ShippingInfo : JSON.stringify({})
  );
  console.log("ParsedShipVal", ParsedShipVal);
  let stateValue = localStorage.getItem("defaultState");
  var state = ParsedShipVal.State.split("|");
  var country = ParsedShipVal.Country.split("|");

  // console.log(subtotal, "SubTotaldata SOCreation");

  var raw = JSON.stringify({
    paypalOrderId: PaypalToken,
    cartId: cartId,
    isAuthorizationandUpdate: true,
    totalValue: subtotal,
    UpdateAddress: 1,
    address1: ParsedShipVal.AddressLine1,
    city: ParsedShipVal.City,
    state: state[0],
    zipcode: ParsedShipVal.Zip,
    country: country[0],
  });
  const requestOptions = {
    method: "POST",
    headers: GetauthHeader(),
  };

  await axios
    .post(
      _EnvData?.REACT_APP_APIURL + "/microsite-api-v1/paypal-order-auth",
      raw,
      { headers: requestOptions.headers }
    )
    .then((results) => {
      // console.log("SOCreation PaypalAuth Payment 33", results.data);

      var data: PaymentOption = {
        paymentType: "CreditCard",
        referenceNum: results.data.authId,
        paymentGateway: "DirectPaypal",
        cardType: "PayPal",
        respCode: "",
        cardPointeProfile: "",
        cardPointeToken: "",
        cardNumber: "",
        authCode: "",
        expiryDate: "",
        errorMsg: results.data.errorMsg,
      };

      if (results.data.errorMsg === "") {
        localStorage.setItem("PaymentInfo", JSON.stringify(data));
      } else {
        localStorage.removeItem("PaymentInfo");
      }

      // console.log("PaymentInfo", JSON.stringify(results.data.errorMsg));
      Message =
        results.data.errorMsg === ""
          ? "Success"
          : results.data.errorMsg.toString();
    })
    .catch((error) => {
      if (error.response) {
        // console.log("Unauthorized response:", error.response);
        Message = error.response.data;
      } else {
        console.error("Error:", error);
        Message = "Error";
      }
    });
  return Message;
};

export async function OrderMail(
  userName: string,
  _EnvData: EnvironmentSettings
) {
  let CadenceToken = localStorage.getItem("authToken");
  const headers: AxiosRequestConfig["headers"] = {
    Accept: "application/json",
    Authorization: "Bearer " + CadenceToken,
    "Content-Type": "application/json",
  };
  let orderHdrid = localStorage.getItem("ShoppingcartHdrId");
  var raw = JSON.stringify({
    orderNo: orderHdrid,
    userName: userName,
    onlineuser: userName !== "" ? true : false,
    userId: 1,
  });

  await axios
    .post(_EnvData?.REACT_APP_APIURL + "/sales-order-api-v1/order-mail", raw, {
      headers: headers,
    })
    .then((results) => {
      if (results.data.errorMsg !== "") {
        alert(results.data.errorMsg);
      } else {
        // alert("Order Mail Sent SuccessFully");
      }
    })
    .catch((error) => {
      if (error.response) {
        // console.log("Unauthorized response:", error.response);
        alert(error.response.data);
      } else {
        console.error("Error:", error);
        alert("Getting Error While Sending Order Mail");
      }
    });
}

export async function SubmitNewsLetter(
  _EnvData: EnvironmentSettings
): Promise<any> {
  //alert(EmailValue.current?.value);
  let SubcriptionMail = localStorage.getItem("EmailInfo");

  let URL = `${_EnvData?.REACT_APP_APIURL}/microsite-api-v1/subscribe-newsletter?SubscriberEmailId=${SubcriptionMail}`;
  GetData(URL, null)
    .then((response) => {
      return response;
    })
    .then((results) => {
      //alert("Thanks for your message!  We’ll be in touch soon.");
      //console.log(results);
    })
    .catch((error) => {
      console.log("Error in SubmitNewsLetter" + error);
    });
}

export async function VoidPayment(_EnvData: EnvironmentSettings): Promise<any> {
  const PaymentInfo: any = localStorage.getItem("PaymentInfo");
  const ParsedPaymentInfo = JSON.parse(
    PaymentInfo ? PaymentInfo : JSON.stringify({})
  );
  let cartId = localStorage.getItem("CartId");

  var Message = "";
  var raw = {
    paypalOrderId: ParsedPaymentInfo.referenceNum,
    cartId: cartId,
    paymentType:
      ParsedPaymentInfo.paymentGateway === "DirectPaypal" ? "PP" : "CP",
  };
  const requestOptions = {
    method: "POST",
    headers: GetauthHeader(),
  };
  await axios
    .post(
      _EnvData?.REACT_APP_APIURL +
        "/microsite-api-v1/void-credit-card-transaction",
      raw,
      { headers: requestOptions.headers }
    )
    .then((results) => {
      // console.log("Void Response", results.data.errorMsg.toString());
      Message =
        results.data.errorMsg === ""
          ? "Success"
          : results.data.errorMsg.toString();
    })
    .catch((error) => {
      if (error.response) {
        // console.log("Unauthorized response:", error.response);
        Message = error.response.data;
      } else {
        console.error("Error:", error);
        Message = "Error";
      }
    });
  return Message;
}

export const ACHAuth = async (
  subtotal: any,
  _EnvData: EnvironmentSettings
): Promise<any> => {
  localStorage.removeItem("OnHoldFlag");
  localStorage.removeItem("OnHoldMsg");
  //alert("From Paypal");
  var Message = "";
  let cartId = localStorage.getItem("CartId");
  let ACHAccountNumber = localStorage.getItem("ACHAccountNumber");
  let ACHRoutingNumber = localStorage.getItem("ACHRoutingNumber");

  // console.log(subtotal, "SubTotaldata ACH SOCreation");

  var raw = JSON.stringify({
    AccountNumber: ACHAccountNumber,
    RoutingNumber: ACHRoutingNumber,
    CartId: cartId,
    Amount: subtotal,
  });
  let CadenceToken = localStorage.getItem("authToken");
  const headers: AxiosRequestConfig["headers"] = {
    Accept: "application/json",
    Authorization: "Bearer " + CadenceToken,
    "Content-Type": "application/json",
  };

  await axios
    .post(_EnvData?.REACT_APP_APIURL + "/checkout-api-v1/ach-payment", raw, {
      headers: headers,
    })
    .then((results) => {
      // console.log("SOCreation PaypalAuth Payment 33", results.data);

      var data: PaymentOption = {
        paymentType: "ACH",
        referenceNum: results.data.cardPointe_RetRef,
        paymentGateway: "CardPointe",
        cardType: "ACH",
        respCode: results.data.respCode,
        cardPointeProfile: results.data.cardPointe_Profileid,
        cardPointeToken: results.data.cardPointe_Tokenid,
        cardNumber: results.data.cardPointe_CardNumber,
        authCode: results.data.cardPointe_approvalCode,
        expiryDate: results.data.cardPointe_Expiry,
        errorMsg: results.data.ErrorMsg,
      };
      localStorage.setItem("PaymentInfo", JSON.stringify(data));
      // console.log("PaymentInfo", JSON.stringify(results.data.errorMsg));
      Message =
        results.data.ErrorMsg === ""
          ? "Success"
          : results.data.ErrorMsg.toString();
    })
    .catch((error) => {
      if (error.response) {
        //console.log("Unauthorized response:", error.response);
        Message = error.response.data;
      } else {
        console.error("Error:", error);
        Message = "Error";
      }
    });
  return Message;
};

export const UpdateTmpTable = async (
  _EnvData: EnvironmentSettings,
  updatefor?: string,
  CaptchaValue?: string
): Promise<any> => {
  // const CaptureCaptchaValue = (state: any) => state.CAPTURECAPTCHAVALUE;
  // const CaptchaValue = useSelector(CaptureCaptchaValue);
  const ShippingInfo: any = localStorage.getItem("ShippingInfo");
  const BillingInfo: any = localStorage.getItem("BillingInfo");

  const parsedShippingInfo = JSON.parse(
    ShippingInfo ? ShippingInfo : JSON.stringify({})
  );
  const ParsedBilling = JSON.parse(
    BillingInfo ? BillingInfo : JSON.stringify({})
  );

  var address1: any = ParsedBilling.AddressLine1;
  var shipaddress1: any = parsedShippingInfo.AddressLine1;

  let Customerndbranch = localStorage.getItem("CartAndBramchId");
  let CustomerndbranchDate = localStorage.getItem("CartAndBramchDate");

  interface shippingingty {
    id: string;
    res: string;
    response: Shippingmethod;
  }
  let shippingType: shippingingty = {
    id: "",
    res: "",
    response: {
      carrierLogo: "",
      carrier_code: "",
      checked: "",
      commonCarrierName: "",
      deliveryDate: "",
      deliveryDay: "",
      delivery_days: "",
      estimated_delivery_date: "",
      handlingCharge: "",
      handlingType: "",
      lastPickup: "",
      list_rate: "",
      override_amount: "",
      priority: "",
      rate: "",
      retail_rate: "",
      returnLabel: "",
      service_type: "",
      shippingPolicyName: "",
      shippingTypeCommonCarrierId: "",
      shippingTypeDesc: "",
      shippingTypeId: "",
      shipping_amount: "",
    },
  };

  const savedShippingType: any = localStorage.getItem("ShippingType");
  if (savedShippingType !== null && savedShippingType !== undefined) {
    shippingType = JSON.parse(savedShippingType);
  }

  const NewBranch =
    localStorage.getItem("AddNewShippingAddress") === "YES" ? 1 : 0;

  const EditBranch =
    localStorage.getItem("UpdateShippingAddress") === "YES" ? 1 : 0;

  const EditCustomer =
    localStorage.getItem("UpdateBillingAddress") === "YES" ? 1 : 0;

  let raw = {
    customerAndBranchId:
      Customerndbranch !== null && Customerndbranch !== undefined
        ? Customerndbranch
        : 0,
    contactFirstName: ParsedBilling.Firstname,
    contactLastName: ParsedBilling.Lastname,
    contactEmail: parsedShippingInfo.Email,
    billingPhone: ParsedBilling.PhoneNumber,
    billingAddress1: ParsedBilling.AddressLine1,
    billingAddress2: "",
    billingAddress3: "",
    billingAddress4: "",
    billingZipId: ParsedBilling.ZipId,
    billingCountryId: ParsedBilling.CountryId,
    billingStateId: ParsedBilling.StateId,
    billingCountyId: 0,
    billingCityId: ParsedBilling.CityId,
    shippingFirstName: parsedShippingInfo.Firstname,
    shippingLastName: parsedShippingInfo.Lastname,
    shippingPhone: parsedShippingInfo.PhoneNumber,
    shippingAddress1: parsedShippingInfo.AddressLine1,
    shippingAddress2: "",
    shippingAddress3: "",
    shippingAddress4: "",
    shippingZipId: parsedShippingInfo.ZipId,
    shippingCountryId: parsedShippingInfo.CountryId,
    shippingStateId: parsedShippingInfo.StateId,
    shippingCountyId: 0,
    shippingCityId: parsedShippingInfo?.CityId,
    cartId: localStorage.getItem("CartId"),
    shippingTypeId:
      shippingType &&
      shippingType.response &&
      shippingType.response.shippingTypeId
        ? shippingType.response.shippingTypeId
        : 0,
    shippingEmail: parsedShippingInfo.Email,
    modifiedDate:
      CustomerndbranchDate !== null && CustomerndbranchDate !== undefined
        ? CustomerndbranchDate
        : new Date(),
    captchaValue:
      CaptchaValue !== null && CaptchaValue !== undefined && CaptchaValue !== ""
        ? CaptchaValue
        : "",
    customerEdit: EditCustomer,
    branchEdit: EditBranch,
    newBranch: NewBranch,
  };

  const header: any = await GetAuthHeaderToken();

  let flag = false;
  console.log(raw, "checkcaptchadata");
  await axios
    .post(
      _EnvData?.REACT_APP_APIURL +
        "/checkout-api-v1/add-or-update-tmp-cust-branch",
      raw,
      { headers: header }
    )
    .then((res: any) => {
      if (res.data !== undefined && res.data !== null) {
        localStorage.setItem("CartAndBramchId", res.data.customerAndBranchId);
        localStorage.setItem("CartAndBramchDate", res.data.modifiedDate);
        flag = true;
      } else {
        alert(
          "Unable to Process , Due To Technical Issue, Please Refresh the Page and Try Again"
        );
      }
    })
    .catch((error) => {
      console.log(" Error" + error);
      return "Error";
    });

  return flag;
};

export const CheckUsername = async (
  username: string,
  _EnvData: EnvironmentSettings
): Promise<any> => {
  const requestOptions = {
    method: "POST",
    headers: GetauthHeader(),
  };
  var Message = "";
  await axios
    .get(
      _EnvData?.REACT_APP_APIURL +
        "/microsite-api-v1/check-user-name?userName=" +
        username,
      {
        headers: requestOptions.headers,
      }
    )
    .then((results) => {
      Message = results.data[0].messgae;
    })
    .catch((error) => {
      console.error("Error:", error);
      Message = "Error";
    });
  return Message;
};
