
export interface Counterstate{
    CompanyName:string;
    Address:string;
    PhoneNo:string;
    Email:string;
    CartId:string;

}

export interface Userstate{
    Address1:string;
    Address2:string;
    AliasCompanyName:string;
    CartId:string;
    City_Name:string;
    CompanyName:string;
    Country_Name:string;
    CountryCode:string;
    County_Name:string;
    Email:string;
    FaxNo:string;
    Micrositename:string;
    PhoneNo:string;
    ShowinFooter:string;    
    State_Name:string;
    Zip:string;
    
}   

export interface footer {
    data:Userstate
}


const initialState:footer = {data:{
    Address1:"",
    Address2:"",
    AliasCompanyName:"",
    CartId:"",
    City_Name:"",
    CompanyName:"",
    Country_Name:"",
    CountryCode:"",
    County_Name:"",
    Email:"",
    FaxNo:"",
    Micrositename:"",
    PhoneNo:"",
    ShowinFooter:"",
    State_Name:"",
    Zip:"",

}}





export default initialState;
