import { ReactNode, useEffect } from "react";
import { createContext, useState } from "react";
import { ICart, ICartContextAction } from "../../Types/CartType";
import { Get_Data } from "../../API/GetDataFromApi";
import { useDispatch, useSelector } from "react-redux";
import { addFreightCount } from "../../Redux/Counterslice";
const defaultCart = {
  cartItems: [
    {
      Partnumber: "",
      description: "",
      price: 0,
      imgflag: true,
      imgUrl: "",
    },
  ],
  setcartItems: (cartItems: ICart) => {},
  deleteItem: (partnumber: string) => {},
};

export const CartContext = createContext<ICartContextAction>(defaultCart);

type IcartProviderProps = {
  children: ReactNode;
};

export default function IcartProvider({ children }: IcartProviderProps) {
  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);
  const [cartItems, setItems] = useState<ICart[]>([]);
  const [Items, setCartItems] = useState<[]>([]);
  let cartid = localStorage.getItem("CartId");
  let ExistingUser = 0;
  if (
    localStorage.getItem("UserId") !== undefined &&
    localStorage.getItem("UserId") !== null &&
    localStorage.getItem("UserId") !== ""
  ) {
    ExistingUser = 1;
  }
  const dispatch = useDispatch();
  // let cart =643066
  // let URL = `${process.env.REACT_APP_APIURL}/microsite-api-v1/get-view-cart-info?CartId=${cartid}&ExistingUser=${ExistingUser}`;

  const URL = `${
    EnvData?.REACT_APP_APIURL
  }/microsite-api-v1/get-view-cart-info?CartId=${
    localStorage.getItem("CartId") || "0"
  }&ExistingUser=${ExistingUser}`;
  let cartId = localStorage.getItem("CartId");
  useEffect(() => {
    if (cartId != "" && cartid != "0" && cartid != undefined) {
      (async () => {
        Get_Data(URL)
          .then((results?) => {
            // console.log(results, cartid, " res from cartviewcart");
            if (results.data.freightCount == "1") {
              dispatch(addFreightCount("1"));
            } else if (results.data.freightCount == "0") {
              dispatch(addFreightCount("0"));
            }
            results?.data?.viewCart_PartResponse?.map((res: any) => {
              //console.log(res, "for viewmap");
              const newCart: ICart = {
                Partnumber: res.partNumber,
                description: res.partDescriptionName,
                price: res.unitPrice,
                imgflag: res.imageAzureFlag,
                imgUrl: res.brandImageName,
              };
              setcartItems(newCart);
            });
          })
          .catch((err) => {
            console.error(err);

            //   localStorage.removeItem("user");
          });
      })();
    }
  }, []);
  const setcartItems = (doCart: ICart) => {
    const newCart: ICart = {
      Partnumber: doCart.Partnumber,
      description: doCart.description,
      price: doCart.price,
      imgflag: doCart.imgflag,
      imgUrl: doCart.imgUrl,
    };

    setItems((items) => {
      if (!items) {
        return [newCart];
      }

      for (let i = 0; i < items.length; i++) {
        if (items[i].Partnumber === newCart.Partnumber) {
          return items;
        }
      }

      return [...items, newCart];
    });
  };

  const deleteItem = (partnumber: string) => {
    setItems((items) => {
      const newitem = items.filter((item) => item.Partnumber !== partnumber);
      return [...newitem];
    });
  };
  return (
    <CartContext.Provider value={{ cartItems, setcartItems, deleteItem }}>
      {children}
    </CartContext.Provider>
  );
}
