import {
  Typography,
  Box,
  Grid,
  Divider,
  TextField,
  Button,
  Stack,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import fedex from "../../Images/fedex.png";
import React, { useState, useEffect } from "react";
import { GetAuthHeaderToken, Get_Data } from "../../API/GetDataFromApi";
import { useQuery } from "react-query";
import { DeliveryOptionData, PaymentOption } from "../../Types/MicrositeType";
import Loader from "../../Components/Loader";
import ProductLoader from "../ProductLoader";
import axios, { AxiosRequestConfig } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AnyARecord } from "dns";

export default function ShippingMethod({
  nxtTab,
  handleshpping,
  statevalue,
  disable,
  handledeliveryall,
  handlechange,
  deliveralldata,
}: any) {
  // console.log(statevalue, "from method");
  const navigate = useNavigate();
  let Shipinfo = localStorage.getItem("ShippingInfo");
  let parsedValue = JSON.parse(Shipinfo ? Shipinfo : "");
  let EasyPostApiKey = localStorage.getItem("EasyPostApiKey");

  let cartid = localStorage.getItem("CartId");

  const Freightcount = (state: any) => state.FREIGHT_DETAILS;
  const FreightcountDetails = useSelector(Freightcount);
  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  let URL = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/get-shipping-types?cartid=${cartid}`;
  const [showloader, setShowloader] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState(
    statevalue.id !== "" ? statevalue.id : 0
  );

  const ShippingOption: any = localStorage.getItem("DeliveryOption");
  const del = JSON.parse(ShippingOption ? ShippingOption : JSON.stringify({}));

  const [selectedDelValue, setSelectedDelValue] = useState<DeliveryOptionData>({
    ShippingTypeid:
      del.ShippingTypeid !== "" ||
      del.ShippingTypeid !== undefined ||
      del.ShippingTypeid !== null
        ? del.ShippingTypeid
        : "",
    ShippingTypename:
      del.ShippingTypename !== "" ||
      del.ShippingTypename !== undefined ||
      del.ShippingTypename !== null
        ? del.ShippingTypename
        : "",
    ShippingTyperate:
      del.ShippingTyperate !== "" ||
      del.ShippingTyperate !== undefined ||
      del.ShippingTyperate !== null
        ? del.ShippingTyperate
        : "",
    deliverShipcomplete:
      del.deliverShipcomplete !== "" ||
      del.deliverShipcomplete !== undefined ||
      del.deliverShipcomplete !== null
        ? del.deliverShipcomplete
        : false,
    deliveryDate:
      del.deliveryDate !== "" ||
      del.deliveryDate !== undefined ||
      del.deliveryDate !== null
        ? del.deliveryDate
        : "",
  });

  useEffect(() => {
    localStorage.setItem("DeliveryOption", JSON.stringify(selectedDelValue));
  }, [selectedDelValue]);

  function updateShippingValue(response: any) {
    setSelectedDelValue({
      ShippingTypeid: response.shippingTypeId,
      ShippingTypename: response.shippingTypeDesc,
      ShippingTyperate: response.override_amount,
      deliverShipcomplete: deliveralldata,
      deliveryDate: response.estimated_delivery_date,
    });
  }
  const [shipType, setShipType] = useState(null);

  const deliveryHandleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    res: any,
    id: number,
    response: any
  ) => {
    setSelectedValue(id.toString());
    const shipdata: any = {
      id: id,
      res: res,
      response: response,
    };
    setShipType(shipdata);
    handleshpping(shipdata);
    updateShippingValue(response);
  };
  const fetchCartInfo = async (): Promise<any> => {
    try {
      setShowloader(true);
      let response;
      if(EasyPostApiKey=="") {
        let apiurl =`${EnvData?.REACT_APP_APIURL}/microsite-api-v1/get-easypost-shipping-types?cartid=${cartid}`;
        response = await Get_Data(apiurl);
      }
      else{
        response = await Get_Data(URL);
      }
      // console.log("resonse.data", response.data);
      setShowloader(false);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch cart info");
    }
  };

  const { data, isLoading, refetch } = useQuery(
    "shipingmethod",
    fetchCartInfo,
    {
      enabled: false,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  async function updateShippingDetails(): Promise<any> {
    let CadenceToken = localStorage.getItem("authToken");
    // const headers: AxiosRequestConfig["headers"] = {
    //   Accept: "application/json",
    //   Authorization: "Bearer " + CadenceToken,
    //   "Content-Type": "application/json",
    // };

    const headers: any = await GetAuthHeaderToken();
    if (headers === "Token Expired") {
      navigate("/login");
      return false;
    }
    var raw = {
      shippingTypeId: statevalue.response.shippingTypeId,
      shippingCharge: statevalue.response.override_amount,
      cartId: localStorage.getItem("CartId"),
      warehouseId: localStorage.getItem("WarehouseId"),
      deliveryDate: statevalue.response.estimated_delivery_date,
      shippingTypeCommonCarrierId:
        statevalue.response.shippingTypeCommonCarrierId,
      shippingPolicyName: statevalue.response.shippingPolicyName,
    };

    await axios
      .post(
        EnvData?.REACT_APP_APIURL + "/checkout-api-v1/update-shipping-details",
        raw,
        { headers: headers }
      )
      .then((results) => {
        // console.log("UpdateShippingDetails", results);
        localStorage.setItem("ShippingType", JSON.stringify(shipType));
        if (results.data.errorMesssage == "Success") {
          nxtTab("OPTIONS");
        }

        //  uncomment if data is success
        // nxtTab("OPTIONS");
      })
      .catch((error) => {
        console.log("Error in " + error);
      });
  }

  function handleTabchange() {
    // console.log(selectedValue, selectedValue, "selectedValue");
    if (selectedValue !== "" && selectedValue !== 0) {
      updateShippingDetails();
    } else {
      alert("Select Shipping method to proceed Payment options");
    }
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setIsDeliveryallChecked(event.target.checked);
    setSelectedDelValue((prevValues: any) => ({
      ...prevValues,
      deliverShipcomplete: event.target.checked,
    }));
    //  ("DeliveryOption",JSON.stringify(selectedDelValue))
    handledeliveryall(event);
  };
  if (showloader || isLoading)
    return (
      <div>
        {" "}
        <Loader />
      </div>
    );

  return (
    <div>
      <Box sx={{ mt: 2 }}>
        <table className="payment-contact-info">
          <tbody>
            <tr>
              <th>
                <Typography variant="subtitle2">CONTACT</Typography>
              </th>
              <td>{parsedValue.Email}</td>
              <td
                className="payment-contact-info-change"
                onClick={() => handlechange("Contact")}
              >
                <Button color="primary" variant="contained">
                  Change
                </Button>
              </td>
            </tr>
            <tr>
              <th>
                <Typography variant="subtitle2">SHIPPING ADDRESS</Typography>
              </th>
              <td>
                {localStorage.getItem("DefaultAddress") +
                  ", " +
                  localStorage.getItem("defaultCity") +
                  ", " +
                  localStorage.getItem("defaultZip") +
                  ", " +
                  parsedValue?.Country.split("|")[1]}
              </td>
              <td
                className="payment-contact-info-change"
                onClick={() => handlechange("Addresss")}
              >
                <Button color="primary" variant="contained">
                  Change
                </Button>
              </td>
            </tr>
            {/* <tr>
              <th>
                <Typography variant="subtitle2">SHIPPING METHOD</Typography>
              </th>
              <td>{ShipMethod} </td>
              <td
                className="payment-contact-info-change"
                onClick={() => handlechange("Method")}
              >
                <Button color="primary" variant="contained">
                  Change
                </Button>
              </td>
            </tr> */}
          </tbody>
        </table>
      </Box>
      <Box sx={{ mt: 2 }}>
        <div className="product-right-border">
          <Typography variant="h4" pb={1}>
            SELECT DELIVERY METHOD
            <Divider
              sx={{ bgcolor: "primary.main" }}
              className="title-divider"
            />
          </Typography>
        </div>

        {EasyPostApiKey !== "" ?(
          <>
        {data && data.shippingTypeOptionsResponse.length > 0 ? (
          <table className="checkout-delivery-table">
            <thead>
              <tr>
                <th>Sel.</th>
                <th colSpan={2}>Shipping options</th>
                <th>Cost</th>
                {/* <th>Best Estimated Time</th> */}
              </tr>
            </thead>
            <tbody>
              {data && data.shippingTypeOptionsResponse.length !== 0
                ? data.shippingTypeOptionsResponse.map((res: any, id: any) => (
                    <tr key={id}>
                      <td>
                        <Radio
                          // defaultValue={statevalue.id}
                          value={selectedValue}
                          checked={
                            selectedValue === res.shippingTypeId ? true : false
                          }
                          onChange={(e) =>
                            deliveryHandleChange(
                              e,
                              res.shippingTypeDesc,
                              res.shippingTypeId,
                              res
                            )
                          }
                          disabled={disable}
                        />
                      </td>
                      <td>{/* <img src={fedex} alt="fedex" /> */}</td>
                      <td>{res.shippingTypeDesc}</td>
                      <td>
                        {res.override_amount == 0
                          ? "FREE SHIPPING"
                          : "$" + res.override_amount}
                      </td>
                      {/* <td>{res.delivery_days} Days</td> */}
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        ) : data !== undefined ? (
          data.errorMessage
        ) : null}
        </>
      )
      : (
        <>
          <table className="checkout-delivery-table">
            <thead>
              <tr>
                <th>Sel.</th>
                <th colSpan={2}>Shipping options</th>
              </tr>
            </thead>
            <tbody>
            {data && data.shippingTypeOptionsResponse.length !== 0
                ? data.shippingTypeOptionsResponse.map((res: any, id: any) => (
                    <tr key={id}>
                      <td>
                        <Radio
                          // defaultValue={statevalue.id}
                          value={selectedValue}
                          checked={
                            selectedValue === res.shippingTypeId ? true : false
                          }
                          onChange={(e) =>
                            deliveryHandleChange(
                              e,
                              res.shippingTypeDesc,
                              res.shippingTypeId,
                              res
                            )
                          }
                          disabled={disable}
                        />
                      </td>
                      <td>{res.shippingTypeDesc}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
          <div>
            <Typography variant="body1" pb={2} color="error">
              Note : Shipping will be calculated at the time of invoice.
            </Typography>
          </div>
        </>
      )}

        {/* <Typography align="center" variant="body2" color="secondary">
          View Shipping Policy
        </Typography> */}
      </Box>
      {EasyPostApiKey !== "" ?(
        <>
          {FreightcountDetails.FreightCount == "1" ? (
            <>
              {" "}
              <Box>
                <div>
                  <Typography variant="body1" pb={2} color="error">
                    Shipping Charges will be calculated later. . The items in the
                    cart are too large to ship via courier service.
                  </Typography>
                </div>
              </Box>
            </>
          ) : (
            <></>
          )}
        </>
      ): (<></>)}

      <Box sx={{ mt: 2 }}>
        <div className="product-right-border">
          <Typography variant="h4" pb={2}>
            ORDER INSTRUCTIONS
            <Divider
              sx={{ bgcolor: "primary.main" }}
              className="title-divider"
            />
          </Typography>
        </div>

        <Stack direction="row">
          <FormControlLabel
            control={
              <Checkbox
                // defaultChecked
                color="primary"
                checked={deliveralldata}
                onChange={handleCheckboxChange}
              />
            }
            label="Deliver all the items at the same time"
          />
        </Stack>
      </Box>

      <Button
        fullWidth
        variant="contained"
        onClick={handleTabchange}
        sx={{ mt: 3 }}
      >
        Continue To Payment
      </Button>
    </div>
  );
}
