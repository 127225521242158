import {
  AppBar,
  Toolbar,
  Button,
  Container,
  Divider,
  Paper,
  Stack,
  Box,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { LeftMenu } from "./LeftMenu";
import "./header.style.css";
import { CartCount } from "../Cart/CartCount";
import { useNavigate } from "react-router-dom";
import {
  IMicrositeProps,
  IgetProductDetails,
  IcustomMenu,
} from "../../Types/MicrositeType";
import { Link } from "react-router-dom";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import { Get_Data } from "../../API/GetDataFromApi";
import React, { useState, useEffect } from "react";

import PartSearchExtender from "./PartSearchExtender";

import { useSelector } from "react-redux";

// const Search = styled("div")(({ theme }) => ({
//   position: "relative",
//   borderRadius: "12px",
//   backgroundColor: "#fff",
//   "&:hover": {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   border: "1px solid #ccc",
//   marginLeft: 0,
//   width: "100%",
//   [theme.breakpoints.up("md")]: {
//     marginLeft: theme.spacing(2),
//     width: "450px",
//     display: "flex",
//   },
//   [theme.breakpoints.up("lg")]: {
//     marginLeft: theme.spacing(2),
//     width: "550px",
//     display: "flex",
//   },
// }));

// const SearchIconWrapper = styled("div")(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: "100%",
//   position: "absolute",
//   pointerEvents: "none",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   right: "0",
//   top: "0",
//   // background:'#ff4400',
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: "inherit",
//   width: "100%",
//   "& .MuiInputBase-input": {
//     padding: theme.spacing(1, 5, 1, 2),
//     // vertical padding + font size from searchIcon
//     paddingright: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create("width"),
//     width: "100%",
//     [theme.breakpoints.up("sm")]: {
//       width: "100%",
//       "&:focus": {
//         width: "100%",
//       },
//     },
//   },
// }));

const CustomMenu = (props: {
  _Dealerlocation: any;
  _menuName: string;
  _subMenuArray: any;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        component="a"
        className="linkstyle"
        sx={{
          color: "primary.light",
          ":hover": {
            color: "primary.main",
          },
        }}
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onMouseOver={handleClick}
      >
        {props._menuName}
      </Button>

      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <Box>
          {props._subMenuArray.map((res: any, index: number) => {
            const string = `/CustomPage/${res.customSubMenuId}?`;
            return (
              <Box key={index} className="custom-menu-box">
                <Button
                  to={string}
                  component={Link}
                  onClick={handleClose}
                  className="custom-dropdown-menu"
                  sx={{
                    color: "primary.light",
                    ":hover": {
                      color: "primary.main",
                    },
                  }}
                >
                  {res.customSubMenuName}{" "}
                </Button>
              </Box>
            );
          })}
        </Box>

        {/* {props._subMenuArray.map((type: any) => (
          str_url = `/Products/${type.customSubMenuId}?`
          return(
            <Grid item md={3}>
          <Button
            to={str_url}
            component={Link}
            onClick={handleClose}
            className="menumega-list"
          >
            {type.customSubMenuName}{" "}
          </Button>
          </Grid>
          );
          //<MenuItem onClick={handleClose}>{type.customSubMenuName}</MenuItem>
        ))} */}
      </Menu>
    </div>
  );
};

export const MenuHeader = (props: { _settings: IMicrositeProps }) => {
  const [menuData, setMenudata] = useState<IcustomMenu[]>([]);
  const [anchorpopupEl, setanchorpopupEl] = useState<null | HTMLElement>(null);
  const [productInfo, setproductInfo] = useState<IgetProductDetails[]>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const productmenuClickpopup = (event: React.MouseEvent<HTMLElement>) => {
    setanchorpopupEl(event.currentTarget);
  };
  const openpopup = Boolean(anchorpopupEl);
  const productmenuClosepopup = () => {
    setanchorpopupEl(null);
  };

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  let showCart: boolean = true;
  let _cartCount: number = 0;
  let _logo: string = "";
  let _showSearch: boolean = true;
  let _showAboutus: boolean = true;
  let _showContactus: boolean = true;
  let _showtestmonial: boolean = true;
  let _showProduct: boolean = true;
  let _showServices: boolean = true;
  let _dealerlocation: string = "";
  let _showTechBulletins: boolean = true;

  const catalogueWithPricing = props._settings.catalogueWithPricing;
  showCart = props._settings.shoppingcart;
  _cartCount = props._settings.cartCount;
  _logo = props._settings.logo;
  _showSearch = props._settings.partsSearchMenu;
  _showAboutus = props._settings.aboutUs;
  _showtestmonial = props._settings.testimonial;
  _showProduct = props._settings.topProducts;
  _showServices = props._settings.services;
  _dealerlocation = props._settings.dealerLocation;
  _showContactus = props._settings.showinFooter;
  _showTechBulletins = props._settings.showTechBulletins;

  let totalMenuCount = 7;
  let MaximumLength = 105;

  if (props._settings.topProducts) {
    totalMenuCount += 11;
  }
  if (props._settings.services) {
    totalMenuCount += 11;
  }
  if (props._settings.showinFooter) {
    totalMenuCount += 13;
  }
  if (props._settings.showTechBulletins) {
    totalMenuCount += 17;
  }
  if (props._settings.dealerLocation) {
    totalMenuCount += 18;
  }

  if (props._settings.partsSearchMenu) {
    totalMenuCount += 14;
  }
  if (props._settings.testimonial) {
    totalMenuCount += 15;
  }
  if (props._settings.aboutUs) {
    totalMenuCount += 11;
  }
  if (EnvData?.REACT_APP_STATICPAGE == "Yes") {
    totalMenuCount += 20;
  }

  const isScreenBelow900 = useMediaQuery("(max-width: 950px)");

  const navigate = useNavigate();


  useEffect(() => {
    (async () => {
      Get_Data(EnvData?.REACT_APP_APIURL + "/microsite-api-v1/get-custom-menu")
        .then((results) => {
          //console.log("ress" , results)

          if (totalMenuCount < 110) {
            setMenudata(results.data);
          }
        })
        .catch((error) => {
          console.log("Error in " + error);
          console.log("custom menu" + error);
        });
    })();
    (async () => {
      Get_Data(EnvData?.REACT_APP_APIURL + "/microsite-api-v1/get-top-products")
        .then((results) => {
          setproductInfo(results.data);
        })
        .catch((error) => {
          console.log("Error in " + error);
        });
    })();
  }, []);
  const endpoint = window.location.pathname;

  const CustomerId =
    localStorage.getItem("CustomerId") === undefined ||
    localStorage.getItem("CustomerId") === null ||
    localStorage.getItem("CustomerId") === ""
      ? "0"
      : localStorage.getItem("CustomerId");

  function NavHome() {
    navigate("/");
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }
  return (
    <div>
      <AppBar
        position="static"
        color="transparent"
        elevation={0}
        component="nav"
      >
        <Container maxWidth="lg">
          <Toolbar className="main-menu-toolbar">
            <a
              className="header_logo"
              // href="/"

              onClick={NavHome}
            >
              <img src={_logo} alt="logo" />
            </a>
            {_showSearch == true ? (
              <div className="header-search-bar">
                <PartSearchExtender _fromPage={""} />
              </div>
            ) : null}

            {showCart === true ||
            (CustomerId !== "0" && catalogueWithPricing === true) ? (
              <Box className="main-menu-right">
                {/* <Button
                  endIcon={
                    <KeyboardArrowDownOutlinedIcon
                      sx={{ verticalAlign: "bottom" }}
                    />
                  }
                >
                  My Account
                </Button> */}
                {/* <Button sx={{ fontSize: "14px", color: "#000", padding: "2px 8px" }} className="header-order-history-button"> <img src={Ordericon}  alt="Order History"/>Order History
                </Button> */}
                <CartCount _cartcount={_cartCount} />
              </Box>
            ) : null}
          </Toolbar>
        </Container>
      </AppBar>

      <Paper
        elevation={0}
        sx={{ padding: "5px 5px", backgroundColor: "#f7f7f7" }}
      >
        <Container maxWidth="lg">
          <Stack direction="row" alignItems="center" spacing={5}>
            <LeftMenu _settings={props._settings} _custommenu={menuData} />

            {/* <Button
              endIcon={<ArrowDropDownIcon
                sx={{ verticalAlign: 'bottom' }} />}
            >
              Products
            </Button> */}
            <Box className="nav-main-menu">
              <Button
                to="/"
                component={Link}
                className="linkstyle"
                sx={{
                  color: "primary.light",
                  ":hover": {
                    color: "primary.main",
                  },
                }}
              >
                Home
              </Button>
              {EnvData?.REACT_APP_STATICPAGE == "Yes" ? (
                <Button
                  to="/FeaturesBenefits"
                  component={Link}
                  className="linkstyle"
                  sx={{
                    color: "primary.light",
                    ":hover": {
                      color: "primary.main",
                    },
                  }}
                >
                  Features-Benefits
                </Button>
              ) : null}
              {_showProduct === true ? (
                <div>
                  <Button
                    id="menumega-button"
                    className="linkstyle"
                    sx={{
                      color: "primary.light",
                      ":hover": {
                        color: "primary.main",
                      },
                    }}
                    aria-controls={open ? "menumega" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={productmenuClickpopup}
                  >
                    Products
                  </Button>
                  <Menu
                    id="menumega"
                    open={openpopup}
                    anchorEl={anchorpopupEl}
                    onClose={productmenuClosepopup}
                    MenuListProps={{
                      "aria-labelledby": "menumega-button",
                    }}
                    className="menumega"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <Box
                      display="flex"
                      sx={{ padding: "10px" }}
                      className="menumega-box"
                    >
                      <Grid container spacing={2}>
                        {productInfo.map((res, index) => {
                          const productUrl = `/Products/${res.productName}?`;
                          return (
                            <Grid item md={3} key={index}>
                              <Button
                                to={productUrl}
                                component={Link}
                                onClick={productmenuClosepopup}
                                className="menumega-list"
                              >
                                {res.productName}{" "}
                              </Button>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </Menu>
                </div>
              ) : null}

              {_showServices === true ? (
                <Button
                  to="/Services"
                  component={Link}
                  className="linkstyle"
                  sx={{
                    color: "primary.light",
                    ":hover": {
                      color: "primary.main",
                    },
                  }}
                >
                  Services
                </Button>
              ) : null}

              {_showSearch == true ? (
                <Button
                  to="/PartSearch"
                  component={Link}
                  className="linkstyle"
                  sx={{
                    color: "primary.light",
                    ":hover": {
                      color: "primary.main",
                    },
                  }}
                >
                  Part Search
                </Button>
              ) : null}

              {_showtestmonial == true ? (
                <Button
                  to="/Testimonial"
                  component={Link}
                  className="linkstyle"
                  sx={{
                    color: "primary.light",
                    ":hover": {
                      color: "primary.main",
                    },
                  }}
                >
                  Testimonials
                </Button>
              ) : null}
              {_showAboutus == true ? (
                <Button
                  to="/AboutUs"
                  className="linkstyle"
                  component={Link}
                  sx={{
                    color: "primary.light",
                    ":hover": {
                      color: "primary.main",
                    },
                  }}
                >
                  About Us
                </Button>
              ) : null}
              {_showTechBulletins === true ? (
                <Button
                  to="/TechBulletins"
                  component={Link}
                  className="linkstyle"
                  sx={{
                    color: "primary.light",
                    ":hover": {
                      color: "primary.main",
                    },
                  }}
                >
                  Tech Bulletins
                </Button>
              ) : null}

              {_showContactus !== true ? (
                <Button
                  to="/ContactUs"
                  component={Link}
                  className="linkstyle"
                  sx={{
                    color: "primary.light",
                    ":hover": {
                      color: "primary.main",
                    },
                  }}
                >
                  Contact Us
                </Button>
              ) : null}

              {_dealerlocation !== "" && totalMenuCount < 115 ? (
                <Button
                  to={_dealerlocation}
                  component={Link}
                  className="linkstyle"
                  sx={{
                    color: "primary.light",
                    ":hover": {
                      color: "primary.main",
                    },
                  }}
                >
                  Dealer Location
                </Button>
              ) : null}

                {EnvData?.REACT_APP_SHOWCATALOG == "Yes" ? (
                  <Button
                  to={"/RequestCatalog"}
                  component={Link}
                  className="linkstyle"
                  sx={{
                    color: "primary.light",
                    ":hover": {
                      color: "primary.main",
                    },
                  }}
                >
                  Request Catalog
                </Button>
                ) : null}

              {isScreenBelow900 == false
                ? menuData.map((elem, index) => {
                    totalMenuCount += elem.customMenuName.length;

                    if (totalMenuCount > 110) {
                      return null;
                    } else {
                      return (
                        <div key={index}>
                          <CustomMenu
                            _Dealerlocation={_dealerlocation}
                            _menuName={elem.customMenuName}
                            _subMenuArray={elem.subMenus}
                          />
                        </div>
                      );
                    }
                  })
                : null}
            </Box>
          </Stack>
        </Container>
      </Paper>
      <Divider />
    </div>
  );
};
