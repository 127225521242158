import axios from "axios";
import React from "react";
// import GetauthHeaderasync from "../APIHooks/useGetAuthHeaderAsync";
import { IMicrositeProps, ICompanyProps } from "../Types/MicrositeType";

import { useNavigate } from "react-router-dom";
import { EnvironmentSettings } from "../Types/MicrositeType";
// import { stringify } from "querystring";

let env: any;

export const Appgetdata = (
  _cartname: string | null,
  _EnvData: EnvironmentSettings
): Promise<any> => {
  env = _EnvData;
  if (!localStorage.getItem("cartName")) {
    var currentDate = new Date();
    var date =
      currentDate.getMonth() +
      "/" +
      currentDate.getDate() +
      "/" +
      currentDate.getFullYear() +
      "/" +
      currentDate.getTime();
    localStorage.setItem(
      "cartName",
      (_EnvData.REACT_APP_MICROSITENAME + "_" + date) as string
    );
  }
  let cartName = localStorage.getItem("cartName");
  const Url = `${_EnvData?.REACT_APP_APIURL}/microsite-api-v1/get-settings?cartName=${cartName}`;

  const getMicrositeData = async (): Promise<any> => {
    const header = await GetauthHeaderasync(_EnvData);
    // console.log(header , "headerr")

    return axios
      .get(Url, {
        headers: header,
      })
      .then((results) => {
        //console.log(results, "results from app getdata");
        // setLoading(false);
        return results;
      })
      .catch((error) => {
        console.log("Error in " + error);
        //localStorage.removeItem("user");
      });
  };

  return getMicrositeData();
};

export const GetDataMultiResults = (url: string): Promise<any> => {
  // let cartName =localStorage.getItem("cartName")
  // let  assetstyle  ="string"

  const requestOptions = {
    method: "POST",
    headers: GetauthHeader(),
  };

  const CustomerId =
    localStorage.getItem("CustomerId") === undefined ||
    localStorage.getItem("CustomerId") === null ||
    localStorage.getItem("CustomerId") === ""
      ? "0"
      : localStorage.getItem("CustomerId");

  const Url = `${url}?cartname=cart&assetstyle=p04&customerId=${CustomerId}`;

  return axios
    .get(Url, { headers: requestOptions.headers })
    .then((results) => {
      //console.log(results.data, " getdatamulti index");
      return results;
    })
    .catch((error) => {
      console.log("Error in " + error);
      //localStorage.removeItem("user");
    });
};

export const GetData = (
  url: string,
  raw: unknown,
  run?: boolean
): Promise<any> => {
  //  var raw = JSON.stringify({
  //         "action": "ms_ServicePage"
  //     });

  const requestOptions = {
    method: "POST",
    headers: GetauthHeader(),
    body: raw,
  };
  // console.log("run in get data" ,run)

  return axios
    .post(url, raw, { headers: requestOptions.headers })
    .then((results) => {
      // console.log(results.data, " getdatamulti");
      return results.data;
    })
    .catch((error) => {
      console.log("Error in " + error);
      //localStorage.removeItem("user");
    });
};

export const Get_Data = (
  url: string,
  raw?: string,
  run?: boolean
): Promise<any> => {
  //  var raw = JSON.stringify({
  //         "action": "ms_ServicePage"
  //     });

  const requestOptions = {
    headers: GetauthHeader(),
  };
  // console.log("run in get data" ,run)

  return axios
    .get(url, { headers: requestOptions.headers })
    .then((results) => {
      // console.log(results.data, " getdatamulti");
      return results;
    })
    .catch((error) => {
      console.error("Response error data:", error.response.data);
      if (
        error.response.data
          .toString()
          .includes("System.UnauthorizedAccessException: Invalid Request")
      ) {
        throw new Error("Invalid Request");
      }
      //localStorage.removeItem("user");
    });
};

export const Get_Product_info_Data = (
  url: string,
  raw: string | undefined,
  run?: boolean
): Promise<any> => {
  //  var raw = JSON.stringify({
  //         "action": "ms_ServicePage"
  //     });

  const requestOptions = {
    method: "POST",
    headers: GetauthHeader(),
  };

  let rawparm = Number(raw);

  const CustomerId =
    localStorage.getItem("CustomerId") === undefined ||
    localStorage.getItem("CustomerId") === null ||
    localStorage.getItem("CustomerId") === ""
      ? "0"
      : localStorage.getItem("CustomerId");

  let URL = `${url}?productName=${raw}`;
  // console.log("run in get data" ,run)

  URL = URL.replaceAll("&", "|");
  URL = URL + "&CustomerId=" + CustomerId;
  // console.log(URL, "producturl");
  return axios
    .get(URL, { headers: requestOptions.headers })
    .then((results) => {
      // console.log(results.data, " getdatamulti");
      return results;
    })
    .catch((error) => {
      console.log("Error in Get Method " + error);
      //localStorage.removeItem("user");
    });
};
export const GetDataAboutUs = (url: string, run?: boolean): Promise<any> => {
  //  var raw = JSON.stringify({
  //         "action": "ms_ServicePage"
  //     });

  const requestOptions = {
    method: "Get",
    headers: GetauthHeader(),
  };
  // console.log("run in get data" ,run)

  return axios
    .post(url, { headers: requestOptions.headers })
    .then((results) => {
      // console.log(results.data, " getdatamulti");
      return results.data;
    })
    .catch((error) => {
      console.log("Error in " + error);
      //localStorage.removeItem("user");
    });
};

export const GetDatawithLoader = (
  url: string,
  requestOption: object,
  raw: string,
  // need to change unkown accordingly
  initialState: unknown
): Promise<any> => {
  return axios
    .post(url, raw, { headers: requestOption })
    .then((results) => {
      //console.log(results.data, " getdatamulti");
      return results.data;
    })
    .catch((error) => {
      console.log("Error in " + error);
      //localStorage.removeItem("user");
    });
};

export const GetViewCartResults = (
  url: string,
  raw: string,

  initialState?: unknown
): Promise<any> => {
  const requestOptions = {
    method: "POST",
    headers: GetauthHeader(),
    body: raw,
  };
  return axios
    .post(url, raw, { headers: requestOptions.headers })
    .then((results) => {
      //console.log(results.data, " getdatamulti");
      return results.data;
    })
    .catch((error) => {
      console.log("Error in " + error);
      //localStorage.removeItem("user");
    });
};

export const GetTestimonialResults = (
  url: string,
  raw: string,

  initialState?: unknown
): Promise<any> => {
  const requestOptions = {
    method: "POST",
    headers: GetauthHeader(),
    body: raw,
  };
  return axios
    .post(url, raw, { headers: requestOptions.headers })
    .then((results) => {
      //console.log(results.data, " getdatamulti");
      return results.data;
    })
    .catch((error) => {
      console.log("Error in " + error);
      //localStorage.removeItem("user");
    });
};

export const GetContactResults = (
  url: string,
  raw: string,

  initialState?: unknown
): Promise<any> => {
  const requestOptions = {
    method: "POST",
    headers: GetauthHeader(),
    body: raw,
  };
  return axios
    .post(url, raw, { headers: requestOptions.headers })
    .then((results) => {
      //console.log(results.data, " getdatamulti");
      return results.data;
    })
    .catch((error) => {
      console.log("Error in " + error);
      //localStorage.removeItem("user");
    });
};

export const GetFAQResults = (
  url: string,
  raw: string,

  initialState?: unknown
): Promise<any> => {
  const requestOptions = {
    method: "POST",
    headers: GetauthHeader(),
    body: raw,
  };
  return axios
    .post(url, raw, { headers: requestOptions.headers })
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      console.log("Error in " + error);
      //localStorage.removeItem("user");
    });
};

// DB Activity
export const DODBActivity = (url: string, raw: string): Promise<any> => {
  const requestOptions = {
    method: "POST",
    headers: GetauthHeader(),
    body: raw,
  };
  return axios
    .post(url, raw, { headers: requestOptions.headers })

    .then((results) => {
      return results;
    })
    .catch((error) => {
      console.log("Error in " + error);
      let results = "Fail";
      return results;
    });
};

//     Custom component to update the cart - used in view cart
export const GetCartUpdated = (url: string, raw: string): Promise<any> => {
  const requestOptions = {
    method: "POST",
    headers: GetauthHeader(),
    body: raw,
  };
  return axios
    .post(url, raw, { headers: requestOptions.headers })
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      console.log("Error in " + error);
      //localStorage.removeItem("user");
    });
};

export const DBActivityResults = (url: string, raw: string): Promise<any> => {
  const requestOptions = {
    method: "POST",
    headers: GetauthHeader(),
    body: raw,
  };
  return axios
    .post(url, raw, { headers: requestOptions.headers })

    .then((results) => {
      return results;
      // console.log(JSON.stringify(cartItems))
    })
    .catch((error) => {
      console.log("Error in " + url);
      //localStorage.removeItem("user");
    })
    .finally(() => {});
};

//  usegenerateToken
export const GenerateToken = async (url: string) => {
  //console.log("token generation");
  var inputData = JSON.stringify({
    userName: env?.REACT_APP_USERNAME,
    password: env?.REACT_APP_PASSWORD,

    // apikey: process.env.REACT_APP_APIKEY,
  });

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: inputData,
  };
  console.log(requestOptions);
  // useEffect(() => {
  const headers = { "Content-Type": "application/json" };
  await axios
    .post(
      url + "/authentication-api-v1/get-authentication-token",
      requestOptions.body,
      { headers }
    )
    .then((response) => {
      //console.log(response, " res from genertate tokedn");
      return response;
    })
    .then((results) => {
      //console.log(results.data.authenticationToken, " results");
      localStorage.setItem("user", results.data.authenticationToken);
      //    let id = localStorage.getItem("user")

      //    if(id) {
      //     console.log("tureeee" ,id)
      //    }
    })
    .catch((error) => {
      console.log("Error in Token Generation");
    });
  // }, [url]);
};

//  Use get auth header async
export const GetauthHeaderasync = async (_EnvData: EnvironmentSettings) => {
  //var user = localStorage.getItem("user");
  var cartName = localStorage.getItem("cartName");
  if (!cartName) {
    var company = sessionStorage.getItem("CompanyName");
    var currentDate = new Date();
    var date =
      currentDate.getMonth() +
      1 +
      "/" +
      currentDate.getDate() +
      "/" +
      currentDate.getFullYear() +
      "/" +
      currentDate.getTime();

    const currDate = "Current Date= " + date;
    localStorage.setItem(
      "cartName",
      (_EnvData?.REACT_APP_MICROSITENAME + "_" + company + "_" + date) as string
    );
  }
  return {
    //Authorization: "Bearer " + user,
    RequestKey: _EnvData?.REACT_APP_APIREQUESTKEY,
    "Content-Type": "application/json",
  };
  // if (user) {
  //   return {
  //     //Authorization: "Bearer " + user,
  //     RequestKey:process.env.REACT_APP_APIREQUESTKEY,
  //     "Content-Type": "application/json",
  //   };
  // } else {
  //   // console.log("GetauthHeader");
  //   let url = process.env.REACT_APP_APIURL as string;

  //   await GenerateToken(url);

  //   user = localStorage.getItem("user");
  //   return {
  //     //Authorization: "Bearer " + user,
  //     RequestKey:process.env.REACT_APP_APIREQUESTKEY,
  //     "Content-Type": "application/json",
  //   };
  // }
};

//  use get auth header

export const GetauthHeader = () => {
  //var user = localStorage.getItem("user");
  var cartName = localStorage.getItem("cartName");
  if (!cartName) {
    var company = sessionStorage.getItem("CompanyName");
    var currentDate = new Date();
    var date =
      currentDate.getMonth() +
      1 +
      "/" +
      currentDate.getDate() +
      "/" +
      currentDate.getFullYear() +
      "/" +
      currentDate.getTime();
    const currDate = "Current Date= " + date;
    localStorage.setItem(
      "cartName",
      (env?.REACT_APP_MICROSITENAME + "_" + company + "_" + date) as string
    );
  }
  return {
    //Authorization: "Bearer " + user,
    RequestKey: env?.REACT_APP_APIREQUESTKEY,
    "Content-Type": "application/json",
  };
  // if (user) {
  //   return {
  //     //Authorization: "Bearer " + user,
  //     RequestKey:process.env.REACT_APP_APIREQUESTKEY,
  //     "Content-Type": "application/json",
  //   };
  // } else {
  //   // console.log("GetauthHeader");
  //   //let url = process.env.REACT_APP_APIURL as string;

  //   //GenerateToken(url);

  //  // user = localStorage.getItem("user");
  //   return {
  //     //Authorization: "Bearer " + user,
  //     RequestKey:process.env.REACT_APP_APIREQUESTKEY,
  //     "Content-Type": "application/json",
  //   };
  // }
};

// Contact

export const GeteMailRes = (url: string, raw: string): Promise<any> => {
  const requestOptions = {
    method: "POST",
    headers: GetauthHeader(),
    body: raw,
  };
  return axios
    .post(url, raw, { headers: requestOptions.headers })
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      console.log("Error in " + error);
      //localStorage.removeItem("user");
    });
};

//  Meta tag

export const GetMetaData = (url: string, raw: string): Promise<any> => {
  const requestOptions = {
    method: "POST",
    headers: GetauthHeader(),
    body: raw,
  };
  return axios
    .post(url, raw, { headers: requestOptions.headers })
    .then((results) => {
      return results;
    })
    .catch((error) => {
      console.log("Error in " + error);
      //localStorage.removeItem("user");
    });
};

//  product grid

export const GetADDToCartData = (
  url: string,
  jsoncartinput: string,
  cartInput: object
): Promise<any> => {
  const requestOptionscart = {
    method: "POST",
    headers: GetauthHeader(),
    body: JSON.stringify(cartInput),
  };
  return axios
    .post(url, jsoncartinput, { headers: requestOptionscart.headers })
    .then((results) => {
      return results;
    })
    .catch((error) => {
      console.log("Error in " + error);
      // localStorage.removeItem("user");
    });
};

export const GetSpecificationData = (
  url: string,
  partid?: number
  // jsoncartinput: string,
  // cartInput: object
): Promise<any> => {
  const requestOptionscart = {
    method: "POST",
    headers: GetauthHeader(),
    // body: JSON.stringify(cartInput),
  };
  return axios
    .get(url, { headers: requestOptionscart.headers })
    .then((results) => {
      // console.log(results , " specss")
      return results;
    })
    .catch((error) => {
      console.log("Error in " + error);
      // localStorage.removeItem("user");
    });
};

//  Submenu

export const Getmenu = () => {
  const requestOptionscart = {
    method: "POST",
    headers: GetauthHeader(),
    body: JSON.stringify({
      action: "ms_AdditionalPage",
      parray: [
        "MicrositeID",
        "1",
        "MicrositeAdditionalSubMenuId",
        "3",
        "Type",
        "List",
      ],
      pageName: "string",
      methodname: "string",
      readwriteFlag: 0,
      outputparam: "",
    }),
  };
  return axios
    .post(
      "https://apatech.in/CadenceAPITest/microsite-api-v1/getDatas_readOnly",
      requestOptionscart.body,
      { headers: requestOptionscart.headers }
    )
    .then((results) => {
      // console.log(results, "submenu results");
      // return(results)
    })
    .catch((error) => {
      console.log("Error in " + error);
      // localStorage.removeItem("user");
    });
};

//Getmenu();
export const GetCrossreference = (
  url: string,
  partid?: number,
  competitorId?: number,
  competotorPartNo?: string
): Promise<any> => {
  const requestOptionscart = {
    method: "GET",
    headers: GetauthHeader(),
  };
  return axios
    .get(url, { headers: requestOptionscart.headers })
    .then((results) => {
      return results;
    })
    .catch((error) => {
      console.log("Error in " + error);
      // localStorage.removeItem("user");
    });
};

export async function GetAuthHeaderToken(): Promise<any> {
  const tokenstatus = await isTokenExpired();
  if (tokenstatus !== true) {
    if (
      localStorage.getItem("userName") !== undefined &&
      localStorage.getItem("userName") !== null &&
      localStorage.getItem("userName") !== ""
    ) {
      return "Token Expired";
      alert("Token  Expired");
    }
    return false;
  } else {
    const ltoken = localStorage.getItem("authToken");
    return {
      Accept: "application/json",
      Authorization: "Bearer " + ltoken,
      "Content-Type": "application/json",
    };
  }
}

const parseJwt = (token: any) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

async function isTokenExpired(): Promise<any> {
  try {
    const token: any = localStorage.getItem("authToken");
    const decodedToken: any = parseJwt(token); // jwt.decode(token);
    if (!decodedToken) {
      const tk = await getCadenceAPIToken();
      if (tk === "Error") {
        alert("Not able to Generate Token");
        return false;
      }
      return true;
    }
    const currentTimestamp = Math.floor(Date.now() / 1000);
    if (decodedToken.exp && decodedToken.exp > currentTimestamp) {
      return true;
    } else if (
      localStorage.getItem("userName") !== undefined &&
      localStorage.getItem("userName") !== null &&
      localStorage.getItem("userName") !== ""
    ) {
      return false;
    } else {
      const tk = await getCadenceAPIToken();
      if (tk === "Error") {
        alert("Not able to Generate Token");
        return false;
      }
      return true;
    }
  } catch (error) {
    return true;
  }
}

async function getCadenceAPIToken(): Promise<any> {
  let CadenceToken = "";
  var raw = JSON.stringify({
    userName: "",
    password: "",
    loginfrom: "CheckOut",
  });
  const requestOptions = {
    method: "POST",
    headers: GetauthHeader(),
    body: raw,
  };
  await axios
    .post(
      env?.REACT_APP_APIURL +
        "/authentication-api-v1/get-microsite-authentication-token",
      raw,
      { headers: requestOptions.headers }
    )
    .then((results) => {
      localStorage.setItem("authToken", results.data["authenticationToken"]);
      CadenceToken = results.data["authenticationToken"];
    })
    .catch((error) => {
      console.log("Error in " + error);
      localStorage.removeItem("userName");
      localStorage.removeItem("authToken");
      CadenceToken = "Error";
    });
  return CadenceToken;
}
