import {
  Autocomplete,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  darken,
  lighten,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  BRAND_FILTER,
  CATEGORY_FILTER,
  DESCRIPTION_FILTER,
  EXTENDER_BRAND_FILTER,
  EXTENDER_CROSSREFERENCE_FILTER,
} from "../../Redux/ReduxConstants";
import {
  addBrandfilter,
  addCategoryfilter,
  addDesfilter,
  addDisplayText,
  addExBrandfilter,
  addExCrossReffilter,
  addSearchtext,
  addVSengine,
  addVSmake,
  addVSmodel,
  addVSyear,
  clearallAttributeWithValue,
  clearalllApplicationWithValue,
  trigger,
} from "../../Redux/Counterslice";
import SearchIcon from "@mui/icons-material/Search";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { GetauthHeader } from "../../API/GetDataFromApi";
import { useNavigate } from "react-router-dom";
import { useResetdata } from "../../Redux/Resetdata";

const PartSearchExtender = (props: { _fromPage: any }) => {
  const [selectedval, setselectedval] = useState([]);
  const [Inpval, setInpval] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchVal, setsearchVal] = useState("");

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  const { ResetreduxData } = useResetdata();
  const handle = (event: any, newvalue: any) => {
    if (newvalue != "") {
      getExtenderData(newvalue);
    // console.log(newvalue, "Option clicked0");
      setInpval(newvalue);
    }
  };
  const selectData = (state: any) => state;
  const data = useSelector(selectData);

  useEffect(() => {
  // console.log("ClearText", data.EXTENDER_TEXT_SEARCH.Text);
    if (data.EXTENDER_TEXT_SEARCH.Text == "") {
      setselectedval([]);
      setInpval("");
    }
  }, [data.EXTENDER_TEXT_SEARCH]);

  const getExtenderData = (newvalues: string) => {
    if (newvalues.length > 2) {
      (async () => {
        const requestOptions = {
          method: "POST",
          headers: GetauthHeader(),
        };
      // console.log(requestOptions);
        axios
          .get(
            EnvData?.REACT_APP_APIURL +
              "/microsite-api-v1/get-part-search-extender?PartsearchText=" +
              newvalues,
            { headers: requestOptions.headers }
          )
          .then((results?) => {
          // console.log(results);
            let transferdata = results?.data.map((item: any) => ({
              KeyId: `${item.keyId}`,
              ProductName: `${item.productName} `,
              PartDescription: `${item.partDescription}`,
              Type: `${item.type}`,
              Seqno: `${item.Seqno}`,
            }));
            setselectedval(transferdata);
          });
      })();
    } else {
      setselectedval([]);
    }
  };

  const GroupHeader = styled("div")(({ theme }) => {
    return {
      position: "sticky",
      top: "-8px",
      padding: "4px 10px",
      color: theme.palette.primary.main,
      backgroundColor:
        theme.palette.mode === "light"
          ? lighten(theme.palette.primary.light, 0.85)
          : darken(theme.palette.primary.main, 0.8),
    };
  });

  const GroupItems = styled("ul")({
    padding: 0,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOptionClick = (event: any, value: any) => {
    let entredtype = {
      text: value.ProductName,
    };
  // console.log(entredtype.text, "Option clicked2");
    dispatch(addSearchtext(entredtype.text));
    if (value["Type"] == "Parts") {
      setInpval("");
    // console.log("Option clicked32", Inpval);
      let p = value["PartDescription"];
      let arr = p.split("\\");
      let Partnumber = arr[0];
      navigate("/Category/" + Partnumber);
    } else if (value["Type"] == "Brand") {
      ResetreduxData();
      let brandid = value["KeyId"];
      let brandname = value["ProductName"];
    // console.log(brandid, "Selected brand");

      let type = {
        type: EXTENDER_BRAND_FILTER,
        brandId: brandid,
        brandName: brandname,
      };
      dispatch(addDisplayText(brandname));
      dispatch(addExBrandfilter(type));
      setTimeout(() => dispatch(trigger()), 200);
      if (props._fromPage != "PartSearch") {
        navigate("/PartSearch");
      }
    } else if (value["Type"] == "CrossReference") {
      ResetreduxData();
      let compID = value["KeyId"];
      let p = value["ProductName"];
      let arr = p.split(" - ");
      let Compnumber = arr[0];
      let result = Compnumber.replace(/^\s+|\s+$/gm, "");
      dispatch(addDisplayText(p));
    // console.log(compID, Compnumber, "CrossReference Selected");

      let type = {
        type: EXTENDER_CROSSREFERENCE_FILTER,
        CompetitorId: compID,
        CompetitorpartNumber: Compnumber,
      };

      dispatch(addExCrossReffilter(type));
      setTimeout(() => dispatch(trigger()), 200);
      if (props._fromPage != "PartSearch") {
        navigate("/PartSearch");
      }
    }
    HideDropdown();
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
    // console.log("Enter key pressed");
      searchbytext();
    }
  };

  function searchbytext() {
    if (Inpval != "") {
      HideDropdown();
      ResetreduxData();
      dispatch(addSearchtext(Inpval));
      dispatch(addDisplayText(Inpval));
      setTimeout(() => dispatch(trigger()), 200);
      navigate("/PartSearch");
    } else {
      alert("Please Enter Part Number or Description");
    }
  }

  function HideDropdown() {
    setDropdownOpen(false);
    setselectedval([]);
  }
  const PartsearchNavigate = () => {
    if (props._fromPage != "PartSearch") {
      setTimeout(() => dispatch(trigger()), 200);
      navigate("/PartSearch");
    }
  };

  return (
    <div
      className={
        props._fromPage == "PartSearch"
          ? "partSearchPageExtender"
          : "headerpartsearchextender"
      }
    >
      <Autocomplete
        options={selectedval}
        open={dropdownOpen && selectedval.length > 0}
        onOpen={() => setDropdownOpen(true)}
        onClose={HideDropdown}
        groupBy={(option) => option["Type"]}
        getOptionLabel={(option) => option["ProductName"]}
        onInputChange={(event, newvalue) => handle(event, newvalue)}
        onKeyDown={handleKeyPress}
        // onChange={(event, newvalue, reason) =>
        //   handleOptionClick(event, newvalue)
        // }

        noOptionsText=""
        ListboxProps={{ style: { maxHeight: 400 } }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search by Part Number or Description"
            variant="outlined"
            value={Inpval}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={searchbytext}
                    className={
                      props._fromPage == "PartSearch"
                        ? "partSearchPageExtenderButton"
                        : "headerExtenderButton"
                    }
                    sx={{
                      backgroundColor: "primary.main",
                      color: "primary.contrastText",
                      ":hover": {
                        backgroundColor: "primary.main",
                      },
                    }}
                  >
                    <SearchIcon sx={{ fontSize: "25px" }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            onClick={(e) => {
              handleOptionClick(e, option);
            }}
          >
            {" "}
            {option["ProductName"]}
          </li>
        )}
        renderGroup={(params) => (
          <li key={params.key}>
            <GroupHeader>{params.group}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
        className="header-search-autocomplete"
      />
      <Button
        onClick={PartsearchNavigate}
        className="header-search-button"
      ></Button>
    </div>
  );
};

export default PartSearchExtender;
