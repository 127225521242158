

import {CentralTurbosFeaturedProductSlider} from "../../Components/Product/CentralTurbo/CentralTurbosFeaturedParts"
import CentralTurbosBannerSlider from "../../Components/Home/CentralTurbo/CentralTurbosBannerSlider"
import { Box, Button, Container, Paper, Typography } from "@mui/material"
import {CentralTurbosCategorySlider} from "../../Components/Product/CentralTurbo/CentralTurbosCategory"
import CentralTurbosBrand from "../../Components/Home/CentralTurbo/CentralTurbosBrand"
import CentralTurbosAbout from "../../Components/Home/CentralTurbo/CentralTurbosAbout"
import CentralTurbosHolsetSale from "../../Components/Home/CentralTurbo/CentralTurbosHolsetSale";
import Banner from "../../Images/centralturbos/banner1.jpg"
import {
  IFeatureproductProps,
  IVidoesProps,
  IBannerProps,
  ITestimonialProps,
  ICompanyProps,
  IProductProps,
  IBrandProps,
  IMicrositeProps,
  IMicrositeSEOProps,
  IMicrositePropshome,
} from "../../Types/MicrositeType";
import { useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GetDataMultiResults } from "../../API/GetDataFromApi";
import Loader from "../../Components/Loader";
import { addproduct } from "../../Redux/Counterslice"

export default function CentralTurbosHome(props: { micrositeInfo: IMicrositeProps }) {
  const [videos, setVideos] = useState<IVidoesProps[]>([]);
  const [banner, setBanner] = useState<IBannerProps[]>([]);
  const [testimonial, setTestimonial] = useState<ITestimonialProps[]>([]);
  const [featureparts, setFeatureparts] = useState<IFeatureproductProps[]>([]);
  const [product, setProduct] = useState<IProductProps[]>([]);
  const [brands, setBrand] = useState<IBrandProps[]>([]);
  const [microsite, setMicrosite] = useState<IMicrositePropshome[]>([]);
  const [micrositeseo, setSeo] = useState<IMicrositeSEOProps[]>([]);
  const [loading, setLoading] = useState(true);
  const [showBanner, setShowBanner] = useState(false);
  const [showTopProduct, setShowTopProduct] = useState(false);
  const [showFeatureParts, setShowFeatureParts] = useState(false);
  const [showTestimonial, setShowTestimonial] = useState(false);
  const [showAboutUs, setShowAboutUs] = useState(false);
  const [showVideos, setShowVideos] = useState(false);
  const [showBrands, setShowBrands] = useState(false);  
  const [seoH1Tag, setSeoH1Tag] = useState("");
  const [seoH2Tag, setSeoH2Tag] = useState("");
  const [seoHeading, setSeoHeading] = useState("");
  const [seoSubheading, setSeoSubheading] = useState("");
  const [seoDescription, setSeoDescription] = useState("");
  const [topProductSeoTitle, setTopProductSeoTitle] = useState("Top Products");
  const [featuredPartsSeoTitle, setFeaturedPartsSeoTitle] = useState("Our Business Partners");
  const [brandSeoTitle, setBrandSeoTitle] = useState("Brands");
  const [aboutUsSeoTitle, setAboutUsSeoTitle] = useState("About Us");
  const [videosSeoTitle, setVideosSeoTitle] = useState("VIDEOS");
  const [testimonialSeoTitle, setTestimonialSeoTitle] = useState("TESTIMONIALS");


  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  var raw = JSON.stringify({
    micrositeName: EnvData?.REACT_APP_MICROSITENAME,
    cartName: localStorage.getItem("cartName"),
    assetstyle: "string",
  });

  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      GetDataMultiResults(
        EnvData?.REACT_APP_APIURL + "/microsite-api-v1/get-index"
      ).then((results?) => {
        // console.log(results, " res from multi");
        setSeo(results.data.micrositeIndex_SEOResponse);
        // console.log(results.data.micrositeIndex_SEOResponse, "tessti");
        setFeatureparts(results.data.micrositeIndex_FeaturedPartsResponse);
        setProduct(results.data.micrositeIndex_FeaturedProductResponse);
        Addproduct(results.data.micrositeIndex_FeaturedProductResponse);
        setBanner(results.data.micrositeIndex_BannerResponse);
        setBrand(results.data.micrositeIndex_BrandResponse);
        setVideos(results.data.micrositeIndex_VideoResponse);
        setTestimonial(results.data.micrositeIndex_TestimonialResponse);
        setMicrosite(results.data.micrositeIndex_ShoworNotResponse);
        setLoading(false);
      });
    })();
  }, []);
  function Addproduct(res: any) {
    dispatch(addproduct(res));
  }

  


useEffect(() => {
  if (!loading) {
    microsite?.forEach(result => {
      setShowBanner(result.banner);
      setShowTopProduct(result.topCategory);
      setShowFeatureParts(result.topProducts);
      setShowTestimonial(result.testimonial);
      setShowAboutUs(result.hAboutUs);
      setShowVideos(result.video);
      setShowBrands(result.popularBrands);
    });

    micrositeseo?.forEach(result => {
      setSeoH1Tag(result.micrositeSEOH1Tag);
      setSeoH2Tag(result.micrositeSEOH2Tag);
      setSeoHeading(result.additionalMicrositeSEOHeading);
      setSeoSubheading(result.additionalMicrositeSEOSubHeading);
      setSeoDescription(result.additionalMicrositeSEODescription);

      switch (result.micrositeSEOHomePageSectionName) {
        case "Top Products":
          if (result.additionalMicrositeSEOAlternateTitle !== "") {
            setTopProductSeoTitle(result.additionalMicrositeSEOAlternateTitle);
          }
          break;
        case "Featured Parts":
          if (result.additionalMicrositeSEOAlternateTitle !== "") {
            setFeaturedPartsSeoTitle(result.additionalMicrositeSEOAlternateTitle);
          }
          break;
        case "Brands":
          if (result.additionalMicrositeSEOAlternateTitle !== "") {
            setBrandSeoTitle(result.additionalMicrositeSEOAlternateTitle);
          }
          break;
        case "About Us":
          if (result.additionalMicrositeSEOAlternateTitle !== "") {
            setAboutUsSeoTitle(result.additionalMicrositeSEOAlternateTitle);
          }
          break;
        case "Featured Products":
          if (result.additionalMicrositeSEOAlternateTitle !== "") {
            setFeaturedPartsSeoTitle(result.additionalMicrositeSEOAlternateTitle);
          }
          break;
        case "Video":
          if (result.additionalMicrositeSEOAlternateTitle !== "") {
            setVideosSeoTitle(result.additionalMicrositeSEOAlternateTitle);
          }
          break;
        case "Testimonial":
          if (result.additionalMicrositeSEOAlternateTitle !== "") {
            setTestimonialSeoTitle(result.additionalMicrositeSEOAlternateTitle);
          }
          break;
        default:
          break;
      }
    });
  }
}, [microsite, micrositeseo, loading]);


  if (loading) {
    return <Loader />;
  }

  return(
    <div>

      <Box pt={2} className="central-turbos-banner">
        <Box sx={{backgroundImage:`url(${Banner})`}} className="banner-bg">
          <Container >
              <div className="banner-bg-content">
              <Typography color="primary.contrastText" variant="subtitle1" fontSize="25px">Looking For A Part?</Typography>
              <Typography color="primary.contrastText" variant="h3" fontSize="35px" sx={{mb:2}}>We Find the Unfindable</Typography>
              <Typography color="primary.contrastText" variant="subtitle2" fontSize="25px">Your Ultimate Source for
              Exclusive Turbochargers</Typography>
              <Button variant="contained" sx={{mt:2}} to="/PartSearch"
                              component={Link} className="central-turbos-button">Shop Now</Button>
            </div>
          </Container>
        </Box>
      </Box>
      
      {/* <CentralTurbosBannerSlider _banner={banner} /> */}
        
      {showFeatureParts !== false && featureparts.length > 0 ? ( 
            <Paper elevation={0} sx={{mb:0, py: 3, backgroundColor:'#EDEDED' }} className="central-turbos-categories">
            <Container maxWidth="lg">
              
              
            <Typography variant="h3" sx={{mb:2}} className="central-turbos-title">  {featuredPartsSeoTitle}</Typography>
            <CentralTurbosFeaturedProductSlider home={true} _product={featureparts} />
            <Box sx={{textAlign:'center', mt:2}}>
            {featureparts.length >= 4 ? (  
            <Button variant="contained"   component={Link}
                    to="/FeatureParts" className="central-turbos-button">VIEW ALL FEATURED</Button>) : null}
           
            </Box>
          </Container>
          </Paper> ):null}

          <CentralTurbosHolsetSale />

        {showTopProduct !== false && featureparts.length > 0 ? ( <Paper elevation={0} sx={{mb:1, py:3,textAlign:'center' }} className="central-turbos-categories">
            <Container maxWidth="lg">
              <Typography variant="h3" sx={{mb:2}} align="center" className="central-turbos-title">  {topProductSeoTitle}</Typography>
              <CentralTurbosCategorySlider count={7} _product={product} partsearch={false}/>
              {product.length >= 6 ? (
                  <Button variant="contained" sx={{textAlign:'center', mt:2}} component={Link}
                  to="/TopProducts" className="central-turbos-button">SEE ALL CATEGORIES</Button>
                ) : null}
              
            </Container>
          </Paper>   ):<></> }

         

          
          
            
         
        

          <Paper elevation={0} sx={{mb:1, py:3,textAlign:'center' }}>
            <Container maxWidth="lg">
              <CentralTurbosBrand   _brand={brands}
          _seoTitle={brandSeoTitle}
          microsite_Information={props.micrositeInfo} />
            </Container>
          </Paper>  

          {showAboutUs !== false ? <CentralTurbosAbout _seoTitle={aboutUsSeoTitle} /> : null}

     
          
    </div>
  )

}