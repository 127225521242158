


import * as React from 'react';
import { Accordion, AccordionDetails, Container, Paper, AccordionSummary, Typography, FormControl, OutlinedInput,Grid, TextField, FormLabel, RadioGroup, FormControlLabel, Button, TableContainer, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '../Css/checkout.style.css';
import TableRows from "./TableRows"
import { useState } from 'react';

import { ISOCart,SOPartRejection,SORejection,SalesOrderInfo  } from "../Types/CartType";
import IconButton from '@mui/material/IconButton';
import ShoppingCartOutlinedIcon from '@mui/icons-material/MoreVert';
import useGetauthHeader from '../APIHooks/useGetAuthHeader';
import Box from '@mui/system/Box';
import { GetData } from "../API/GetDataFromApi";
import { useSelector } from 'react-redux';

export default function SalesOrder() {
    let authHeader = useGetauthHeader();
    let env = (state: any) => state.ENV_SETTINGS;
    const EnvData = useSelector(env);

  const [expanded, setExpanded] = React.useState<string | false>(false);  
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
    
    const [rowsData, setRowsData] =  useState<ISOCart[]>([]);
    const [arrayWithData, setarrayWithData] =  useState<SalesOrderInfo[]>([]);
    const [arrayPartRejection, setarrayPartRejection] =  useState<SOPartRejection[]>([]);
    const [arrayRejection, setarrayRejection] =  useState<SORejection[]>([]);
    const [hide, setHide] = useState(false);
    const [PartRejection, setPartRejection] = useState(false);
    const [SORejection, setSORejection] = useState(false);
        
 
    const addTableRows = ()=>{  
        const rowsInput:ISOCart={
            partNumber:'',
            orderQty:0  ,
            unitPrice:0,
            corePrice:0 ,            
            epa:0,
            notes:''
        } 
        setRowsData([...rowsData, rowsInput])      
   
    }
    const deleteTableRows = (index = 0) => {
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
   }
              
    const rowsInput = [...rowsData];
    const handleChange1 = (index: number, evnt: any) => {

        const { name, value } = evnt.target;
        const rowsInput = [...rowsData];
        //rowsInput[0][name] = value;
        const post3 = rowsInput[index];

        if (post3) {
            if (name == "Partnumber")
                post3.partNumber = value;
            if (name == "UnitPrice")
                post3.unitPrice = value;
            if (name == "CorePrice")
                post3.corePrice = value;
            if (name == "Qty")
                post3.orderQty = value;
            if (name == "EPA")
                post3.epa = value;
            if (name == "Notes")
                post3.notes = value;
        }

        setRowsData(rowsInput);
        // console.log(evnt.target);
      // console.log(rowsInput);
        // console.log(name);
        // console.log(value);
        // console.log(index);
    }



    const createOrder = () => {
        const txtShipFirstName = document.getElementById('txtShipFirstName') as HTMLInputElement | null;
        const txtShipFirstNamevalue = txtShipFirstName?.value;
        const txtShipLastName = document.getElementById('txtShipLastName') as HTMLInputElement | null;;
        const txtShipLastNamevalue = txtShipLastName?.value;
        const txtShipAddress1 = document.getElementById('txtShipAddress1') as HTMLInputElement | null;;
        const txtShipAddress1value = txtShipAddress1?.value;
        const txtShipAddress2 = document.getElementById('txtShipAddress2') as HTMLInputElement | null;;
        const txtShipAddress2value = txtShipAddress2?.value;
        const txtShipZipCode = document.getElementById('txtShipZipCode') as HTMLInputElement | null;;
        const txtShipZipCodevalue = txtShipZipCode?.value;
        const txtShipCountry = document.getElementById('txtShipCountry') as HTMLInputElement | null;;
        const txtShipCountryvalue = txtShipCountry?.value;
        const txtShipState = document.getElementById('txtShipState') as HTMLInputElement | null;;
        const txtShipStatevalue = txtShipState?.value;
        const txtShipCounty = document.getElementById('txtShipCounty') as HTMLInputElement | null;;
        const txtShipCountyvalue = txtShipCounty?.value;
        const txtShipCity = document.getElementById('txtShipCity') as HTMLInputElement | null;;
        const txtShipCityvalue = txtShipCity?.value;
        const txtShipEmail = document.getElementById('txtShipEmail') as HTMLInputElement | null;;
        const txtShipEmailvalue = txtShipEmail?.value;
        const txtShipPhone = document.getElementById('txtShipPhone') as HTMLInputElement | null;;
        const txtShipPhonevalue = txtShipPhone?.value;

        const txtBillFirstName = document.getElementById('txtBillFirstName') as HTMLInputElement | null;;
        const txtBillFirstNamevalue = txtBillFirstName?.value;
        const txtBillLastName = document.getElementById('txtBillLastName') as HTMLInputElement | null;;
        const txtBillLastNamevalue = txtBillLastName?.value;
        const txtBillAddress1 = document.getElementById('txtBillAddress1') as HTMLInputElement | null;;
        const txtBillAddress1value = txtBillAddress1?.value;
        const txtBillAddress2 = document.getElementById('txtBillAddress2') as HTMLInputElement | null;;
        const txtBillAddress2value = txtBillAddress2?.value;
        const txtBillZipCode = document.getElementById('txtBillZipCode') as HTMLInputElement | null;;
        const txtBillZipCodevalue = txtBillZipCode?.value;
        const txtBillCountry = document.getElementById('txtBillCountry') as HTMLInputElement | null;;
        const txtBillCountryvalue = txtBillCountry?.value;
        const txtBillState = document.getElementById('txtBillState') as HTMLInputElement | null;;
        const ttxtBillStatevalue = txtBillState?.value;
        const txtBillCounty = document.getElementById('txtBillCounty') as HTMLInputElement | null;;
        const txtBillCountyvalue = txtBillCounty?.value;
        const txtBillCity = document.getElementById('txtBillCity') as HTMLInputElement | null;;
        const txtBillCityvalue = txtBillCity?.value;
        const txtBillEmail = document.getElementById('txtBillEmail') as HTMLInputElement | null;;
        const txtBillEmailvalue = txtBillEmail?.value;
        const txtBillPhone = document.getElementById('txtBillPhone') as HTMLInputElement | null;;
        const txtBillPhonevalue = txtBillPhone?.value;


        const txtMicrositeName = document.getElementById('txtMicrositeName') as HTMLInputElement | null;;
        const txtMicrositeNamevalue = txtMicrositeName?.value;
        const txtPoNumber = document.getElementById('txtPoNumber') as HTMLInputElement | null;;
        const txtPoNumbervalue = txtPoNumber?.value;
        const txtCustomerCode = document.getElementById('txtCustomerCode') as HTMLInputElement | null;;
        const txtCustomerCodevalue = txtCustomerCode?.value;
        const txtCustomerName = document.getElementById('txtCustomerName') as HTMLInputElement | null;;
        const txtCustomerNamevalue = txtCustomerName?.value;
        const txtBranchCode = document.getElementById('txtBranchCode') as HTMLInputElement | null;;
        const txtBranchCodevalue = txtBranchCode?.value;
        const txtBranchName = document.getElementById('txtBranchName') as HTMLInputElement | null;;
        const txtBranchNamevalue = txtBranchName?.value;
        const txtContactEmail = document.getElementById('txtContactEmail') as HTMLInputElement | null;;
        const txtContactEmailvalue = txtContactEmail?.value;

        const txtShippingType = document.getElementById('txtShippingType') as HTMLInputElement | null;;
        const txtShippingTypevalue = txtShippingType?.value;
        const txtShippingCharge = document.getElementById('txtShippingCharge') as HTMLInputElement | null;;
        const txtShippingChargevalue = txtShippingCharge?.value;
        const txtDeliveryDate = document.getElementById('txtDeliveryDate') as HTMLInputElement | null;;
        const txtDeliveryDatevalue = txtDeliveryDate?.value;
        const txtShipComplete = document.getElementById('txtShipComplete') as HTMLInputElement | null;;
        const txtShipCompletevalue = txtShipComplete?.value;

        const txtPaymentType = document.getElementById('txtPaymentType') as HTMLInputElement | null;;
        const txtPaymentTypevalue = txtPaymentType?.value;
        const txtTransactionNumber = document.getElementById('txtTransactionNumber') as HTMLInputElement | null;;
        const txtTransactionNumbervalue = txtTransactionNumber?.value;
        const txtCardType = document.getElementById('txtCardType') as HTMLInputElement | null;;
        const txtCardTypevalue = txtCardType?.value;
        const txtExpiryDate = document.getElementById('txtExpiryDate') as HTMLInputElement | null;;
        const txtExpiryDatevalue = txtExpiryDate?.value;

        // alert(txtShipFirstNamevalue);   

        const rowsInput = [...rowsData];
        var raw = JSON.stringify({
            "cartInfo": rowsInput,
            "orderinfo": [
                {
                    "micrositeName": EnvData?.REACT_APP_MICROSITENAME,
                    "poNumber": txtPoNumbervalue,
                    "customerCode": txtCustomerCodevalue,
                    "branchCode": txtBranchCodevalue,
                    "contactEmail": txtContactEmailvalue
                }
            ],
            "paymentInfo": [
                {
                    "paymentType": txtPaymentTypevalue,
                    "transactionNumber": txtTransactionNumbervalue,
                    "cardType": txtCardTypevalue,
                    "expiryDate": txtExpiryDatevalue
                }
            ],
            "deliveryinfo": [
                {
                    "shipComplete": txtShipCompletevalue,
                    "shippingType": txtShippingTypevalue,
                    "shippingCharge": txtShippingChargevalue,
                    "deliveryDate": txtDeliveryDatevalue
                }
            ]
        });
        

      // console.log(raw);

        const requestOptions = {
            method: 'POST',
            headers: authHeader,
            body: raw
        }

      // console.log(requestOptions);
        return GetData(EnvData?.REACT_APP_APIURL + '/api/Microsite/CreateSO', raw)
      
            .then((results?) => {
              // console.log(results,'result from salaesorder');
                // console.log(results);
                // console.log(results.Table);
                // console.log(results.Table[0].Status);   
                setPartRejection(false);
                    setSORejection(false);
                    setHide(false);   
                if(results.Table[0].Status=="Success"){
                    setarrayWithData(results.Table1);
                    setHide(true);
                   
                    alert("Order Has been Created");
                }
                else{   
                               
                    if(results.Table1.length>0)  
                    {
                        setarrayPartRejection(results.Table1);
                        setPartRejection(true);
                    } 
                    if(results.Table2.length>0)  
                    {
                        setarrayRejection(results.Table2);
                        setSORejection(true);                   
                    }             
                    alert("Not able to Create Order ! Pls see Rejection Reason");                          
                }                
              
                
            })
            .catch(error => {
                console.log("Error in " + error);
            });
    }
  


  return (
    <div>
        <Paper elevation={0} sx={{mt:2, mb:1, py:3,}}>
        <Container>
        <Box  sx={{  border: '1px solid grey', padding:'10px' }}>
            <Accordion expanded={expanded === 'panel0'} onChange={handleChange('panel0')} className="accordion-sec">
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header" className='accordion-summary'
                >
                <Typography variant='h6'>
                Account Info.
                </Typography>
                </AccordionSummary>
                <AccordionDetails className='accordion-details'>
                <div>                   
                <Grid container spacing={2}>                        
                        <Grid item md={3}>
                            <TextField fullWidth id="txtCustomerCode" placeholder="Customer Code" variant="outlined"/>
                        </Grid>
                        
                        <Grid item md={3}>
                            <TextField fullWidth  id="txtCustomerName" placeholder="Customer Name" variant="outlined"/>
                        </Grid>  
                        <Grid item md={3}>
                            <TextField fullWidth id="txtBranchCode" placeholder="Branch Code" variant="outlined"/>
                        </Grid>  
                       
                        <Grid item md={3}>
                            <TextField fullWidth  id="txtBranchName" placeholder="Branch Name" variant="outlined"/>
                        </Grid>         
                        <Grid item md={3}>
                            <TextField fullWidth  id="txtContactEmail" placeholder="Contact Email" variant="outlined"/>
                        </Grid>                 
                    </Grid>
                </div>
                
                </AccordionDetails>
            </Accordion>
            <div><Typography variant='h6' align='center'>(OR)</Typography></div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className="accordion-sec">
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header" className='accordion-summary'
                >
                <Typography variant='h6'>
                    Shipping Info.
                </Typography>
                
                </AccordionSummary>
                <AccordionDetails className='accordion-details'>
                <div>
                    <Grid container spacing={2} mb={2}>
                        <Grid item md={3}>
                            <TextField fullWidth id='txtShipFirstName' placeholder="First Name" variant="outlined" />
                        </Grid>
                        <Grid item md={2}>
                            <TextField fullWidth id='txtShipLastName' placeholder="Last Name" variant="outlined"/>
                        </Grid>
                        <Grid item md={4}>
                            <TextField fullWidth id='txtShipAddress1'  placeholder="Adderss" variant="outlined"/>
                        </Grid>
                        <Grid item md={3}>
                            <TextField fullWidth id='txtShipAddress2' placeholder="Apt, Suite etc. (optional)" variant="outlined"/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <TextField fullWidth id='txtShipZipCode' placeholder="Zip" variant="outlined" />
                        </Grid>
                        <Grid item md={3}>
                            <TextField fullWidth id='txtShipCountry'  placeholder="Country" variant="outlined"/>
                        </Grid>
                        <Grid item md={3}>
                            <TextField fullWidth id='txtShipState' placeholder="State" variant="outlined"/>
                        </Grid>
                        <Grid item md={3}>
                            <TextField fullWidth  id='txtShipCounty'  placeholder="County" variant="outlined"/>
                        </Grid>
                        <Grid item md={3}>
                            <TextField fullWidth id='txtShipCity' placeholder="City" variant="outlined"/>
                        </Grid>
                        <Grid item md={5}>
                            <TextField fullWidth id='txtShipEmail'  placeholder="Email id" variant="outlined"/>
                        </Grid>
                        <Grid item md={4}>
                            <TextField fullWidth id='txtShipPhone'  placeholder="Phone #" variant="outlined"/>
                        </Grid>
                    </Grid>
                </div>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className="accordion-sec">
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header" className='accordion-summary'
                >
                <Typography variant='h6'>Billing Info.</Typography>
               
                </AccordionSummary>
                <AccordionDetails className='accordion-details'>
                <div>
                    <Grid container spacing={2} mb={2}>
                        <Grid item md={3}>
                            <TextField fullWidth id="txtBillFirstName" placeholder="First Name" variant="outlined" />
                        </Grid>
                        <Grid item md={2}>
                            <TextField fullWidth  id="txtBillLastName" placeholder="Last Name" variant="outlined"/>
                        </Grid>
                        <Grid item md={4}>
                            <TextField fullWidth id="txtBillAddress1" placeholder="Adderss" variant="outlined"/>
                        </Grid>
                        <Grid item md={3}>
                            <TextField fullWidth id="txtBillAddress2"  placeholder="Apt, Suite etc. (optional)" variant="outlined"/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <TextField fullWidth id="txtBillZipCode" placeholder="Zip" variant="outlined" />
                        </Grid>
                        <Grid item md={3}>
                            <TextField fullWidth id="txtBillCountry" placeholder="Country" variant="outlined"/>
                        </Grid>
                        <Grid item md={3}>
                            <TextField fullWidth  id="txtBillState" placeholder="State" variant="outlined"/>
                        </Grid>
                        <Grid item md={3}>
                            <TextField fullWidth id="txtBillCounty" placeholder="County" variant="outlined"/>
                        </Grid>
                        <Grid item md={3}>
                            <TextField fullWidth  id="txtBillCity"  placeholder="City" variant="outlined"/>
                        </Grid>
                        <Grid item md={5}>
                            <TextField fullWidth id="txtBillEmail" placeholder="Email id" variant="outlined"/>
                        </Grid>
                        <Grid item md={4}>
                            <TextField fullWidth id="txtBillPhone" placeholder="Phone #" variant="outlined"/>
                        </Grid>
                    </Grid>
                </div>
                </AccordionDetails>
            </Accordion>
        </Box>
        <br></br>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className="accordion-sec">
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header" className='accordion-summary'
                >
                <Typography variant='h6'>
                Cart Info.
                </Typography>
                </AccordionSummary>
                <AccordionDetails className='accordion-details'>
                <div>                   
                    {/* <Grid container spacing={2}>
                        <Grid item md={3}>
                            <TextField fullWidth placeholder="Part Number" variant="outlined" />
                        </Grid>
                        <Grid item md={3}>
                            <TextField fullWidth placeholder="Unit Price" variant="outlined"/>
                        </Grid>
                        <Grid item md={3}>
                            <TextField fullWidth placeholder="Core Price" variant="outlined"/>
                        </Grid>
                        <Grid item md={3}>
                            <TextField fullWidth placeholder="Qty" variant="outlined"/>
                        </Grid>  
                        <Grid item md={3}>
                            <TextField fullWidth placeholder="EPA" variant="outlined"/>
                        </Grid>  
                        <Grid item md={3}>
                            <TextField fullWidth placeholder="Notes" variant="outlined"/>
                        </Grid>                        
                    </Grid> */}
                     <table>
                    <TableHead>
                      <TableRow>
                          <th>PartNumber</th>
                          <th>UnitPrice</th>
                          <th>CorePrice</th>
                          <th>Qty</th>                        
                          <th>Notes</th>
                          <th><button type='submit' className="btn btn-outline-success" onClick={addTableRows} >+</button></th>
                      </TableRow>
                    </TableHead>
                   <tbody>
                   <TableRows rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange1} />
                   </tbody> 
                </table>

                </div>
                
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} className="accordion-sec">
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header" className='accordion-summary'
                >
                <Typography variant='h6'>
                Order Info.
                </Typography>
                </AccordionSummary>
                <AccordionDetails className='accordion-details'>
                <div>                   
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <TextField fullWidth  id="txtMicrositeName" placeholder="Microsite Name" variant="outlined" />
                        </Grid>
                        <Grid item md={3}>
                            <TextField fullWidth  id="txtPoNumber" placeholder="Po Number" variant="outlined"/>
                        </Grid>                      
                    </Grid>
                </div>
                
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} className="accordion-sec">
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5bh-content"
                id="panel5bh-header" className='accordion-summary'
                >
                <Typography variant='h6'>
                Delivery Info.
                </Typography>
                </AccordionSummary>
                <AccordionDetails className='accordion-details'>
                <div>                   
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <TextField fullWidth id="txtShippingType" placeholder="Shipping Type" variant="outlined" />
                        </Grid>
                        <Grid item md={3}>
                            <TextField fullWidth id="txtShippingCharge" placeholder="Shipping Charge" variant="outlined"/>
                        </Grid>
                        <Grid item md={3}>
                            <TextField fullWidth id="txtDeliveryDate" placeholder="Delivery Date" variant="outlined"/>
                            {/* <DatePicker fullWidth id="txtDeliveryDate" label="Basic date picker" variant="outlined" /> */}
                        </Grid>
                        <Grid item md={3}>
                            <TextField fullWidth  id="txtShipComplete" placeholder="Ship Complete" variant="outlined"/>
                        </Grid>                                      
                    </Grid>
                </div>
                
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} className="accordion-sec">
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel6bh-content"
                id="panel6bh-header" className='accordion-summary'
                >
                <Typography variant='h6'>
                Payment Info.
                </Typography>
                </AccordionSummary>
                <AccordionDetails className='accordion-details'>
                <div>                   
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <TextField fullWidth id="txtPaymentType" placeholder="Payment Type" variant="outlined" />
                        </Grid>
                        <Grid item md={3}>
                            <TextField fullWidth id="txtTransactionNumber" placeholder="Transaction Number" variant="outlined"/>
                        </Grid>
                        <Grid item md={3}>
                            <TextField fullWidth id="txtCardType" placeholder="Card Type" variant="outlined"/>
                        </Grid>
                        <Grid item md={3} style={{ display: "none" }}>
                            <TextField fullWidth id="txtExpiryDate" placeholder="Expiry Date" variant="outlined"/>
                        </Grid>                                      
                    </Grid>
                </div>
                
                </AccordionDetails>
            </Accordion>
            </Container>
            <br></br>
                        <Button variant="contained"  onClick={(e) => createOrder()}>Create Order</Button>
<br></br>
<br></br>
                        <div  style={{ display: hide ? "block" : "none" }}>
                        <TableContainer component={Paper}>
            <Table>
            <TableHead>
                <TableRow>
                <th>SO #</th>
                <th>PT #</th>
                <th>B0 #</th>
                <th>Allotted</th>
                <th>Status</th>
                <th>Delivery Type</th>
                <th>Warehosue</th>
                </TableRow>
            </TableHead>
            <TableBody>
                {arrayWithData.map(item => {
                return (
                    <TableRow key={item.ShoppingCartHdrId}>
                    <TableCell align="center" >{ item.ShoppingCartHdrId }</TableCell >
                    <TableCell align="center">{ item.PickTicketid }</TableCell >
                    <TableCell align="center">{ item.BackOrderHdrId }</TableCell >
                    <TableCell align="center">{ item.Allottedhdrid }</TableCell >
                    <TableCell align="center" >{ item.OrderStatus }</TableCell >
                    <TableCell align="center">{ item.ShippingDelivery }</TableCell >
                    <TableCell align="center">{ item.Warehouse }</TableCell >
                   
                    </TableRow>
                );
                })}
            </TableBody>
            </Table>
            </TableContainer>
            </div>

            <div  style={{ display: PartRejection ? "block" : "none" }}>
    <TableContainer component={Paper}>
            <Table>
            <TableHead>
                <TableRow>
                <th>Part #</th>
                <th>UnitPrice</th>
                <th>CorePrice</th>
                <th>OrderQty</th>
                <th>Status</th>
                <th>Reason</th>                
                </TableRow>
            </TableHead>
            <TableBody>
                {arrayPartRejection.map(item => {
                return (
                    <TableRow key={item.Partnumber}>
                        <TableCell align="center" >{ item.Partnumber }</TableCell >
                    <TableCell align="center" >{ item.UnitPrice }</TableCell >
                    <TableCell align="center">{ item.CorePrice }</TableCell >
                    <TableCell align="center">{ item.OrderQty }</TableCell >
                    <TableCell align="center">{ item.Status }</TableCell >
                    <TableCell align="center" >{ item.Reason }</TableCell >                                      
                    </TableRow>
                );
                })}
            </TableBody>
            </Table>
            </TableContainer>
            </div>
                   
            <div  style={{ display: SORejection ? "block" : "none" }}>
    <TableContainer component={Paper}>
            <Table>
            <TableHead>
                <TableRow>
                <th>Key Name</th>               
                <th>Reason</th>                
                </TableRow>
            </TableHead>
            <TableBody>
                {arrayRejection.map(item => {
                return (
                    <TableRow key={item.Name}>
                    <TableCell align="center" >{ item.Reason }</TableCell >                                                
                    </TableRow>
                );
                })}
            </TableBody>
            </Table>
            </TableContainer>
            </div>
                   
      </Paper>
    </div>
  );
}

