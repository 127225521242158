import { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  Toolbar,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import InputIcon from "@mui/icons-material/Input";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
// import logo from "../../Images/main-logo.png";
import logoblock from "../../Images/centralturbos/central-turbo-block.png"
import hamber from "../../Images/hamber.png";
import hamberwhite from "../../Images/hamber_white.png";
import phone from "../../Images/phone.png";
import { Link } from "react-router-dom";
import { IMicrositeProps, IgetProductDetails } from "../../Types/MicrositeType";
import formatPhoneNumber from "../../API/GetMicrositeSettings";
import { Get_Data } from "../../API/GetDataFromApi";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useSelector } from "react-redux";
import CustomMenu from "./CustomMenu";

export const CentralTurbosLeftMenu = (props: { _settings: IMicrositeProps, _custommenu: any }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [submenu, setSubMenu] = useState(false);
  const [productInfo, setproductInfo] = useState<IgetProductDetails[]>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  let env = (state: any) => state.ENV_SETTINGS;

  const EnvData = useSelector(env);




  const handleClick = () => {
    setIsOpen(true);
  };
  const handleDrawerClose = () => {
    setIsOpen(false);
    setSubMenu(false);
  };

  const submenuClick = () => {
    setSubMenu((current) => !current);
  };

  const navigate = useNavigate();

  const handleButtonClick = () => {
    // Redirect to the 'About' component when the button is clicked
    navigate("/Login");
    setIsOpen(false);
  };

  const logout = () => {
    localStorage.removeItem("userName");
    localStorage.removeItem("authToken");
    navigate("/");
    setIsOpen(false);
  };

  let showCart: boolean = true;
  let _cartCount: number = 0;
  let _logo: string = "";
  let _showSearch: boolean = true;
  let _showAboutus: boolean = true;
  let _showServices: boolean = true;
  let _showNewsletter: boolean = true;
  let _dealerlocation: string = "";
  let _tollfreeNumber: string = "";
  let _showtestmonial: boolean = true;
  let _showTechbulletins: boolean = true;
  // props._settings.map(function (result) {
  showCart = props._settings.shoppingcart;
  _cartCount = props._settings.cartCount;
  _logo = props._settings.logo;
  _showSearch = props._settings.partsSearchMenu;
  _showAboutus = props._settings.aboutUs;
  _showNewsletter = props._settings.newsLetter;
  _dealerlocation = props._settings.dealerLocation;
  _showServices = props._settings.services;
  _tollfreeNumber = props._settings.phonePrefix + '' + formatPhoneNumber(props._settings.tollFreeNumber);
  _showtestmonial = props._settings.testimonial;
  _showTechbulletins = props._settings.showTechBulletins;
  // });
  useEffect(() => {
    (async () => {
      Get_Data(
        EnvData?.REACT_APP_APIURL + "/microsite-api-v1/get-top-products"
      )
        .then((results) => {
          //  console.log(results, "products page res");
          setproductInfo(results.data);
        })
        .catch((error) => {
          console.log("Error in " + error);
        });
    })();
  }, []);

  const location = useLocation();
  const isRootPath = location.pathname === '/';

  return (
    <Box>
      <Toolbar
        sx={{
          pr: "0!important",
          pl: "0!important",
          minHeight: "100%!important",
        }}
      >
       {isRootPath == true ? (
          <div onClick={handleClick}>
            
            <img src={hamberwhite} className="hamber-icons" alt="logo" />
          </div>
        ) : (
          <div onClick={handleClick}>
            <img src={hamber} className="hamber-icons" alt="logo" />
          </div>
        )}
        <Drawer
          anchor="left"
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="left-menu"
        >
          <div className="drawer-header centralturbos-drawer-header">
            <Button to="/" component={Link} className="linkstyle">
              {/* <img src={_logo} className="drawer-header-logo" alt="logo" /> */}
              <img src={logoblock} alt="logo" />
            </Button>

            <IconButton
              className="brawer-close-button"
              onClick={handleDrawerClose}
            >
              <CloseRoundedIcon />
            </IconButton>
          </div>

          <Button
            href="tel:1(203)408-1666"
            color="secondary"
            sx={{
              alignItems: "left",
              justifyContent: "left",
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <img src={phone} className="imageicons" alt="logo" />
            </IconButton>
            {_tollfreeNumber}
          </Button>
          <ul className="drawer-header-list">
            <li onClick={handleDrawerClose} className="linkstyle">
              <Button
                to="/"
                component={Link}
                className="linkstyle"
                sx={{
                  color: "primary.dark",
                  ":hover": {
                    color: "primary.main",
                  },
                }}
              >
                Home
              </Button>
            </li>
            {EnvData.REACT_APP_STATIC_PAGE === 'true' ? (  <li onClick={handleDrawerClose} className="linkstyle">
                  <Button
                    to="CustomPage/4"
                    component={Link}
                    className="linkstyle"
                    sx={{
                      color: "primary.dark",
                      ":hover": {
                        color: "primary.main",
                      },
                    }}
                  >
                    Products
                  </Button>
                </li>) : (
              <li className="linkstyle" onClick={submenuClick}>
                <Button
                  component="a"
                  className="linkstyle"
                  sx={{
                    color: "primary.dark",
                    ":hover": {
                      color: "primary.main",
                    },
                  }}
                >
                  Products
                  <IconButton sx={{ marginLeft: "auto" }}>
                    {submenu ? (
                      <RemoveIcon fontSize="small" />
                    ) : (
                      <AddIcon fontSize="small" />
                    )}
                  </IconButton>
                </Button>
                {submenu &&
                  productInfo.map((res, index) => {
                    const productUrl = `/Products/${res.productName}?`;
                    return (
                      <ul className="submenu" key={index}>
                        <li>
                          <Button
                            to={productUrl}
                            component={Link}
                            onClick={handleDrawerClose}
                            className="menumega-list"
                          >
                            {res.productName}
                          </Button>
                        </li>
                      </ul>
                    );
                  })}
              </li>
            )}



            {EnvData.REACT_APP_STATIC_PAGE == 'true' ? (<li onClick={handleDrawerClose} className="linkstyle">
              <Button
                to="/PartSearch"
                component={Link}
                className="linkstyle"
                sx={{
                  color: "primary.dark",
                  ":hover": {
                    color: "primary.main",
                  },
                }}
              >
                Catalog
              </Button>
            </li>) : (_showSearch === true ? (
              <li onClick={handleDrawerClose} className="linkstyle">
                <Button
                  to="/PartSearch"
                  component={Link}
                  className="linkstyle"
                  sx={{
                    color: "primary.dark",
                    ":hover": {
                      color: "primary.main",
                    },
                  }}
                >
                  Part Search
                </Button>
              </li>
            ) : null)}

            {/* {EnvData.REACT_APP_STATIC_PAGE == 'true' ? (null) : (_showServices === true ? (
              <li onClick={handleDrawerClose} className="linkstyle">
                <Button
                  to="/Services"
                  component={Link}
                  className="linkstyle"
                  sx={{
                    color: "primary.dark",
                    ":hover": {
                      color: "primary.main",
                    },
                  }}
                >
                  Services
                </Button>
              </li>
            ) : null)} */}


            {/* <li onClick={handleDrawerClose} className="linkstyle">
                            <Button to="/Services" component={Link} className="linkstyle">Services</Button>
                        </li> */}

            {EnvData.REACT_APP_STATIC_PAGE == 'true' ? (null) : (
              EnvData?.REACT_APP_STATICPAGE == "Yes" ? (
                <li onClick={handleDrawerClose} className="linkstyle">
                  <Button
                    to="/FeaturesBenefits"
                    component={Link}
                    className="linkstyle"
                    sx={{
                      color: "primary.dark",
                      ":hover": {
                        color: "primary.main",
                      },
                    }}
                  >
                    Features-Benefits
                  </Button>
                </li>
              ) : null

            )}
            {EnvData.REACT_APP_STATIC_PAGE == 'true' ? (null) : (
              _showtestmonial == true ? (
                <li onClick={handleDrawerClose} className="linkstyle">
                  <Button
                    to="/Testimonial"
                    component={Link}
                    className="linkstyle"
                    sx={{
                      color: "primary.dark",
                      ":hover": {
                        color: "primary.main",
                      },
                    }}
                  >
                    Testimonials
                  </Button>
                </li>
              ) : null)}

            {EnvData.REACT_APP_STATIC_PAGE == 'true' ? (null) : (
              showCart == true ? (
                <li onClick={handleDrawerClose} className="linkstyle">
                  <Button
                    to="/OrderHistory"
                    component={Link}
                    className="linkstyle"
                    sx={{
                      color: "primary.dark",
                      ":hover": {
                        color: "primary.main",
                      },
                    }}
                  >
                    Order History
                  </Button>
                </li>
              ) : null)}

            {_showAboutus == true ? (
              <li onClick={handleDrawerClose} className="linkstyle">
                <Button
                  to="/AboutUs"
                  component={Link}
                  className="linkstyle"
                  sx={{
                    color: "primary.dark",
                    ":hover": {
                      color: "primary.main",
                    },
                  }}
                >
                  About Us
                </Button>
              </li>
            ) : null}
            {/* {EnvData.REACT_APP_STATIC_PAGE == 'true' ? (null) : (
              _showTechbulletins == true ? (
                <li onClick={handleDrawerClose} className="linkstyle">
                  <Button
                    to="/TechBulletins"
                    component={Link}
                    className="linkstyle"
                    sx={{
                      color: "primary.dark",
                      ":hover": {
                        color: "primary.main",
                      },
                    }}
                  >
                    Tech Bulletins
                  </Button>
                </li>
              ) : null)} */}
            {EnvData.REACT_APP_STATIC_PAGE == 'true' ? (<li onClick={handleDrawerClose} className="linkstyle">
              <Button
                to={EnvData?.REACT_APP_CADENCELOGINURL}
                component={Link}
                className="linkstyle"
                sx={{
                  color: "primary.dark",
                  ":hover": {
                    color: "primary.main",
                  },
                }}
              >
                Distributor Portal
              </Button>
            </li>) : (null)}

            {EnvData.REACT_APP_SHOW_CENTRAL_TURBO == "true"  ? (<li onClick={handleDrawerClose} className="linkstyle">
              <Button
                to="/Warranties"
                component={Link}
                className="linkstyle"
                sx={{
                  color: "primary.dark",
                  ":hover": {
                    color: "primary.main",
                  },
                }}
              >
                Forms + Warranties
              </Button>
            </li>) : (null)}

            {EnvData.REACT_APP_SHOW_CENTRAL_TURBO == "true"  ? (<li onClick={handleDrawerClose} className="linkstyle">
              <Button
                to="/Brochures"
                component={Link}
                className="linkstyle"
                sx={{
                  color: "primary.dark",
                  ":hover": {
                    color: "primary.main",
                  },
                }}
              >
               Brochures
              </Button>
            </li>) : (null)}

            {/* <li onClick={handleDrawerClose} className="linkstyle">
                            <Button to="/TechBulletins" component={Link} className="linkstyle">Tech Bulletins</Button>
                        </li> */}
            {/* {
                            _dealerlocation !== "" ? <li onClick={handleDrawerClose} className="linkstyle">
                                <Button to="/" component={Link} className="linkstyle">Dealer Location</Button>
                            </li> : null
                        } */}

            {/* <li onClick={handleDrawerClose} className="linkstyle">
                            Literature / Videos
                            <IconButton>
                                <AddIcon fontSize="small" />
                            </IconButton> </li> */}
            {/* <li onClick={handleDrawerClose} className="linkstyle">
                            Information / How to Videos
                            <IconButton>
                                <AddIcon fontSize="small" />
                            </IconButton></li> */}

            <li onClick={handleDrawerClose} className="linkstyle">
              <Button
                to="/ContactUs"
                component={Link}
                className="linkstyle"
                sx={{
                  color: "primary.dark",
                  ":hover": {
                    color: "primary.main",
                  },
                }}
              >
                Contact Us
              </Button>
            </li>
            {/* <li className="linkstyle">
              <Button className="linkstyle" onClick={handleButtonClick}>
                <LoginIcon sx={{ pr: 1 }} /> Login
            </Button>
            </li> */}

            {
              Array.isArray(props._custommenu) &&
              props._custommenu.map((res: any, index: any) => {

                return <CustomMenu res={res} />;
              })
            }


          </ul>
          {EnvData.REACT_APP_STATIC_PAGE == 'true' ? (null) : (
            <Box
              sx={{
                display: "flex",
                m: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {props._settings.loginOption == "InternalCadenceLogin" ? (
                <Button
                  variant="contained"
                  sx={{ mr: 2 }}
                  onClick={handleButtonClick}
                >
                  <InputIcon sx={{ pr: 1, fontSize: "17px" }} /> Login
                </Button>
              ) : props._settings.loginOption == "B2CShoppingCartLogin" &&
                localStorage.getItem("userName") ? (
                <div>
                  <Typography variant="subtitle2" color="secondary" mb={1}>
                    <AccountCircleIcon sx={{ verticalAlign: "middle" }} />{" "}
                    {localStorage.getItem("userName")}
                  </Typography>
                  <Button variant="contained" sx={{ mr: 2 }} onClick={logout}>
                    <ExitToAppIcon sx={{ pr: 1, fontSize: "17px" }} /> Logout
                  </Button>
                </div>
              ) : props._settings.loginOption == "B2CShoppingCartLogin" ? (
                <Button
                  variant="contained"
                  sx={{ mr: 2 }}
                  onClick={handleButtonClick}
                >
                  <InputIcon sx={{ pr: 1, fontSize: "17px" }} /> Login
                </Button>
              ) : null}
            </Box>
          )}
        </Drawer>
      </Toolbar>
    </Box>
  );
};
