import {
  Typography,
  Container,
  Paper,
  Box,
  Grid,
  Pagination,
  Button,
  Divider,
} from "@mui/material";
import ProductGrid from "../Components/Product/ProductGrid";
import {
  IFeatureproductProps,
  IgetPartDescDetails,
} from "../Types/MicrositeType";
import { useProductResults } from "../APIHooks/useGetData";

import React from "react";
import logo from "../Images/main-logo.png";
import "../Css/product.style.css";
import { useEffect, useState } from "react";
import { GetData, Get_Product_info_Data } from "../API/GetDataFromApi";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EastIcon from "@mui/icons-material/East";
import breadcrumbsbanner from "../Images/centralturbos/breadcrumbs-common-banner.png";
import {
  addExBrandfilter,
  addExCrossReffilter,
  addSearchtext,
  addDesfilter,
  addCategoryfilter,
  addCategorySelection,
  addDescriptionSelection,
} from "../Redux/Counterslice";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useLocation,
  useSearchParams,
  useParams,
} from "react-router-dom";
import Loader from "../Components/Loader";
import { useResetdata } from "../Redux/Resetdata";

const firstIndex = 0;
const pageSize = 20;

const readmorefirstIndex = 0;
const readmorepageSize = 10;

export default function Products() {
  const [searchparam] = useSearchParams();
  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  const { productnum } = useParams();

  const [PartDescInfo, setHeaderItems] = useState<IgetPartDescDetails[]>([]);
  const [ProductBanner, setBannerItems] = useState<string>("");
  const [ProductBannerHone, setProductBannerHone] = useState<string>("");
  const [ProductBannerHtwo, setProductBannerHtwo] = useState<string>("");
  const [ProductBanneralttext, setBannerItemsalttext] = useState<string>("");
  const [FeatureProducts, setDetailsItems] = useState<IFeatureproductProps[]>(
    []
  );
  const [loading, setLoading] = useState(true);
  const [parmms, setparms] = useState<string | undefined>("");
  const [isReadMore, setIsReadMore] = useState(false);
  const [sum, setsum] = useState(0);

  const sums = PartDescInfo.reduce(
    (total: number, obj) => total + Number(obj.descriptionCount),
    0
  );

  const { ResetreduxData } = useResetdata();
  useEffect(() => {
    setsum(sums);
  }, [sums]);

  let BannerImage = "";
  let BannerAltText = "";
  let TotalAllPartDescCount = PartDescInfo?.length;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  function Navigatepartsearch(productid: any, productname: any, category: any) {
    ResetreduxData();
    let type = {
      // type : DESCRIPTION_FILTER,
      descriptionId: productid,
      desDescriptionName: productname,
    };

    let ctype = {
      categoryId: productid,
      categoryName: category,
    };
    dispatch(addDesfilter(type));
    dispatch(addCategoryfilter(ctype));
    dispatch(addCategorySelection(false));
    dispatch(addDescriptionSelection(false));
    navigate("/PartSearch");
  }
  var raw = productnum;

  // const [PartDescInfo, ProductBanner, FeatureProducts, loading] =
  //   useProductResults<
  //     IgetPartDescDetails[],
  //     IgetProductBanner[],
  //     IFeatureproductProps[]
  //   >(
  //     process.env.REACT_APP_APIURL + "/microsite-api-v1/getDatas_readOnly",
  //     requestOptions,
  //     [],
  //     [],
  //     []
  //   );

  useEffect(() => {
    if (productnum) {
      setparms(productnum);
    }
  }, [productnum]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      Get_Product_info_Data(
        EnvData?.REACT_APP_APIURL + "/microsite-api-v1/get-product-info",
        raw
      )
        .then((results) => {
          setIsReadMore(true);
          // console.log(results, "products page res");
          setHeaderItems(results.data.productList);
          setBannerItems(results.data.bannerImage);
          setBannerItemsalttext(results.data.bannerAltText);
          setDetailsItems(results.data.productPartList);
          setProductBannerHone(results.data?.seoH1tag);
          setProductBannerHtwo(results.data?.seoH2tag);
          // setHeaderReadItems(results.data.productList?.slice(readmorefirstIndex, readmorepageSize))
        })
        .catch((error) => {
          localStorage.removeItem("user");
        })
        .finally(() => {
          setLoading(false);
        });
    })();

    toggleReadMore();

    return setIsReadMore(true);
  }, [productnum]);
  {
    // ProductBanner!=="" &&{
    //   BannerImage = ProductBanner
    // }
    // // ()=>
  }
  // {
  //   PartDescInfo &&
  //     PartDescInfo.map(
  //       (elem2) =>
  //         (TotalAllPartDescCount =
  //           Number(TotalAllPartDescCount) + Number(elem2.descriptionCount))
  //     );
  // }
  const [page, setPage] = React.useState(1);
  const [data, setData] = React.useState(
    FeatureProducts?.slice(firstIndex, pageSize)
  );

  React.useEffect(() => {
    setData(FeatureProducts?.slice(0, pageSize));
  }, [FeatureProducts]);

  // const handleChange = (_: unknown, value: any) => {
  //   setPage(value);
  //   setData(
  //     FeatureProducts?.slice(
  //       firstIndex + pageSize * (value - 1),
  //       pageSize * value
  //     )
  //   );
  // };
  if (loading) return <Loader />;
  return (
    <div>
      {EnvData.REACT_APP_SHOW_CENTRAL_TURBO == "true"  ? (
                <>
               
                <Paper elevation={0} sx={{ mt: 0, mb: 1, p: 7,  backgroundImage: `url(${breadcrumbsbanner})`, }} className="central-turbos-breadcrumbs-banner">
                    <Container>
                        <Box sx={{px:5}}>
                            <Typography className="central-turbos-breadcrumbs-title">Product</Typography>
                        </Box>
                    </Container>
                </Paper> 
               </>
              ) : (
                <></>
              )}
      <Paper elevation={0} sx={{ mt: 2, mb: 1, py: 3 }}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={3}>
              <Box mb={3} sx={{ display: "flex" }}>
                <Typography variant="h4">
                  Part Description
                  <Divider
                    sx={{ bgcolor: "primary.main" }}
                    className="title-divider"
                  />
                </Typography>
              </Box>
              <Box className="part-description">
                <ul className="all-product">
                  <li>
                    <Typography variant="body1" color="primary">
                      All Products
                    </Typography>{" "}
                    <Typography color="primary" className="product-count">
                      ({sum})
                    </Typography>
                  </li>
                </ul>
                <ul className="all-product">
                  {PartDescInfo &&
                    PartDescInfo.slice(
                      readmorefirstIndex,
                      isReadMore ? readmorepageSize : PartDescInfo.length
                    ).map((elem, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          Navigatepartsearch(
                            elem.partDescriptionId,
                            elem.partDescriptionName,
                            elem.productName
                          )
                        }
                      >
                        <a>{elem.partDescriptionName}</a>{" "}
                        <span className="product-count">
                          {elem.descriptionCount.toString()}
                        </span>
                      </li>
                    ))}
                  {PartDescInfo.length > 10 ? (
                    isReadMore == false ? (
                      <Button
                        onClick={toggleReadMore}
                        sx={{ float: "right", mt: 2 }}
                        endIcon={<EastIcon />}
                        variant="outlined"
                      >
                        {" "}
                        View Less
                      </Button>
                    ) : (
                      <Button
                        onClick={toggleReadMore}
                        sx={{ float: "right", mt: 2 }}
                        endIcon={<EastIcon />}
                        variant="outlined"
                      >
                        {" "}
                        View More
                      </Button>
                    )
                  ) : null}

                  {/* <p onClick={toggleReadMore}> Read more</p> */}
                </ul>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              {ProductBanner !== "" && ProductBanneralttext !== "" ? (
                <Box className="product-banner">
                  <img
                    src={ProductBanner}
                    alt={ProductBanneralttext}
                    loading="lazy"
                  />
                </Box>
              ) : (
                <></>
              )}
              {ProductBannerHone !== "" || ProductBannerHtwo !== "" ? (
                <Box textAlign="center" mb={3}>
                  <Typography variant="h1">{ProductBannerHone}</Typography>
                  <Typography variant="h4">{ProductBannerHtwo}</Typography>
                </Box>
              ) : (
                <></>
              )}

              <Box>
                <ProductGrid _product={FeatureProducts} />

                {/* <Typography>Page: {page}</Typography>
                <Pagination
                  count={Math.ceil(FeatureProducts?.length / pageSize)}
                  page={page}
                  onChange={handleChange}
                /> */}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </div>
  );
}
