import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { addEnvSettings } from "../Redux/Counterslice";
import { EnvironmentSettings } from "../Types/MicrositeType";

export const GetMicrositeSettings = <Ts, Ta>(
  URL: string,
  body: Object,
  header: Object,
  initialSettings: Ts,
  initialAddress: Ta
) => {
  const [settings, setSettings] = useState<Ts>(initialSettings);
  const [address, setAddress] = useState<Ta>(initialAddress);
  axios
    .post(URL, body, header)
    .then((res) => {
      setSettings(res.data.Table);
      setAddress(res.data.Table1);
    })
    .catch((e) => {
      console.error(e);
    });

  return [settings, address];
};

function formatPhoneNumber(phoneNumber: String) {
  phoneNumber = phoneNumber
    .replaceAll(" ", "")
    .replaceAll("(", "")
    .replaceAll(")", "")
    .replaceAll("-", "");
  if (phoneNumber.length == 11) {
    phoneNumber = phoneNumber.substring(1);
  }
  var cleaned = ("" + phoneNumber).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    return +match[1] + "-" + match[2] + "-" + match[3];
  } else {
    return "";
  }
}
export const restrictedInputs = (txtValue: string) => {
  txtValue = txtValue.replaceAll(/'/g, "").replaceAll(/"/g, "");
  // console.log(txtValue, "177658");
  return txtValue;
};
export const clearLocalstorageValues = (flag?: string) => {
  if (flag == "Login") {
    localStorage.removeItem("BillingInfo");
    localStorage.removeItem("CartAndBramchDate");
    localStorage.removeItem("__paypal_storage__");
    localStorage.removeItem("ShippingType");
    localStorage.removeItem("CartId");
    localStorage.removeItem("PONUMBER");
    localStorage.removeItem("cpToken");
    // localStorage.removeItem("authToken");
    localStorage.removeItem("DeliveryOption");
    localStorage.removeItem("PaymentInfo");
    localStorage.removeItem("ShippingInfo");
    localStorage.removeItem("CartAndBramchId");
    localStorage.removeItem("cartName");
    localStorage.removeItem("CartId");
    localStorage.removeItem("PaypalToken");
    localStorage.removeItem("cartName");
    // localStorage.removeItem("ShoppingcartHdrId");
    localStorage.removeItem("BilldefaultCity");
    localStorage.removeItem("BilldefaultState");
    localStorage.removeItem("EmailInfo");
    localStorage.removeItem("defaultState");
    localStorage.removeItem("defaultCity");
    localStorage.removeItem("defaultCountry");
    localStorage.removeItem("defaultZip");
    localStorage.removeItem("DefaultAddress");
    // localStorage.removeItem("WarehouseId");
    localStorage.removeItem("BillDefaultAddress");
    localStorage.removeItem("BilldefaultZip");
    localStorage.removeItem("BilldefaultCountry");
    localStorage.removeItem("Otherbranch");
    localStorage.removeItem("NewsAndOffersText");
    localStorage.removeItem("paypallookup");
    localStorage.removeItem("cpexpiry");
  } else if (flag === "Address") {
    localStorage.removeItem("BillingInfo");
    localStorage.removeItem("CartAndBramchDate");
    localStorage.removeItem("__paypal_storage__");
    localStorage.removeItem("cpToken");
    localStorage.removeItem("authToken");
    localStorage.removeItem("DeliveryOption");
    localStorage.removeItem("PaymentInfo");
    localStorage.removeItem("ShippingInfo");
    localStorage.removeItem("CartAndBramchId");
    localStorage.removeItem("ShippingType");
    localStorage.removeItem("PaypalToken");
    localStorage.removeItem("BilldefaultCity");
    localStorage.removeItem("BilldefaultState");
    localStorage.removeItem("EmailInfo");
    localStorage.removeItem("defaultState");
    localStorage.removeItem("defaultCity");
    localStorage.removeItem("defaultCountry");
    localStorage.removeItem("defaultZip");
    localStorage.removeItem("DefaultAddress");
    localStorage.removeItem("BillDefaultAddress");
    localStorage.removeItem("BilldefaultZip");
    localStorage.removeItem("BilldefaultCountry");
    localStorage.removeItem("Otherbranch");
    localStorage.removeItem("paypallookup");
    localStorage.removeItem("cpexpiry");
    // localStorage.removeItem("CustomerInfo");
    // localStorage.removeItem("userName");
    // localStorage.removeItem("CustomerId");
    // localStorage.removeItem("Cstbrnid");
    // localStorage.removeItem("UserId");
  } else {
    localStorage.removeItem("BillingInfo");
    localStorage.removeItem("CartAndBramchDate");
    localStorage.removeItem("__paypal_storage__");
    localStorage.removeItem("ShippingType");
    localStorage.removeItem("CartId");
    localStorage.removeItem("PONUMBER");
    localStorage.removeItem("cpToken");
    localStorage.removeItem("authToken");
    localStorage.removeItem("DeliveryOption");
    localStorage.removeItem("PaymentInfo");
    localStorage.removeItem("ShippingInfo");
    localStorage.removeItem("CartAndBramchId");
    localStorage.removeItem("cartName");
    localStorage.removeItem("CartId");
    localStorage.removeItem("PaypalToken");
    localStorage.removeItem("cartName");
    // localStorage.removeItem("ShoppingcartHdrId");
    localStorage.removeItem("BilldefaultCity");
    localStorage.removeItem("BilldefaultState");
    localStorage.removeItem("EmailInfo");
    localStorage.removeItem("defaultState");
    localStorage.removeItem("defaultCity");
    localStorage.removeItem("defaultCountry");
    localStorage.removeItem("defaultZip");
    localStorage.removeItem("DefaultAddress");
    // localStorage.removeItem("WarehouseId");
    localStorage.removeItem("BillDefaultAddress");
    localStorage.removeItem("BilldefaultZip");
    localStorage.removeItem("BilldefaultCountry");
    localStorage.removeItem("Otherbranch");
    localStorage.removeItem("CustomerInfo");
    localStorage.removeItem("NewsAndOffersText");
    localStorage.removeItem("userName");
    localStorage.removeItem("CustomerId");
    localStorage.removeItem("Cstbrnid");
    localStorage.removeItem("UserId");
    localStorage.removeItem("paypallookup");
    localStorage.removeItem("cpexpiry");
  }
};
export default formatPhoneNumber;

export const CheckValidEmailAddress = (Email: string) => {
  const expression = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const result: boolean = expression.test(Email);
  // console.log(txtValue, "177658");
  return result;
};

// export const GetEnvironmentSettings = async (): Promise<any> => {
//   try {
//     const response = await axios.get('./settings.json');
//     const settings = response.data;
//     console.log(settings, 'GetEnvironmentSettings 2');
//     return settings;
//   } catch (error) {
//     throw error;
//   }
// };

export async function GetEnvironmentSettings() {
  // const dispatch = useDispatch();
  try {
    let settingsdata: EnvironmentSettings;
    const response = await axios.get("/settings.txt");

    // let json =  JSON.parse(response.data)
    // console.log(json, "Json")
    settingsdata = response.data;

    // let envsettings = {
    //   REACT_APP_MICROSITENAME:settingsdata.REACT_APP_MICROSITENAME,
    //   REACT_APP_MICROSITEID: settingsdata.REACT_APP_MICROSITEID,
    //   REACT_APP_PAYFLOW_HOST: settingsdata.REACT_APP_PAYFLOW_HOST,
    //   REACT_APP_PAYFLOW_HOST_ONETIME: settingsdata.REACT_APP_PAYFLOW_HOST_ONETIME,
    //   REACT_APP_PAYFLOW_HOST_MODE: settingsdata.REACT_APP_PAYFLOW_HOST_MODE,
    //   REACT_APP_SHOWSERVICEMENU: settingsdata.REACT_APP_SHOWSERVICEMENU,
    //   REACT_APP_STATICPAGE: settingsdata.REACT_APP_STATICPAGE,
    //   REACT_APP_PAYPALURL: settingsdata.REACT_APP_PAYPALURL,
    //   REACT_APP_CARDPOINTEURL: settingsdata.REACT_APP_CARDPOINTEURL,
    //   REACT_APP_CARDPOINTEIFRAMEURL: settingsdata.REACT_APP_CARDPOINTEIFRAMEURL,
    //   REACT_APP_APIURL: settingsdata.REACT_APP_APIURL,
    //   REACT_APP_CDNURL: settingsdata.REACT_APP_CDNURL,
    //   REACT_APP_NOIMAGEURL: settingsdata.REACT_APP_NOIMAGEURL,
    //   REACT_APP_USERNAME: settingsdata.REACT_APP_USERNAME,
    //   REACT_APP_PASSWORD: settingsdata.REACT_APP_PASSWORD,
    //   REACT_APP_APIKEY: settingsdata.REACT_APP_APIKEY,
    //   REACT_APP_APIREQUESTKEY: settingsdata.REACT_APP_APIREQUESTKEY,
    // }

    return settingsdata;
  } catch (error) {
    console.error("Error fetching settings:", error);
    return null;
  }
}
