import { Typography, Paper, Box, Button, Stack } from "@mui/material";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import {
  addCategoryfilter,
  addDesfilter,
  addBrandfilter,
  addVSyear,
  addVSmake,
  addVSmodel,
  addVSengine,
  // addAttribute,
  // addApplication,
  removeApplicationWithValue,
  removeAttributeWithValue,
  trigger,
  clearDescriptionSelection,
  clearBrandSelection,
  clearCategorySelection,
  clearYearSelection,
  clearMakeSelection,
  clearModelSelection,
  clearEngineSelection,
  addVehicleSearchFilterExists,
  clearallAttributeWithValue,
} from "../../Redux/Counterslice";
import {
  DESCRIPTION_FILTER,
  BRAND_FILTER,
  CATEGORY_FILTER,
} from "../../Redux/ReduxConstants";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { memo, useState } from "react";
import eventEmitter from "../PartSearchTest/eventEmmiter";
import { useResetdata } from "../../Redux/Resetdata";

function Recentsearch({ cleardesfilter, closeApplication, resgrid }: any) {
  const ExtenderData = (state: any) => state.EXTENDER_BRAND_FILTER;
  const BrandData = (state: any) => state.BRAND_FILTER;
  const DescData = (state: any) => state.DESCRIPTION_FILTER;
  const CategoryData = (state: any) => state.CATEGORY_FILTER;
  const YearData = (state: any) => state.YEAR_FILTER;
  const MakeData = (state: any) => state.MAKE_FILTER;
  const ModelData = (state: any) => state.MODEL_FILTER;
  const engineData = (state: any) => state.ENGINE_FILTER;
  const attributeData = (state: any) => state.ATTRIBUTE_FILTER_WITH_VALUE.items;
  const applicationData = (state: any) =>
    state.APPLICATION_FILTER_WITH_VALUE.items;

  const enteredData = (state: any) => state.EXTRA_FILTERS.DisplayText;
  const Categoryfilterdata = useSelector(CategoryData);
  const Descfilterdata = useSelector(DescData);
  const Brandfilterdata = useSelector(BrandData);
  const EnteredText = useSelector(enteredData);
  const YearFilterData = useSelector(YearData);
  const MakeFilterData = useSelector(MakeData);
  const ModelFilterData = useSelector(ModelData);
  const engineFilterData = useSelector(engineData);
  const attributeFilterData = useSelector(attributeData);
  const applicatioFilternData = useSelector(applicationData);

  const extreFilters = (state: any) => state.EXTRA_FILTERS;
  const extreFiltersData = useSelector(extreFilters);

  //console.log("from actio id search", attributeFilterData);

  const dispatch = useDispatch();
  const { ResetreduxData } = useResetdata();
  function triggerGrid() {
    setTimeout(() => dispatch(trigger()), 200);
  }

  function clearNorecordFoundErr() {
    dispatch(addVehicleSearchFilterExists(true));
  }
  const clearCategoryfilter = async () => {
    let type = {
      type: CATEGORY_FILTER,
      categoryId: "",
      categoryName: "",
    };
    dispatch(addCategoryfilter(type));
    dispatch(clearCategorySelection());
    eventEmitter.emit("triggersearchfilter", {});
    triggerGrid();
    clearNorecordFoundErr();
  };

  function resetData() {
    eventEmitter.emit("triggerFunction", {});
    eventEmitter.emit("triggerresetCompetiter", {});
    ResetreduxData();
    setTimeout(() => dispatch(trigger()), 200);
    // triggerGrid();
  }
  const clearDiscriptionfilter = () => {
    let type = {
      type: DESCRIPTION_FILTER,
      descriptionId: "",
      desDescriptionName: "",
    };
    dispatch(addDesfilter(type));
    dispatch(clearDescriptionSelection());
    clearNorecordFoundErr();
    dispatch(clearallAttributeWithValue());
    // cleardesfilter();
    eventEmitter.emit("triggersearchfilter", {});
    triggerGrid();
  };

  const clearBrandfilter = () => {
    let type = {
      type: BRAND_FILTER,
      brandId: "",
      brandName: "",
    };
    dispatch(addBrandfilter(type));
    dispatch(clearBrandSelection());
    eventEmitter.emit("triggersearchfilter", {});
    triggerGrid();
    clearNorecordFoundErr();
  };

  const clearYearfilter = () => {
    let type = {
      YearId: "",
      YearName: "",
    };
    dispatch(addVSyear(type));
    dispatch(clearYearSelection());
    // resgrid();
    triggerGrid();
  };

  const clearMakefilter = () => {
    let type = {
      MakeId: "",
      MakeName: "",
    };
    dispatch(addVSmake(type));
    dispatch(clearMakeSelection());
    // resgrid();
    triggerGrid();
  };

  const clearModelfilter = () => {
    let type = {
      ModelId: "",
      ModelName: "",
    };
    dispatch(addVSmodel(type));
    dispatch(clearModelSelection());
    // resgrid();
    triggerGrid();
  };

  const clearEnginefilter = () => {
    let type = {
      EngineId: "",
      EngineName: "",
    };
    dispatch(addVSengine(type));
    dispatch(clearEngineSelection());
    // resgrid();
    triggerGrid();
  };

  const clearAttribute = (id: any) => {
    // console.log(id, " from actio id");
    dispatch(removeAttributeWithValue(id));
    // resgrid();
    triggerGrid();
    eventEmitter.emit("triggersearchfilter", {});
  };

  const clearApplication = (id: any) => {
    dispatch(removeApplicationWithValue(id));
    // resgrid();
    triggerGrid();
  };

  return (
    <>
      <Box className="searched-sec central-turbos-searched-sec">
        <div className="searched-left">
          <Typography variant="subtitle1">
            You have searched for: <span>{EnteredText}</span>
          </Typography>
        </div>
        {/* <div className="searched-right">
          <Button variant="outlined" onClick={resetData}>
            {" "}
            Reset All Filters
          </Button>
        </div> */}
      </Box>
      {(Categoryfilterdata.categoryId &&
        Categoryfilterdata.categoryName != "" &&
        extreFiltersData.CategorySelection == false) ||
      (Brandfilterdata.brandId &&
        Brandfilterdata.brandName != "" &&
        extreFiltersData.BrandSelection == false) ||
      (Descfilterdata.descriptionId &&
        Descfilterdata.desDescriptionName != "" &&
        extreFiltersData.DescriptionSelection == false) ||
      attributeFilterData.length > 0 ||
      (YearFilterData.YearName != "" &&
        extreFiltersData.YearSelection == false) ||
      (MakeFilterData.MakeName != "" &&
        extreFiltersData.MakeSelection == false) ||
      (engineFilterData.EngineName != "" &&
        extreFiltersData.EngineSelection == false) ||
      (ModelFilterData.ModelName != "" &&
        extreFiltersData.ModelSelection == false) ||
      applicatioFilternData.length > 0 ? (
        <Paper elevation={0} sx={{ padding: "0px 10px 10px 0px" }}>
          <div className="current-selection-container">
            {/* <Typography variant="h4" className="current-selection-text">Current Selection - </Typography> */}
            <div className="current-selection-box">
              {Categoryfilterdata.categoryId &&
              Categoryfilterdata.categoryName != "" &&
              extreFiltersData.CategorySelection == false ? (
                <div className="current-selection-field">
                  <span>Category : </span>
                  <Button
                    sx={{ color: "primary.light" }}
                    className="selected-box"
                  >
                    {Categoryfilterdata.categoryName}{" "}
                    <DisabledByDefaultIcon
                      sx={{ color: "error.main" }}
                      onClick={clearCategoryfilter}
                    />
                  </Button>
                </div>
              ) : null}
              {Brandfilterdata.brandId &&
              Brandfilterdata.brandName != "" &&
              extreFiltersData.BrandSelection == false ? (
                <div className="current-selection-field">
                  <span>Brand : </span>
                  <Button
                    sx={{ color: "primary.light" }}
                    className="selected-box"
                  >
                    {Brandfilterdata.brandName}{" "}
                    <DisabledByDefaultIcon
                      sx={{ color: "error.main" }}
                      onClick={clearBrandfilter}
                    />
                  </Button>
                </div>
              ) : null}
              {Descfilterdata.descriptionId &&
              Descfilterdata.desDescriptionName != "" &&
              extreFiltersData.DescriptionSelection == false ? (
                <div className="current-selection-field">
                  <span>Description : </span>
                  <Button
                    sx={{ color: "primary.light" }}
                    className="selected-box"
                  >
                    {Descfilterdata.desDescriptionName}{" "}
                    <DisabledByDefaultIcon
                      sx={{ color: "error.main" }}
                      onClick={clearDiscriptionfilter}
                    />
                  </Button>
                </div>
              ) : null}

              {attributeFilterData.length > 0 ? (
                <div className="current-selection-field">
                  <span>Attribute : </span>
                  {attributeFilterData.map((value: any, index: number) => (
                    <Button
                      key={index}
                      sx={{ color: "primary.light" }}
                      className="selected-box"
                    >
                      {value.AttributeValue !== ""
                        ? value.AttributeName + " : " + value.AttributeValue
                        : value.AttributeName +
                          " : " +
                          "From : " +
                          value.Attributefrom +
                          " - " +
                          "To : " +
                          value.Attributeto}
                      {/* {value.AttributeName + " : " + value.AttributeValue !== ""
                        ? value.AttributeValue
                        : value.Attributefrom !== ""
                        ? "From " + value.Attributefrom
                        : "" + " - " + value.Attributeto !== ""
                        ? "To " + value.Attributeto
                        : ""} */}
                      {/* {attributeFilterData.length == index + 1 ? ( */}
                      <DisabledByDefaultIcon
                        sx={{ color: "error.main" }}
                        onClick={() => clearAttribute(value.Attributeid)}
                      />
                      {/* ) : null} */}
                    </Button>
                  ))}
                </div>
              ) : null}

              {YearFilterData.YearName != "" &&
              extreFiltersData.YearSelection == false ? (
                <div className="current-selection-field">
                  <span>Year : </span>
                  <Button
                    sx={{ color: "primary.light" }}
                    className="selected-box"
                  >
                    {YearFilterData.YearName}{" "}
                    <DisabledByDefaultIcon
                      sx={{ color: "error.main" }}
                      onClick={clearYearfilter}
                    />
                  </Button>
                </div>
              ) : null}
              {MakeFilterData.MakeName != "" &&
              extreFiltersData.MakeSelection == false ? (
                <div className="current-selection-field">
                  <span>Make : </span>
                  <Button
                    sx={{ color: "primary.light" }}
                    className="selected-box"
                  >
                    {MakeFilterData.MakeName}{" "}
                    <DisabledByDefaultIcon
                      sx={{ color: "error.main" }}
                      onClick={clearMakefilter}
                    />
                  </Button>
                </div>
              ) : null}
              {ModelFilterData.ModelName != "" &&
              extreFiltersData.ModelSelection == false ? (
                <div className="current-selection-field">
                  <span>Model : </span>
                  <Button
                    sx={{ color: "primary.light" }}
                    className="selected-box"
                  >
                    {ModelFilterData.ModelName}{" "}
                    <DisabledByDefaultIcon
                      sx={{ color: "error.main" }}
                      onClick={clearModelfilter}
                    />
                  </Button>
                </div>
              ) : null}
              {engineFilterData.EngineName != "" &&
              extreFiltersData.EngineSelection == false ? (
                <div className="current-selection-field">
                  <span>Engine : </span>
                  <Button
                    sx={{ color: "primary.light" }}
                    className="selected-box"
                  >
                    {engineFilterData.EngineName}{" "}
                    <DisabledByDefaultIcon
                      sx={{ color: "error.main" }}
                      onClick={clearEnginefilter}
                    />
                  </Button>
                </div>
              ) : null}

              {applicatioFilternData.length > 0 ? (
                <div className="current-selection-field">
                  <span>Application : </span>
                  {applicatioFilternData.map((value: any, index: number) => (
                    <Button
                      key={index}
                      sx={{ color: "primary.light" }}
                      className="selected-box"
                    >
                      <span className="application-name">
                        {value.ApplicationName}
                      </span>
                      {" : " + value.ApplicationValue}
                      {/* {applicatioFilternData.length == index + 1 ? ( */}
                      <DisabledByDefaultIcon
                        sx={{ color: "error.main" }}
                        onClick={() => clearApplication(value.Applicationid)}
                      />
                      {/* ) : null} */}
                    </Button>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        </Paper>
      ) : (
        <></>
      )}
    </>
  );
}

export default memo(Recentsearch);
