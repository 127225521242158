import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import axios from "axios";

import { Get_Data } from "../API/GetDataFromApi";
import { useSelector } from "react-redux";

// standard react-helmet library is now considered deprecated. Instead, you should use react-helmet-async.

export default function MetaTag(props: {
  _pagename?: string;
  _fetchdata?: boolean;
  _productsdetails?: any;
}) {
  const [Title, setTitle] = useState("");
  const [description, setdescription] = useState("");
  const [keywords, setkeywords] = useState("");
  const [structuredata, setstructuredata] = useState("");

  let _title: string = Title;
  let _description: string = description;
  let _keywords: string = keywords;
  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  let URL = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/get-seo?pageName=${props._pagename}`;

  const selectData = (state: any) => state.MS_SETTINGS_DATA;
  const msSettings = useSelector(selectData);

  useEffect(() => {
    if (props._fetchdata) {
      (async () => {
        try {
          const res = await Get_Data(URL);
          setTitle(res.data[0].micrositeSEOMetaTitle);
          setdescription(res.data[0].additionalMicrositeSEODescription);
          setkeywords(res.data[0].micrositeSEOMetaKeyword);
        } catch (error) {
          console.log("Error in " + error);
        }
      })();
    } else {
      //console.log(props._productsdetails , " fro   productdetails")
      setTitle(
        `${props._productsdetails[0].micrositeTitle} - ${props._productsdetails[0].salesPartNumber}`
      );
      setdescription(
        `${props._productsdetails[0].micrositeTitle} - ${props._productsdetails[0].salesPartNumber}`
      );
      setkeywords(props._productsdetails[0].seoKeywords);

      let ProductCategoryName1 = "";
      let ProductCategoryName2 = "";
      let ProductCategoryName3 = "";
      if (props._pagename === "ProductDetails") {
        if (
          props._productsdetails[0].configProductCategoryName !== undefined &&
          props._productsdetails[0].configProductCategoryName !== null &&
          props._productsdetails[0].configProductCategoryName !== ""
        ) {
          var configProductCategoryName =
            props._productsdetails[0].configProductCategoryName.split(">");
          if (configProductCategoryName.length > 0) {
            ProductCategoryName1 = configProductCategoryName[0];
          }
          if (configProductCategoryName.length > 1) {
            ProductCategoryName2 = configProductCategoryName[1];
          }
          if (configProductCategoryName.length > 2) {
            ProductCategoryName3 = configProductCategoryName[2];
          }
        }
        setstructuredata(`
        [
          {
            "@context":"http://schema.org/",
            "@type":"Product",
            "name":"${props._productsdetails[0].micrositeTitle}",
            "model":"${props._productsdetails[0].partNumber}",
            "image":"${props._productsdetails[0].imageurl}",
            "description":"${props._productsdetails[0].partDescriptionName}",
            "mpn":"${props._productsdetails[0].salesPartNumber}",
            "sku":"${props._productsdetails[0].partNumber}",
            "manufacturer":[
              {
                "@type":"Organization",
                "name":"${props._productsdetails[0].brandName}"
              }
            ],
            "brand":[
              {
                "@type":"Brand",
                "name":"${props._productsdetails[0].brandName}"
              }
            ],
            "offers":
              {
                "@type":"Offer",
                "url":"${window.location.href}",
                "priceCurrency":"USD",
                "price":"${
                  msSettings.loginOption === "CatalogSite"
                    ? "0.00"
                    : props._productsdetails[0].discountUnitPrice
                }",
                "priceValidUntil":"${
                  props._productsdetails[0].pricevalidUntil
                }",
                "itemCondition":"${
                  props._productsdetails[0].newReman === "N"
                    ? "https://schema.org/NewCondition"
                    : "https://schema.org/RefurbishedCondition"
                }",
                "availability":"${
                  props._productsdetails[0].stockInfo === "In Stock"
                    ? "https://schema.org/InStock"
                    : "https://schema.org/OutOfStock"
                }",
                "seller":
                  {
                    "@type":"Organization",
                    "name":"${EnvData?.REACT_APP_MICROSITENAME}"
                  }
              }
          }
        ]
      
      dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
dataLayer.push({
  event: "view_item",
  ecommerce: {
    items: [{
      item_name: "${props._productsdetails[0].micrositeTitle}", 
      item_id: "${props._productsdetails[0].partNumber}",
      price: "${
        msSettings.loginOption === "CatalogSite"
          ? "0.00"
          : props._productsdetails[0].discountUnitPrice
      }"",
      item_brand: "${props._productsdetails[0].brandName}",
      item_category: "${ProductCategoryName1}",
      item_category2: "${ProductCategoryName2}",
      item_category3: "${ProductCategoryName3}",
      item_category4: "${props._productsdetails[0].productCategoryName}",     
      quantity: "${props._productsdetails[0].stdSellingPack}",
    }]
  }
});
      
      `);
      }
    }
  }, []);
  // const scriptTag = localStorage
  //   .getItem("gScript")
  //   ?.replace('<script type="text/javascript">', "")
  //   .replace("</script>", "")
  //   .replace("<!--Start of Tawk.to Script-->", "")
  //   .replace("<!--End of Tawk.to Script-->", "")
  //   .replace(
  //     '<meta name="google-site-verification" content="L14FeRADskYHTB_eT0Gc4FU5kMmAiWM2_dcOli64GtI" />',
  //     ""
  //   );

  return (
    <Helmet>
      {/* Standard metadata tags */}= <title>{_title}</title>
      <meta name="description" content={_description} />
      <meta name="title" content={_title} />
      <meta name="keywords" content={_keywords} />
      {/* {props._pagename == "" ? props._structuredData : null} */}
      {/* End standard metadata tags */}
      {/* End Twitter tags */}
      {structuredata !== "" ? (
        <script type="application/ld+json">{structuredata}</script>
      ) : null}
    </Helmet>
  );
}
