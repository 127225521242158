import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import {
  Typography,
  Container,
  Paper,
  Box,
  Grid,
  Divider,
  TextField,
  Button,
  Stack,
  CircularProgress,
} from "@mui/material";
import "../../Css/checkoutnew.style.css";
// import product1 from "../../Images/additives-chemicals.png";
// import product2 from "../../Images/starter-motors.png";
// import product3 from "../../Images/alternators.png";
// import product4 from "../../Images/battery.png";
// import product5 from "../../Images/turbo-chargers.png";
// import product6 from "../../Images/battery-chargers-booster-packs.png";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import AddIcon from "@mui/icons-material/Add";

import { Get_Data } from "../../API/GetDataFromApi";
import Ordersummaryproducts from "./Ordersummaryproducts";
import { OurProducts } from "../../Pages/OurProducts";
import { styled } from "@mui/material/styles";
import Paypall from "../../Images/paypall.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addSubtotal, addFreightCount } from "../../Redux/Counterslice";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import eventEmitter from "../PartSearchTest/eventEmmiter";
import { useNavigate } from "react-router-dom";
import { clearLocalstorageValues } from "../../API/GetMicrositeSettings";

const PaypalButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 15,
  padding: "6px 16px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#fdc439",
  borderColor: "#fdc439",
  borderRadius: "4px",
  width: "100%",
  marginTop: "15px",
  color: "#000",
  img: {
    marginRight: "15px",
  },
  "&:hover": {
    backgroundColor: "#fdc439",
    borderColor: "#fdc439",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#fdc439",
    borderColor: "#fdc439",
  },
});

export default function OrderSummary(props: {
  ordersummary?: boolean;
  closedrawer?: any;
  Tab?: string;
}) {
  // let cartid = 643066;

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);
  let PaypalGenrated = (state: any) => state.PAYPALGENERATED;
  const PaypalGeneratedId = useSelector(PaypalGenrated);

  let msset = (state: any) => state.MS_SETTINGS_DATA;
  const MsData = useSelector(msset);

  const [data, setData] = useState<any>(null);
  const [isLoading, setLoader] = useState(false);
  const [cartError, setCartError] = useState(false);
  let ExistingUser = 0;
  if (
    localStorage.getItem("UserId") !== undefined &&
    localStorage.getItem("UserId") !== null &&
    localStorage.getItem("UserId") !== ""
  ) {
    ExistingUser = 1;
  }

  const navigate = useNavigate();
  // let URL = `${
  //   process.env.REACT_APP_APIURL
  // }/microsite-api-v1/get-view-cart-info?CartId=${localStorage.getItem(
  //   "CartId"
  // )}&ExistingUser=${ExistingUser}`;

  //PaypalToken
  let Taxlookup = "";
  if (
    (localStorage.getItem("paypallookup") === null ||
      localStorage.getItem("paypallookup") === undefined ||
      localStorage.getItem("paypallookup") === "") &&
    localStorage.getItem("PaypalToken") !== null &&
    localStorage.getItem("PaypalToken") !== undefined &&
    localStorage.getItem("PaypalToken") !== ""
  ) {
    Taxlookup = "Yes";
  }

  const URL = `${
    EnvData?.REACT_APP_APIURL
  }/microsite-api-v1/get-view-cart-info?CartId=${
    localStorage.getItem("CartId") || "0"
  }&ExistingUser=${ExistingUser}&paypallookup=${Taxlookup}`;

  useEffect(() => {
    // setTimeout(()=>{
    fetchCartInfo();
    // },500)
  }, [props.Tab, PaypalGeneratedId]);

  function refetch() {
    fetchCartInfo();
  }

  const fetchCartInfo = async () => {
    try {
      Get_Data(URL)
        .then((response: any) => {
          setLoader(true);
          console.log(response, "resposne from cart  OS");
          if (response?.data?.freightCount == "1") {
            dispatch(addFreightCount("1"));
          } else if (response?.data?.freightCount == "0") {
            dispatch(addFreightCount("0"));
          }
          console.log(response.data.taxValue, "taxValue");
          setData(response?.data);

          if (
            localStorage.getItem("PaypalToken") !== null ||
            localStorage.getItem("PaypalToken") !== undefined ||
            localStorage.getItem("PaypalToken") !== ""
          ) {
            localStorage.setItem("paypallookup", "Done");
          }
          //console.log(response.data.totalValue, "resposne from cart  OS");
          dispatch(addSubtotal(response?.data?.totalValue));
        })

        .then(() => {
          setLoader(false);
        })
        .catch((error) => {
          //setCartError(true);

          clearLocalstorageValues();
          CloseDrawer();
          alert(
            "There is a problem with your current shopping cart. Please contact customer service at " +
              MsData.tollFreeNumber +
              " for assistance or email us at " +
              MsData.contactEmail +
              ""
          );
          window.location.reload();

          console.log("View Cart Error", error);
        });
    } finally {
      setLoader(false);
    }
  };

  const [NaviateHome, setNaviateHome] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [PaypalToken, setPaypalToken] = useState("");
  const dispatch = useDispatch();
  if (data?.viewCart_PartResponse?.length == 0) {
    navigate("/");
  }
  function CloseDrawer() {
    if (props.closedrawer && props.closedrawer !== undefined) {
      props.closedrawer();
    }
  }

  const createOrder = (data: any, actions: any) => {
    navigate("/Checkout");

    return actions.order
      .create({
        purchase_units: [
          {
            description: "Part",
            amount: {
              currency_code: "USD",
              value: 20,
            },
          },
        ],
      })

      .then((orderID: any) => {
        //setOrderID(orderID);

        return orderID;
      });
  };

  // check Approval
  const onApprove = (data: any, actions: any) => {
    localStorage.setItem("PaypalToken", data.orderID);

    eventEmitter.emit("triggerPaypalToken", { token: data.orderID });
    setPaypalToken(data.orderID);
    // navigate("/Checkout");
    CloseDrawer();

    return actions.order.capture().then(function (details: any) {
      const { payer } = details;
    });
  };
  //capture likely error
  const onError = (data: any, actions: any) => {
    setErrorMessage("An Error occured with your payment ");
  };

  // const OrderSummaryTotal = (state: any) => state.ORDERSUMMARY_INFO;
  // const ordersummaryTotal = useSelector(OrderSummaryTotal);
  // console.log(ordersummaryTotal, ' jj')
  // useEffect(() => {
  //   refetch();
  //   //console.log("resposne from cart  OS total", data?.totalValue);
  // }, [PaypalGeneratedId]);

  // useEffect(()=>{

  //   if(data?.viewCart_PartResponse?.length==1) {
  //     setNaviateHome(true)
  //   }
  //   else{

  //     setNaviateHome(false)
  //   }

  // },[data?.viewCart_PartResponse?.length])
  const string = `/Checkout`;
  if (isLoading)
    return (
      <div>
        {" "}
        <CircularProgress sx={{ textAlign: "center" }} />
      </div>
    );
  // if (error||isError) return <div>Loading...</div>;
  if (data && typeof data === "object") {
    if (Object.keys(data).length === 0) {
      navigate("/");
      CloseDrawer();

      return (
        <>
          <h3>No records found</h3>
        </>
      );
    }
  }

  return (
    <div>
      {data && data.viewCart_PartResponse?.length !== 0 && data !== null ? (
        <>
          {" "}
          <Box className="order-summary-box">
            {props?.ordersummary ? (
              <>
                <div className="product-right-border">
                  <Typography variant="h4" pb={3}>
                    ORDER SUMMARY
                    <Divider
                      sx={{ bgcolor: "primary.main" }}
                      className="title-divider"
                    />
                  </Typography>
                </div>
              </>
            ) : (
              <></>
            )}

            {data.viewCart_PartResponse?.length !== 0 && isLoading == false ? (
              data.viewCart_PartResponse?.map((res: any, id: number) => (
                <Ordersummaryproducts
                  data={res}
                  key={id}
                  refetch={refetch}
                  closeDraw={CloseDrawer}
                  NavigateHome={data?.viewCart_PartResponse?.length == 1}
                  orderSummary={props?.ordersummary}
                />
              ))
            ) : (
              <></>
            )}
          </Box>
          {props?.ordersummary !== true ? (
            <></>
          ) : (
            <>
              {props?.Tab === "OPTIONS" ? (
                <div>
                  <Box className="price-item-total-box">
                    <table className="price-item-table">
                      <tbody>
                        <tr>
                          <th>Item Total</th>
                          <td>${data?.unitTotal.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <th>Core Deposit (Core Policy)</th>
                          <td>${data?.coreTotal.toFixed(2)}</td>
                        </tr>
                        {data?.epaTotal <= 0 ? (
                          <></>
                        ) : (
                          <>
                            <tr>
                              <th>EPA</th>
                              <td>${data?.epaTotal.toFixed(2)}</td>
                            </tr>
                          </>
                        )}

                        <tr>
                          <th>Shipping & Handling</th>
                          <td>${data?.shippingCharge.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <th>Tax</th>
                          <td>${data?.taxValue.toFixed(2)}</td>
                        </tr>
                      </tbody>
                    </table>
                    <Divider sx={{ mt: 2, mb: 1 }} />
                    <table className="price-value-table">
                      <tbody>
                        <tr>
                          <th>Total</th>
                          <td>
                            <Typography color="primary" variant="subtitle1">
                              ${data?.totalValue.toFixed(2)}
                            </Typography>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Box>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
          {props?.Tab === "OPTIONS" ? (
            <></>
          ) : (
            <>
              <Box className="order-summary-total-box">
                <div className="sub-total-box">
                  <table className="price-value-table">
                    <tbody>
                      <tr>
                        <th>Subtotal Value </th>
                        <td>
                          <Typography color="primary" variant="subtitle1">
                            ${data?.subTotal?.toFixed(2)}
                          </Typography>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Typography variant="body2" className="shipping-tax-text">
                    Shipping & tax is calculated at checkout.
                  </Typography>
                </div>

                {props?.ordersummary != true ? (
                  <>
                    <div>
                      <Box sx={{ mb: 2 }}>
                        <PayPalScriptProvider
                          options={{
                            clientId: data?.paypalClientId,
                            currency: "USD",
                            intent: "authorize",
                            "disable-funding": "credit,card",
                            commit: false,
                          }}
                        >
                          <PayPalButtons
                            style={{ layout: "vertical" }}
                            createOrder={createOrder}
                            onApprove={onApprove}
                          />
                        </PayPalScriptProvider>

                        <Divider>- OR -</Divider>
                      </Box>

                      {/* <Button  className="addtocart"
                                variant="contained"
                                color="primary" fullWidth sx={{my:1}}> Paypall</Button>  */}
                      <Button
                        className="addtocart"
                        variant="contained"
                        color="primary"
                        component={Link}
                        to="/Checkout"
                        fullWidth
                        onClick={CloseDrawer}
                        sx={{ fontSize: "18px" }}
                      >
                        Checkout
                      </Button>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </>
          )}
        </>
      ) : cartError == true ? (
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle2" color="error">
            There is Some Problem in this Cart, Please Contact Customer Service
            and Refresh the Page and try again..
          </Typography>
        </Box>
      ) : (
        <>
          <CircularProgress sx={{ textAlign: "center" }} />
        </>
      )}
    </div>
  );
}
