import React, { useEffect } from "react";
import {
  Typography,
  Container,
  Paper,
  Box,
  styled,
  Grid,
  Button,
  InputBase,
  Stack,
  MenuItem,
  Select,
  FormControl,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import GeneralSearch from "./GeneralSearch";
import VehicleSearch from "./VehicleSearch";
import { AttributeFilter } from "../Partsearch/AttributeFilter";
import { ApplicationFilter } from "../Partsearch/ApplicationFilter";
import { useResetdata } from "../../Redux/Resetdata";
import { useSelector, useDispatch } from "react-redux";
import { trigger } from "../../Redux/Counterslice";

const Filter = () => {
  const [value, setValue] = React.useState(0);

  const { ResetreduxData } = useResetdata();

  const selectData = (state: any) =>
    state.EXTRA_FILTERS.VehicleSearchFilterExists;
  const data = useSelector(selectData);

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    margin: "10px auto",
    //borderRadius: '12px',
    color: "#000",
    backgroundColor: "#fff",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#fff",
    },
    border: "1px solid #ccc",
    marginLeft: "auto!important",
    width: "60%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(2),
      //     width: '60%',
      //     display: 'flex',
      //     alignItems: 'center',
      // justifyContent: 'center',
    },
  }));

  // useEffect(() => {
  //   return () => {
  //     ResetreduxData();
  //     // ShowGrid()
  //   };
  // }, []);
  // const SearchIconWrapper = styled("div")(({ theme }) => ({
  //   padding: theme.spacing(0, 0),
  //   height: "100%",
  //   position: "absolute",
  //   pointerEvents: "none",
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   right: "0",
  //   top: "0",
  //   "& .MuiButton-contained": {
  //     backgroundColor: theme.palette.primary.main,
  //     // color: theme.palette.primary.light,
  //     borderRadius: 0,
  //     margin: 2,
  //   },
  // }));

  // const StyledInputBase = styled(InputBase)(({ theme }) => ({
  //   color: "inherit",
  //   width: "100%",
  //   "& .MuiInputBase-input": {
  //     padding: theme.spacing(1, 2, 1, 2),
  //     paddingright: `calc(1em + ${theme.spacing(4)})`,
  //     transition: theme.transitions.create("width"),
  //     width: "80%",
  //     [theme.breakpoints.up("sm")]: {
  //       width: "80%",
  //       "&:focus": {
  //         width: "80%",
  //       },
  //     },
  //   },
  // }));

  interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  // console.log(newValue, data, "from fliterss Test");
    // if (newValue == 1 && data == true ){
    //   setValue(newValue);
    // }
    // else if(newValue == 0  ){
    //   setValue(newValue);
    // }
    // else if (newValue == 1 && data == false){
    //   alert('No records in vehicle search')
    // }

    if (data == true || newValue == 0) {
      setValue(newValue);
    } else if (newValue == 1 && data == false) {
      alert("Application data is not available for your current selection.");
    }
  };

  const changeTabToIndexZero = () => {
    setValue(0);
  };

  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  return (
    <>
    <Box className={EnvData?.REACT_APP_SHOW_CENTRAL_TURBO === "true" ? "search-tab-box central-turbos-search-tab-box" : "search-tab-box"}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          className="tabs-box"
          aria-label="full width tabs example"
        >
          <Tab label="General Search" {...a11yProps(0)} className="tab-item" />
          {localStorage.getItem("showVehicleSearch") === "true" ? (
            <Tab
              label="Vehicle Search"
              {...a11yProps(1)}
              className="tab-item"
            />
          ) : null}
        </Tabs>
        <Box className="tab-panel-box">
          <TabPanel value={value} index={0}>
            <GeneralSearch />
          </TabPanel>
          {localStorage.getItem("showVehicleSearch") === "true" ? (
            <TabPanel value={value} index={1}>
              <VehicleSearch changeTabToIndexZero={changeTabToIndexZero} />
            </TabPanel>
          ) : null}
        </Box>
      </Box>
    </>
  );
};

export default Filter;
function dispatch(arg0: {
  payload: undefined;
  type: "triggerrestfunction/trigger";
}): void {
  throw new Error("Function not implemented.");
}
