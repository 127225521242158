import { Avatar, Box, Button, Container, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Grid, IconButton, InputBase, List, ListItem, ListItemText, Modal, OutlinedInput, Stack, TextField, Typography, styled } from "@mui/material";
import footerlogo from "../Images/centralturbos/footer-logo.png";

import PlaceIcon from "@mui/icons-material/Place";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GetData } from "../API/GetDataFromApi";
import { useDispatch, useSelector } from "react-redux";
import { useRef, useState , useEffect } from "react";
import SendIcon from "@mui/icons-material/Send";
import { ICompanyProps, IMicrositeProps, IgetImageURL } from "../Types/MicrositeType";
import formatPhoneNumber, { CheckValidEmailAddress, restrictedInputs } from "../API/GetMicrositeSettings";
import LanguageIcon from "@mui/icons-material/Language";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { addOrderHistoryDetails } from "../Redux/Counterslice";
import cadenceLogo from "../Images/newcadence_logo.jpg";
import CloseIcon from "@mui/icons-material/Close";



export default function CentralTurbosFooter(props: {
  _settings: IMicrositeProps;
  _address: ICompanyProps;
  _cookies: string;
  _cardimage: IgetImageURL[];
}) {
  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [orderNo, setorderNo] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [emailVal, setemailVal] = useState("");
  const [cookiesOpen, setCookiesOpen] =useState(false);
  const [cookies, setCookies] = useState(false);

  const location = useLocation();
  const isRootPath = location.pathname === '/';

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    margin: "10px 0",
    //borderRadius: '12px',
    color: "#000",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
    },
    border: "1px solid #ccc",
    marginLeft: "0px!important",
    borderRadius: 5,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(2),
      width: "100%",
      display: "flex",
    },
  }));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 0),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    right: "0",
    top: "0",
    "& .MuiButton-contained": {
      backgroundColor: theme.palette.primary.main,
      // color: theme.palette.primary.light,
      borderRadius: 0,
      margin: 2,
    },
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 5, 1, 2),
      paddingright: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "100%",
        "&:focus": {
          width: "100%",
        },
      },
    },
  }));

  let EmailValue = useRef<HTMLInputElement>(null);

  const validateEmail = (email_val: any) => {
    const expression: RegExp =
      /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
    const result: boolean = expression.test(email_val);
    if (!result) {
      return "Please enter a valid email address";
    } else {
      return "";
    }
  };

  const dispatch = useDispatch();

  const SubmitNewsLetter = () => {
    //alert(EmailValue.current?.value);
    let err_msg = validateEmail(EmailValue?.current?.value);
    let success = 0;
    if (err_msg !== "") {
      alert(err_msg);
    } else {
      let URL = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/subscribe-newsletter?SubscriberEmailId=${EmailValue.current?.value}`;
      GetData(URL, null)
        .then((response) => {
          return response;
        })
        .then((results) => {
          // console.log(results);
          success = 1;
          alert("Thanks for your message!  We’ll be in touch soon.");
          //console.log(results);
        })
        .catch((error) => {
          console.log("Error in " + error);
        });
      if (success === 1) {
        validateEmail("");
      }
    }
  };

  let showCart: boolean = true;
  let _cartCount: number = 0;
  let _logo: string = "";
  let _showSearch: boolean = true;
  let _showAboutus: boolean = true;
  let _showServices: boolean = true;
  let _showNewsletter: boolean = true;
  let _dealerlocation: string = "";
  let _showProduct: boolean = true;
  let _showTestimonial: boolean = true;
  let _showFaq: boolean = true;
  let _showterms: boolean = true;
  let _showContactus: boolean = false;
  let _keepinTouch: boolean = false;

  showCart = props._settings.shoppingcart;
  _cartCount = props._settings.cartCount;
  _logo = props._settings.logo;
  _showSearch = props._settings.search;
  _showAboutus = props._settings.aboutUs;
  _showNewsletter = props._settings.newsLetter;
  _dealerlocation = props._settings.dealerLocation;
  _showServices = props._settings.services;
  _showProduct = props._settings.Product;
  _showTestimonial = props._settings.testimonial;
  _showFaq = props._settings.faq;
  _showterms = props._settings.policiesandTerms;
  _showContactus = props._settings.showinFooter;
  _keepinTouch = props._settings.keepinTouch;
  

  const setValue_orderNo = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let orderNo = restrictedInputs(event.target.value);

    setorderNo(orderNo.toString());
  };

  const setValue_Email = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let email = restrictedInputs(event.target.value);
    const result: boolean = CheckValidEmailAddress(email);
    setemailVal(email.toString());
    if (!result) {
      setError("Please enter a valid email address");
    } else {
      setError("");
    }

    // validateEmail();
  };
  
  const TrackOrder = async () => {
    let err_msg = "";
    if (orderNo === null || orderNo === "") {
      err_msg = "Enter Order Number";
    }
    if (error !== null && error !== "") {
      if (err_msg !== "") {
        err_msg = err_msg + "\n";
      }
      err_msg = err_msg + error.toString();
    } else if (error === null) {
      if (err_msg !== "") {
        err_msg = err_msg + "\n";
      }
      err_msg = err_msg + "Enter Email Address";
    }
    console.log(err_msg);
    if (err_msg !== "") {
      alert(err_msg);
    } else {
      // localStorage.setItem("orderHistory_OrderNo", orderNo);
      // localStorage.setItem("orderHistory_Email", emailVal);

      let type = {
        Id: orderNo,
        mail: emailVal,
      };
      dispatch(addOrderHistoryDetails(type));

      navigate("/OrderHistory");
    }
  };


  useEffect(() => {
    if (props._cookies == "") {
      setCookies(true);
    }
  }, [props._cookies]);

  const SetCookies = () => {
    document.cookie = "name=Mondial; expires=Fri, 31 Dec 2024 23:59:59 UTC;";
    setCookies(false);
  };
  const cookieClickOpen = () => {
    setCookiesOpen(true);
  };
  const cookiepopup = Boolean(cookiesOpen);
  const cookieClose = () => {
    setCookiesOpen(false);
  };

  
  return(
    <div>
      {/* dont delete */}
      {
        isRootPath &&   <Box className="central-turbos-newsletter-footer">
        <Container maxWidth="lg">
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={8}>
              <Typography variant="h6" className="central-turbos-newsletter-title">Subscribe to our newsletter</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button variant="contained" onClick={handleOpen} className="central-turbos-button">SUBSCRIBE</Button>
            </Grid>
            
          </Grid>
        </Container>
      </Box>
      }
    
      <Box className="central-turbos-footer">
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
                <Box>
                   <img src={footerlogo} alt=""  className="footer-logo" />
                   <Typography variant="body2" sx={{mt:2}}>Choosing Central Turbos means opting for unparalleled quality, wisdom woven from years of experience, and an unwavering commitment to powering your satisfaction. Gear up for a game-changing experience!</Typography>
                   <Stack direction="row" alignItems="center" spacing={1} mt={2} className="central-turbos-footer-location-text">
                      <Link to="/ContactUs"><Typography>FLORIDA </Typography></Link> 
                      <Divider color="primary" orientation="vertical" flexItem />
                      <Link to="/ContactUs"><Typography>VIRGINIA </Typography></Link>
                      <Divider color="primary" orientation="vertical" flexItem />
                      <Link to="/ContactUs"><Typography>NORTH CAROLINA</Typography></Link>
                     
                   </Stack>
                   <List>
                    <>
                      <ListItem
                        disablePadding={true}
                        component="a"
                        href={props._address["locationUrl"]}
                        target="_blank"
                        // sx={{ color: "primary.contrastText" }}
                        
                      >
                        <Avatar
                          sx={{ bgcolor: "transparent", marginRight: "10px", color:"#000" }}
                        >
                          <PlaceIcon fontSize="medium" />
                        </Avatar>
                        <span style={{ fontWeight: "600", textDecoration: "none" , color:"rgba(61,61,61,1)" }}>
                          {" "}
                          {props._address.address1 +
                            ", " +
                            props._address["cityName"] +
                            ", " +
                            props._address["stateCode"] +
                            ", " +
                            props._address["zipCode"] +
                            ", " +
                            props._address["countryName"]}{" "}
                        </span>
                      </ListItem>
                     
                        <ListItem
                          disablePadding={true}
                          component="a"
                          href={"mailto:" + props._address.email}
                          // sx={{ color: "primary.contrastText" }}
                        >
                          <Avatar
                            sx={{ bgcolor: "transparent", marginRight: "10px", color:"#000" }}
                          >
                            <EmailIcon fontSize="medium" />
                          </Avatar>
                          <span style={{ fontWeight: "600", textDecoration: "none" , color:"rgba(61,61,61,1)" }}> {props._address.email}</span>
                        </ListItem>
                     

                      <ListItem
                        disablePadding={true}
                        component="a"
                        href={
                          "tel:" +
                          props._settings.phonePrefix +
                          "" +
                          formatPhoneNumber(props._settings.tollFreeNumber)
                        }

                      >
                        <Avatar
                          sx={{ bgcolor: "transparent", marginRight: "10px", color:"#000" }}
                        >
                          <PhoneIcon fontSize="medium" />
                        </Avatar>
                        <span style={{ fontWeight: "600", textDecoration: "none" , color:"rgba(61,61,61,1)" }}>
                          {" "}
                          {props._settings.phonePrefix !== ""
                            ? props._settings.phonePrefix +
                              "" +
                              formatPhoneNumber(props._settings.tollFreeNumber)
                            : formatPhoneNumber(props._settings.tollFreeNumber)}
                        </span>
                      </ListItem>
                    </>
                  </List>
                  {/* <Stack direction="row" alignItems="center" spacing={1} mt={1} className="central-turbos-social-icons">
                      <IconButton>
                      
                      </IconButton>
                  </Stack> */}
                </Box>
                {_keepinTouch == true ? (
                  <>
                    {/* <Typography variant="h4">Keep in touch:</Typography> */}
                    {props._settings.facebookId != "" ? (
                      <IconButton
                      className="central-turbos-social-icon"
                        color="inherit"
                        href={
                          !props._settings.facebookId.startsWith("http")
                            ? "https://" + props._settings.facebookId
                            : props._settings.facebookId
                        }
                        target={"_blank"}
                      >
                        <FacebookIcon />
                      </IconButton>
                    ) : null}
                    {props._settings.linkedinId !== "" ? (
                      <IconButton
                      className="central-turbos-social-icon"
                        color="inherit"
                        href={
                          !props._settings.linkedinId.startsWith("http")
                            ? "https://" + props._settings.linkedinId
                            : props._settings.linkedinId
                        }
                        target={"_blank"}
                      >
                        <LinkedInIcon />
                      </IconButton>
                    ) : null}
                    {props._settings.twitterId !== "" ? (
                      <IconButton
                      className="central-turbos-social-icon"
                        color="inherit"
                        href={
                          !props._settings.twitterId.startsWith("http")
                            ? "https://" + props._settings.twitterId
                            : props._settings.twitterId
                        }
                        target={"_blank"}
                      >
                        <TwitterIcon />
                      </IconButton>
                    ) : null}

                    {props._settings.instagramId !== "" ? (
                      <IconButton
                      className="central-turbos-social-icon"
                        color="inherit"
                        href={
                          !props._settings.instagramId.startsWith("http")
                            ? "https://" + props._settings.instagramId
                            : props._settings.instagramId
                        }
                        target={"_blank"}
                      >
                        <InstagramIcon />
                      </IconButton>
                    ) : null}
                    {props._settings.emailAddress &&
                    props._settings.emailAddress !== "" ? (
                      <IconButton
                      className="central-turbos-social-icon"
                        color="inherit"
                        href={"mailto:" + props._settings.emailAddress}
                        target={"_blank"}
                      >
                        <EmailIcon />
                      </IconButton>
                    ) : null}
                  </>
                ) : null}
            </Grid>
            <Grid item> </Grid>
            <Grid item xs={12} md={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4}>
                    <Box className="central-turbos-footer-list">
                      <Typography variant="subtitle2" className="central-turbos-footer-title">Products</Typography>
                      <List>
                        <ListItem disablePadding={true}>
                          <ListItemText>
                            <Button
                              to="/PartSearch"
                              component={Link}
                              className="central-turbos-footer-link"
                              sx={{
                                color: "primary.light",
                                ":hover": {
                                  color: "primary.main",
                                },
                              }}
                            >
                              Product Search
                            </Button>
                          </ListItemText>
                        </ListItem>
                     </List>
                     <List>
                        <ListItem disablePadding={true}>
                          <ListItemText>
                            <Button
                              to="/TopProducts"
                              component={Link}
                              className="central-turbos-footer-link"
                              sx={{
                                color: "primary.light",
                                ":hover": {
                                  color: "primary.main",
                                },
                              }}
                            >
                              Category
                            </Button>
                          </ListItemText>
                        </ListItem>
                     </List>
                     {/* <List>
                        <ListItem disablePadding={true}>
                          <ListItemText>
                            <Button
                              to="/"
                              component={Link}
                              className="central-turbos-footer-link"
                              sx={{
                                color: "primary.light",
                                ":hover": {
                                  color: "primary.main",
                                },
                              }}
                            >
                              Brand
                            </Button>
                          </ListItemText>
                        </ListItem>
                     </List> */}
                     <List>
                        {/* <ListItem disablePadding={true}>
                          <ListItemText>
                            <Button
                              to="/"
                              component={Link}
                              className="central-turbos-footer-link"
                              sx={{
                                color: "primary.light",
                                ":hover": {
                                  color: "primary.main",
                                },
                              }}
                            >
                              SUBSCRIBE
                            </Button>
                          </ListItemText>
                        </ListItem> */}
                     </List>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box className="central-turbos-footer-list">
                      <Typography variant="subtitle2" className="central-turbos-footer-title">Account</Typography>
                      <List>
                        <ListItem disablePadding={true}>
                          <ListItemText>
                            <Button
                              to="/Login"
                              component={Link}
                              className="central-turbos-footer-link"
                              sx={{
                                color: "primary.light",
                                ":hover": {
                                  color: "primary.main",
                                },
                              }}
                            >
                              Login
                            </Button>
                          </ListItemText>
                        </ListItem>
                     </List>
                     <List>
                        <ListItem disablePadding={true}>
                          <ListItemText>
                            <Button
                              to="/OrderHistory"
                              component={Link}
                              className="central-turbos-footer-link"
                              sx={{
                                color: "primary.light",
                                ":hover": {
                                  color: "primary.main",
                                },
                              }}
                            >
                              Order History
                            </Button>
                          </ListItemText>
                        </ListItem>
                     </List>
                     <List>
                        <ListItem disablePadding={true}>
                          <ListItemText>
                            <Button
                             to="/Login"
                              component={Link}
                              className="central-turbos-footer-link"
                              sx={{
                                color: "primary.light",
                                ":hover": {
                                  color: "primary.main",
                                },
                              }}
                            >
                              Forgot Password
                            </Button>
                          </ListItemText>
                        </ListItem>
                     </List>
                     <List>
                        <ListItem disablePadding={true}>
                          <ListItemText>
                            <Button
                              to="/Login"
                              component={Link}
                              className="central-turbos-footer-link"
                              sx={{
                                color: "primary.light",
                                ":hover": {
                                  color: "primary.main",
                                },
                              }}
                            >
                              Track Order
                            </Button>
                          </ListItemText>
                        </ListItem>
                     </List>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box className="central-turbos-footer-list">
                      <Typography variant="subtitle2" className="central-turbos-footer-title">Resources</Typography>
                      {/* <List>
                        <ListItem disablePadding={true}>
                          <ListItemText>
                            <Button
                              to="/"
                              component={Link}
                              className="central-turbos-footer-link"
                              sx={{
                                color: "primary.light",
                                ":hover": {
                                  color: "primary.main",
                                },
                              }}
                            >
                              News
                            </Button>
                          </ListItemText>
                        </ListItem>
                     </List>
                     <List>
                        <ListItem disablePadding={true}>
                          <ListItemText>
                            <Button
                              to="/"
                              component={Link}
                              className="central-turbos-footer-link"
                              sx={{
                                color: "primary.light",
                                ":hover": {
                                  color: "primary.main",
                                },
                              }}
                            >
                              Blog
                            </Button>
                          </ListItemText>
                        </ListItem>
                     </List>
                     <List>
                        <ListItem disablePadding={true}>
                          <ListItemText>
                            <Button
                              to="/"
                              component={Link}
                              className="central-turbos-footer-link"
                              sx={{
                                color: "primary.light",
                                ":hover": {
                                  color: "primary.main",
                                },
                              }}
                            >
                              Videos
                            </Button>
                          </ListItemText>
                        </ListItem>
                     </List> */}
                     <List>
                        <ListItem disablePadding={true}>
                          <ListItemText>
                            <Button
                              to="/Faq"
                              component={Link}
                              className="central-turbos-footer-link"
                              sx={{
                                color: "primary.light",
                                ":hover": {
                                  color: "primary.main",
                                },
                              }}
                            >
                              FAQs
                            </Button>
                          </ListItemText>
                        </ListItem>
                     </List>
                     <List>
                        <ListItem disablePadding={true}>
                          <ListItemText>
                            <Button
                              to="/Warranties"
                              component={Link}
                              className="central-turbos-footer-link"
                              sx={{
                                color: "primary.light",
                                ":hover": {
                                  color: "primary.main",
                                },
                              }}
                            >
                              Forms + Warranties
                            </Button>
                          </ListItemText>
                        </ListItem>
                     </List>
                     {
                      props._settings.showTechBulletins === true ? (
                        <List>
                        <ListItem disablePadding={true}>
                          <ListItemText>
                            <Button
                              to="/Brochures"
                              component={Link}
                              className="central-turbos-footer-link"
                              sx={{
                                color: "primary.light",
                                ":hover": {
                                  color: "primary.main",
                                },
                              }}
                            >
                             Brochures
                            </Button>
                          </ListItemText>
                        </ListItem>
                     </List>
                       ):null
                     }
                   
                    </Box>
                  </Grid>
                </Grid>
               <Box p={1}>
               <Typography variant="subtitle2" className="central-turbos-footer-title">Subscribe</Typography>
                  <Typography variant="body2">Sign up with your email address to receive news and updates.</Typography>
                  {/* <FormControl sx={{ m: 1, width: '100%',background:"#fff" }} variant="outlined"> */}
                    {/* <OutlinedInput
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      placeholder="EMAIL ADDRESS"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    /> */}
                    <Search>
                          <StyledInputBase
                            placeholder="Enter your E-Mail"
                            inputProps={{ "aria-label": "Enter your E-Mail" }}
                            inputRef={EmailValue}
                          />
                          <SearchIconWrapper></SearchIconWrapper>
                          <Button
                            variant="contained"
                            className="footer-newsletter"
                            style={{
                              minWidth: "40px",
                              padding: "9px 8px",
                              marginRight: "-1px",
                              borderRadius: "2px 5px 5px 0",
                            }}
                            onClick={() => SubmitNewsLetter()}
                          >
                            <SendIcon />
                          </Button>
                        </Search>
                   
                  {/* </FormControl> */}
                  </Box>
                
               
             
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className="central-turbos-footer-bottom">
        <Container>
          <Grid container spacing={1}>
          <Grid item md={9}>
          {props._settings.copyRights !== "" ? (
              
                <Typography>  {props._settings.copyRights}</Typography>
             
               ) : null}
               </Grid>
              <Grid item md={3}>
                <Stack direction="row" alignItems="center" spacing={10}>
                  <Typography style={{cursor:"pointer"}} onClick={cookieClickOpen}>Privacy Policy</Typography>
                  <Typography to="/TermsAndConditions"
                  style={{textDecoration:"none"}}
                            component={Link}>Terms of Service</Typography>
                </Stack>
              </Grid>
          </Grid>
        </Container>
      </Box>

      

      {cookies == true ? (
        <>
          <div className="cookie-info-bar">
            <div className="cookie-info-bar-container">
              <div className="cookie-info-bar-content">
                <div className="cookie-notice">
                  We are using cookies to give you the best experience on our
                  website. More information about our
                  <span>
                    <a onClick={cookieClickOpen}>Privacy Policy.</a>
                  </span>
                  <Button
                    className="cookie-button"
                    variant="contained"
                    sx={{ ml: 1 }}
                    onClick={SetCookies}
                  >
                    Got it!
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <Dialog
        open={cookiepopup}
        onClose={cookieClose}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle>
          PRIVACY POLICY
          <IconButton
            aria-label="close"
            onClick={cookieClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="cookie-modal-content">
          <DialogContentText>
            <p>
              <b>{props._address?.companyName}</b> operates{" "}
              <b>{props._settings?.websiteUrl}</b>.
            </p>
            <p
              dangerouslySetInnerHTML={{
                __html: props._settings.privacyPolicy,
              }}
            ></p>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          Enter Email
        </DialogTitle>
      <DialogContent>
      <div >
        <Search>
                        <StyledInputBase
                          placeholder="Enter your E-Mail"
                          inputProps={{ "aria-label": "Enter your E-Mail" }}
                          inputRef={EmailValue}
                        />
                        <SearchIconWrapper></SearchIconWrapper>
                        <Button
                          variant="contained"
                          className="footer-newsletter"
                          style={{
                            minWidth: "40px",
                            padding: "9px 8px",
                            marginRight: "-1px",
                            borderRadius: "2px 5px 5px 0",
                          }}
                          onClick={() => SubmitNewsLetter()}
                        >
                          <SendIcon />
                        </Button>
                      </Search>
        </div>
      </DialogContent>
    </Dialog>

      {/* <Modal open={open} onClose={handleClose}>
        
      </Modal> */}

    </div>
  )
}