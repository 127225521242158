import React, { useRef, useState } from "react";
import {
  ListItem,
  List,
  ImageList,
  ImageListItem,
  ListItemText,
  Box,
  IconButton,
  Grid,
  styled,
  InputBase,
  Container,
  Typography,
  Avatar,
  Divider,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PlaceIcon from "@mui/icons-material/Place";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LanguageIcon from "@mui/icons-material/Language";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import { Link } from "react-router-dom";
import "../Css/footer.style.css";
import {
  IMicrositeProps,
  ICompanyProps,
  IgetImageURL,
} from "../Types/MicrositeType";
import cadenceLogo from "../Images/newcadence_logo.jpg";
import { useSelector, useDispatch } from "react-redux";
import type { RootState } from "../Redux/Store";
import amex from "../Images/american.png";
import discover from "../Images/discover.png";
import mastercard from "../Images/mastercard.png";
import visa from "../Images/visa.png";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import formatPhoneNumber from "../API/GetMicrositeSettings";
import { GetData } from "../API/GetDataFromApi";
import footerstaticimge1 from "../Images/image009_1.jpg";
import footerstaticimge2 from "../Images/image010_1.jpg";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  margin: "10px 0",
  //borderRadius: '12px',
  color: "#000",
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
  },
  border: "1px solid #ccc",
  marginLeft: "0px!important",
  borderRadius: 5,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(2),
    width: "100%",
    display: "flex",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 0),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  right: "0",
  top: "0",
  "& .MuiButton-contained": {
    backgroundColor: theme.palette.primary.main,
    // color: theme.palette.primary.light,
    borderRadius: 0,
    margin: 2,
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 5, 1, 2),
    paddingright: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
}));

export default function Footer(props: {
  _settings: IMicrositeProps;
  _address: ICompanyProps;
  _cookies: string;
  _cardimage: IgetImageURL[];
}) {
  // const [address, setaddress] = React.useState("")
  // const [phone, setphone] = React.useState("")
  // const [email, setemail] = React.useState("")
  // const address = sessionStorage.getItem('Address');
  // const phone = sessionStorage.getItem('PhoneNo');
  // const email = sessionStorage.getItem('Email');

  // const data = useSelector((state: RootState) => state.counter.data)

  // function Settingdata() {
  //     if (data) {
  //         setphone(data.PhoneNo);
  //         setemail(data.Email)
  //         setaddress(`${data.Address1},${data.City_Name},${data.State_Name},${data.Zip},${data.Country_Name}`)

  //     }

  // }

  // setTimeout(Settingdata, 500)

  //console.log(props, "from footer");
  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  let showCart: boolean = true;
  let _cartCount: number = 0;
  let _logo: string = "";
  let _showSearch: boolean = true;
  let _showAboutus: boolean = true;
  let _showServices: boolean = true;
  let _showNewsletter: boolean = true;
  let _dealerlocation: string = "";
  let _showProduct: boolean = true;
  let _showTestimonial: boolean = true;
  let _showFaq: boolean = true;
  let _showterms: boolean = true;
  let _showContactus: boolean = false;
  let _keepinTouch: boolean = false;

  showCart = props._settings.shoppingcart;
  _cartCount = props._settings.cartCount;
  _logo = props._settings.logo;
  _showSearch = props._settings.search;
  _showAboutus = props._settings.aboutUs;
  _showNewsletter = props._settings.newsLetter;
  _dealerlocation = props._settings.dealerLocation;
  _showServices = props._settings.services;
  _showProduct = props._settings.Product;
  _showTestimonial = props._settings.testimonial;
  _showFaq = props._settings.faq;
  _showterms = props._settings.policiesandTerms;
  _showContactus = props._settings.showinFooter;
  _keepinTouch = props._settings.keepinTouch;

  const [cookiesOpen, setCookiesOpen] = React.useState(false);
  const [cookies, setCookies] = React.useState(false);
  const [error, setError] = useState<string | null>(null);

  React.useEffect(() => {
    if (props._cookies == "") {
      setCookies(true);
    }
  }, [props._cookies]);

  const SetCookies = () => {
    document.cookie = "name=Mondial; expires=Fri, 31 Dec 2023 23:59:59 UTC;";
    setCookies(false);
  };
  const cookieClickOpen = () => {
    setCookiesOpen(true);
  };
  const cookiepopup = Boolean(cookiesOpen);
  const cookieClose = () => {
    setCookiesOpen(false);
  };

  let EmailValue = useRef<HTMLInputElement>(null);

  const validateEmail = (email_val: any) => {
    const expression: RegExp =
      /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
    const result: boolean = expression.test(email_val);
    if (!result) {
      return "Please enter a valid email address";
    } else {
      return "";
    }
  };

  const SubmitNewsLetter = () => {
    //alert(EmailValue.current?.value);
    let err_msg = validateEmail(EmailValue?.current?.value);
    let success = 0;
    if (err_msg !== "") {
      alert(err_msg);
    } else {
      let URL = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/subscribe-newsletter?SubscriberEmailId=${EmailValue.current?.value}`;
      GetData(URL, null)
        .then((response) => {
          return response;
        })
        .then((results) => {
          // console.log(results);
          success = 1;
          alert("Thanks for your message!  We’ll be in touch soon.");
          //console.log(results);
        })
        .catch((error) => {
          console.log("Error in " + error);
        });
      if (success === 1) {
        validateEmail("");
      }
    }
  };

  // console.log(props._cardimage);

  return (
    <div>
      <div className="footer">
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box>
                <Typography variant="subtitle1">Site Map</Typography>
                <nav className="footer-sitemap">
                  <List>
                    <ListItem disablePadding={true}>
                      <ListItemText>
                        <Button
                          to="/"
                          component={Link}
                          className="footerlink"
                          sx={{
                            color: "primary.contrastText",
                            ":hover": {
                              color: "primary.main",
                            },
                          }}
                        >
                          <KeyboardArrowRightIcon
                            sx={{ fontSize: "18px", verticalAlign: "middle" }}
                          />
                          Home
                        </Button>
                        {/* <Link href="/" className="footerlink"> <KeyboardArrowRightIcon sx={{ fontSize: '18px', verticalAlign: 'middle' }} />Home</Link> */}
                      </ListItemText>
                    </ListItem>
                    {EnvData.REACT_APP_STATIC_PAGE ==
                    "true" ? null : EnvData?.REACT_APP_STATICPAGE == "Yes" ? (
                      <ListItem disablePadding={true}>
                        <ListItemText>
                          <Button
                            to="/FeaturesBenefits"
                            component={Link}
                            className="footerlink"
                            sx={{
                              color: "primary.contrastText",
                              ":hover": {
                                color: "primary.main",
                              },
                            }}
                          >
                            <KeyboardArrowRightIcon
                              sx={{ fontSize: "18px", verticalAlign: "middle" }}
                            />
                            Features-Benefits
                          </Button>
                        </ListItemText>
                      </ListItem>
                    ) : null}
                    {_showProduct === true ? (
                      <ListItem disablePadding={true}>
                        <ListItemText>
                          <Button
                            to="/Products"
                            component={Link}
                            className="footerlink"
                            sx={{
                              color: "primary.contrastText",
                              ":hover": {
                                color: "primary.main",
                              },
                            }}
                          >
                            <KeyboardArrowRightIcon
                              sx={{ fontSize: "18px", verticalAlign: "middle" }}
                            />
                            Products
                          </Button>
                        </ListItemText>
                      </ListItem>
                    ) : null}
                    {EnvData.REACT_APP_STATIC_PAGE ==
                    "true" ? null : _showServices === true ? (
                      <ListItem disablePadding={true}>
                        <ListItemText>
                          <Button
                            to="/Services"
                            component={Link}
                            className="footerlink"
                            sx={{
                              color: "primary.contrastText",
                              ":hover": {
                                color: "primary.main",
                              },
                            }}
                          >
                            <KeyboardArrowRightIcon
                              sx={{ fontSize: "18px", verticalAlign: "middle" }}
                            />
                            Services
                          </Button>
                        </ListItemText>
                      </ListItem>
                    ) : null}

                    {EnvData.REACT_APP_STATIC_PAGE == "true" ? (
                      <ListItem disablePadding={true}>
                        <ListItemText>
                          <Button
                            to="/PartSearch"
                            component={Link}
                            className="footerlink"
                            sx={{
                              color: "primary.contrastText",
                              ":hover": {
                                color: "primary.main",
                              },
                            }}
                          >
                            <KeyboardArrowRightIcon
                              sx={{ fontSize: "18px", verticalAlign: "middle" }}
                            />
                            Catalog
                          </Button>
                          {/* <Link className="footerlink"><KeyboardArrowRightIcon sx={{ fontSize: '18px', verticalAlign: 'middle' }} />Part Search</Link> */}
                        </ListItemText>
                      </ListItem>
                    ) : _showSearch == true ? (
                      <ListItem disablePadding={true}>
                        <ListItemText>
                          <Button
                            to="/PartSearch"
                            component={Link}
                            className="footerlink"
                            sx={{
                              color: "primary.contrastText",
                              ":hover": {
                                color: "primary.main",
                              },
                            }}
                          >
                            <KeyboardArrowRightIcon
                              sx={{ fontSize: "18px", verticalAlign: "middle" }}
                            />
                            Part Search
                          </Button>
                          {/* <Link className="footerlink"><KeyboardArrowRightIcon sx={{ fontSize: '18px', verticalAlign: 'middle' }} />Part Search</Link> */}
                        </ListItemText>
                      </ListItem>
                    ) : null}

                    {EnvData.REACT_APP_STATIC_PAGE ==
                    "true" ? null : _showTestimonial === true ? (
                      <ListItem disablePadding={true}>
                        <ListItemText>
                          <Button
                            to="/Testimonial"
                            component={Link}
                            className="footerlink"
                            sx={{
                              color: "primary.contrastText",
                              ":hover": {
                                color: "primary.main",
                              },
                            }}
                          >
                            <KeyboardArrowRightIcon
                              sx={{ fontSize: "18px", verticalAlign: "middle" }}
                            />
                            Testimonials
                          </Button>
                          {/* <Link href="/TestimonialVideos" className="footerlink"><KeyboardArrowRightIcon sx={{ fontSize: '18px', verticalAlign: 'middle' }} />Testimonial</Link> */}
                        </ListItemText>
                      </ListItem>
                    ) : null}
                    {_showAboutus === true ? (
                      <ListItem disablePadding={true}>
                        <ListItemText>
                          <Button
                            to="/AboutUs"
                            component={Link}
                            className="footerlink"
                            sx={{
                              color: "primary.contrastText",
                              ":hover": {
                                color: "primary.main",
                              },
                            }}
                          >
                            <KeyboardArrowRightIcon
                              sx={{ fontSize: "18px", verticalAlign: "middle" }}
                            />
                            About Us
                          </Button>
                        </ListItemText>
                      </ListItem>
                    ) : null}
                    {EnvData.REACT_APP_STATIC_PAGE ==
                    "true" ? null : showCart == true ? (
                      <ListItem disablePadding={true}>
                        <ListItemText>
                          <Button
                            to="/OrderHistory"
                            component={Link}
                            className="footerlink"
                            sx={{
                              color: "primary.contrastText",
                              ":hover": {
                                color: "primary.main",
                              },
                            }}
                          >
                            <KeyboardArrowRightIcon
                              sx={{ fontSize: "18px", verticalAlign: "middle" }}
                            />
                            Order History
                          </Button>
                          {/* <Link className="footerlink"><KeyboardArrowRightIcon sx={{ fontSize: '18px', verticalAlign: 'middle' }} />Order History</Link> */}
                        </ListItemText>
                      </ListItem>
                    ) : null}
                    {_dealerlocation !== "" ||
                    EnvData.REACT_APP_STATIC_PAGE == "true" ? (
                      <>
                        <ListItem disablePadding={true}>
                          <ListItemText>
                            <Button
                              to={
                                EnvData.REACT_APP_STATIC_PAGE == "true"
                                  ? EnvData.REACT_APP_CADENCELOGINURL
                                  : _dealerlocation
                              }
                              component={Link}
                              className="footerlink"
                              sx={{
                                color: "primary.contrastText",
                                ":hover": {
                                  color: "primary.main",
                                },
                              }}
                            >
                              <KeyboardArrowRightIcon
                                sx={{
                                  fontSize: "18px",
                                  verticalAlign: "middle",
                                }}
                              />
                              {EnvData.REACT_APP_STATIC_PAGE == "true"
                                ? "Distributor Portal "
                                : "Dealer Location"}
                            </Button>
                            {/* <Link className="footerlink"><KeyboardArrowRightIcon sx={{ fontSize: '18px', verticalAlign: 'middle' }} />Dearler Location</Link> */}
                          </ListItemText>
                        </ListItem>
                      </>
                    ) : null}
                    {_showFaq === true ? (
                      <ListItem disablePadding={true}>
                        <ListItemText>
                          <Button
                            to="/Faq"
                            component={Link}
                            className="footerlink"
                            sx={{
                              color: "primary.contrastText",
                              ":hover": {
                                color: "primary.main",
                              },
                            }}
                          >
                            <KeyboardArrowRightIcon
                              sx={{ fontSize: "18px", verticalAlign: "middle" }}
                            />
                            FAQ
                          </Button>
                          {/* <Link href="/Faq" className="footerlink"><KeyboardArrowRightIcon sx={{ fontSize: '18px', verticalAlign: 'middle' }} />Faq</Link> */}
                        </ListItemText>
                      </ListItem>
                    ) : null}
                    {_showterms === true ? (
                      <ListItem disablePadding={true}>
                        <ListItemText>
                          <Button
                            to="/TermsAndConditions"
                            component={Link}
                            className="footerlink-Terms"
                            sx={{
                              color: "primary.contrastText",
                              ":hover": {
                                color: "primary.main",
                              },
                            }}
                          >
                            <KeyboardArrowRightIcon
                              sx={{ fontSize: "18px", verticalAlign: "middle" }}
                            />
                            Terms and Conditions
                          </Button>
                          {/* <Link href="/TermsAndConditions" className="footerlink"><KeyboardArrowRightIcon sx={{ fontSize: '18px', verticalAlign: 'middle' }} />Terms and Condtions</Link> */}
                        </ListItemText>
                      </ListItem>
                    ) : null}
                    {_showContactus !== false ? (
                      <ListItem disablePadding={true}>
                        <ListItemText>
                          <Button
                            to="/ContactUs"
                            component={Link}
                            className="footerlink"
                            sx={{
                              color: "primary.contrastText",
                              ":hover": {
                                color: "primary.main",
                              },
                            }}
                          >
                            <KeyboardArrowRightIcon
                              sx={{ fontSize: "18px", verticalAlign: "middle" }}
                            />
                            Contact Us
                          </Button>
                        </ListItemText>
                      </ListItem>
                    ) : null}

                    {EnvData.REACT_APP_STATIC_PAGE == "true" ? (
                      <>
                        <ListItem disablePadding={true}>
                          <ListItemText>
                            <Button
                              to="https://torqstop-ecat.com/CustomPage/1"
                              component={Link}
                              className="footerlink"
                              sx={{
                                color: "primary.contrastText",
                                ":hover": {
                                  color: "primary.main",
                                },
                              }}
                            >
                              <KeyboardArrowRightIcon
                                sx={{
                                  fontSize: "18px",
                                  verticalAlign: "middle",
                                }}
                              />
                              Product
                            </Button>
                          </ListItemText>
                        </ListItem>
                      </>
                    ) : null}
                  </List>
                </nav>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              {props._address && (
                <Box>
                  <Divider orientation="vertical" />
                  <Typography variant="subtitle1">Contact</Typography>
                  <List>
                    <>
                      <ListItem
                        disablePadding={true}
                        component="a"
                        href={props._address["locationUrl"]}
                        target="_blank"
                        sx={{ color: "primary.contrastText" }}
                      >
                        <Avatar
                          sx={{ bgcolor: "transparent", marginRight: "10px" }}
                        >
                          <PlaceIcon fontSize="medium" color="primary" />
                        </Avatar>
                        <span>
                          {" "}
                          {props._address.address1 +
                            ", " +
                            props._address["cityName"] +
                            ", " +
                            props._address["stateCode"] +
                            ", " +
                            props._address["zipCode"] +
                            ", " +
                            props._address["countryName"]}{" "}
                        </span>
                      </ListItem>
                      {props._address.email != "" ? (
                        <ListItem
                          disablePadding={true}
                          component="a"
                          href={"mailto:" + props._address.email}
                          sx={{ color: "primary.contrastText" }}
                        >
                          <Avatar
                            sx={{ bgcolor: "transparent", marginRight: "10px" }}
                          >
                            <EmailIcon fontSize="medium" color="primary" />
                          </Avatar>
                          <span> {props._address.email}</span>
                        </ListItem>
                      ) : null}

                      {
                        props._settings.tollFree==true&&props._settings.tollFreeNumber!==""?
                        <ListItem
                        disablePadding={true}
                        component="a"
                        href={
                          "tel:" +
                          props._settings.phonePrefix +
                          "" +
                          formatPhoneNumber(props._settings.tollFreeNumber)
                        }
                        sx={{ color: "#fff" }}
                      >
                        <Avatar
                          sx={{ bgcolor: "transparent", marginRight: "10px" }}
                        >
                          <PhoneIcon fontSize="medium" color="primary" />
                        </Avatar>
                        <span>
                          {" "}
                          {props._settings.phonePrefix !== ""
                            ? props._settings.phonePrefix +
                              "" +
                              formatPhoneNumber(props._settings.tollFreeNumber)
                            : formatPhoneNumber(props._settings.tollFreeNumber)}
                        </span>
                      </ListItem>
                      :null
                      }

                    
                    </>
                  </List>
                </Box>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <Box>
                {_showNewsletter == true ? (
                  <>
                    <Typography variant="subtitle1">Newsletter</Typography>
                    <p>
                      Subscribe to our Newsletter to get important updates about
                      our network and latest offers.
                    </p>
                    <div style={{ flexGrow: 1 }}>
                      <Search>
                        <StyledInputBase
                          placeholder="Enter your E-Mail"
                          inputProps={{ "aria-label": "Enter your E-Mail" }}
                          inputRef={EmailValue}
                        />
                        <SearchIconWrapper></SearchIconWrapper>
                        <Button
                          variant="contained"
                          className="footer-newsletter"
                          style={{
                            minWidth: "40px",
                            padding: "9px 8px",
                            marginRight: "-1px",
                            borderRadius: "2px 5px 5px 0",
                          }}
                          onClick={() => SubmitNewsLetter()}
                        >
                          <SendIcon />
                        </Button>
                      </Search>
                    </div>
                  </>
                ) : null}
                {_keepinTouch == true ? (
                  <>
                    <Typography variant="h4">Keep in touch:</Typography>
                    {props._settings.facebookId != "" ? (
                      <IconButton
                        color="inherit"
                        href={
                          !props._settings.facebookId.startsWith("http")
                            ? "https://" + props._settings.facebookId
                            : props._settings.facebookId
                        }
                        target={"_blank"}
                      >
                        <FacebookIcon />
                      </IconButton>
                    ) : null}
                    {props._settings.linkedinId !== "" ? (
                      <IconButton
                        color="inherit"
                        href={
                          !props._settings.linkedinId.startsWith("http")
                            ? "https://" + props._settings.linkedinId
                            : props._settings.linkedinId
                        }
                        target={"_blank"}
                      >
                        <LinkedInIcon />
                      </IconButton>
                    ) : null}
                    {props._settings.twitterId !== "" ? (
                      <IconButton
                        color="inherit"
                        href={
                          !props._settings.twitterId.startsWith("http")
                            ? "https://" + props._settings.twitterId
                            : props._settings.twitterId
                        }
                        target={"_blank"}
                      >
                        <TwitterIcon />
                      </IconButton>
                    ) : null}

                    {props._settings.instagramId !== "" ? (
                      <IconButton
                        color="inherit"
                        href={
                          !props._settings.instagramId.startsWith("http")
                            ? "https://" + props._settings.instagramId
                            : props._settings.instagramId
                        }
                        target={"_blank"}
                      >
                        <InstagramIcon />
                      </IconButton>
                    ) : null}
                    {props._settings.emailAddress &&
                    props._settings.emailAddress !== "" ? (
                      <IconButton
                        color="inherit"
                        href={"mailto:" + props._settings.emailAddress}
                        target={"_blank"}
                      >
                        <EmailIcon />
                      </IconButton>
                    ) : null}
                  </>
                ) : null}
                  {EnvData.REACT_APP_STATIC_PAGE == "true" ? (
                  <Box>
                    <Typography variant="h4">Proud supplier partner with:</Typography>
                    <div className="footer-torqstop-static-img">
                      <img src={footerstaticimge1} alt="footerimage" />
                    </div>
                    <div className="footer-torqstop-static-img">
                      <img src={footerstaticimge2} alt="footerimage" />
                    </div>
                  </Box>
                ) : null}
                {showCart == true ? (
                  <>
                    <Typography variant="h4">Payment Methods</Typography>
                    <Box className="payment-icons">
                      {props._cardimage &&
                        props._cardimage?.map((elem, index) => (
                          <React.Fragment key={index}>
                            <div className="payment-icons-colum">
                              <div className="payment-icons-box">
                                <img
                                  src={elem?.imageURL}
                                  alt=""
                                  loading="lazy"
                                />
                              </div>
                            </div>
                          </React.Fragment>
                        ))}
                      {/* <img src={discover} alt="" loading="lazy" />
                      <img src={mastercard} alt="" loading="lazy" />
                      <img src={visa} alt="" loading="lazy" /> */}
                    </Box>
                  </>
                ) : null}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
      {props._settings.copyRights !== "" ? (
        <div className="footerbottom">
          <Container maxWidth="lg">
            <Box className="footerbottom-box">
              <Typography>Powered by </Typography>
              <img
                src={cadenceLogo}
                alt=""
                className="cadence-logo"
                loading="lazy"
              />
              {props._settings.copyRights.includes("<a") ? (
                <>
                  <Typography
                    variant="body2"
                    sx={{ marginLeft: "auto" }}
                    dangerouslySetInnerHTML={{
                      __html: props._settings.privacyPolicy,
                    }}
                  ></Typography>
                </>
              ) : (
                <>
                  <Typography variant="body2" sx={{ marginLeft: "auto" }}>
                    {props._settings.copyRights}
                  </Typography>
                </>
              )}
            </Box>
          </Container>
        </div>
      ) : null}

      {cookies == true ? (
        <>
          <div className="cookie-info-bar">
            <div className="cookie-info-bar-container">
              <div className="cookie-info-bar-content">
                <div className="cookie-notice">
                  We are using cookies to give you the best experience on our
                  website. More information about our
                  <span>
                    <a onClick={cookieClickOpen}>Privacy Policy.</a>
                  </span>
                  <Button
                    className="cookie-button"
                    variant="contained"
                    sx={{ ml: 1 }}
                    onClick={SetCookies}
                  >
                    Got it!
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <Dialog
        open={cookiepopup}
        onClose={cookieClose}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle>
          PRIVACY POLICY
          <IconButton
            aria-label="close"
            onClick={cookieClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="cookie-modal-content">
          <DialogContentText>
            <p>
              <b>{props._address?.companyName}</b> operates{" "}
              <b>{props._settings?.websiteUrl}</b>.
            </p>
            <p
              dangerouslySetInnerHTML={{
                __html: props._settings.privacyPolicy,
              }}
            ></p>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
