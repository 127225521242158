import { useState, useEffect } from "react";
import { Get_Data } from "../API/GetDataFromApi";
import { clearLocalstorageValues } from "../API/GetMicrositeSettings";
import { useSelector } from "react-redux";

export default function SalesReceipt() {
  const [content, setContent] = useState("");

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  var val = localStorage.getItem("ShoppingcartHdrId");
  // console.log("Sales Receipt Start");
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      Get_Data(
        EnvData?.REACT_APP_APIURL +
          "/microsite-api-v1/sohtml?orderNo=" +
          val,
        ""
      )
        .then((results?) => {
          // console.log(results.data, "html content");
          setContent(results.data.sohtml.m_StringValue);
        })
        .catch((err) => {
          console.error(err);
        });
    })();
    //clearLocalstorageValues();
  }, []);

  return <div dangerouslySetInnerHTML={{ __html: content }}></div>;
}
