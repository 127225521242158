import { Stack, Typography, Button } from '@mui/material'
import React from 'react'
import { Link as RouterLink } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import turbochargers from "../Images/turbo-chargers.png";
import shipped from "../Images/shipped.png";


const OrderHistoryComps = ({orders , Addtocart }:any) => {

 
    let queryString = orders?.partNumber;
    const stringd = `/Category/${queryString}`;
    let trackingNum = orders?.trackingNumbers.includes("amp;")
    ? orders?.trackingNumbers.replace(/amp;/g, "")
    : orders?.trackingNumbers;
  

   let revisedTrackingNum = trackingNum.split("@#")[0].trim()
   let revisedTrackingNumURL = trackingNum.split("@#$")[1]
   if(revisedTrackingNumURL?.startsWith("http")) {
    revisedTrackingNumURL =revisedTrackingNumURL

   } else {
   
    revisedTrackingNumURL ="https://" + revisedTrackingNumURL

   }






    // function Reorder () {
    //     Addtocart(
    //         orders. partId,
    //         orders.  partNumber,
    //         orders. partDescription,
    //         orders. price,
    //         '',
    //     )
    // }
  return (
    <div className="order-info-box">
    <Stack
      className="order-info-box-header"
      direction="row"
      spacing={2}
    >
      <div className="order-info-box-header-left-text">
        <b>Order No : </b>
        <span>{orders?.shoppingCartHdrId}</span>
      </div>
      <div className="order-info-box-header-right-text">
        {orders?.creationDate}
      </div>
    </Stack>
    <div className="order-info-box-body">
      <div className="order-info-table-responsive">
        <RouterLink to={stringd}>
          <div className="order-info-table-img">
            <a className="entry-thumbnail">
              <img src={orders?.imageUrl} />
            </a>
          </div>
          <div className="order-info-table-description">
            <Typography variant="subtitle2">
              {" "}
              {orders?.salesPartnumber}{" "}
            </Typography>
            <Typography variant="h4" sx={{ mt: 1 }}>
              {" "}
              {orders?.partDescription}{" "}
            </Typography>
            <Typography className="order-info-qty-info-price">
              Qty. <b>{orders?.orderQty} </b>
              <span className="order-info-price">
                Price : <b>$ {orders?.price}</b>
              </span>
            </Typography>
          </div>
        </RouterLink>

        <div className="order-info-table-shipped">
          <Typography variant="subtitle2">
            <img className="shipped-img" src={shipped} />
            {orders?.orderStatus}
          </Typography>
          <Typography className="table-tracking-id">
            Tracking ID : <a target="_blank" href={revisedTrackingNumURL}>{revisedTrackingNum}</a >
          </Typography>
        
          {/* <Button variant="contained" sx={{ mt: 1 }}>
            Request RMA
          </Button> */}
        </div>
        <div className="order-info-table-reorder">
          <RouterLink to={stringd}>
            <Button variant="contained" >
              <ShoppingCartIcon sx={{ mr: 1, fontSize: 15 }} />
              Re-Order
            </Button>
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
  )
}

export default OrderHistoryComps