import {
  Container,
  Box,
  Typography,
  Stack,
  Button,
  Divider,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import ProductGrid from "../Components/Product/ProductGrid";

import EastIcon from "@mui/icons-material/East";
import { ProductList } from "../Components/Product/ProductList";
import "../Css/home.style.css";
import { Brand } from "../Components/Home/Brand";
import { Testimonial } from "../Components/Home/Testimonial";
import { Videos } from "../Components/Home/Videos";
import { BannerSlider } from "../Components/Home/BannerSlider";
import { useGetDataMultiResults } from "../APIHooks/useGetData";
import {
  IFeatureproductProps,
  IVidoesProps,
  IBannerProps,
  ITestimonialProps,
  ICompanyProps,
  IProductProps,
  IBrandProps,
  IMicrositeProps,
  IMicrositeSEOProps,
  IMicrositePropshome,
} from "../Types/MicrositeType";
// import AboutUs from "./AboutUs";
// import MetaTag from "../SEO/MetaTag";
import React, { Suspense } from "react";
import { useEffect, useState, memo } from "react";
import { addproduct } from "../Redux/Counterslice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GetDataMultiResults } from "../API/GetDataFromApi";
import Loader from "../Components/Loader";
import CentralTurbosHome from "./CentralTurbo/CentralTurbosHome";

const AboutUs = React.lazy(() => import("./AboutUs"));
const MetaTag = React.lazy(() => import("../SEO/MetaTag"));

function Home(props: { micrositeInfo: IMicrositeProps }) {
  const [videos, setVideos] = useState<IVidoesProps[]>([]);
  const [banner, setBanner] = useState<IBannerProps[]>([]);
  const [testimonial, setTestimonial] = useState<ITestimonialProps[]>([]);
  const [featureparts, setFeatureparts] = useState<IFeatureproductProps[]>([]);
  const [product, setProduct] = useState<IProductProps[]>([]);
  const [brands, setBrand] = useState<IBrandProps[]>([]);
  const [microsite, setMicrosite] = useState<IMicrositePropshome[]>([]);
  const [micrositeseo, setSeo] = useState<IMicrositeSEOProps[]>([]);
  const [loading, setLoading] = useState(true);

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);


  var raw = JSON.stringify({
    micrositeName: EnvData?.REACT_APP_MICROSITENAME,
    cartName: localStorage.getItem("cartName"),
    assetstyle: "string",
  });

  // const [videos, banner, testimonial, company, featureparts, product, brands, microsite, micrositeseo] = useGetDataMultiResults<IVidoesProps[], IBannerProps[], ITestimonialProps[], ICompanyProps[], IFeatureproductProps[], IProductProps[], IBrandProps[], IMicrositeProps[], IMicrositeSEOProps[]>(process.env.REACT_APP_APIURL + '/api/Microsite/Getmicrositeindexdata', requestOptions, [], [], [], [], [], [], [], [], [])   ;
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      GetDataMultiResults(
        EnvData?.REACT_APP_APIURL + "/microsite-api-v1/get-index"
      ).then((results?) => {
        // console.log(results, " res from multi");
        setSeo(results.data.micrositeIndex_SEOResponse);
        // console.log(results.data.micrositeIndex_SEOResponse, "tessti");
        setFeatureparts(results.data.micrositeIndex_FeaturedPartsResponse);
        setProduct(results.data.micrositeIndex_FeaturedProductResponse);
        Addproduct(results.data.micrositeIndex_FeaturedProductResponse);
        setBanner(results.data.micrositeIndex_BannerResponse);
        setBrand(results.data.micrositeIndex_BrandResponse);
        setVideos(results.data.micrositeIndex_VideoResponse);
        setTestimonial(results.data.micrositeIndex_TestimonialResponse);
        setMicrosite(results.data.micrositeIndex_ShoworNotResponse);
        setLoading(false);
      });
    })();
  }, []);

  function Addproduct(res: any) {
    dispatch(addproduct(res));
  }
  //console.log("hai");
  // console.log(JSON.stringify(company))

  let showBanner: boolean = false;
  let showTopproduct = false;
  let showFeatureparts = false; //
  let showTestimonial = false;
  let showAboutas = false;
  let showvidoes = false;
  let showbrands = false;
  microsite &&
    microsite.map(function (result) {
      showBanner = result.banner;
      showTopproduct = result.topCategory;
      showFeatureparts = result.topProducts; //
      showTestimonial = result.testimonial;
      showAboutas = result.hAboutUs;
      showvidoes = result.video;
      showbrands = result.popularBrands;
    });
  let seoH1tag = "";
  let seoH2tag = "";
  let seoHeading = "";
  let seoSubheading = "";
  let seoDescription = "";
  let topproductSeoTitle = "Top Products";
  let featuredpartsSeoTitle = "Featured Parts";

  let brandSeoTitle = "Brands";
  let aboutusSeoTitle = "About Us";
  let vieosSeoTitle = "VIDEOS";
  let testimonialSeoTitle = "TESTIMONIALS";

  micrositeseo &&
    micrositeseo.map(function (result) {
      seoH1tag = result.micrositeSEOH1Tag;
      seoH2tag = result.micrositeSEOH2Tag;
      seoHeading = result.additionalMicrositeSEOHeading;

      seoSubheading = result.additionalMicrositeSEOSubHeading;
      seoDescription = result.additionalMicrositeSEODescription;
      if (result.micrositeSEOHomePageSectionName === "Top Products") {
        topproductSeoTitle =
          result.additionalMicrositeSEOAlternateTitle != ""
            ? result.additionalMicrositeSEOAlternateTitle
            : topproductSeoTitle;
      } else if (result.micrositeSEOHomePageSectionName === "Featured Parts") {
        featuredpartsSeoTitle =
          result.additionalMicrositeSEOAlternateTitle != ""
            ? result.additionalMicrositeSEOAlternateTitle
            : featuredpartsSeoTitle;
        //featuredpartsSeoParagraphtag = result.additionalmicrositeseoparagraphtag
      } else if (result.micrositeSEOHomePageSectionName === "Brands") {
        brandSeoTitle =
          result.additionalMicrositeSEOAlternateTitle != ""
            ? result.additionalMicrositeSEOAlternateTitle
            : brandSeoTitle;
      } else if (result.micrositeSEOHomePageSectionName === "About Us") {
        aboutusSeoTitle =
          result.additionalMicrositeSEOAlternateTitle != ""
            ? result.additionalMicrositeSEOAlternateTitle
            : aboutusSeoTitle;
      } else if (
        result.micrositeSEOHomePageSectionName === "Featured Products"
      ) {
        featuredpartsSeoTitle =
          result.additionalMicrositeSEOAlternateTitle != ""
            ? result.additionalMicrositeSEOAlternateTitle
            : featuredpartsSeoTitle;
      } else if (result.micrositeSEOHomePageSectionName === "Video") {
        vieosSeoTitle =
          result.additionalMicrositeSEOAlternateTitle != ""
            ? result.additionalMicrositeSEOAlternateTitle
            : vieosSeoTitle;
      } else if (result.micrositeSEOHomePageSectionName === "Testimonial") {
        testimonialSeoTitle =
          result.additionalMicrositeSEOAlternateTitle != ""
            ? result.additionalMicrositeSEOAlternateTitle
            : testimonialSeoTitle;
      }
    });

  let seo_colorcode = "#fff";
  {
    showvidoes !== false && videos.length > 0
      ? (seo_colorcode = "#f6f6f6")
      : (seo_colorcode = "#fff");
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      {/* <CentralTurbosHome /> */}
      <MetaTag _pagename="Index.aspx" _fetchdata={true} />

      {showBanner !== false ? <BannerSlider _banner={banner} /> : null}
      
      <Container maxWidth={"lg"}>
        <Box sx={{ width: "100%", margin: "10px 0" }}>
          <Typography variant="h1" align="center">
            {seoH1tag}
          </Typography>
          <Typography variant="h2" align="center" sx={{ fontSize: "17px", fontWeight:400 }}>
            {seoH2tag}
          </Typography>
        </Box>
      </Container>
      {showTopproduct !== false && featureparts.length > 0 ? (
        <Paper elevation={0} sx={{ mt: 2, mb: 2, py: 3 }}>
          <Container maxWidth={"lg"}>
            <Box sx={{ width: "100%", margin: "10px 0" }}>
              <Stack direction="row" alignItems="center" spacing={5}>
                <Typography variant="h4">
                  {featuredpartsSeoTitle}
                  <Divider
                    sx={{ bgcolor: "primary.main" }}
                    className="title-divider"
                  />
                </Typography>
                {featureparts.length >= 4 ? (
                  <Button
                    variant="outlined"
                    endIcon={<EastIcon />}
                    component={Link}
                    to="/FeatureParts"
                  >
                    View More
                  </Button>
                ) : null}
              </Stack>
              <Box
                sx={{
                  mt: 3,
                  backgroundColor: "rgb(240, 240, 240)",
                  px: 3,
                  py: 3,
                }}
              >
                <ProductGrid home={true} _product={featureparts} />
              </Box>
            </Box>
          </Container>
        </Paper>
      ) : null}

      {showFeatureparts !== false && product.length > 0 ? (
        <Paper elevation={0} sx={{ mt: 2, mb: 2, py: 3 }}>
          <Container maxWidth={"lg"}>
            <Box sx={{ width: "100%", margin: "10px 0" }}>
              <Stack direction="row" alignItems="center" spacing={5}>
                <Typography variant="h4">
                  {topproductSeoTitle}
                  <Divider
                    sx={{ bgcolor: "primary.main" }}
                    className="title-divider"
                  />
                </Typography>
                {product.length >= 6 ? (
                  <Button
                    variant="outlined"
                    endIcon={<EastIcon />}
                    // href="/OurProducts"
                    component={Link}
                    to="/TopProducts"
                  >
                    View More
                  </Button>
                ) : null}
              </Stack>

              <Box
                sx={{
                  mt: 3,
                  backgroundColor: "rgb(240, 240, 240)",
                  px: 3,
                  py: 3,
                }}
              >
                <ProductList count={7} _product={product} />
              </Box>
            </Box>
          </Container>
        </Paper>
      ) : null}
      {showAboutas !== false ? <AboutUs _seoTitle={aboutusSeoTitle} /> : null}

      {showbrands !== false ? (
        <Brand
          _brand={brands}
          _seoTitle={brandSeoTitle}
          microsite_Information={props.micrositeInfo}
        />
      ) : null}
      {showTestimonial !== false ? (
        <Testimonial
          _testimonial={testimonial}
          _seoTitle={testimonialSeoTitle}
        />
      ) : null}

      {showvidoes !== false && videos.length > 0 ? (
        <Videos _videos={videos} _seoTitle={vieosSeoTitle} />
      ) : null}

      <Box sx={{ pt: 4, pb: 4, backgroundColor: seo_colorcode }}>
        <Container maxWidth={"lg"}>
          <Typography variant="h3" pb={2} sx={{ fontSize: "25px" }}>
            {seoHeading}
          </Typography>

          <Typography variant="subtitle2" pb={2}>
            {seoSubheading}
          </Typography>
          <Typography variant="body2">{seoDescription}</Typography>
        </Container>
      </Box>
    </div>
  );
}
export default memo(Home);
