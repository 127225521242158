import { Box, Button, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Slider from "react-slick";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import holsetbanner from "../../../Images/centralturbos/holset-banner-img.png";
import Banner2 from "../../../Images/centralturbos/banner2.jpg";
import Banner3 from "../../../Images/centralturbos/banner3.jpg";
import holset from "../../../Images/centralturbos/holset.png";
import "../../../Css/centralturbos.style.css";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Link } from "react-router-dom";

function SampleNextArrow(props: any) {
  const { style, onClick } = props;
  return (
    <Box className="ct-holset-slider-arrow-left" onClick={onClick}>
        <KeyboardArrowLeftIcon />
    </Box>
    // <Button
    //   variant="contained"
    //   className=""
    //   style={{
    //     ...style,
    //     display: "block",
    //     bottom: "6%",
    //     padding: "6px",
    //     position: "absolute",
    //     left: "5%",
    //     zIndex: "99",
    //     transform: "translate(0, -50%)",
    //     minWidth: "auto",
    //     lineHeight: 1,
    //   }}
    //   onClick={onClick}
    // >
    //   <KeyboardArrowRightOutlinedIcon sx={{ lineHeight: 1 }} />
    // </Button>
  );
}

function SamplePrevArrow(props: any) {
  const { style, onClick } = props;
  return (
    <Box className="ct-holset-slider-arrow-right" onClick={onClick}>
      <KeyboardArrowRightIcon />
    </Box>
    // <Button
    //   variant="contained"
    //   style={{
    //     ...style,
    //     display: "block",
    //     bottom: "6%",
    //     padding: "6px",
    //     position: "absolute",
    //     left: "8%",
    //     zIndex: "99",
    //     transform: "translate(0, -50%)",
    //     minWidth: "auto",
    //     lineHeight: 1,
    //   }}
    //   onClick={onClick}
    // >
    //   <KeyboardArrowLeftOutlinedIcon sx={{ lineHeight: 1 }} />
    // </Button>
  );
}


const settings = {
  dots: false,
  fade: true,
  className: "bannerslider",
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  cssEase: "linear",
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};






export default function CentralTurbosHolsetSale() {
    return(
      <div>
          <Box className="central-turbos-holset-sec">
          <Slider {...settings}>
          
              <div>
                <Box sx={{backgroundImage:`url(${holsetbanner})`}} className="central-turbos-holset-banner">
                  <Container>
                      <div className="central-turbos-holset-content">
                        <img src={holset} alt="" />
                        <Typography color="primary.contrastText" variant="h3" fontSize="35px" sx={{mb:2}}>HOLSET SALE</Typography>
                        <Typography color="primary.contrastText" variant="body2" >Original Holset turbochargers and parts are built to meet or
                        exceed your engine’s original specifications for performance,
                        reliability and durability in a demanding environment.</Typography>
                        <Button variant="contained" sx={{mt:2}}  to="/PartSearch"
                              component={Link} className="central-turbos-button">Shop Now</Button>
                      </div>
                  </Container>
                </Box>
                
              </div>
              {/* <div>
                <Box sx={{backgroundImage:`url(${holsetbanner})`}} className="central-turbos-holset-banner">
                  <Container>
                      <div className="central-turbos-holset-content">
                        <img src={holset} alt="" />
                        <Typography color="primary.contrastText" variant="h3" fontSize="35px" sx={{mb:2}}>HOLSET SALE</Typography>
                        <Typography color="primary.contrastText" variant="body2" >Original Holset turbochargers and parts are built to meet or
                        exceed your engine’s original specifications for performance,
                        reliability and durability in a demanding environment.</Typography>
                        <Button variant="contained" sx={{mt:2}}  to="/PartSearch" component={Link} className="central-turbos-button">Shop Now</Button>
                      </div>
                  </Container>
                </Box>
                
              </div> */}

          </Slider>
      </Box>
      </div>
    )
}