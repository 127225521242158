import * as React from "react";
import { Tabs, Container, Grid, Paper } from "@mui/material";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { useFAQResults } from "../APIHooks/useGetData";
import {
  GetData,
  Get_Data,
  GetauthHeader,
  GeteMailRes,
} from "../API/GetDataFromApi";
import { useState, useEffect } from "react";

import "../Css/faq.style.css";
import { useSelector } from "react-redux";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


export default function Faq() {
  interface IFAQTopic {
    topics: string;
    topicId: number;
  }
  interface IFAQQandA {
    question: string;
    answer: string;
    topicId: number;
  }
  const [value, setValue] = React.useState(0);
  const [data, setDetailsItems] = useState<IFAQQandA[]>([]);
  const [data1, setHeaderItems] = useState<IFAQTopic[]>([]);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  var raw = JSON.stringify({
    action: "ms_FAQPage",
    parray: ["MicrositeId", EnvData?.REACT_APP_MICROSITEID, "Userid", "1"],
  });

  // const [data, data1] = useFAQResults<IFAQTopic[], IFAQQandA[]>(process.env.REACT_APP_APIURL + '/api/Microsite/getDatas_readOnly', requestOptions, [], []);
  useEffect(() => {
    (async () => {
      Get_Data(EnvData?.REACT_APP_APIURL + "/microsite-api-v1/get-faq", raw)
        .then((results?) => {
         console.log(results.data, " res from FAQ");
          setHeaderItems(results.data.topicList);
          setDetailsItems(results.data.questionAnswers);
        })
        .catch((err) => {
          console.error(err);
          //   localStorage.removeItem("user");
        });
    })();
  }, []);
  return (
    <div>
      <Paper elevation={0} sx={{ mt: 2, mb: 1, py: 3 }}>
        <Container sx={{ width: "100%" }}>
          {/* <Typography variant="h1" color="primary" align="center">
            H1 Tag Content
          </Typography>
          <Typography variant="body1" pb={3} align="center" sx={{fontSize:'17px'}}>
            H2 Tag Content
          </Typography> */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3}>
              <Box>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  orientation="vertical"
                  className="custom-tab-vertical"
                >
                  {data1 &&
                    data1.map((elem, index) => (
                      <Tab
                      key={index}
                        label={elem.topics}
                        {...a11yProps(elem.topicId)}
                        className="custom-tab-items"
                      />
                    ))}
                </Tabs>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              {data1 &&
                data1.map((elem, index) => (
                  <TabPanel value={value} index={index}>
                    {data &&
                      data.map((elem1, index) => (
                        <>
                          {elem.topicId === elem1.topicId ? (
                            <Box sx={{ mb: 3 }} key={index}>
                              <Typography variant="h5">
                                {elem1.question}
                              </Typography>
                              {/* <p>{elem1.answer}</p> */}
                              <p
                    
                    dangerouslySetInnerHTML={{
                      __html: elem1.answer,
                    }}
                  ></p>
                            </Box>
                          ) : null}
                        </>
                      ))}
                  </TabPanel>
                ))}
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </div>
  );
}
