
import { GetData } from "../../API/GetDataFromApi";

import { EnvironmentSettings } from "../../Types/MicrositeType";


const  FilterApiFetch = (raw:any , EnvData:EnvironmentSettings):Promise<any> =>  {


   return GetData(
      EnvData?.REACT_APP_APIURL +
        "/microsite-api-v1/get-part-search-filters",
        raw
    )
    .then((res:any)=>{
     return res
    })

    .catch ((err:any)=>{
      return err
    })

  
};

export default FilterApiFetch;
