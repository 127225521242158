import React from "react";
import {
  EXTENDER_BRAND_FILTER,
  EXTENDER_CROSSREFERENCE_FILTER,
  DESCRIPTION_FILTER,
  BRAND_FILTER,
  CATEGORY_FILTER,
} from "../Redux/ReduxConstants";
import { useDispatch } from "react-redux";
import {
  addCategoryfilter,
  addDesfilter,
  addBrandfilter,
  addApplicationWithValue,
  addAttributeWithValue,
  clearalllApplicationWithValue,
  clearallAttributeWithValue,
  addExBrandfilter,
  addExCrossReffilter,
  addSearchtext,
  addVSyear,
  addVSmake,
  addVSmodel,
  addVSengine,
  trigger,
  ClearApplicationfilters,
  clearattributefilters,
  clearDisplayText,
  clearCategorySelection,
  clearBrandSelection,
  clearDescriptionSelection,
  clearMakeSelection,
  clearYearSelection,
  clearModelSelection,
  clearEngineSelection,
  addVehicleSearchFilterExists,
  clearPartStatusFilter,
} from "../Redux/Counterslice";

export const useResetdata = () => {
  const dispatch = useDispatch();

  let yeartype = {
    YearId: "",
    YearName: "",
  };
  let Maketype = {
    MakeId: "",
    MakeName: "",
  };
  let modeltype = {
    ModelId: "",
    ModelName: "",
  };

  let enginetype = {
    EngineId: "",
    EngineName: "",
  };

  let Catetype = {
    type: CATEGORY_FILTER,
    categoryId: "",
    categoryName: "",
  };
  let Desctype = {
    type: DESCRIPTION_FILTER,
    descriptionId: "",
    desDescriptionName: "",
  };
  let Brandtype = {
    type: BRAND_FILTER,
    brandId: "",
    brandName: " ",
  };
  let extbrandtype = {
    type: EXTENDER_BRAND_FILTER,
    brandId: "",
    brandName: "",
  };
  let excrossreftype = {
    type: EXTENDER_CROSSREFERENCE_FILTER,
    CompetitorId: "",
    CompetitorpartNumber: "",
  };

  let inpVal = "";

  const ResetreduxData = () => {
    dispatch(addCategoryfilter(Catetype));
    dispatch(addDesfilter(Desctype));
    dispatch(addBrandfilter(Brandtype));
    dispatch(addExBrandfilter(extbrandtype));
    dispatch(addExCrossReffilter(excrossreftype));
    dispatch(addSearchtext(inpVal));
    dispatch(addVSyear(yeartype));
    dispatch(addVSmake(Maketype));
    dispatch(addVSmodel(modeltype));
    dispatch(addVSengine(enginetype));
    dispatch(clearallAttributeWithValue());
    dispatch(clearalllApplicationWithValue());
    dispatch(ClearApplicationfilters([]));
    dispatch(clearattributefilters([]));
    dispatch(clearDisplayText());
    dispatch(clearCategorySelection());
    dispatch(clearBrandSelection());
    dispatch(clearDescriptionSelection());
    dispatch(clearMakeSelection());
    dispatch(clearYearSelection());
    dispatch(clearModelSelection());
    dispatch(clearEngineSelection());
    dispatch(addVehicleSearchFilterExists(true));
    dispatch(clearPartStatusFilter());
  };

  return {
    ResetreduxData,
  };
};

export const useResetGeneralSearch = () => {
  const dispatch = useDispatch();

  let Catetype = {
    type: CATEGORY_FILTER,
    categoryId: "",
    categoryName: "",
  };
  let Desctype = {
    type: DESCRIPTION_FILTER,
    descriptionId: "",
    desDescriptionName: "",
  };
  let Brandtype = {
    type: BRAND_FILTER,
    brandId: "",
    brandName: " ",
  };

  const ResetreduxData = () => {
    dispatch(addCategoryfilter(Catetype));
    dispatch(addDesfilter(Desctype));
    dispatch(addBrandfilter(Brandtype));
    dispatch(clearallAttributeWithValue());
    dispatch(clearattributefilters([]));
    dispatch(clearCategorySelection());
    dispatch(clearBrandSelection());
    dispatch(clearDescriptionSelection());
    dispatch(addVehicleSearchFilterExists(true));
  };
  return {
    ResetreduxData,
  };
};

export const useResetVehicleSearch = () => {
  const dispatch = useDispatch();

  let yeartype = {
    YearId: "",
    YearName: "",
  };
  let Maketype = {
    MakeId: "",
    MakeName: "",
  };
  let modeltype = {
    ModelId: "",
    ModelName: "",
  };

  let enginetype = {
    EngineId: "",
    EngineName: "",
  };

  const ResetreduxData = () => {
    dispatch(addVSyear(yeartype));
    dispatch(addVSmake(Maketype));
    dispatch(addVSmodel(modeltype));
    dispatch(addVSengine(enginetype));
    dispatch(clearalllApplicationWithValue());
    dispatch(ClearApplicationfilters([]));
    dispatch(clearMakeSelection());
    dispatch(clearYearSelection());
    dispatch(clearModelSelection());
    dispatch(clearEngineSelection());
    dispatch(addVehicleSearchFilterExists(true));
  };
  return {
    ResetreduxData,
  };
};
