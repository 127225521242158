


export default function Loader() {
  let logo = localStorage.getItem("logo");

  return (
    <div className="loader-wrap">
      <div className="loaded">
        <img
          src={logo !== null && logo !== "" ? logo : "https://cadencestoragecdn.azureedge.net/cadencellc/Microsite/GOCADENCE/MenuLogo/GOCADENCE2507221113Cadence.jpg"}
           alt="logo"
        />
        <div className="loader-circle">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
