import { Box, Grid, Link, Typography } from "@mui/material";
import Brand1 from "../../../Images/centralturbos/brand1.png";
import Brand2 from "../../../Images/centralturbos/brand2.png";
import Brand3 from "../../../Images/centralturbos/brand3.png";
import Brand4 from "../../../Images/centralturbos/brand4.png";
import Brand5 from "../../../Images/centralturbos/brand5.png";
import Brand6 from "../../../Images/centralturbos/brand6.png";
import Brand7 from "../../../Images/centralturbos/brand7.png";
import Brand8 from "../../../Images/centralturbos/brand8.png";
import Brand9 from "../../../Images/centralturbos/brand9.png";
import Brand10 from "../../../Images/centralturbos/brand10.png";

import { IBrandProps, IMicrositeProps } from "../../../Types/MicrositeType";
import { BRAND_FILTER } from "../../../Redux/ReduxConstants";

import { useSelector } from "react-redux";
import { addBrandSelection, addBrandfilter } from "../../../Redux/Counterslice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useResetdata } from "../../../Redux/Resetdata";

export default function CentralTurbosBrand(props: {
  _brand: IBrandProps[];
  _seoTitle: string;
  microsite_Information: IMicrositeProps;
}) {
  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  let url = EnvData?.REACT_APP_CDNURL + "/BrandImages/";

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { ResetreduxData } = useResetdata();
  const selectbrand = (brandname: string, brandId: any) => {
    ResetreduxData();
    let Brandtype = {
      type: BRAND_FILTER,
      brandId: brandId,
      brandName: brandname,
    };
    dispatch(addBrandfilter(Brandtype));
    dispatch(addBrandSelection(false));
    navigate("/PartSearch");
  };


  return(
    <div>
      
      <Grid container spacing={3} alignItems="center">
          <Grid item md={4}>
              <Box>
                <Typography variant="h4" className="central-turbos-title" sx={{textAlign:"left"}}> {props._seoTitle}</Typography>
                <Typography variant="body2" className="central-turbos-brand-text">Browse our wide selection of top-quality OEM and aftermarket diesel products. Competitive prices, fast shipping, and exceptional customer service. As Franchise and Master Distributors for some of the largest and most prominent brands and manufacturers in the industry, you can always count on Central Turbos to find the part you need!</Typography>
              </Box>
          </Grid>
          <Grid item md={8}>
            <Grid container spacing={2}>
            {props.microsite_Information.showBrandNameOrLogo === "Logo" ? ( <>
            
           
              {props._brand.map((elem, index) => (
                   <Grid item md={3}>
                 <Link
                 
                 key={index}
                 onClick={() => selectbrand(elem.brandName, elem.brandId)}
                 // href={elem.brandUrl !== "" ? elem.brandUrl : ""}
               >
                  <Box className="central-turbos-brand-img-div">
                  <img  src={
                    elem.imageAzureFlag === true
                      ? url + elem.imageName
                      : EnvData?.REACT_APP_NOIMAGEURL
                  }
                  alt="Logo" />
                  </Box>
                </Link>
                </Grid>
                
              ) ) }
                 
            
            </>) : (<>
              {props._brand.map((elem, index) => (
                <Grid item md={3}>
                    <Link
                  // className="brand-text"
                  key={index}
                  // href={elem.brandUrl !== "" ? elem.brandUrl : ""}
                  onClick={() => selectbrand(elem.brandName, elem.brandId)}
                >
                  {elem.brandName}
                </Link>
                </Grid>
              
              ))}
            </>) }
             
            </Grid>
          </Grid>
      </Grid>
    </div>
  )
}

