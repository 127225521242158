import {
  Typography,
  Container,
  Paper,
  Box,
  styled,
  InputBase,
  Button,
  Grid,
  Stack,
} from "@mui/material";
import { useState, useEffect } from "react";
import { Get_Data } from "../API/GetDataFromApi";
import Loader from "../Components/Loader";

import TechBulletinsjpg from "../Images/techbulletin-jpg-file.svg";
import TechBulletinspdf from "../Images/techbulletin-pdf-file.svg";
import TechBulletinsdoc from "../Images/techbulletin-doc-file.svg";
import TechBulletinstxt from "../Images/techbulletin-txt-file.svg";
import TechBulletinsppt from "../Images/techbulletin-ppt-file.svg";
import TechBulletinsvideo from "../Images/techbulletin-video.svg";
import TechBulletinswebsite from "../Images/techbulletin-link-file.svg";
import TechBulletinsxls from "../Images/techbulletin-xls-file.svg";
import TechBulletinszip from "../Images/techbulletin-zip-file.svg";
import TechBulletinshtml from "../Images/techbulletin-html-file.svg";

import SearchIcon from "@mui/icons-material/Search";

import "../Css/tech-bulletins.style.css";
import { useSelector } from "react-redux";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  // margin: "10px 0",
  //borderRadius: '12px',
  color: "#000",
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
  },
  border: "1px solid #ccc",
  borderRadius: 5,
  marginLeft: "0px!important",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(2),
    width: "100%",
    display: "flex",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 0),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  right: "0",
  top: "0",
  "& .MuiButton-contained": {
    backgroundColor: theme.palette.primary.main,
    // color: theme.palette.primary.light,
    borderRadius: 0,
    margin: 2,
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 5, 1, 2),
    paddingright: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
}));

export default function TechBulletins() {
  interface IgetDataTechBulletins {
    fileNameText: string;
    fileNameNavigateUrl: string;
    imageUrl: string;
    videoUrl: string;
    fileNameToolTip: string;
    iconURL : string;
  }

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setsearchText] = useState("");
  const [data, setDetailsItems] = useState<IgetDataTechBulletins[]>([]);
  let resultshowornot = 1;

  let url =
    EnvData?.REACT_APP_APIURL + "/microsite-api-v1/get-techbulletins";
  if (searchText !== "") {
    url = url + "?Filename=" + searchText;
  }

  const FindTechBulletins = async () => {
    setLoading(true);
    Get_Data(url, "")
      .then((results?) => {
        resultshowornot = 1;
        if (results.data.techBulletinsDtl == null) {
          alert("No Records Found");
          resultshowornot = 0;
          setDetailsItems([]);
        } else {
        // console.log(results.data.techBulletinsDtl.length, "Total Rows Count");
          setDetailsItems(results.data.techBulletinsDtl);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    setLoading(false);
  };

  const ResetFilter = async () => {
    setLoading(true);   
    setsearchText(""); 
    resultshowornot = 0;
    setDetailsItems([]);        
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      Get_Data(url, "")
        .then((results?) => {
        // console.log(results.data.techBulletinsDtl.length, "Total Rows Count");
          setDetailsItems(results.data.techBulletinsDtl);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
        });
    })();
  }, []);

  return (
    <>
      {loading !== true ? (
        <>
          {" "}
          <div>
            <Paper elevation={0} sx={{ mt: 2, mb: 1, py: 3 }}>
              <Container>
                <Stack direction="row" spacing={2} sx={{mb: 4}}>
                <Box className="tech-bulletin-searchbox">
                  <Search>
                    <StyledInputBase
                      placeholder="FileName/Comments/Tag"
                      inputProps={{ "aria-label": "Document Search" }}
                      onChange={(e) => {
                        setsearchText(e.target.value);
                      }}
                      value={searchText}
                    />
                    <SearchIconWrapper></SearchIconWrapper>
                    <Button
                      variant="contained"
                      className="footer-newsletter"
                      style={{
                        minWidth: "40px",
                        padding: "9px 8px",
                        marginRight: "-1px",
                        borderRadius: "2px 5px 5px 0",
                      }}
                      onClick={() => FindTechBulletins()}
                    >
                      <SearchIcon />
                    </Button>                    
                  </Search>
                </Box>
                <Button
                      variant="contained"
                      
                      onClick={() => ResetFilter()}
                    >      
                    Reset                
                    </Button>
                </Stack>
                <Grid container spacing={3} mb={3}>
                  {resultshowornot === 0 ? null : (
                    <>
                      {data &&
                        data.map((elem, index) => (
                          <Grid item xs={12} md={3} key={index}>
                            <Box className="tech-bulletin-box">
                             
                                <div className="tech-bulletin-box-img-sec">
                                  <div className="tech-bulletin-box-img">
                                    {elem.iconURL != "" ? (
                                    <>
                                      <img
                                          src={elem.iconURL}
                                          loading="lazy"
                                        />
                                    </>
                                    ) : elem.imageUrl == "images/doc_pdf.png" ? (
                                      <>
                                        <img
                                          src={TechBulletinspdf}
                                          loading="lazy"
                                        />
                                      </>
                                    ) : elem.imageUrl == "images/doc_jpg.png" ? (
                                      <>
                                        <img
                                          src={TechBulletinsjpg}
                                          loading="lazy"
                                        />
                                      </>
                                    ) : elem.imageUrl == "images/doc_doc.png" ? (
                                      <>
                                        <img
                                          src={TechBulletinsdoc}
                                          loading="lazy"
                                        />
                                      </>
                                    ) : elem.imageUrl == "images/doc_pptx.png" ? (
                                      <>
                                        <img
                                          src={TechBulletinsppt}
                                          loading="lazy"
                                        />
                                      </>
                                    ) : elem.imageUrl == "images/doc_xls.png" ? (
                                      <>
                                        <img
                                          src={TechBulletinsxls}
                                          loading="lazy"
                                        />
                                      </>
                                    ) : elem.imageUrl == "images/doc_zip.png" ? (
                                      <>
                                        <img
                                          src={TechBulletinszip}
                                          loading="lazy"
                                        />
                                      </>
                                    ) : elem.imageUrl == "images/doc_html.png" ? (
                                      <>
                                        <img
                                          src={TechBulletinshtml}
                                          loading="lazy"
                                        />
                                      </>
                                    ) : elem.imageUrl ==
                                      "images/doc_website.png" ? (
                                      <>
                                        <img
                                          src={TechBulletinswebsite}
                                          loading="lazy"
                                        />
                                      </>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="tech-bulletin-box-content">
                                  <Typography
                                    onClick={() => {
                                      window.open(
                                        elem.fileNameNavigateUrl,
                                        "_blank"
                                      );
                                    }}
                                    // color="secondary"
                                  >
                                    {elem.fileNameText}
                                  </Typography>
                                </div>
                             
                            </Box>
                          </Grid>
                        ))}
                    </>
                  )}
                </Grid>
              </Container>
            </Paper>
          </div>
        </>
      ) : (
        <>
          <Loader />
        </>
      )}
    </>
  );
}
