import { Box, Container, Grid, Typography, Paper, Divider, Button } from "@mui/material";
import HomeAbout from "../../../Images/centralturbos/home-about.png"
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { GetData, Get_Data } from "../../../API/GetDataFromApi";
import { IAboutUs } from "../../../Types/MicrositeType";
import Loader from "../../../Components/Loader";
import { Link } from "react-router-dom";



export default function CentralTurbosAbout(props: { _seoTitle: string }) {
  const [aboutus, setData] = useState<IAboutUs[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
 

  let ImgURL = "";
  let AboutusAltText = "";
  let Content = "";

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);
  useEffect(() => {

    (async () => {
     
      setLoading(true)
      
      var raw = JSON.stringify({
        parray: ["MicrositeId", EnvData?.REACT_APP_MICROSITEID],
      });
      Get_Data(EnvData?.REACT_APP_APIURL+"/microsite-api-v1/get-about-us", raw)
        .then((results?) => {
          // console.log(results ," res from About us")
          setData(results.data);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          //   localStorage.removeItem("user");
        });
    })();
  

}, []);

{
  aboutus &&
    aboutus.map(
      (elem1) => (
        (ImgURL = elem1.imgURL),
        (AboutusAltText = elem1.aboutusAltText),
        (Content = elem1.content)
      )
    );
}

  return(
    <div>
      <Paper elevation={0} sx={{py:8, background:'#25282E', borderRadius:'0'}} className="central-turbos-homeabout-paper" >
            <Container maxWidth="lg">
              <Grid container spacing={3} alignItems="center">
                <Grid item md={5}>
                  <div className="central-turbos-homeabout-img">
                  <img
                              src={ImgURL}
                              alt={AboutusAltText}
                              loading="lazy"
                            />
                  </div>
                  
                </Grid>
                <Grid item>
                
                  <Box className="central-turbos-vertical-line"></Box>            
                </Grid>
                <Grid item md={6}>
                    <Typography variant="h4" color="primary.contrastText" className="central-turbos-title-white"> {props._seoTitle}</Typography>
                    <Typography variant="body2" color="primary.contrastText" className="central-turbos-homeabout-content"  dangerouslySetInnerHTML={{
                            __html: Content,
                          }}></Typography>
                    {/* <Typography variant="body2" color="primary.contrastText" sx={{mt:2}}>At Central Turbos, we’re not just about selling products; we’re committed to providing exceptional customer service. Our expert team is always on hand to offer guidance and support, helping you find the perfect solution for your turbocharger and diesel engine requirements.</Typography> */}
                  <Button to="/AboutUs"  component={Link}variant="contained" className="central-turbos-button" sx={{mt:2}}>Read More</Button>
                 
                </Grid>
              </Grid>
            </Container>
          </Paper>  
    </div>
  )

}