import * as React from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Container,
  Divider,
  Paper,
  Stack,
  Box,
  Grid,
  Typography,
  List,

  ListItem,
  ListItemText,
  ListItemButton,
  useMediaQuery,
} from "@mui/material";
import logo from "../../staticimages/torqstop.png";
import { LeftMenu } from "./LeftMenu";
import { IMicrositeProps, IcustomMenu, IgetProductDetails } from "../../Types/MicrositeType";
import { useEffect, useState } from "react";
import { Get_Data } from "../../API/GetDataFromApi";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
// import CustomStaticMenu from "./CustomMenuStatic";

export const StaticMenuHeader = (props: { _settings: IMicrositeProps, _contact?: string }) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [menuData, setMenudata] = useState<IcustomMenu[]>([]);
  
  const [LoginLink, setLoginLink] = useState<any>();
 
  const [anchorpopupEl, setanchorpopupEl] = useState<null | HTMLElement>(null);
  const [productInfo, setproductInfo] = useState<IgetProductDetails[]>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);


  const navigate = useNavigate();
  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env); 
  const handleListItemClick = (
   
    index: number
  ) => {
    // if(index!==4) {
    //   localStorage.setItem('staticcount',String(index))
    // }
    
    setSelectedIndex(index);
  };
  let Loginaddress= EnvData?.REACT_APP_CADENCELOGINURL
  let torquestopCustsomPage ='https://torqstop-ecat.com/CustomPage/1'
  let torquestopCustsomPageNav = 'CustomPage/4'
  let torquestopSALESCustsomPageNav = 'CustomPage/9'
  let torquestopBlogsPageNav = 'CustomPage/11'

  // const string = `/CustomPage/${res.customSubMenuId}?`;

  let _dealerlocation: string = "";

  const url = window.location.href;
  useEffect(()=>{
  
    let endpoint = url.substring(url.lastIndexOf('/') + 1)

    if(endpoint=='') {
      handleListItemClick(0)
    }
    else if(endpoint=='AboutUs' ) {
      handleListItemClick( 1)
    }

    else if(endpoint=='PartSearch') {
      handleListItemClick( 3)
    }

    else if(endpoint=='ContactUs') {
      handleListItemClick( 5)
    }
    else if(endpoint=='1') {
      handleListItemClick( 6)
    }

    else if( endpoint=='9') {
      // console.log(torquestopCustsomPageNav)
      handleListItemClick( 7)
    }
    else if( endpoint=='11') {
      // console.log(torquestopCustsomPageNav)
      handleListItemClick( 8)
    }
    
    else  {
      handleListItemClick( 10) 
    }

  },[url])

  useEffect(() => {
  //  let menucount =localStorage.getItem('staticcount')
  //   const prevCount = parseInt(menucount?menucount:'');
  //   if (!isNaN(prevCount)) {
  //     setSelectedIndex(prevCount);
  //   }
    
    (async () => {

      Get_Data(EnvData?.REACT_APP_APIURL + "/microsite-api-v1/get-custom-menu")
        .then((results) => {
          //console.log("ress" , results)

          // if (totalMenuCount < 110) {
            setMenudata(results.data);
          // }
        })
        .catch((error) => {
          console.log("Error in " + error);
          console.log("custom menu" + error);
        });
    })();

    (async () => {
      Get_Data(EnvData?.REACT_APP_APIURL + "/microsite-api-v1/get-top-products")
        .then((results) => {
          setproductInfo(results.data);
        })
        .catch((error) => {
          console.log("Error in " + error);
        });
    })();
  }, []);


  function NavigateTo(path:string) {
    navigate(path);
   
    // setSelectedIndex(0);
    // localStorage.setItem('staticcount',String(0))
  }

  const isScreenBelow900 = useMediaQuery("(max-width: 950px)");

  // const productmenuClickpopup = (event: React.MouseEvent<HTMLElement>) => {
  //   setanchorpopupEl(event.currentTarget);
  // };
  // const openpopup = Boolean(anchorpopupEl);
  // const productmenuClosepopup = () => {
  //   setanchorpopupEl(null);
  // };

  let totalMenuCount = 7;
  let MaximumLength = 105;

  if (props._settings.topProducts) {
    totalMenuCount += 11;
  }
  if (props._settings.services) {
    totalMenuCount += 11;
  }
  if (props._settings.showinFooter) {
    totalMenuCount += 13;
  }
  if (props._settings.showTechBulletins) {
    totalMenuCount += 17;
  }
  if (props._settings.dealerLocation) {
    totalMenuCount += 18;
  }

  if (props._settings.partsSearchMenu) {
    totalMenuCount += 14;
  }
  if (props._settings.testimonial) {
    totalMenuCount += 15;
  }
  if (props._settings.aboutUs) {
    totalMenuCount += 11;
  }
  if (EnvData?.REACT_APP_STATICPAGE == "Yes") {
    totalMenuCount += 20;
  }
  return (
    <div>
      <Paper elevation={0} component="nav" className="header-section">
        <Container maxWidth="lg">
          <Toolbar className="torqstop-main-menu-toolbar">
            <LeftMenu _settings={props._settings} _custommenu={menuData}  />
            <a className="torqstop-header-logo">
              <img src={logo} alt="logo"  onClick={()=>{
                NavigateTo("/")
                handleListItemClick(0)
                }} />
            </a>
            <Box className="torqstop-main-menu-center">
              {/* {navItems.map((item) => (
                <Button component={Link} className="nav-menu-link">Home</Button>
                ))} */}
              <List component="nav">
                <ListItemButton
                  selected={selectedIndex === 0}
                  onClick={(event) => {handleListItemClick( 0);
                    NavigateTo("/")
                  }}
                  
                  className="torqstop-nav-menu-link"
                >
                  <ListItemText className="torqstop-nav-menu-link-text" >
                    Home
                  </ListItemText>
                </ListItemButton>
                <ListItemButton
                  selected={selectedIndex === 1}
                  onClick={(event) => {handleListItemClick( 1);
                    NavigateTo("/AboutUs")}}
                  className="torqstop-nav-menu-link"
                >
                  <ListItemText className="torqstop-nav-menu-link-text" onClick={()=>NavigateTo("/AboutUs")}>
                    About Us
                  </ListItemText>
                </ListItemButton>
                <ListItemButton
                // href={torquestopCustsomPage}
                  selected={selectedIndex === 2}
                  onClick={(event) => {
                    handleListItemClick( 2) 
                    NavigateTo(torquestopCustsomPageNav)
                   }}
                  className="torqstop-nav-menu-link"
                  // aria-controls={open ? "menumega" : undefined}
                  // aria-haspopup="true"
                  // aria-expanded={open ? "true" : undefined}
                  // onClick={}
                >
                  <ListItemText className="torqstop-nav-menu-link-text">
                    Products
                  </ListItemText>
                </ListItemButton>
                {/* <Menu
                    id="menumega"
                    open={openpopup}
                    anchorEl={anchorpopupEl}
                    onClose={productmenuClosepopup}
                    MenuListProps={{
                      "aria-labelledby": "menumega-button",
                    }}
                    className="menumega"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <Box
                      display="flex"
                      sx={{ padding: "10px" }}
                      className="menumega-box"
                    >
                      <Grid container spacing={2}>
                        {productInfo.map((res, index) => {
                          const productUrl = `/Products/${res.productName}?`;
                          return (
                            <Grid item md={3} key={index}>
                              <Button
                                to={productUrl}
                                component={Link}
                                onClick={productmenuClosepopup}
                                className="menumega-list"
                              >
                                {res.productName}{" "}
                              </Button>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </Menu> */}

                  {/* <ListItemButton
                // href ={ torquestopCustsomPage}
                  selected={selectedIndex === 6}
                  onClick={(event:any) => {handleListItemClick( 6);
                    // NavigateTo(Loginaddress)
                    NavigateTo("/CustomPage/1");
                  }}
                  
                  className="torqstop-nav-menu-link"
                >
                  <ListItemText className="torqstop-nav-menu-link-text" >
                  Product Highlights
                  </ListItemText>
                </ListItemButton> */}
                <ListItemButton
                  selected={selectedIndex === 3}
                  onClick={(event) => {handleListItemClick( 3);
                    NavigateTo("/PartSearch")}}
                  className="torqstop-nav-menu-link"
                >
                  <ListItemText className="torqstop-nav-menu-link-text" onClick={()=>NavigateTo("/PartSearch")}>
                    Catalog
                  </ListItemText>
                </ListItemButton>

                <ListItemButton
                href ={ Loginaddress}
                  selected={selectedIndex === 4}
                  onClick={(event:any) => {handleListItemClick( 4);
                    // NavigateTo(Loginaddress)
                  }}
                  
                  className="torqstop-nav-menu-link"
                >
                  <ListItemText className="torqstop-nav-menu-link-text" >
                    Distributor Portal
                  </ListItemText>
                </ListItemButton>
                <ListItemButton
                  selected={selectedIndex === 5}
                  onClick={(event) => {handleListItemClick( 5) ;
                    NavigateTo('/ContactUs')}}
                  className="torqstop-nav-menu-link"
                >
                  <ListItemText className="torqstop-nav-menu-link-text"  onClick={()=>NavigateTo('/ContactUs')}>
                    Contact Us
                  </ListItemText>
                </ListItemButton>
                <ListItemButton
                  selected={selectedIndex === 7}
                  // onClick={(event) => {handleListItemClick( 5) ;
                  //   NavigateTo('/ContactUs')}}
                  onClick={()=>
                    {
                      // handleListItemClick(7)
                      NavigateTo(torquestopSALESCustsomPageNav)
                    }
                    }
                  className="torqstop-nav-menu-link"
                >
                  <ListItemText className="torqstop-nav-menu-link-text"  onClick={()=>NavigateTo(torquestopSALESCustsomPageNav)}>
                  Sales & Specials
                  </ListItemText>
                </ListItemButton>
                <ListItemButton
                  selected={selectedIndex === 8}
                  // onClick={(event) => {handleListItemClick( 5) ;
                  //   NavigateTo('/ContactUs')}}
                  onClick={()=>
                    {
                      // handleListItemClick(7)
                      NavigateTo(torquestopBlogsPageNav)
                    }
                    }
                  className="torqstop-nav-menu-link"
                >
                <ListItemText className="torqstop-nav-menu-link-text"  onClick={()=>NavigateTo(torquestopBlogsPageNav)}>
                Blogs
                  </ListItemText>
                </ListItemButton>
                {/* {isScreenBelow900 == false
                ? menuData?.map((elem, index) => {
                  console.log(elem)
                    totalMenuCount += elem.customMenuName.length;

                    if (totalMenuCount > 110) {
                      return null;
                    } else {
                      return (
                        <div key={index}>
                       

                         <CustomStaticMenu
                            _Dealerlocation={_dealerlocation}
                            _menuName={elem.customMenuName}
                            _subMenuArray={elem.subMenus}
                          />
                        </div>
                      );
                    }
                  })
                : null} */}
              </List>
            </Box>
            {/* <Box className='main-menu-right'>
                <Typography sx={{ position: 'relative' }}>
                  <img src={cart} className="cart-icons" alt="logo" />
                  <span className="cartCount">1</span>
                
              </Typography>
              </Box> */}
          </Toolbar>
        </Container>
        <Divider sx={{ bgcolor: "primary.main", padding: "1px" }} />
      </Paper>
    </div>
  );
};
