import React from 'react'
import {
  Typography,
  Container,
  Paper,
  Box,
  Grid,
  Divider,
  Stack,
  Button,
  TextField,
} from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import {
  PartAccessories,
  // AlternativeProduct,
} from "../../Components/Product/ProductList";
import { Link ,useParams, } from "react-router-dom";
import {addPDInfo ,addprevPart} from '../../Redux/Counterslice'
import { useDispatch, useSelector } from "react-redux";
import centralTurboLogo from "../../Images/centralturbos/n_7.png"



const PartAccessoriess = (props:any ) => {

//  console.log(props ," access")

 const dispatch = useDispatch();

 const { partnum } = useParams(); 
 const { productName } = useParams();

 
 let env = (state: any) => state.ENV_SETTINGS;
 const EnvData = useSelector(env);

 let partid = props.partid[0].partId;
 let flag = 'Accessories'

//  const Partaccesprops = {
//   // partid: props[0].partId,
//   flag: 'Accessories',
// };
function storePartNumber() {
  let type = {
    partnumber: props.partid[0].partNumber,
    partflag: flag,
  };
  dispatch(addPDInfo(type));

}


function ViewmoreNav () {
  let nav = `/Category/${partnum}`;

  if (productName !== undefined && productName !== "") {
    nav += `/${productName}`;
  }
  dispatch(addprevPart(nav))
}
const string = `/${flag}/${partid}`
  return (
    <div className="product-border">
    {/* <Divider
      sx={{
        bgcolor: "primary.main",
        padding: "2px",
        borderRadius: "5px 5px 0 0 ",
      }}
    /> */}
    <div className="part-box">
      <Stack
        direction="row"
        alignItems="center"
        spacing={5}
        mb={3}
        className="justify-space"
      >
        <Typography variant="subtitle2" sx={{fontSize:'16px'}}>
          {
            EnvData?.REACT_APP_SHOW_CENTRAL_TURBO == "true" ? (<>
              <div style={{ display: 'flex', alignItems: 'center' , marginRight:'2px' }}>
              <img src={centralTurboLogo} alt="" />
            PART ACCESSORIES
              </div>
           
            </>) : (<>
              PART ACCESSORIES
            </>)
          }
         
          <Divider sx={{ bgcolor: "primary.main"}} className="title-divider" />
        </Typography>
        {props.props.length >=3 ?(<>    <Button
          variant="outlined"
          size="small"
          sx={{ fontSize: "11px" }}
          to={string}
          component={Link}
          endIcon={<EastIcon />}
        onClick={ViewmoreNav}
        >
          View More
        </Button></>):(<></>)}
    
      </Stack>
      {/* <Divider sx={{ my: 2 }} /> */}

      <PartAccessories accessoriesdata={props} triggsetdetails={storePartNumber} />
    </div>
  </div>
  )
}

export default PartAccessoriess