import { useEffect, useState } from "react";
import {
    Grid,
    Box,
    Button,
    Divider,
    Menu,
    Typography,
    Tooltip,
  } from "@mui/material";

const Description = (props:{Fulltext:string , slicedtext:string ,ordersumm?:boolean}) => {

    const [isHovered, setIsHovered] = useState(true);
    const handleMouseEnter = () => {
        setIsHovered(false);
      };
    
      const handleMouseLeave = () => {
        setIsHovered(false);
      };
  return (
    <span
    className={props.ordersumm === true ? "order-box-title" : "part-accessories-title"}
    onMouseEnter={handleMouseEnter}
    // onMouseLeave={handleMouseLeave}
  >
    {/* {elem.partDescriptionName} */}
    {isHovered && props.slicedtext.length>59 ? props.slicedtext+ ' .....' : props.Fulltext}
  </span>
  )
}

export default Description  