import React, { useState, useEffect } from "react";
import { default as Slider, Settings } from "react-slick";
import { Button } from "@mui/material";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../../Css/productdetails.style.css";

export default function ProductSlider(props) {
  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the default context menu behavior
  };

  let images = props.props;

  function SampleNextArrow(props) {
    const { style, onClick } = props;
    return (
      <Button
        variant="text"
        style={{
          ...style,
          display: "block",
          top: "50%",
          padding: "1px",
          position: "absolute",
          right: "0",
          zIndex: "99",
          transform: "translate(0, -50%)",
          minWidth: "auto",
          lineHeight: 1,
          color: "#7c7c7c",
        }}
        onClick={onClick}
      >
        <ArrowForwardIosIcon sx={{ lineHeight: 1, fontSize: "32px" }} />
      </Button>
    );
  }

  function SamplePrevArrow(props) {
    const { style, onClick } = props;
    return (
      <Button
        variant="text"
        style={{
          ...style,
          display: "block",
          top: "50%",
          padding: "1px",
          position: "absolute",
          left: "20px",
          zIndex: "99",
          transform: "translate(0, -50%)",
          minWidth: "auto",
          lineHeight: 1,
          color: "#7c7c7c",
        }}
        onClick={onClick}
      >
        <ArrowBackIosIcon sx={{ lineHeight: 1, fontSize: "32px" }} />
      </Button>
    );
  }

  const [slidernav1, setSliderNav1] = useState(null);
  const [slidernav2, setliderNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setSliderNav1(slider1);
    setliderNav2(slider2);
  });

  const settings1 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: false,
    asNavFor: ".slider-nav",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const settings2 = {
    slidesToShow: images.length,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    swipeToSlide: true,
    focusOnSelect: true,
    //centerPadding: '10px',
    vertical: true,
    verticalSwiping: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: images.length,
          infinite: false,
          verticalSwiping: false,
          vertical: false,
        },
      },
    ],
  };

  return (
    <div onContextMenu={handleContextMenu}>
      <div className="slider-container">
        <div
          className={
            images.length == 1 ? "slider-wrapper-full" : "slider-wrapper"
          }
        >
          <Slider
            {...settings1}
            asNavFor={slidernav2}
            ref={(slider) => setSlider1(slider)}
          >
            {images.map((elem, index) => (
              <div key={index} className="slider-slick-item">
                <img
                  onContextMenu={handleContextMenu}
                  src={elem.assetUrl}
                  loading="lazy"
                />
                <span className="water-mark">
                  {sessionStorage.getItem("WaterMark")}
                </span>
              </div>
            ))}
          </Slider>
        </div>
        <div className="thumbnail-slider-wrap">
          <Slider
            {...settings2}
            asNavFor={slidernav1}
            ref={(slider) => setSlider2(slider)}
          >
            {images.length > 1 &&
              images.map((elem, index) => (
                <div key={index} className="slider-slick-item">
                  <img
                    onContextMenu={handleContextMenu}
                    src={elem.assetUrl}
                    loading="lazy"
                  />
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}
