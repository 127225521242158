import {
  Typography,
  Container,
  Paper,
  Box,
  Grid,
  Divider,
  Link,
  Modal,
  IconButton,
} from "@mui/material";

// import { useGetData } from '../APIHooks/useGetData';
import { useState, useEffect } from "react";
import Serviceimg1 from "../Images/dls.jpg";
import "../Css/service.style.css";
import { GetData, Get_Data } from "../API/GetDataFromApi";
import { IgetDataService } from "../Types/MicrositeType";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Loader from "../Components/Loader";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 5,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function Services() {
  const [data, setData] = useState<IgetDataService[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);


  var raw = JSON.stringify({
    action: "ms_ServicePage", 
  });

  // const [data] = useGetData<IgetDataService[]>(process.env.REACT_APP_APIURL + '/api/Microsite/getDatas_readOnly', requestOptions, []);
  useEffect(() => {
   
    (async () => {
      setLoading(true)
      Get_Data(
        EnvData?.REACT_APP_APIURL + "/microsite-api-v1/get-services-info",
        raw
      )
        .then((results?) => {
          // console.log(results, " res from service");
          setData(results.data);
          setLoading(false)
        })
        .catch((err) => {
          console.error(err);
        });
    })();
  }, []);

  const [showPopup, setshowPopup] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  let _videoUrl: string = "";

  const openLightbox = (vUrl: string) => {
  // console.log(vUrl);
    if (vUrl !== "") {
      _videoUrl = vUrl;
    }
    setVideoUrl(_videoUrl);
    setshowPopup(true);
  };

  const closeLightbox = () => {
    setshowPopup(false);
  };

  return (
    <>
    
    {loading !== true?(<> <div>
      <Paper elevation={0} sx={{ mt: 2, mb: 1, py: 3 }}>
        <Container>
          {/* <Typography variant="h1" align="center">
             H1 Tag Content
            </Typography>
          <Typography variant="body1" pb={3} align="center">
                H2 Tag Content
          </Typography> */}
          <div className="product-right-border">
              <Typography variant="h4" sx={{ pb: 2 }}>
                Services
                <Divider
                  sx={{ bgcolor: "primary.main" }}
                  className="title-divider"
                />
              </Typography>
            </div>
          {data &&
            data.map((elem, index) => (
              <Box key={index} sx={{ mb: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <div className="service-img-box">
                      <img src={elem.imageUrl} alt="dls" loading="lazy" />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <div>
                      <div className="product-right-border">
                        <Typography variant="h4">
                          {elem.shortDescription}
                          <Divider
                            sx={{ bgcolor: "primary.main" }}
                            className="title-divider"
                          />
                        </Typography>
                      </div>
                      <p>
                        {elem.longDescription}
                        {elem.serviceVideo.length > 0 ? (
                          <Link onClick={() => openLightbox(elem.serviceVideo)} sx={{ml:1}}>
                            The {elem.serviceTypeDescription} Video
                          </Link>
                        ) : null}
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            ))}

          <Dialog
            onClose={closeLightbox}
            open={showPopup}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            fullWidth={true}
            maxWidth="sm"
          >
            <DialogTitle>
            Videos
            <IconButton
                    className="brawerclosebutton"
                    onClick={closeLightbox}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseRoundedIcon />
                  </IconButton>
            
              </DialogTitle>
            <DialogContent>
            
              <Box>
                
                <iframe
                  width="100%"
                  height="300"
                  src={videoUrl}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </Box>
            </DialogContent>
          </Dialog>
        </Container>
      </Paper>
    </div></>):(<>
      <Loader/>
      </>)}
    </>
   
   
  );
}
