import { Box, Button, IconButton, Link, Menu, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Slider from "react-slick";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import featuredproduct1 from "../../../Images/centralturbos/featured-product1.png";
import featuredproduct2 from "../../../Images/centralturbos/featured-product2.png";
import featuredproduct3 from "../../../Images/centralturbos/featured-product3.png";
import featuredproduct4 from "../../../Images/centralturbos/featured-product4.png";
import "../../../Css/centralturbos.style.css";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useState, useContext, memo, useEffect } from "react";
import {
  IFeatureproductProps,
  ISpecProps,
  ICrossRefProps,
} from "../../../Types/MicrositeType";
import {
  GetData,
  GetADDToCartData,
  GetSpecificationData,
  GetCrossreference,
} from "../../../API/GetDataFromApi";
import { addPDInfo, clearPDInfo } from "../../../Redux/Counterslice";
import { Navigate, Link as RouterLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useResetdata } from "../../../Redux/Resetdata";
import eventEmitter from "../../PartSearchTest/eventEmmiter";
import ProductLoader from "../../ProductLoader";
import { useNavigate } from "react-router-dom";
import useGetauthHeader from "../../../APIHooks/useGetAuthHeader";
import { ICart, ICartContextAction } from "../../../Types/CartType";
import { CartContext } from "../../Cart/CartContext";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";




export default function CentralTurbosFeaturedParts() {
  return(
    <div>
        <Box>

        </Box>
    </div>
  )

}



function SampleNextArrow(props: any) {
  const { style, onClick } = props;
  return (
    <Box className="slider-arrow-left" onClick={onClick}>
        <KeyboardArrowRightIcon />
    </Box>
  );
}

function SamplePrevArrow(props: any) {
  const { style, onClick } = props;
  return (
    <Box className="slider-arrow-right" onClick={onClick}>
       <KeyboardArrowLeftIcon />
    </Box>
  );
}


const settings = {
  dots: false,
  infinite: false,
  slidesToShow:4,
  slidesToScroll: 1,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  cssEase: "linear",
  className: "",
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

export function CentralTurbosFeaturedProductSlider(props: {
  _product: IFeatureproductProps[];
  partsearch?: boolean;
  home?: boolean;
  Seo?:boolean
})
 {


  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);
  const selectData = (state: any) => state;
  const data = useSelector(selectData);
  const CustomerId =
    localStorage.getItem("CustomerId") === undefined ||
    localStorage.getItem("CustomerId") === null ||
    localStorage.getItem("CustomerId") === ""
      ? "0"
      : localStorage.getItem("CustomerId");
  const [currentPage, setCurrentPage] = useState(1);
  const [loaded, setLoad] = useState<boolean>(false);
  const [popupPart, setpopupPart] = useState("");
  const [state, setState] = useState({
    extcompetitorID: data.EXTENDER_CROSSREFERENCE_FILTER.CompetitorId,
    extcompetitiorPartNumber:
      data.EXTENDER_CROSSREFERENCE_FILTER.CompetitorpartNumber,
    EnteredText: data.EXTENDER_TEXT_SEARCH.Text,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ResetreduxData } = useResetdata();
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      extcompetitorID: data.EXTENDER_CROSSREFERENCE_FILTER.CompetitorId,
      extcompetitiorPartNumber:
        data.EXTENDER_CROSSREFERENCE_FILTER.CompetitorpartNumber,
      EnteredText: data.EXTENDER_TEXT_SEARCH.Text,
    }));
  }, [
    data.EXTENDER_CROSSREFERENCE_FILTER.CompetitorId,
    data.EXTENDER_CROSSREFERENCE_FILTER.CompetitorpartNumber,
    data.EXTENDER_CROSSREFERENCE_FILTER.CompetitorpartNumber,
    data.EXTENDER_TEXT_SEARCH.Text,
  ]);

  // console.log(props.partsearch , "partsearch ")
  let url = EnvData?.REACT_APP_CDNURL + "/PartImages/LargeImages/";
  const authHeader = useGetauthHeader();

  let partsearch = props.partsearch;

  const rowsPerPage = 20; // Number of rows per page

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const currentPageData = props._product?.slice(startIndex, endIndex);
  //console.log(currentPageData, "current");
  // console.log(currentPage ,Math.ceil(props._product?.length / rowsPerPage) , "page number")
  const [loading, setLoading] = useState(false);
  const { setcartItems } = useContext(CartContext) as ICartContextAction;
  const AddTocart = (
    partid: number,
    partnumber: string,
    description: string,
    price: number,
    url: string
  ) => {
    setLoading(true);
    var cartName = localStorage.getItem("cartName");
    var cartInput = {
      // micrositeId: process.env.REACT_APP_MICROSITEID,
      partid: partid,
      cartName: cartName,
      customerId: CustomerId,
    };
    const requestOptionscart = {
      method: "POST",
      headers: authHeader,
      body: JSON.stringify(cartInput),
    };
    // console.log(JSON.stringify(requestOptionscart))

    return GetADDToCartData(
      EnvData?.REACT_APP_APIURL + "/microsite-api-v1/add-to-cart",
      JSON.stringify(cartInput),
      cartInput
    )
      .then((results) => {
        const output_array = JSON.stringify(results).split("|");
        //console.log(output_array);
        let cartid = output_array[1];
        if (cartid !== "") {
          //alert("Item added to the Cart");

          const newCart: ICart = {
            Partnumber: partnumber,
            description: description,
            price: price,
            imgflag: true,
            imgUrl: url,
          };
          setcartItems(newCart);
        }
      })
      .catch((error) => {
        console.log("Error in " + error);
      })

      .finally(() => {
        setLoading(false);
        // const myTimeout = setTimeout(()=>{
        eventEmitter.emit("triggerOpenDraw", {});
        // },1000)
      });
  };

  

  const [specification, setSpecification] = useState<ISpecProps[]>([]);
  const getSpecification = (partid: number) => {
    var specInput = {
      action: "ms_GetSpecforPart",
      parray: [
        "PartId",
        partid,
        "SiteName",
        String(EnvData?.REACT_APP_MICROSITENAME),
      ],
      pageName: "",
      methodname: "",
      readwriteFlag: 0,
      outputparam: "Yes",
    };
    // const requestOptionsSpec = {
    //   method: 'POST',
    //   headers: authHeader,
    //   body: JSON.stringify(specInput)
    // }
    let URL = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/get-part-specifications?PartId=${partid}`;

    return (
      GetSpecificationData(URL, partid)
        // .then((response) => {
        //   return response.json()
        // })
        .then((results) => {
          // console.log(results, " res from Specification");
          setSpecification(results.data);
        })
        .catch((error) => {
          console.log("Error in " + error);
        })
    );
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    partid: number,
    partNumber: string
  ) => {
    setpopupPart(partNumber);
    getSpecification(partid);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleSpecClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);

  const [anchorContactAvailabilityEl, setanchorContactAvailabilityEl] =
    useState<null | HTMLElement>(null);

  const contactAvailabilityClick = (event: React.MouseEvent<HTMLElement>) => {
    setanchorContactAvailabilityEl(
      anchorContactAvailabilityEl ? null : event.currentTarget
    );
  };

  const openContact = Boolean(anchorContactAvailabilityEl);

  const [popupEl, setPopupEl] = useState<null | HTMLElement>(null);
  const [crossRef, setcrossRef] = useState<ICrossRefProps[]>([]);
  const handleClickOpen = (
    event: React.MouseEvent<HTMLElement>,
    partid: number,
    partNumber: string
  ) => {
    setpopupPart(partNumber);
    setLoad(true);
    setPopupEl(popupEl ? null : event.currentTarget);

    let ExtraParam = "";

    if (state.extcompetitorID != "" || state.extcompetitiorPartNumber != "") {
      if (state.extcompetitorID != "") {
        ExtraParam = "&competitorId=" + state.extcompetitorID;
      }
      if (state.extcompetitiorPartNumber != "") {
        ExtraParam =
          ExtraParam + "&competitorPartNo=" + state.extcompetitiorPartNumber;
      }
    } else {
      if (state.EnteredText != "") {
        ExtraParam = ExtraParam + "&competitorPartNo=" + state.EnteredText;
      }
    }

    let URL = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/get-cross-references?PartId=${partid}`;
    if (ExtraParam != "") {
      URL = URL + ExtraParam;
    }
    return GetCrossreference(URL, partid)
      .then((results) => {
        setcrossRef(results.data);
        setLoad(false);
      })
      .catch((error) => {
        console.log("Error in " + error);
      });
  };
  const openPopup = Boolean(popupEl);
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setPopupEl(popupEl ? null : event.currentTarget);
  };

  if (loading)
    return (
      <div>
        {" "}
        <ProductLoader />
      </div>
    );
  return(
    <div>
        <Box>
          <Slider {...settings}>
             
                
                {currentPageData?.map((elem, index) => {
  let queryString = elem.partNumber; //
  let querdesp = elem.partDescriptionName; //


  const firstPart = elem.partDescriptionName.slice(0, 15);
  const remainingParts = elem.partDescriptionName.slice(15);


  const stringd = `/Category/${queryString}`;
  function pdBreadcrumbs() {
    if (props.home == true) {
      ResetreduxData();
    }
    if (props.partsearch == true) {
      let type = {
        partnumber: queryString,
        partflag: "PartSearch",
      };
      dispatch(addPDInfo(type));
    }
  }
                  return(
              //       <div>
              //       <Paper elevation={0} className="featured-product-slider-paper">
              //     <div className="featured-product-slider-box">
              //     <div className="featured-product-slider-image">
              //     <RouterLink to={stringd} onClick={pdBreadcrumbs}>
              //   <Link className="product_imagethumb_link">
              //     <img
              //       src={elem.imageUrl}
              //       alt="product thumb"
              //       className="product_imagethumb"
              //       loading="lazy"
              //     />
              //     <span className="water-mark">
              //       {sessionStorage.getItem("WaterMark")}
              //     </span>
              //   </Link>
              // </RouterLink>
              //     </div>
              //     <Stack className="featured-product-slider-content">
              //       <Typography className="categorytitle" variant="body2">
              //       <RouterLink to={stringd} onClick={pdBreadcrumbs}>
              //         {elem.salesPartNumber !== elem.partNumber &&
              //         elem.partNumber !== "" &&
              //         elem.partNumber !== undefined &&
              //         elem.partNumber !== null &&
              //         elem.showInventoryPartnumber === true
              //           ? elem.partNumber.toUpperCase() +
              //             " / " +
              //             elem.salesPartNumber.toUpperCase()
              //           : elem.salesPartNumber.toUpperCase()}
              //       </RouterLink>
              //       <Typography className="product_dec">
              //       <RouterLink to={stringd} onClick={pdBreadcrumbs}>
              //         {elem.partDescriptionName}
              //       </RouterLink>
              //     </Typography>
                    
              //       </Typography>
                    
              //       {
              //          (CustomerId !== "0" && elem.catalogueWithPricing === true) ? (
              //           <Box className="price-cart">
              //             {parseFloat(elem.unitPrice.toFixed(2)) === 0 ? (
              //               // ||
              //               // (elem.alwaysShowReadytoShip == false &&
              //               //   elem.stockInfo == "No Stock")
              //               <div
              //                 className="price-contact"
              //                 onClick={contactAvailabilityClick}
              //                 aria-controls={
              //                   open ? "contact-availability" : undefined
              //                 }
              //                 aria-haspopup="true"
              //                 aria-expanded={open ? "true" : undefined}
              //               >
              //                 <Button
              //                   variant="outlined"
              //                   color="primary"
              //                   endIcon={<KeyboardArrowDownIcon />}
              //                   sx={{ width: "100%" }}
              //                 >
              //                   Contact for Availability{" "}
              //                 </Button>
              //                 <Menu
              //                   id="contact-availability"
              //                   elevation={2}
              //                   className="contact-availability-popup"
              //                   open={openContact}
              //                   anchorEl={anchorContactAvailabilityEl}
              //                   anchorOrigin={{
              //                     vertical: "bottom",
              //                     horizontal: "left",
              //                   }}
              //                   transformOrigin={{
              //                     vertical: "top",
              //                     horizontal: "left",
              //                   }}
              //                 >
              //                   <div className="contact-availability">
              //                     <Box
              //                       sx={{ textAlign: "center", fontSize: "15px" }}
              //                     >
              //                       Please Contact
              //                       <Button
              //                         color="secondary"
              //                         sx={{ py: 0, fontSize: "15px" }}
              //                       >
              //                         {elem.phoneNumber}
              //                       </Button>{" "}
              //                       or
              //                       <Button
              //                         color="secondary"
              //                         sx={{ py: 0, fontSize: "15px" }}
              //                       >
              //                         {elem.contactEmail}
              //                       </Button>
              //                       for Availability as the item may not be in
              //                       stock.
              //                     </Box>
              //                   </div>
              //                 </Menu>
              //               </div>
              //             ) : (elem.shoppingCart == true ||
              //                 (CustomerId !== "0" &&
              //                   elem.catalogueWithPricing === true)) &&
              //               elem.unitPrice > 0 ? (
                         
              //               <IconButton className="featured-product-slider-cart" onClick={() => {
              //                 AddTocart(
              //                   elem.partId,
              //                   elem.partNumber,
              //                   elem.partDescriptionName,
              //                   elem.disPer,
              //                   elem.assetName !== ""
              //                     ? url + elem.assetName
              //                     : (EnvData?.REACT_APP_NOIMAGEURL as string)
              //                 );
              //               }}>
              //               <ShoppingCartIcon />
              //             </IconButton>
              //             ) : null}
    
                        
              //           </Box>
              //         ) : null
              //       }
                      
              //     </Stack>
              //  </div>
              //  </Paper>

               <div>
                <Paper elevation={0} className="featured-product-slider-paper">
                  <div className="featured-product-slider-box">
                      <div className="featured-product-slider-image">
                        <RouterLink to={stringd} onClick={pdBreadcrumbs}>
                            <img
                              src={elem.imageUrl}
                              alt="product thumb"
                            
                              loading="lazy"
                            />
                            <span className="water-mark">
                              {sessionStorage.getItem("WaterMark")}
                            </span>
                          </RouterLink>
                      </div>
                      <Stack className="featured-product-slider-content">
                        <Typography className="categorytitle" variant="body2">
                        <RouterLink to={stringd} onClick={pdBreadcrumbs}>
                      {elem.salesPartNumber !== elem.partNumber &&
                      elem.partNumber !== "" &&
                      elem.partNumber !== undefined &&
                      elem.partNumber !== null &&
                      elem.showInventoryPartnumber === true
                        ? elem.partNumber.toUpperCase() +
                          " / " +
                          elem.salesPartNumber.toUpperCase()
                        : elem.salesPartNumber.toUpperCase()}
                    </RouterLink>
                    <Tooltip title={remainingParts}>
                    <RouterLink to={stringd} onClick={pdBreadcrumbs}>
        {firstPart}...
        </RouterLink>
    </Tooltip>
                      {/* {elem.partDescriptionName} */}
                    
                        </Typography>
                        {
                       (CustomerId !== "0" && elem.catalogueWithPricing === true) ? (
                        <Box className="price-cart">
                          {parseFloat(elem.unitPrice.toFixed(2)) === 0 ? (
                            // ||
                            // (elem.alwaysShowReadytoShip == false &&
                            //   elem.stockInfo == "No Stock")
                            // <div
                            //   className="price-contact"
                            //   onClick={contactAvailabilityClick}
                            //   aria-controls={
                            //     open ? "contact-availability" : undefined
                            //   }
                            //   aria-haspopup="true"
                            //   aria-expanded={open ? "true" : undefined}
                            // >
                            //   <Button
                            //     variant="outlined"
                            //     color="primary"
                            //     endIcon={<KeyboardArrowDownIcon />}
                            //     sx={{ width: "100%" }}
                            //   >
                            //     Contact for Availability{" "}
                            //   </Button>
                            //   <Menu
                            //     id="contact-availability"
                            //     elevation={2}
                            //     className="contact-availability-popup"
                            //     open={openContact}
                            //     anchorEl={anchorContactAvailabilityEl}
                            //     anchorOrigin={{
                            //       vertical: "bottom",
                            //       horizontal: "left",
                            //     }}
                            //     transformOrigin={{
                            //       vertical: "top",
                            //       horizontal: "left",
                            //     }}
                            //   >
                            //     <div className="contact-availability">
                            //       <Box
                            //         sx={{ textAlign: "center", fontSize: "15px" }}
                            //       >
                            //         Please Contact
                            //         <Button
                            //           color="secondary"
                            //           sx={{ py: 0, fontSize: "15px" }}
                            //         >
                            //           {elem.phoneNumber}
                            //         </Button>{" "}
                            //         or
                            //         <Button
                            //           color="secondary"
                            //           sx={{ py: 0, fontSize: "15px" }}
                            //         >
                            //           {elem.contactEmail}
                            //         </Button>
                            //         for Availability as the item may not be in
                            //         stock.
                            //       </Box>
                            //     </div>
                            //   </Menu>
                            // </div>
                            <>
                            </>
                          ) : (elem.shoppingCart == true ||
                              (CustomerId !== "0" &&
                                elem.catalogueWithPricing === true)) &&
                            elem.unitPrice > 0 ? (
                         
                            <IconButton className="featured-product-slider-cart" onClick={() => {
                              AddTocart(
                                elem.partId,
                                elem.partNumber,
                                elem.partDescriptionName,
                                elem.disPer,
                                elem.assetName !== ""
                                  ? url + elem.assetName
                                  : (EnvData?.REACT_APP_NOIMAGEURL as string)
                              );
                            }}>
                            <ShoppingCartIcon />
                          </IconButton>
                          ) : null}
    
                        
                        </Box>
                      ) : null
                    }
                        
                          {/* <IconButton className="featured-product-slider-cart">
                            <ShoppingCartIcon />
                          </IconButton> */}
                      </Stack>
                   </div>
                </Paper>
              </div>
              //  </div>

               
               )
                 } )}
                  
             
              
             
          </Slider>
        </Box>
    </div>
  )

}