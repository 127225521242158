import { Box, Button } from "@mui/material";
import { Container } from "@mui/system";
import Slider from "react-slick";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useGetDatawithMultipleArray } from "../../APIHooks/useGetData";

import { IBannerProps } from "../../Types/MicrositeType";
import { Key } from "react";
import { useSelector } from "react-redux";

function SampleNextArrow(props: any) {
  const { style, onClick } = props;
  return (
    <Button
      variant="contained"
      style={{
        ...style,
        display: "block",
        top: "50%",
        padding: "6px",
        position: "absolute",
        right: "10px",
        zIndex: "99",
        transform: "translate(0, -50%)",
        minWidth: "auto",
        lineHeight: 1,
      }}
      onClick={onClick}
    >
      <KeyboardArrowRightOutlinedIcon sx={{ lineHeight: 1 }} />
    </Button>
  );
}

function SamplePrevArrow(props: any) {
  const { style, onClick } = props;
  return (
    <Button
      variant="contained"
      style={{
        ...style,
        display: "block",
        top: "50%",
        padding: "6px",
        position: "absolute",
        left: "10px",
        zIndex: "99",
        transform: "translate(0, -50%)",
        minWidth: "auto",
        lineHeight: 1,
      }}
      onClick={onClick}
    >
      <KeyboardArrowLeftOutlinedIcon sx={{ lineHeight: 1 }} />
    </Button>
  );
}
const settings = {
  dots: false,
  fade: true,
  className: "bannerslider",
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 500,
  // lazyLoad: "progressive",
  autoplaySpeed: 5000,
  cssEase: "linear",
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};
export const BannerSlider = (props: { _banner: IBannerProps[] }) => {

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  let url =
    EnvData?.REACT_APP_CDNURL +
    "/Microsite/" +
    EnvData?.REACT_APP_MICROSITENAME +
    "/Banner/";

  return (
    <Box py={2}>
      <Container>
        <Slider {...settings}>
          {props._banner.map((elem, index) => (
            <div key={index}>
              {elem.url != "" ? (
                <a href={elem.url} key={index} target="_blank">
                  <img src={url + elem.bannerName} alt="Banner images" />
                </a>
              ) : (
                <img src={url + elem.bannerName} alt="Banner images" />
              )}
            </div>
          ))}

          {/* {banner.map((elem, index) => (
            <div key={index}>
              <img src={url + elem['BannerName']} alt="Banner images" />
            </div>
          ))} */}
        </Slider>
      </Container>
    </Box>
  );
};
