
import {   TableCell, TableRow } from "@mui/material";
function TableRows({rowsData, deleteTableRows, handleChange}) {
    return(
        
        rowsData.map((data, index)=>{
            const {PartNumber, UnitPrice, CorePrice,Qty,EPA,Notes}= data;
            return(
                <TableRow key={index}>
                <TableCell>
               <input type="text" placeholder="Part Number" variant="outlined" name="Partnumber" onChange={(evnt)=>(handleChange(index, evnt))} />
                </TableCell>
                <TableCell><input type="text"  placeholder="Unit Price" label="Outlined" variant="outlined" onChange={(evnt)=>(handleChange(index, evnt))} name="UnitPrice" className="form-control"/> </TableCell>
                <TableCell><input type="text"  placeholder="Core Price"  label="Outlined" variant="outlined" onChange={(evnt)=>(handleChange(index, evnt))} name="CorePrice" className="form-control" /> </TableCell>
                <TableCell><input type="text"  placeholder="Qty" label="Outlined" variant="outlined" onChange={(evnt)=>(handleChange(index, evnt))} name="Qty" className="form-control" /> </TableCell>
                {/* <td><input type="text"  placeholder="EPA" variant="outlined" onChange={(evnt)=>(handleChange(index, evnt))} name="EPA" className="form-control" /> </td> */}
                <TableCell><input type="text"  placeholder="Notes" label="Outlined" variant="outlined" onChange={(evnt)=>(handleChange(index, evnt))} name="Notes" className="form-control" /> </TableCell>
                <TableCell><button type="submit"  className="btn btn-outline-danger" onClick={()=>(deleteTableRows(index))}>x</button></TableCell>
            </TableRow>
            )
        })
   
    )
    
}
export default TableRows;