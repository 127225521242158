import { Typography, Container, Paper, Box, Button, Link } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";

import "../Css/table.style.css";
import { useViewCartResults } from "../APIHooks/useGetData";
import useGetauthHeader from "../APIHooks/useGetAuthHeader";
import { ICartProps, IValueProps } from "../Types/MicrositeType";
import { useContext, useEffect, useState } from "react";
import { CartContext } from "../Components/Cart/CartContext";
// import { DODBActivity, DBActivityResults } from "../APIHooks/DBActivity";
import {
  GetData,
  Get_Data,
  DODBActivity,
  DBActivityResults,
  GetCartUpdated,
} from "../API/GetDataFromApi";
import { NULL } from "node-sass";
import { useSelector } from "react-redux";

let cartid = localStorage.getItem("CartId");

export const ViewCart = () => {
  const [cartItems, setcartItems] = useState<ICartProps[]>([]);
  const [cartValues, setcartValues] = useState<IValueProps[]>([]);

  const { deleteItem } = useContext(CartContext);

  let authHeader = useGetauthHeader();

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  // var raw = JSON.stringify({
  //   action: "ms_ViewCart",
  //   parray: ["Cartid", cartid, "existinguser", "0"],
  //   pageName: "string",
  //   methodname: "string",
  //   readwriteFlag: 0,
  //   outputparam: "",
  //   rowcount: 0,
  // });

  let ExistingUser = 0;
  if (
    localStorage.getItem("UserId") !== undefined &&
    localStorage.getItem("UserId") !== null &&
    localStorage.getItem("UserId") !== ""
  ) {
    ExistingUser = 1;
  }

  // let URL = `${
  //   process.env.REACT_APP_APIURL
  // }/microsite-api-v1/get-view-cart-info?CartId=${localStorage.getItem(
  //   "CartId"
  // )}&ExistingUser=${ExistingUser}`;

  const URL = `${
    EnvData?.REACT_APP_APIURL
  }/microsite-api-v1/get-view-cart-info?CartId=${
    localStorage.getItem("CartId") || "0"
  }&ExistingUser=${ExistingUser}`;
  let cartId = localStorage.getItem("CartId");
  // const requestOptions = {
  //   method: "POST",
  //   headers: authHeader,
  //   body: raw,
  // };
  // let [cartItem, cartValue] = useViewCartResults<ICartProps[], IValueProps[]>(
  //   process.env.REACT_APP_APIURL + "/api/Microsite/getDatas_readOnly",
  //   requestOptions,
  //   [],
  //   []
  // );
  //console.log(JSON.stringify(cartItems))

  useEffect(() => {
    if (cartId != "" && cartid != "0" && cartid != undefined) {
      (async () => {
        Get_Data(URL)
          .then((results?) => {
            setcartItems([results.data]); // check it
            setcartValues([results.data]); // check it
          })
          .catch((err) => {
            console.error(err);
            //   localStorage.removeItem("user");
          });
      })();
    }
  }, []);

  const deleteCart = async (Id: number, partnumber: string) => {
    var raw = {
      action: "ms_UpdateorDeleteCart",
      parray: ["Id", Id.toString(), "Action", "delete", "cartid", ""],
    };
    // const res = DODBActivity(
    //   process.env.REACT_APP_APIURL + "/api/Microsite/UpdateOrDelete",
    //   requestOption
    // );
    const res = DODBActivity(
      EnvData?.REACT_APP_APIURL + "/microsite-api-v1/UpdateOrDelete",
      JSON.stringify(raw)
    );
    if ((await res) == "Success") {
      //alert("Item has been deleted");
      deleteItem(partnumber);

      GetCartUpdated(
        EnvData?.REACT_APP_APIURL + "/microsite-api-v1/getDatas_readOnly",
        JSON.stringify(raw)
      )
        .then((response) => {
          return response.json();
        })
        .then((results) => {
          setcartItems(results.Table);
          setcartValues(results.Table1);
        })
        .catch((error) => {
          // localStorage.removeItem("user");
        })
        .finally(() => {});
    }
  };
  const updateCart = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    Id: number
  ) => {
    const qty = event.target.value;
    var raw = {
      action: "ms_UpdateorDeleteCart",
      parray: [
        "Id",
        Id.toString(),
        "Action",
        "update",
        "cartid",
        "",
        "Qty",
        qty.toString(),
      ],
    };

    const result = DODBActivity(
      EnvData?.REACT_APP_APIURL + "/microsite-api-v1/UpdateOrDelete",
      JSON.stringify(raw)
    );
    if ((await result) == "Success") {
      //alert("Qty has been Updated");

      //let [cartItem1, cartValue1] = await DBActivityResults<ICartProps[], IValueProps[]>(process.env.REACT_APP_APIURL + '/api/Microsite/getDatas_readOnly', requestOptions, [], []);

      GetCartUpdated(
        EnvData?.REACT_APP_APIURL + "/microsite-api-v1/getDatas_readOnly",
        JSON.stringify(raw)
      )
        .then((results?) => {
          setcartItems(results.Table);
          setcartValues(results.Table1);
        })
        .catch((error) => {
          localStorage.removeItem("user");
        })
        .finally(() => {});
    }
  };
  return (
    <div>
      <Paper elevation={0} sx={{ mt: 2, mb: 1, py: 3 }}>
        <Container>
          <Typography variant="h4" color="custom.main" sx={{ mb: 2 }}>
            Your Cart Details
          </Typography>
          <div>
            <table className="view-cart-table">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Part Details</th>
                  <th>Price</th>
                  <th className="text-right">Extended Total</th>
                  <th className="text-right">Quantity</th>
                </tr>
              </thead>
              <tbody>
                {cartItems &&
                  cartItems?.map((elem, index) => (
                    <tr key={index}>
                      <td>
                        <Link
                          href={elem.PartReplDescription}
                          className="table-img"
                        >
                          <img src={elem.Imgsrc} alt="images" loading="lazy" />
                        </Link>
                      </td>
                      <td>
                        <Typography
                          variant="body1"
                          color="secondary"
                          sx={{ fontWeight: 500 }}
                        >
                          {elem.PartDescriptionName}
                        </Typography>
                        <Typography>{elem.PartNumber}</Typography>
                      </td>
                      <td>
                        <p>
                          <b>Unit</b> :${elem.UnitPrice}
                        </p>
                        <p>
                          <b>Core</b> :${elem.CorePrice}
                        </p>
                      </td>
                      <td className="text-right">${elem.ExtendTotal}</td>
                      <td className="text-right">
                        <FormControl sx={{ width: "60px" }}>
                          <OutlinedInput
                            value={elem.Qty}
                            onChange={(event) => updateCart(event, elem.Id)}
                            inputProps={{
                              "aria-label": "Qty",
                            }}
                          />
                        </FormControl>
                        <IconButton
                          onClick={() => deleteCart(elem.Id, elem.PartNumber)}
                        >
                          <DeleteForeverRoundedIcon color="error" />
                        </IconButton>
                      </td>
                    </tr>
                  ))}

                {cartValues &&
                  cartValues?.map((elem, index) => (
                    <tr key={index}>
                      <td colSpan={2}>
                        <p className="subtotal-info">
                          Your subtotal does not include Tax &amp; Shipping
                          Charges.
                        </p>
                      </td>
                      <td></td>
                      <td colSpan={2} className="text-right">
                        <strong>Subtotal &nbsp;: &nbsp;$ &nbsp;</strong>
                        {elem.SubTotal}{" "}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div className="cart-value-container">
            <Box className="cart-value-box">
              <Typography variant="h5" color="custom.main" sx={{ mb: 2 }}>
                Your Cart Details
              </Typography>

              {cartValues &&
                cartValues?.map((elem, index) => (
                  <table className="cart-value-table" key={index}>
                    <tbody>
                      <tr>
                        <th>Unit Price Total</th>
                        <td>{elem.UnitTotal}</td>
                      </tr>
                      <tr>
                        <th>Core Price Total</th>
                        <td>{elem.CoreTotal}</td>
                      </tr>
                      <tr>
                        <th>EPA</th>
                        <td>{elem.EPATOTAL}</td>
                      </tr>
                      <tr>
                        <th>Shipping and handing charges</th>
                        <td className="red">
                          Calculated during the Checkout Process
                        </td>
                      </tr>
                      <tr>
                        <th>Tax</th>
                        <td className="red">
                          Calculated during the Checkout Process
                        </td>
                      </tr>
                      <tr className="cart-total-value">
                        <th>Total Value </th>
                        <td>{elem.TotalValue}</td>
                      </tr>
                    </tbody>
                  </table>
                ))}
            </Box>
          </div>
          <div className="cart-value-container">
            <Button
              color="primary"
              sx={{ textAlign: "right" }}
              variant="contained"
            >
              Proceed to Checkout
            </Button>
          </div>
        </Container>
      </Paper>
    </div>
  );
};
