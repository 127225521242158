import {
  Typography,
  Container,
  Paper,
  Box,
  TextField,
  Divider,
  Stack,
  Button,
  Grid,
} from "@mui/material";

import mailadderss from "../Images/mail-adderss.png";
import mail from "../Images/mail.png";
import print from "../Images/print-vector-icon.png";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GetAuthHeaderToken, GetauthHeader } from "../API/GetDataFromApi";
// import ClearAllLocalStorage from "../Components/Checkout/ClearLocalstorage";
import { clearLocalstorageValues } from "../API/GetMicrositeSettings";
import axios, { AxiosRequestConfig } from "axios";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

export default function PaymentSuccess() {
  const [orderID, setOrderID] = useState("");
  const [Address, setAddress] = useState("");
  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);
  const navigate = useNavigate();
  let AddressJson = localStorage.getItem("ShippingInfo");
  let Info = JSON.parse(AddressJson ? AddressJson : "");

  let OrderId = localStorage.getItem("ShoppingcartHdrId");
  const [googleAnalyticsCode, setgoogleAnalyticsCode] = useState("");
  //google anaylytics start

  useEffect(() => {
    Loadseoinfo();
  }, []);

  async function Loadseoinfo() {
    let CadenceToken = localStorage.getItem("authToken");

    const headers: any = await GetAuthHeaderToken();
    if (headers === "Token Expired") {
      navigate("/login");
      return false;
    }

    axios
      .get(
        EnvData?.REACT_APP_APIURL +
          "/checkout-api-v1/google-analytics-for-order-success?ShoppingcartHdrId=" +
          OrderId,
        {
          headers: headers,
        }
      )
      .then((results) => {
        if (results.data.googleAnalytics !== "") {
          const script = document.createElement("script");
          script.innerText = results.data.googleAnalytics
            .replace(
              "<!-- Event snippet for Purchase conversion page --><script>",
              ""
            )
            .replace("</script>", "")
            .replace("<break>", "");
          document.head.appendChild(script);
          //document.body.appendChild(script);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log("Unauthorized response PaymentSuccess:", error.response);
        }
        // console.log("Unauthorized response PaymentSuccess:", error);
      });
  }

  //google anaylytics end

  // const navigate = useNavigate();

  useEffect(() => {
    //console.log(OrderId, "OrderId");
    // window.location.reload();
    setOrderID(OrderId ? OrderId : "");
    // var add = Info.AddressLine1.split(",");
    var address1: any = Info.AddressLine1;

    var state = Info.State.split("|");
    var country = Info.Country.split("|");
    setAddress(
      address1["value"] === undefined
        ? Info.AddressLine1
        : address1["value"] +
            ", " +
            Info.City +
            ", " +
            state[0] +
            ", " +
            country[0] +
            ", " +
            Info.Zip
    );

    return () => {
      clearLocalstorageValues("Login");
      setTimeout(() => {
        window.location.reload();
      }, 500);
      // GetauthHeader()
    };
  }, [OrderId]);

  function formatDate(date: any) {
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const formattedDate = `${
      monthIndex.lenght === 1 ? "0" : "" + (monthIndex + 1)
    }/${day}/${year}`;
    return formattedDate;
  }

  const handleclick = () => {
    //
    const newTab = window.open("/SalesReceipt", "_blank");
    navigate("/");
  };

  // Get the current date and format it
  const currentDate = new Date();
  const formattedDate = formatDate(currentDate);

  return (
    <div>
      {/* <Helmet>
        <script>{googleAnalyticsCode}</script>

      </Helmet> */}

      <Paper elevation={0} sx={{ mt: 3, mb: 1, py: 3 }}>
        <Container>
          <Box sx={{ mb: 1 }}>
            <Divider
              className="payment-thanks-title"
              sx={{
                "&::before, &::after": {
                  borderColor: "primary.main",
                },
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  border: "2px solid",
                  borderColor: "primary.main",
                  padding: "15px",
                }}
              >
                Thank You for Shopping with us !
              </Typography>
            </Divider>
          </Box>
          <Box sx={{ mt: 3, mb: 5, textAlign: "center" }}>
            <Stack
              direction="row"
              spacing={3}
              sx={{ alignItems: "center", justifyContent: "center" }}
              className="payment-order-stack"
            >
              <Typography variant="h2">Your order is Confirmed ! </Typography>
              <Box sx={{ display: "flex" }}>
                <Typography variant="h2" sx={{ fontWeight: "600" }}>
                  Order # :
                </Typography>
                <Typography
                  variant="h2"
                  component="a"
                  sx={{
                    textDecoration: "underline",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                  color="secondary"
                  onClick={handleclick}
                >
                  {orderID}
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Typography variant="h2" sx={{ fontWeight: "600" }}>
                  Date :
                </Typography>
                <Typography variant="h2" sx={{ marginLeft: "10px" }}>
                  {formattedDate}
                </Typography>
              </Box>
            </Stack>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Stack
                direction="row"
                spacing={1}
                className="payment-success-stack"
              >
                <Box>
                  <img src={mailadderss} />
                </Box>
                <Box>
                  <Typography variant="h6" color="secondary" mb={1}>
                    SHIPPING ADDRESS
                  </Typography>
                  <Typography mb={1}>
                    {" "}
                    {/* USA Drive, EAST ALTON, illinois, United States-62024 */}
                    {/* {Address} */}
                    {localStorage.getItem("DefaultAddress") +
                      ", " +
                      localStorage.getItem("defaultCity") +
                      ", " +
                      localStorage.getItem("defaultZip") +
                      ", " +
                      Info.Country.split("|")[1]}
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <Typography
                      component="a"
                      href="tel:874-320-9145"
                      sx={{ textDecoration: "none" }}
                    >
                      <PhoneIphoneOutlinedIcon
                        fontSize="small"
                        sx={{ paddingRight: "5px", verticalAlign: "middle" }}
                      />
                      {/* 874-320-9145 */}
                      {Info.Phone}
                    </Typography>
                    <Typography
                      component="a"
                      href="mailto:delip.raj@apaengineering.com"
                      sx={{ textDecoration: "none" }}
                    >
                      <EmailOutlinedIcon
                        fontSize="small"
                        sx={{ paddingRight: "5px", verticalAlign: "middle" }}
                      />
                      {Info.Email}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Stack
                direction="row"
                spacing={1}
                className="payment-success-stack"
              >
                <Box>
                  <img src={mail} />
                </Box>
                <Box>
                  <Typography mb={1}>
                    An email receipt including the details about your order has
                    been sent to the email address.
                  </Typography>
                  <Typography
                    component="a"
                    href="mailto:delip.raj@apaengineering.com"
                    sx={{ textDecoration: "none" }}
                  >
                    <EmailOutlinedIcon
                      fontSize="small"
                      sx={{ paddingRight: "5px", verticalAlign: "middle" }}
                    />
                    {Info.Email}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3, mb: 5, p: 2, textAlign: "center" }}>
            <Typography onClick={handleclick}>
              <img src={print} className="print-image" />{" "}
              {/* <a target="_blank" href="/SalesReceipt"> */}
              Click to Print Order Details {/* </a>{" "} */}
            </Typography>
          </Box>
        </Container>
      </Paper>
    </div>
  );
}
