import {
  Typography,
  Container,
  Paper,
  Box,
  TextField,
  Divider,
  Stack,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import "../Css/orderhistory.style.css";
import { GetADDToCartData, Get_Data } from "../API/GetDataFromApi";
import { useContext, useEffect, useState } from "react";
import useGetauthHeader from "../APIHooks/useGetAuthHeader";
import { CartContext } from "../Components/Cart/CartContext";
import eventEmitter from "../Components/PartSearchTest/eventEmmiter";
import { ICartContextAction, ICart } from "../Types/CartType";
import { Link as RouterLink } from "react-router-dom";
import OrderHistoryComps from "./OrderHistoryComps";
import { ordersummary } from "../Redux/Partsearchfilter";
import ProductLoader from "../Components/ProductLoader";
import { useDispatch, useSelector } from "react-redux";
import { addOrderHistoryDetails } from "../Redux/Counterslice";
import breadcrumbsbanner from "../Images/centralturbos/breadcrumbs-common-banner.png";

export default function OrderHistory() {
  const [Orders, setOrders] = useState<any>();
  const [EmailId, setEmailId] = useState<string>("");
  const [OrderNo, setOrderNo] = useState<string>("");
  const [Loading, setLoading] = useState<any>(false);
  const [Header, setHeader] = useState<any>(true);
  const [LoggedIn, setLoggedIn] = useState<any>(false);
  const [OrderIn, setOrderIn] = useState<any>(1);
  const { setcartItems } = useContext(CartContext) as ICartContextAction;
  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  const dispatch = useDispatch();
  const OrderHistory = (state: any) => state.ORDERHISTORY_DETAILS;
  const OrderHistoryDetails = useSelector(OrderHistory);

  const Logincustomerdetails = localStorage.getItem("CustomerInfo");
  let loginemail = Logincustomerdetails
    ? JSON.parse(Logincustomerdetails)
    : null;
  useEffect(() => {
    // console.log("SubTotal OH" , OrderHistoryDetails)
    if (OrderHistoryDetails.OrderId !== "" || null || undefined) {
      setEmailId(OrderHistoryDetails.OrderMail);
      setOrderNo(OrderHistoryDetails.OrderId);
      FindOrderHistoryAuto(
        OrderHistoryDetails.OrderId,
        OrderHistoryDetails.OrderMail
      );
    }
    if (localStorage.getItem("CustomerInfo")) {
      setEmailId(loginemail?.customerEmail);
    }
    FindOrderHistoryLogin();
    let type = {
      Id: "",
      mail: "",
    };

    return () => {
      // dispatch(addOrderHistoryDetails(type));
    };
  }, []);

  useEffect(() => {
    if (OrderIn !== "" || undefined || null) {
      FindOrderHistoryLogin();
    }
  }, [OrderIn]);

  function changeOrderIn(res: string) {
    setOrderIn(res);
  }

  let authHeader = useGetauthHeader();

  const AddTocart = (
    partid: number,
    partnumber: string,
    description: string,
    price: number,
    url: string
  ) => {
    var cartName = localStorage.getItem("cartName");
    const CustomerId =
      localStorage.getItem("CustomerId") === undefined ||
      localStorage.getItem("CustomerId") === null ||
      localStorage.getItem("CustomerId") === ""
        ? "0"
        : localStorage.getItem("CustomerId");
    var cartInput = {
      // micrositeId: process.env.REACT_APP_MICROSITEID,
      partid: partid,
      cartName: cartName,
      customerId: CustomerId,
    };
    const requestOptionscart = {
      method: "POST",
      headers: authHeader,
      body: JSON.stringify(cartInput),
    };
    // console.log(JSON.stringify(requestOptionscart))
    return GetADDToCartData(
      EnvData?.REACT_APP_APIURL + "/microsite-api-v1/add-to-cart",
      JSON.stringify(cartInput),
      cartInput
    )
      .then((results) => {
        const output_array = JSON.stringify(results).split("|");
        //console.log(output_array);
        let cartid = output_array[1];
        if (cartid !== "") {
          //alert("Item added to the Cart");

          const newCart: ICart = {
            Partnumber: partnumber,
            description: description,
            price: price,
            imgflag: true,
            imgUrl: url,
          };
          setcartItems(newCart);

          eventEmitter.emit("triggerOpenDraw", {});
        }
      })
      .catch((error) => {
        console.log("Error in " + error);
      });
  };

  const FindOrderHistory = () => {
    if (
      OrderNo !== "" &&
      OrderNo !== undefined &&
      OrderNo !== null &&
      EmailId !== "" &&
      EmailId !== undefined &&
      EmailId !== null
    ) {
      setLoading(true);

      // let URL = `${process.env.REACT_APP_APIURL}/microsite-api-v1/get-order-history?UserID=1&OrderNo=${OrderNo}&EmailID=${EmailId}&DateRange=5`;
      let URL = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/get-order-history?UserID=0&OrderNo=${OrderNo}&EmailID=${EmailId}&DateRange=1`;

      Get_Data(URL).then((res) => {
        setOrders(res.data);
    
        setLoading(false);
        setHeader(false);
      });
    } else if (OrderNo == "" || undefined || null) {
      alert("Enter the order No ");
    } else if (EmailId == "" || undefined || null) {
      alert("Enter the Email ");
    }
  };

  const FindOrderHistoryLogin = () => {
    // console.log(loginemail?.customerEmail , "loginemail?.customerEmail")
    if (localStorage.getItem("CustomerInfo")) {
      setLoggedIn(true);
      setLoading(true);
      // let URL = `${process.env.REACT_APP_APIURL}/microsite-api-v1/get-order-history?UserID=1&OrderNo=${OrderNo}&EmailID=${EmailId}&DateRange=5`;
      let URL = `${
        EnvData?.REACT_APP_APIURL
      }/microsite-api-v1/get-order-history?UserID=${localStorage.getItem(
        "UserId"
      )}&OrderNo=0&EmailID=${loginemail?.customerEmail}&DateRange=${OrderIn}`;

      Get_Data(URL).then((res) => {
        setOrders(res?.data);
   
        setLoading(false);
        setHeader(false);
      });
    }
  };

  const FindOrderHistoryAuto = (No: string, EmailId: string) => {
    setLoading(true);
    let URL = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/get-order-history?UserID=0&OrderNo=${No}&EmailID=${EmailId}&DateRange=1`;

    Get_Data(URL).then((res) => {
      setOrders(res.data);
   
      setLoading(false);
      setHeader(false);
    });
  };

  if (Loading == true) {
    return <ProductLoader />;
  }

  return (
    <div>
      {EnvData.REACT_APP_SHOW_CENTRAL_TURBO == "true"  ? (
                <>
               
                <Paper elevation={0} sx={{ mt: 0, mb: 1, p: 7,  backgroundImage: `url(${breadcrumbsbanner})`, }} className="central-turbos-breadcrumbs-banner">
                    <Container>
                        <Box sx={{px:5}}>
                            <Typography className="central-turbos-breadcrumbs-title">Order History</Typography>
                        </Box>
                    </Container>
                </Paper> 
               </>
              ) : (
                <></>
              )}
      <Paper elevation={0} sx={{ mt: 2, mb: 1, py: 3 }}>
        <Container>
          <Box sx={{ mb: 3 }}>
            <div className="product-right-border">
              <Typography variant="h4" pb={1}>
                Order Info
                <Divider
                  sx={{ bgcolor: "primary.main" }}
                  className="title-divider"
                />
              </Typography>
            </div>

            {LoggedIn ? (
              <>
                <FormControl className="sort-by">
                  <InputLabel id="demo-simple-select-helper-label">
                    Order In
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    variant="outlined"
                    // value={Sortby || ""}
                    value={OrderIn}
                    onChange={(event: any) => {
                      changeOrderIn(event.target.value);
                      // setTimeout(() => dispatch(trigger()), 200);
                    }}
                    label=" Sort By"
                  >
                    <MenuItem value="1">Past 3 Months</MenuItem>
                    <MenuItem value="2">Past 6 Months</MenuItem>
                    <MenuItem value="3">Past 9 Months</MenuItem>
                    <MenuItem value="4">Past 1 Year</MenuItem>
                    <MenuItem value="5">Past 2 Year</MenuItem>
                  </Select>
                </FormControl>
              </>
            ) : (
              <>
                {" "}
                <Box className="order-history-field">
                  <Typography
                    variant="subtitle2"
                    className="order-history-label"
                  >
                    Order #
                  </Typography>
                  <TextField
                    placeholder="Enter your order No"
                    onChange={(event: any) => {
                      const value = event.target.value;

                      if (/[^0-9]/.test(value)) {
                        // alert('Enter valid order ID (should not contain alphabets)');
                      } else {
                        setOrderNo(value);
                        // setTimeout(() => dispatch(trigger()), 200);
                      }
                    }}
                    // onChange={(e) => {
                    //   setOrderNo(e.target.value);
                    // }}
                    value={OrderNo}
                  />
                  <Typography
                    variant="subtitle2"
                    className="order-history-label"
                  >
                    Email Address
                  </Typography>
                  <TextField
                    placeholder="Enter you Email Address"
                    onChange={(e) => {
                      setEmailId(e.target.value);
                    }}
                    value={EmailId}
                  />
                  <Button
                    variant="contained"
                    onClick={() => FindOrderHistory()}
                  >
                    SUBMIT
                  </Button>
                </Box>
              </>
            )}

            {/* <Typography variant="body2" component="a">Need help finding your order ID ?</Typography> */}
          </Box>

          {LoggedIn ? (
            <></>
          ) : (
            <>
              {" "}
              <Box sx={{ mb: 2 }}>
                {Header == false ? (
                  <>
                    {" "}
                    <div className="product-right-border">
                      <Typography variant="h4" pb={1}>
                        Order You Searched For
                        <Divider
                          sx={{ bgcolor: "primary.main" }}
                          className="title-divider"
                        />
                      </Typography>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {Orders?.searchOrderNoList?.length == 0 ? (
                  <>
                    <div className="product-right-border">
                      <Typography variant="body1" pb={1} color="error">
                        NO RECORDS FOUND
                      </Typography>
                    </div>
                  </>
                ) : (
                  <>
                    {Orders?.searchOrderNoList?.map(
                      (orders: any, id: number) => (
                        <OrderHistoryComps
                          orders={orders}
                          Addtocart={AddTocart}
                          key={id}
                        />
                      )
                    )}
                  </>
                )}
              </Box>
            </>
          )}

          <Box sx={{ mb: 2 }}>
            {Header == false ? (
              <>
                {" "}
                <div className="product-right-border">
                  <Typography variant="h4" pb={1}>
                    Shipped Orders
                    <Divider
                      sx={{ bgcolor: "primary.main" }}
                      className="title-divider"
                    />
                  </Typography>
                </div>
              </>
            ) : (
              <></>
            )}

            {Orders?.shippedOrderList?.length == 0 ? (
              <>
                <div className="product-right-border">
                  <Typography variant="body1" pb={1} color="error">
                    NO RECORDS FOUND
                  </Typography>
                </div>
              </>
            ) : (
              <>
                {Orders?.shippedOrderList?.map((orders: any, id: number) => (
                  <OrderHistoryComps
                    orders={orders}
                    Addtocart={AddTocart}
                    key={id}
                  />
                ))}
              </>
            )}
          </Box>

          <Box sx={{ mb: 2 }}>
            {Header == false ? (
              <>
                {" "}
                <div className="product-right-border">
                  <Typography variant="h4" pb={1}>
                    Other Active Orders
                    <Divider
                      sx={{ bgcolor: "primary.main" }}
                      className="title-divider"
                    />
                  </Typography>
                </div>
              </>
            ) : (
              <></>
            )}

            {Orders?.otherActiveOrdersList?.length == 0 ? (
              <>
                <div className="product-right-border">
                  <Typography variant="body1" pb={1} color="error">
                    NO RECORDS FOUND
                  </Typography>
                </div>
              </>
            ) : (
              <>
                {Orders?.otherActiveOrdersList?.map(
                  (orders: any, id: number) => (
                    <OrderHistoryComps
                      orders={orders}
                      Addtocart={AddTocart}
                      key={id}
                    />
                  )
                )}
              </>
            )}
          </Box>
        </Container>
      </Paper>
    </div>
  );
}
