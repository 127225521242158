import { useSelector } from "react-redux";

const useGetauthHeader = () => {
  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  var cartName = localStorage.getItem("cartName");
  if (!cartName) {
    var company = sessionStorage.getItem("CompanyName");
    var currentDate = new Date();
    var date =
      currentDate.getMonth() +
      "/" +
      currentDate.getDate() +
      "/" +
      currentDate.getFullYear() +
      "/" +
      currentDate.getTime();
    // console.log(date, "date from authheader");
    const currDate = "Current Date= " + date;
    localStorage.setItem(
      "cartName",
      (EnvData?.REACT_APP_MICROSITENAME + "_" + date) as string
    );
  }
  return {
    RequestKey: EnvData?.REACT_APP_APIREQUESTKEY,
    "Content-Type": "application/json",
  };

  // if (user) {
  //     return {
  //         "Authorization": 'Bearer ' + user,
  //         "Content-Type": 'application/json'
  //     };
  // }
  // else {
  //     // console.log("GetauthHeader");
  //     let url = (process.env.REACT_APP_APIURL as string)

  //     GenerateToken(url);

  //     user = localStorage.getItem('user');
  //     return {
  //         "Authorization": 'Bearer ' + user,
  //         "Content-Type": 'application/json'
  //     };
  // }
};

export default useGetauthHeader;
