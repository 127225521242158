import { useEffect, useState } from "react";
import { Get_Data } from "../API/GetDataFromApi";
import "../Css/custompage.style.css";
import { useSearchParams, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

export default function CustomPage() {
  const [searchparam] = useSearchParams();
  const params = searchparam.get("val");
  const { val } = useParams();

// console.log("URL value : ", val);

  const [content, setContent] = useState("");

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);
  useEffect(() => {
    (async () => {
      Get_Data(
        EnvData?.REACT_APP_APIURL +
          "/microsite-api-v1/get-custom-menu-content?Id=" +
          val,
        ""
      )
        .then((results?) => {
        // console.log(results.data, "html content");
          setContent(results.data);
        })
        .catch((err) => {
          console.error(err);
          //   localStorage.removeItem("user");
        });
    })();
  }, [val]);

  return <div dangerouslySetInnerHTML={{ __html: content }}></div>;
}
