import {
  Box,
  Button,
  Container,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import turbochargers from "../Images/turbo-chargers.png";
import { ProductList } from "../Components/Product/ProductList";
import {
  IFeatureproductProps,
  IVidoesProps,
  IBannerProps,
  ITestimonialProps,
  ICompanyProps,
  IProductProps,
  IBrandProps,
  IMicrositeProps,
  IMicrositeSEOProps,
  IMicrositePropshome,
  IPartinformationProps,
  IProductDescription,
  ICrossRefProps,
  IProductalternativeProps,
  IProductImageProps,
} from "../Types/MicrositeType";
import { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import {
  BrowserRouter as Router,
  // Route,
  Link,
  // useLocation,
  useSearchParams,
  useParams,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { GetData } from "../API/GetDataFromApi";
import ProductGrid from "../Components/Product/ProductGrid";
import { useNavigate } from "react-router-dom";
import ProductSlider from "../Components/Product/ProductSlider";
import ProductLoader from "../Components/ProductLoader";
import MetaTag from "../SEO/MetaTag";

export default function ProductDetailsCompetitor() {
  const [product, setProduct] = useState<IProductProps[]>([]);
  const [parmms, setparms] = useState<string | undefined>("");
  const [Compparmms, setCompparms] = useState<string | undefined>("");
  const [partdetails, setPartDetails] = useState<IPartinformationProps[]>([]);
  const [productdescription, setproductdescription] = useState<
    IProductDescription[]
  >([]);
  const [crossref, setCrossRefItems] = useState<ICrossRefProps[]>([]);
  const [alterproducts, setalterproducts] = useState<[]>([]);
  const [images, setimages] = useState<IProductImageProps[]>([]);
  const [unitPrice, setUnitPrice] = useState(0);
  const [corePrice, setCorePrice] = useState(0);

  const [loading, setloading] = useState(true);

  const navigate = useNavigate();

  const { partnum } = useParams();

  const { Comppartnum } = useParams();

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  useEffect(() => {
    if (partnum && Comppartnum) {
      setparms(partnum);
      setCompparms(Comppartnum);
    }
    let raw = {
      partnumber: partnum,
      cartId: "0",
      compitorPartNo: Comppartnum,
      // "customerId": "24"
    };
    GetData(
      EnvData?.REACT_APP_APIURL +
        "/microsite-api-v1/get-Competitor-product-replacement-details",
      raw
    )
      .then((results) => {
        setCrossRefItems(results.productCrossreferences);
        setPartDetails(results.partInformation);
        setalterproducts(results.productAlternatives);
        setimages(results.productImages);
        setproductdescription(results.piesDescription);
        setUnitPrice(results.partInformation[0].discountUnitPrice);
        setCorePrice(results.partInformation[0].discountCorePrice);
        setloading(false);
      })
      .catch((err) => {
        console.error(err, "in product details");
      });
  }, [partnum]);

  let queryString = partdetails[0]?.partNumber; //
  let querdesp = partdetails[0]?.partDescriptionName; //
  const NavLink = `/Category/${queryString}`;
  return (
    <div>
      {loading == true ? (
        <>
          <ProductLoader />
        </>
      ) : (
        <>
         <MetaTag
            _pagename={"ProductDetails"}
            _productsdetails={partdetails}
            _fetchdata={false}
          />
          <Paper elevation={0} sx={{ mt: 1, mb: 1, py: 1 }}>
            <Container>
              <div className="product-right-border">
                <Typography
                  variant="h1"
                  className="part-accessories-competitornumber"
                >
                  {Comppartnum} {partdetails[0]?.competitorName}
                  <Divider
                    sx={{ bgcolor: "primary.main" }}
                    className="title-divider"
                  />
                </Typography>
              </div>
              <Typography className="stack-row">
                {partdetails[0]?.competitorName} {Comppartnum} can be replaced
                by {partdetails[0]?.brandName} <span style={{ marginRight:4}}>
                  <b>{partdetails[0]?.salesPartNumber}</b>
                  <Divider
                    sx={{ bgcolor: "primary.main"  }}
                    className="title-divider"
                  />
                </span>{" "} {partdetails[0]?.partDescriptionName}
               
              </Typography>

              {partdetails[0]?.notes !== "" && (
                <Typography>{partdetails[0]?.notes}</Typography>
              )}

              <div className="product-detail-main-container">
                <div className="product-detail-left product-detail-left-competitor">
                  <div className="product-detail-container">
                    <div className="product-detail-slider">
                      <ProductSlider props={images} />
                    </div>
                    <div className="product-detail-desc">
                      <div className="product-detail-descbox">
                        <Typography
                          className="product-detail-desc-para"
                          variant="h2"
                        >
                          <span className="part-number-divider part-accessories-number">
                            {partdetails[0]?.salesPartNumber}
                            <Divider
                              sx={{ bgcolor: "primary.main" }}
                              className="title-divider"
                            />
                          </span>
                        </Typography>
                      </div>
                      <div>{partdetails[0]?.micrositeTitle}</div>

                      <Typography
                        className="price-original"
                        variant="body2"
                        mt={2}
                      >
                        MSRP : ${partdetails[0]?.totalPrice.toFixed(2)}
                      </Typography>
                      <Stack
                        direction="row"
                        spacing={1}
                        mt={1}
                        alignItems="center"
                        className="product-detail-price-stack"
                      >
                        <Typography
                          variant="subtitle2"
                          fontSize={28}
                          className="total-price"
                        >
                          ${unitPrice.toFixed(2)}
                        </Typography>

                        {
  partdetails[0]?.discountPercentage.toString()!=="0.00"&& <div className="price-save">
                      <Typography variant="body2" sx={{ px: 1 }}>
                        {partdetails[0]?.discountPercentage}% OFF
                      </Typography>
                    </div>

}
                  {parseFloat(unitPrice.toFixed(2)) ===
                            0 ? null : partdetails[0]?.stockInfo === "In Stock" ? (
                              <p className="ready-to-ship">
                                <CheckCircleIcon /> In Stock
                              </p>
                            ) : partdetails[0]?.showOutOfStock ? (
                              <p className="out-of-stock">
                                <CancelSharpIcon sx={{ marginRight: "5px" }} />{" "}
                                Out of stock
                              </p>
                            ) : null}
                            
                      </Stack>
                      {
                    (corePrice).toFixed(2)!=="0.00" &&   <Typography
                    variant="body2"
                    sx={{
                      mt: 1,
                      fontWeight: "500",
                      fontSize: "14px",
                    }}
                  >
                    Core price : ${(corePrice).toFixed(2)}
                  </Typography>
                  }
                      <Typography
                        variant="h3"
                        sx={{
                          mt: 1,
                          fontWeight: "500",
                          fontSize: "14px",
                        }}
                      >
                        Total price : ${(unitPrice + corePrice).toFixed(2)}
                      </Typography>

                      <Button
                        onClick={() => navigate(NavLink)}
                        variant="contained"
                        sx={{ mt: 1 }}
                      >
                        View Details of the Product
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              {alterproducts.length > 0 ? (
                <>
                  {" "}
                  <Box mt={2}>
                    <div className="product-right-border">
                      <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        Similar Products
                        <Divider
                          sx={{ bgcolor: "primary.main" }}
                          className="title-divider"
                        />
                      </Typography>
                    </div>

                    <ProductGrid _product={alterproducts} Seo={true} />
                  </Box>
                </>
              ) : (
                <></>
              )}
            </Container>
          </Paper>
        </>
      )}
    </div>
  );
}
