import { Button, IconButton } from '@mui/material';
import React, { useState } from 'react'
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Link } from "react-router-dom";

const CustomMenu = (res:any) => {
    const [custommenu, setcustommenu] = useState(false);

   
    const handleDrawerClose = () => {
        setcustommenu(false);
    
      };
    

      const submenuClick = () => {
        setcustommenu((current) => !current);
      };
    

      console.log(res)
    return (
      <li className="linkstyle" onClick={submenuClick} key={res.res.id}>
        <Button
          // to="/Services"
          component="a"
          className="linkstyle"
          sx={{
            color: "primary.main",
            ":hover": {
              color: "primary.main",
            },
          }}
        >
          {res?.res?.customMenuName}
          <IconButton sx={{ marginLeft: "auto" }}>
            {custommenu ? (
              <RemoveIcon fontSize="small" />
            ) : (
              <AddIcon fontSize="small" />
            )}
          </IconButton>
        </Button>
        {custommenu &&
        res?.res?.subMenus.map((menu: any, index: number) => {
  
            const CustomMenuUrl = `/CustomPage/${menu?.customSubMenuId}?`;
            return (
              <ul className="submenu" key={index}>
                <li>
                  <Button
                    to={CustomMenuUrl}
                    component={Link}
                    onClick={handleDrawerClose}
                    className="menumega-list"
                  >
                    {menu?.customSubMenuName}{" "}
                  </Button>
                </li>
              </ul>
            );
          })
        }
      </li>
    );
}

export default CustomMenu