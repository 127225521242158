import { Container, Box, styled, Grid, InputBase, Paper, Typography } from "@mui/material";
import "../Css/partsearch.style.css";
import Filter from "../Components/PartSearchTest/Filter";
import BannerSearch from "../Components/PartSearchTest/BannerSearch";
import breadcrumbsbanner from "../Images/centralturbos/breadcrumbs-common-banner.png";
import { useSelector } from "react-redux";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  margin: "10px auto",
  color: "#000",
  backgroundColor: "#fff",
  textAlign: "center",
  "&:hover": {
    backgroundColor: "#fff",
  },
  border: "1px solid #ccc",
  marginLeft: "auto!important",
  width: "60%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(2),
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 0),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  right: "0",
  top: "0",
  "& .MuiButton-contained": {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
    margin: 2,
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 2, 1, 2),
    paddingright: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "80%",
    [theme.breakpoints.up("sm")]: {
      width: "80%",
      "&:focus": {
        width: "80%",
      },
    },
  },
}));

export default function PartSearchTest() {
  var raw = JSON.stringify({
    SearchText: "1131MF",
    // "MicrositeName" : process.env.REACT_APP_MICROSITENAME
  });

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  // const requestOptions = {
  //   method: "POST",
  //   headers: useGetauthHeader(),
  //   body: raw,
  // };
  // const [product] = useGetData<IFeatureproductProps[]>(
  //   process.env.REACT_APP_APIURL + "/microsite-api-v1/PartSearch",
  //   requestOptions,
  //   []
  // );

  // const [value, setValue] = React.useState(0);

  // const handleChangeIndex = (index: number) => {
  //   setValue(index);
  // };

  return (
    <div>
      {
         EnvData?.REACT_APP_SHOW_CENTRAL_TURBO == "true" ? (  <Paper elevation={0} sx={{mb: 1, py: 6,  backgroundImage: `url(${breadcrumbsbanner})`, }} className="central-turbos-breadcrumbs-banner">
         <Container>
             <Box sx={{px:5}}>
                 <Typography className="central-turbos-breadcrumbs-title">Part Search</Typography>
                 {/* <Typography className="central-turbos-breadcrumbs-sub-title">subhead</Typography> */}
             </Box>
         </Container>
     </Paper>):(null)
      }
          
      <Container>
        <Box sx={{ mt: 5, mb: 5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={3}>
              {/* ---------------------------------------------Filter -------------------------------------------------------------------- */}

              <Filter />

              {/* --------------------------------------------Attribute search------------------------------------------------------------------------------- */}
              <Box className="part-description">
                {/* Attribute Search section */}
                {/* <Stack mb={1} direction="row" spacing={2} >
                        <Typography variant="subtitle1">Attribute</Typography>
                        <Button variant="text" color="error" sx={{marginLeft:'auto!important'}} >
                                <RefreshIcon/> Reset
                          </Button>
                    </Stack> */}
                {/* <AttributeFilter /> */}
              </Box>

              {/* -------------------------------------------------------------------------------------------------------------------------- */}
            </Grid>

            {/* ------------------------------------------------------------------------------------------------------------ */}
            <Grid item xs={12} sm={12} md={12} lg={9}>
              <BannerSearch />
            </Grid>

            {/* -------------------------------------------------------------------------------------------------------------- */}
          </Grid>
        </Box>
      </Container>
    </div>
  );
}
