import React, { useEffect, useState, useRef, memo } from "react";

import {
  Autocomplete,
  Typography,
  Paper,
  Box,
  Grid,
  Button,
  TextField,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  makeStyles,
  FormControl,
} from "@mui/material";
import FilterExtender from "./FilterExtender";
import { useDispatch, useSelector } from "react-redux";
import {
  addBrandSelection,
  addCategorySelection,
  addDescriptionSelection,
  addEngineSelection,
  addMakeSelection,
  addModelSelection,
  addYearSelection,
  clearEngineSelection,
  clearMakeSelection,
  clearModelSelection,
  clearYearSelection,
  trigger,
} from "../../Redux/Counterslice";
import {
  addCategoryfilter,
  addDesfilter,
  addBrandfilter,
  addApplicationWithValue,
  addAttributeWithValue,
  clearalllApplicationWithValue,
  clearallAttributeWithValue,
} from "../../Redux/Counterslice";
import {
  EXTENDER_BRAND_FILTER,
  EXTENDER_CROSSREFERENCE_FILTER,
  DESCRIPTION_FILTER,
  BRAND_FILTER,
  CATEGORY_FILTER,
} from "../../Redux/ReduxConstants";
import {
  addExBrandfilter,
  addExCrossReffilter,
  addSearchtext,
  addVSyear,
  addVSmake,
  addVSmodel,
  addVSengine,
} from "../../Redux/Counterslice";
import { useResetVehicleSearch, useResetdata } from "../../Redux/Resetdata";

import { IVinAttribute } from "../../Types/MicrositeType";
import FilterApiFetch from "./FilterApiFetch";
import { GetData } from "../../API/GetDataFromApi";
import { IPartsearchAttributeTypeProps } from "../../Types/MicrositeType";
import SearchIcon from "@mui/icons-material/Search";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import "../../Css/partsearch.style.css";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import { ApplicationFilter } from "../Partsearch/ApplicationFilter";

import eventEmitter from "./eventEmmiter";

const VehicleSearch = ({ changeTabToIndexZero }: any) => {
  const selectData = (state: any) => state;
  const data = useSelector(selectData);
  const dispatch = useDispatch();

  const [Yearopen, setYearOpen] = React.useState(false);
  const [Makeopen, setMakeOpen] = React.useState(false);
  const [Modelopen, setModelOpen] = React.useState(false);
  const [Engineopen, setEngineOpen] = React.useState(false);
  const [SearchType, setSearchType] = React.useState("");
  const [Applicationfilter, setApplicationfilter] = useState<
    IPartsearchAttributeTypeProps[]
  >([]);
  const [SubApplicationFilters, setSubApplicationFilters] = useState<
    IPartsearchAttributeTypeProps[]
  >([]);
  const [YearValue, setYearValue] = React.useState<[]>([]);
  const [MakeValue, setMakeValue] = React.useState<[]>([]);
  const [ModelValue, setModelValue] = React.useState<[]>([]);
  const [EngineValue, setEngineValue] = React.useState<[]>([]);

  const [ApplicationType, setApplicationType] = useState("");

  const [Make, setMake] = useState(null);
  const [Model, setModel] = useState(null);
  const [Year, setYear] = useState(null);
  const [Engine, setEngine] = useState(null);

  const [state, setState] = useState({
    extBrandFilter: data.EXTENDER_BRAND_FILTER.brandId,
    extcompetitorID: data.EXTENDER_CROSSREFERENCE_FILTER.CompetitorId,
    extcompetitiorPartNumber:
      data.EXTENDER_CROSSREFERENCE_FILTER.CompetitorpartNumber,
    categoryFilter: data.CATEGORY_FILTER.categoryName,
    brandFilter: data.BRAND_FILTER.brandId,
    descriptionFilterr: data.DESCRIPTION_FILTER.descriptionId,
    YearFilter: data.YEAR_FILTER.YearName,
    makefilter: data.MAKE_FILTER.MakeName,
    ModelFilter: data.MODEL_FILTER.ModelName,
    EngineFilter: data.ENGINE_FILTER.EngineName,
    AttributeFiltervalus: data.ATTRIBUTE_FILTER_WITH_VALUE.items,
    ApplicationFiltervalus: data.APPLICATION_FILTER_WITH_VALUE.items,
    EnteredText: data.EXTENDER_TEXT_SEARCH.Text,
  });

  const { ResetreduxData } = useResetdata();

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      extBrandFilter: data.EXTENDER_BRAND_FILTER.brandId,
      extcompetitorID: data.EXTENDER_CROSSREFERENCE_FILTER.CompetitorId,
      extcompetitiorPartNumber:
        data.EXTENDER_CROSSREFERENCE_FILTER.CompetitorpartNumber,
      categoryFilter: data.CATEGORY_FILTER.categoryName,
      brandFilter: data.BRAND_FILTER.brandId,
      descriptionFilterr: data.DESCRIPTION_FILTER.descriptionId,
      YearFilter: data.YEAR_FILTER.YearName,
      makefilter: data.MAKE_FILTER.MakeName,
      ModelFilter: data.MODEL_FILTER.ModelName,
      EngineFilter: data.ENGINE_FILTER.EngineName,
      AttributeFiltervalus: data.ATTRIBUTE_FILTER_WITH_VALUE.items,
      ApplicationFiltervalus: data.APPLICATION_FILTER_WITH_VALUE.items,
      EnteredText: data.EXTENDER_TEXT_SEARCH.Text,
    }));

    if (data.MAKE_FILTER.MakeName === "") {
      setMake(null);
    }
    if (data.MODEL_FILTER.ModelName === "") {
      setModel(null);
    }
    if (data.CATEGORY_FILTER.YearName === "") {
      setYear(null);
    }
    if (data.YEAR_FILTER.EngineName == "") {
      setEngine(null);
    }
  }, [data]);

  useEffect(() => {
    if (
      (data.CATEGORY_FILTER.categoryName !== "" &&
        data.EXTRA_FILTERS.CategorySelection == true) ||
      (data.BRAND_FILTER.brandId !== "" &&
        data.EXTRA_FILTERS.BrandSelection == true) ||
      (data.DESCRIPTION_FILTER.descriptionId !== "" &&
        data.EXTRA_FILTERS.DescriptionSelection == true)
    ) {
      if (data.CATEGORY_FILTER.categoryName !== "") {
        dispatch(addCategorySelection(false));
      }

      if (data.BRAND_FILTER.brandId !== "") {
        dispatch(addBrandSelection(false));
      }

      if (data.DESCRIPTION_FILTER.descriptionId !== "") {
        dispatch(addDescriptionSelection(false));
      }
      setTimeout(() => dispatch(trigger()), 200);
      if (data.EXTRA_FILTERS.VehicleSearchFilterExists == false) {
        changeTabToIndexZero();
      }
    }

    if (
      state.makefilter ||
      state.ModelFilter ||
      state.EngineFilter ||
      state.YearFilter !== ""
    ) {
      setApplicationType("Application Additional Search");
      triggerGrid("application");
    }

    eventEmitter.on("triggerFunction", ClearVehicleFilters);

    return () => {
      // ResetreduxData()
      eventEmitter.off("triggerFunction", ClearVehicleFilters);
      // ShowGrid()
    };
  }, []);

  useEffect(() => {
    if (data.EXTRA_FILTERS.VehicleSearchFilterExists == false) {
      alert("No records in vehicle search");
      changeTabToIndexZero();
    }
  }, [data.EXTRA_FILTERS.VehicleSearchFilterExists]);

  useEffect(() => {
    //  setApplicationType("application");
    // setApplicationType('application')
    // triggerGrid("application");
    if (
      data.YEAR_FILTER.YearName !== "" ||
      data.MAKE_FILTER.MakeName !== "" ||
      data.MODEL_FILTER.ModelName !== "" ||
      data.ENGINE_FILTER.EngineName !== ""
    ) {
      //setAttributeType("Attribute Search");
      setApplicationType("Application Additional Search");
      triggerGrid("application");
    } else {
      triggerGrid(KeyName);
    }
  }, [
    state.ApplicationFiltervalus,
    state.YearFilter,
    state.makefilter,
    state.ModelFilter,
    state.EngineFilter,
  ]);

  const [vinNumber, setvinNumber] = useState("");
  const [VinAttribute, setVinAttribute] = useState<IVinAttribute[]>([]);
  const [AppDataColumn, setAppDataColumn] = useState<string[]>([]);
  const [vinApplication, setVinApplication] = useState(false);
  const [KeyName, setKeyName] = useState("");

  const Vin_Value = useRef<HTMLInputElement>(null);
  // -------------------------------------------------------------------//
  const handleSpecClose = () => {
    // setAnchorEl(anchorEl ? null : event.currentTarget);
    setVinApplication(false);
  };

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  const handleKeyPress = (event: any, VinExtractionId: any) => {
    if (event.key === "Enter") {
      // Call your function here
      // dispatch(trigger());
      SearchbyVin(event, 0);
    }
  };

  const handleVinChange = (event: any) => {
    if (event.target.value != "") {
      ClearVehicleFilters();
      setvinNumber(event.target.value);
    }
  };

  function ClearVehicleFilters() {
    setvinNumber("");
    setMake(null);
    setModel(null);
    setYear(null);
    setEngine(null);
    setKeyName("");
    setApplicationfilter([]);
    setSubApplicationFilters([]);
  }

  function SearchbyVin(e: any, VinExtractionId: any) {
    // console.log("vinsearch");
    const VIN = Vin_Value.current?.value;
    setvinNumber(VIN ? VIN : "");
    let raw = {
      vinNumber: VIN,
      applicationDataExtractedId: VinExtractionId,
    };

    if (VIN !== "") {
      // console.log(raw, "reset from vinsearch1");
      GetData(
        EnvData?.REACT_APP_APIURL +
          "/microsite-api-v1/get-part-search-vin-info",
        raw
      )
        .then((results?) => {
          // console.log(results, "reset from vinsearch1");
          if (results.length > 0) {
            handleSpecClose();
            if (results[0].flag == "Parts") {
              let type: any = {
                YearId: "",
                YearName: results[0].year.trim(),
              };

              let maketype: any = {
                MakeId: "",
                MakeName: results[0].make.trim(),
              };

              let modeltype: any = {
                ModelId: "",
                ModelName: results[0].model.trim(),
              };
              let engtype: any = {
                EngineId: "",
                EngineName: results[0].liter.trim(),
              };
              // setMake(maketype);
              // setModel(modeltype);
              // setYear(type);
              // setEngine(engtype);
              dispatch(addVSyear(type));
              dispatch(addVSmake(maketype));
              dispatch(addVSmodel(modeltype));
              dispatch(addVSengine(engtype));
              setApplicationType("Application Additional Search");
              setTimeout(() => dispatch(trigger()), 200);
              setKeyName("application");
            } else {
              var DefaultColumn = "VehicleType,Make,Model,Year,Notes,";
              DefaultColumn += results[0].attribute;
              setAppDataColumn(DefaultColumn.split(","));
              setVinAttribute(results);

              setVinApplication(true);
            }
          } else {
            alert(
              "Sorry, we have no application data at this time. Please call customer service for further assistance"
            );
          }
        })
        // .then(() => {
        //   setTimeout(() => dispatch(trigger()), 200);
        //   setKeyName("application");
        //   // if (
        //   //   data.YEAR_FILTER.YearName !== "" ||
        //   //   data.MAKE_FILTER.MakeName !== "" ||
        //   //   data.MODEL_FILTER.ModelName !== "" ||
        //   //   data.ENGINE_FILTER.EngineName !== ""
        //   // ) {
        //   //   //setAttributeType("Attribute Search");
        //   //   ApplicationType = "Application Additional Search";
        //   //   triggerGrid("application");
        //   // }

        //   // setTimeout(() => dispatch(trigger()), 200);
        //   // setTimeout(() => triggerGrid("application"), 500);
        //   //triggerGrid("application");
        //   //dispatch(trigger());
        // })
        .catch((err) => {
          console.error(err, "From VIn");
        });
    }
  }

  const makeloading = Makeopen && MakeValue.length === 0;
  const yearloading = Yearopen && YearValue.length === 0;
  const modelloading = Modelopen && ModelValue.length === 0;
  const engineloading = Engineopen && EngineValue.length === 0;

  const newFilteredArray = state.AttributeFiltervalus.filter(
    (value: any) => value.AttributeName !== KeyName
  );

  const destinationArray = newFilteredArray.map((obj: any) => ({
    attributeId: obj.Attributeid,
    attributeValue: obj.AttributeValue,
    fromValue: obj.Attributefrom,
    toValue: obj.Attributeto,
  }));
  const newFilteredAppArray = state.ApplicationFiltervalus.filter(
    (value: any) => value.ApplicationName !== KeyName
  );

  const appdesitinationarray = newFilteredAppArray.map((obj: any) => ({
    applicationId: obj.Applicationid,
    applicationValue: obj.ApplicationValue,
  }));

  function CurrentData(newValue: any) {
    setvinNumber("");
    // setSelectedCatValue(newValue);
    if (KeyName == "Year") {
      let type = {
        YearId: newValue.keyId,
        YearName: newValue.keyName,
      };
      dispatch(addVSyear(type));
      dispatch(clearYearSelection());
    } else if (KeyName == "Make") {
      // console.log("Data NewValue111", newValue);
      let type = {
        MakeId: newValue.keyId,
        MakeName: newValue.keyName,
      };
      dispatch(addVSmake(type));
      dispatch(clearMakeSelection());
    } else if (KeyName == "Model") {
      let type = {
        ModelId: newValue.keyId,
        ModelName: newValue.keyName,
      };
      dispatch(addVSmodel(type));
      dispatch(clearModelSelection());
    } else if (KeyName == "Engine") {
      if (newValue.keyName !== "All Engines") {
        let type = {
          EngineId: newValue.keyId,
          EngineName: newValue.keyName,
        };
        dispatch(addVSengine(type));
        dispatch(clearEngineSelection());
      }
    } else {
      console.error("Key name is incorrect");
    }
  }

  useEffect(() => {
    if (KeyName != "") {
      let raw = JSON.stringify({
        keyName: KeyName,
        sessionid: "",
        keyvalue:
          state.extcompetitorID == "" &&
          state.extcompetitiorPartNumber == "" &&
          state.extBrandFilter == ""
            ? state.EnteredText
            : "",
        brandId: state.extBrandFilter,
        competitorId: state.extcompetitorID,
        make: KeyName?.toLowerCase() == "make" ? "" : state.makefilter,
        model: KeyName?.toLowerCase() == "model" ? "" : state.ModelFilter,
        year: KeyName?.toLowerCase() == "year" ? "" : state.YearFilter,
        engine: KeyName?.toLowerCase() == "engine" ? "" : state.EngineFilter,
        category:
          KeyName?.toLowerCase() == "micrositecategory"
            ? ""
            : state.categoryFilter,
        brandFilter:
          KeyName?.toLowerCase() == "brandname" ? "" : state.brandFilter,
        type:
          KeyName?.toLowerCase() == "make" ||
          KeyName?.toLowerCase() == "model" ||
          KeyName?.toLowerCase() == "year" ||
          KeyName?.toLowerCase() == "engine" ||
          KeyName?.toLowerCase() == "micrositecategory" ||
          KeyName?.toLowerCase() == "brandname" ||
          KeyName?.toLowerCase() == "partdescription"
            ? ""
            : ApplicationType,
        descriptions:
          KeyName?.toLowerCase() == "partdescription"
            ? ""
            : state.descriptionFilterr,
        competitorPartno: state.extcompetitiorPartNumber,
        attributeFilters: destinationArray,
        applicationFilters: appdesitinationarray,
      });
      // console.log("Data Check For VIN 1235", state);
      (async () => {
        FilterApiFetch(raw, EnvData).then((res: any) => {
          if (res.length > 0) {
            if (KeyName == "Year") {
              setYearValue(res);
            } else if (KeyName == "Make") {
              setMakeValue(res);
            } else if (KeyName == "Model") {
              setModelValue(res);
            } else if (KeyName == "Engine") {
              setEngineValue(res);
            }
            // setOptions(res)
            else if (ApplicationType == "Application Additional Search") {
              setApplicationfilter(res);
            } else if (ApplicationType == "application") {
              setSubApplicationFilters(res);
            }
          } else {
            if (
              (KeyName == "Year" ||
                KeyName == "Make" ||
                KeyName == "Model" ||
                KeyName == "Engine") &&
              ApplicationType !== "Application Additional Search" &&
              ApplicationType !== "application"
            ) {
              alert(KeyName + " Filters Not Available");
            }

            const norec: any = [{ keyId: 0, keyName: "All Engines" }];

            setKeyName("");
            if (KeyName == "Year") {
              setYearValue([]);
            } else if (KeyName == "Make") {
              setMakeValue([]);
            } else if (KeyName == "Model") {
              setModelValue([]);
            } else if (KeyName == "Engine") {
              setEngineValue(norec);
            }
            // setOptions(res)
            else if (ApplicationType == "Application Additional Search") {
              setApplicationfilter([]);
            } else if (ApplicationType == "application") {
              setSubApplicationFilters([]);
            }
          }
        });
      })();
    }
  }, [KeyName]);

  function triggerGrid(keynme: string) {
    setKeyName(keynme);
    // console.log("Data Check For VIN 123", data);
    // console.log("Data Check For VIN 1234", state);
  }

  function ShowGrid() {
    if (state.makefilter !== "") {
      dispatch(addMakeSelection(false));
    }
    if (state.ModelFilter !== "") {
      dispatch(addModelSelection(false));
    }
    if (state.YearFilter !== "") {
      dispatch(addYearSelection(false));
    }
    if (state.EngineFilter !== "") {
      dispatch(addEngineSelection(false));
    }

    if (vinNumber != "") {
      SearchbyVin("", "");
    } else {
      // console.log("erorr");
      setTimeout(() => dispatch(trigger()), 200);
      if (
        data.YEAR_FILTER.YearName !== "" ||
        data.MAKE_FILTER.MakeName !== "" ||
        data.MODEL_FILTER.ModelName !== "" ||
        data.ENGINE_FILTER.EngineName !== ""
      ) {
        //setAttributeType("Attribute Search");
        setApplicationType("Application Additional Search");
        triggerGrid("application");
      } else {
        triggerGrid(KeyName);
      }
    }
  }

  function SelectApplication(key: any, keytype: any) {
    //setAttributeType("Attribute");
    setApplicationType("application");
    setKeyName(key);
    triggerGrid(key);
  }

  function ResetData() {
    // console.log("reset");
    setvinNumber("");
    ClearVehicleFilters();
    ResetreduxData();
    setTimeout(() => dispatch(trigger()), 200);
    // setTimeout(() => ShowGrid(), 200);
    // console.log("reset");
  }

  function showapplicationsub() {
    ShowGrid();
  }
  return (
    <>
      <Box sx={{ mb: 2, minWidth: "100%" }} className="tab-panel-formcontrol">
        {/* <InputLabel>Select Make</InputLabel> */}
        {/* <FilterExtender _keyname={"Year"} value='' /> */}
        {data.EXTRA_FILTERS.YearSelection == true ? (
          <Autocomplete
            id="asynchronous-demo"
            open={Yearopen}
            onOpen={() => {
              setYearOpen(true);
            }}
            onClose={() => {
              setYearOpen(false);
            }}
            isOptionEqualToValue={(option: any, value: any) =>
              option.keyName === value.keyName
            }
            getOptionLabel={(option: any) => option.keyName}
            options={YearValue}
            loading={yearloading}
            disableClearable
            value={Year}
            onChange={(event, newValue: any) => {
              setYear(newValue);
              CurrentData(newValue);
            }}
            renderInput={(params) => (
              <TextField
                onClick={() => triggerGrid("Year")}
                {...params}
                placeholder="Year"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {yearloading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        ) : (
          <div className="disabled-textbox">{data.YEAR_FILTER.YearName}</div>
        )}
      </Box>
      <Box sx={{ mb: 2, minWidth: "100%" }} className="tab-panel-formcontrol">
        {/* <InputLabel>Select Make</InputLabel> */}
        {/* <FilterExtender _keyname={"Make"} value=''  /> */}
        {data.EXTRA_FILTERS.MakeSelection == true ? (
          <Autocomplete
            id="asynchronous-demo"
            open={Makeopen}
            onOpen={() => {
              setMakeOpen(true);
            }}
            onClose={() => {
              setMakeOpen(false);
            }}
            isOptionEqualToValue={(option: any, value: any) =>
              option.keyName === value.keyName
            }
            getOptionLabel={(option: any) => option.keyName}
            options={MakeValue}
            loading={makeloading}
            disableClearable
            value={Make}
            onChange={(event, newValue: any) => {
              // console.log("Make Change", newValue);
              setMake(newValue);
              CurrentData(newValue);
            }}
            renderInput={(params) => (
              <TextField
                onClick={() => triggerGrid("Make")}
                {...params}
                placeholder="Make"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {makeloading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        ) : (
          <div className="disabled-textbox">{data.MAKE_FILTER.MakeName}</div>
        )}
      </Box>

      <Box sx={{ mb: 2, minWidth: "100%" }} className="tab-panel-formcontrol">
        {/* <FilterExtender _keyname={"Model"} value='' /> */}
        {data.EXTRA_FILTERS.ModelSelection == true ? (
          <Autocomplete
            id="asynchronous-demo"
            open={Modelopen}
            onOpen={() => {
              setModelOpen(true);
            }}
            onClose={() => {
              setModelOpen(false);
            }}
            isOptionEqualToValue={(option: any, value: any) =>
              option.keyName === value.keyName
            }
            getOptionLabel={(option: any) => option.keyName}
            options={ModelValue}
            loading={modelloading}
            disableClearable
            value={Model}
            onChange={(event, newValue) => {
              setModel(newValue);
              CurrentData(newValue);
            }}
            renderInput={(params) => (
              <TextField
                onClick={() => triggerGrid("Model")}
                {...params}
                placeholder="Model"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {modelloading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        ) : (
          <div className="disabled-textbox">{data.MODEL_FILTER.ModelName}</div>
        )}
      </Box>

      <Box sx={{ mb: 2, minWidth: "100%" }} className="tab-panel-formcontrol">
        {/* <FilterExtender _keyname={"Engine"} value='' /> */}
        {data.EXTRA_FILTERS.EngineSelection == true ? (
          <Autocomplete
            id="asynchronous-demo"
            open={Engineopen}
            onOpen={() => {
              setEngineOpen(true);
            }}
            onClose={() => {
              setEngineOpen(false);
            }}
            isOptionEqualToValue={(option: any, value: any) =>
              option.keyName === value.keyName
            }
            getOptionLabel={(option: any) => option.keyName}
            options={EngineValue}
            loading={engineloading}
            disableClearable
            value={Engine}
            onChange={(event, newValue) => {
              setEngine(newValue);
              CurrentData(newValue);
            }}
            renderInput={(params) => (
              <TextField
                onClick={() => triggerGrid("Engine")}
                {...params}
                placeholder="Engine"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {engineloading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            noOptionsText="No records found"
          />
        ) : (
          <div className="disabled-textbox">
            {data.ENGINE_FILTER.EngineName}
          </div>
        )}
      </Box>

      <Typography variant="subtitle2" sx={{ pt: 1, textAlign: "center" }}>
        (OR)
      </Typography>
      <Typography variant="subtitle2" sx={{ p: 1 }}>
        VIN SEARCH
      </Typography>
      <TextField
        value={vinNumber}
        inputRef={Vin_Value}
        onKeyDown={(e) => handleKeyPress(e, 0)}
        onChange={handleVinChange}
        fullWidth
        placeholder="VIN Number Search"
        variant="outlined"
        className="tab-panel-formcontrol"
      />
      <Box sx={{ mt: 2 }}>
        <Button
          onClick={ShowGrid}
          variant="contained"
          color="primary"
          sx={{ mr: 2 }}
        >
          Search
        </Button>
        <Button variant="outlined" onClick={ResetData}>
          Reset
        </Button>
      </Box>

      <Dialog
        open={vinApplication}
        onClose={handleSpecClose}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          Vin Application - {vinNumber}
          <IconButton
            aria-label="close"
            onClick={handleSpecClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TableContainer
            component={Paper}
            elevation={0}
            className="specification-popup-container"
          >
            <Table
              stickyHeader
              aria-label="simple table"
              className="cross-ref-table"
            >
              <TableHead>
                <TableRow>
                  {AppDataColumn?.map((res: any, id: any) => {
                    return <TableCell key={id}>{res}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {VinAttribute?.map((subres: any, id: any) => {
                  return (
                    <TableRow
                      key={id}
                      onClick={(e) =>
                        SearchbyVin(
                          e,
                          String(subres.applicationDataExtractedId)
                        )
                      }
                      className="pointer-Cursor"
                    >
                      <TableCell>{subres.vechicle}</TableCell>
                      <TableCell>{subres.make}</TableCell>
                      <TableCell>{subres.model}</TableCell>
                      <TableCell>{subres.year}</TableCell>
                      <TableCell>{subres.notes}</TableCell>
                      <TableCell>{subres.attribute1}</TableCell>
                      <TableCell>{subres.attribute2}</TableCell>
                      <TableCell>{subres.attribute3}</TableCell>
                      <TableCell>{subres.attribute4}</TableCell>
                      <TableCell>{subres.attribute5}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>

      {Applicationfilter.length > 0 ? (
        <ApplicationFilter
          _application={Applicationfilter}
          selectapplication={SelectApplication}
          applicationSub={SubApplicationFilters}
          trigg={showapplicationsub}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default VehicleSearch;
