import React from "react";
import { useNavigate } from "react-router-dom";

const Referencetable = (props: {
  props: any;
  count: any;
  data: any;
  table?: string;
}) => {
  const navigate = useNavigate();
  return (
    <div className="">
      <div className="cross-ref">
        {props.count !== 0 ? (
          <>
            <table className="cross-ref-table">
              <tbody>
                {props.table == "productWhereused" ? (
                  <tr>
                    <th style={{width:"120px"}}> {props.props.Head_no}</th>
                    <th style={{width:"120px"}}> {props.props.Head_SalesPart_no}</th>
                    <th> {props.props.Head_name}</th>
                  </tr>
                ) : props.table == "Application_info" ? (
                  <tr>
                    <th> {props.props.Head_no}</th>
                    <th> {props.props.Head_name}</th>
                    <th> {props.props.Head_type}</th>
                    <th> {props.props.Head_VechType}</th>
                    <th> {props.props.Head_noter}</th>
                  </tr>
                ) : (
                  <tr>
                    <th> {props.props.Head_no}</th>
                    <th style={{width:"110px"}}> {props.props.Head_name}</th>
                    <th> {props.props.Head_type}</th>
                    <th className="note-th"> {props.props.Head_noter}</th>
                  </tr>
                )}

                {props.table == "productBOM"
                  ? props.data &&
                    props.data.map((elem: any, index: any) => {
                      let partsearchlink = `/Category/${elem?.partNumber}`;
                      return (
                        <tr key={index}>
                          <td className="image-td">
                            <img src={elem.imageUrl} alt="" loading="lazy" />
                          </td>
                          <td
                            className={elem.active ? "Referencetabledata" : ""}
                            onClick={
                              elem.active
                                ? () => {
                                    navigate(partsearchlink);
                                  }
                                : undefined
                            }
                          >
                            {elem["partNumber"]}
                          </td>
                          <td>{elem["description"]}</td>
                          <td>{elem["notes"]}</td>
                        </tr>
                      );
                    })
                  : props.table == "Application_info"
                  ? props.data &&
                    props.data.map((elem: any, index: any) => {
                      return (
                        <tr key={index}>
                          {/* <td>{elem.imageUrl}</td> */}
                          {/* <img src={elem.imageUrl} alt="" loading='lazy'/> */}
                          <td>{elem["yearRange"]}</td>
                          <td>{elem["make"]}</td>
                          <td>{elem["model"]}</td>
                          <td>{elem["vehicleType"]}</td>
                          <td>{elem["notes"]}</td>
                        </tr>
                      );
                    })
                  : props.table == "productWhereused"
                  ? props.data &&
                    props.data.map((elem: any, index: any) => {
                      let partsearchlink = `/Category/${elem?.partNumber}`;
                      return (
                        <tr key={index}>
                          <td
                            className={elem.active ? "Referencetabledata" : ""}
                            onClick={
                              elem.active
                                ? () => navigate(partsearchlink)
                                : undefined
                            }
                          >
                            {elem["partNumber"]}
                          </td>
                          <td>{elem["salesPartNumber"]}</td>
                          <td>{elem["description"]}</td>
                        </tr>
                      );
                    })
                  : props.data &&
                    props.data.map((elem: any, index: any) => (
                      <tr key={index}>
                        <td>{elem["competitorPartNo"]}</td>
                        <td>{elem["competitorName"]}</td>
                        <td>{elem["interchangeTypes"]}</td>
                        <td>{elem["notes"]}</td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Referencetable;
