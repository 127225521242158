import React from 'react';
import { Typography, FormGroup, FormControlLabel, Checkbox, Button, Stack, InputLabel, OutlinedInput, Box, Grid } from '@mui/material';
import { addAttribute, addAttributeWithValue  , addfromto } from '../../Redux/Counterslice';
import { useDispatch, useSelector } from 'react-redux';

const RangeFilter = ({props , triggersearch}:any) => {
    const [from, setFrom] = React.useState('');    
    const [to, setTo] = React.useState(''); 
    const enteredData = (state: any) => state.ATTRIBUTE_FILTER_WITH_VALUE;

    const EnteredText = useSelector(enteredData);


   React.useEffect(()=>{
    if(EnteredText.items.length == 0) {
      setFrom('')
      setTo('')
    }
    
   },[EnteredText])
  
    const dispatch = useDispatch();
    // console.log(props , "props")
    function Serchfromto (resfromApi:any) {
  
        let type = {
            Attributeid: resfromApi.keyId ,
         AttributeName:resfromApi.keyName,
         AttributeValue:"",
         Attributefrom :Number(from) ,
         Attributeto:Number(to)
        }

        dispatch(addAttributeWithValue(type))
        triggersearch()
      }
      
  return (
  <>
    <Stack direction="row" spacing={2} sx={{alignItems:'center', marginBottom:'10px'}} className='range-filter-stack'>
                        <InputLabel shrink className="input-label">From</InputLabel>
                        <OutlinedInput value={from} placeholder="" type="number"  inputProps={{
        pattern: '[0-9]*', // Only allow numbers
      }}onChange={(e)=>{setFrom(e.target.value)}}/>
                        <InputLabel shrink className="input-label">To</InputLabel>
                        <OutlinedInput value={to} placeholder="" type="number"  inputProps={{
        pattern: '[0-9]*', // Only allow numbers
      }} onChange={(e)=>{setTo(e.target.value)}}/>               
                        </Stack>
                        <Button variant="contained" onClick={()=>Serchfromto(props)}>Search</Button> 
  </>
  )
}

export default RangeFilter