import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Typography,
  Container,
  Paper,
  Box,
  Grid,
  Divider,
  TextField,
  Button,
  Stack,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { DODBActivity } from "../../API/GetDataFromApi";
import { CartContext } from "../Cart/CartContext";
import Description from "../Product/description";
import { DebounceInput } from "react-debounce-input";
import { useNavigate } from "react-router-dom";

import ProductLoader from "../ProductLoader";

import { clearLocalstorageValues } from "../../API/GetMicrositeSettings";
import { useSelector } from "react-redux";

const Ordersummaryproducts = (props: {
  data: any;
  key: any;
  refetch: any;
  closeDraw: any;
  NavigateHome: any;
  orderSummary: boolean | undefined;
}) => {
  const [count, setCount] = useState(props.data.qty);
  const [Data, setData] = useState(props.data);
  // const [previousCount, setPreviousCount] = useState(props.data.qty);
  const [Stdcount, setStdCount] = useState(props.data.stdSellingPack);
  const [inputValue, setInputValue] = useState(props.data.qty);
  const [SelectDelete, setselectDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  let timeouts: any = [];

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  function clearAllTimeouts() {
    timeouts.forEach((id: any) => {
      clearTimeout(id);
    });

    // Empty the array after clearing the timeouts
    timeouts = [];
  }
  useEffect(() => {
    //console.log(props.data, "propos data");
    setData({ ...props.data });
  }, [props.data]);
  // console.log(props.NavigateHome, "Cart Info ree");

  const { deleteItem } = useContext(CartContext);
  const navigate = useNavigate();
  useEffect(() => {
    // Clear the input value after 3 seconds if it is '0'
    if (inputValue === "") {
      const myTimeout = setTimeout(() => {
        setCount(Data.qty);
        setInputValue("1");
      }, 1000);

      // Clear the timer when the component unmounts or the inputValue changes
      return () => clearAllTimeouts();
    }
  }, [inputValue]);

  const CustomerId =
    localStorage.getItem("CustomerId") === undefined ||
    localStorage.getItem("CustomerId") === null ||
    localStorage.getItem("CustomerId") === ""
      ? "0"
      : localStorage.getItem("CustomerId");

  const handleInputChange = async (e: any, productId: number) => {
    const value = e.target.value;
    setInputValue(value);
    //console.log(value, " value");
    // Update the count state with the parsed integer value if it is a valid number
    const previousCount = count;
    const parsedValue = parseInt(value, 10);
    if (
      !isNaN(parsedValue) &&
      parsedValue >= 0 &&
      parsedValue % Stdcount === 0
    ) {
      // setCount(parsedValue);
      var raw = {
        id: productId,
        //   qty: count,
        qty: parsedValue,
        action: "update",
        cartId: 0,
        customerId: CustomerId,
      };

      const res = await DODBActivity(
        EnvData?.REACT_APP_APIURL + "/microsite-api-v1/update-cart-info",
        JSON.stringify(raw)
      );

      if (res) {
        const myTimeout = setTimeout(() => {
          props.refetch();
        }, 1000);

        timeouts.push(myTimeout);
      }
    } else if (parsedValue % Stdcount !== 0 && parsedValue > 0) {
      // setInputValue(previousCount.toString());
      //console.log(parsedValue % Stdcount, previousCount, "Parsed");
      alert("Invalid Entry");

      setCount(Stdcount);

      // props.refetch();
    } else {
      setCount(Data.qty);
      var raw = {
        id: productId,
        //   qty: count,
        qty: parsedValue,
        action: "update",
        cartId: 0,
        customerId: CustomerId,
      };
      (async () => {
        const res = await DODBActivity(
          EnvData?.REACT_APP_APIURL + "/microsite-api-v1/update-cart-info",
          JSON.stringify(raw)
        );

        if (res) {
          //console.log(res.status, "from response");
          const myTimeout = setTimeout(() => {
            props.refetch();
          }, 1000);
          timeouts.push(myTimeout);
        }
      })();
    }
  };

  // const handleInputChange = async (e: any, productId: number) => {
  //   const value = e.target.value;
  //   setInputValue(value);

  //   const parsedValue = parseInt(value, 10);

  //   if (!isNaN(parsedValue) && parsedValue >= 0 && parsedValue % Stdcount === 0) {
  //     try {
  //       setLoading(true);

  //       const raw = {
  //         id: productId,
  //         qty: parsedValue,
  //         action: "update",
  //         cartId: 0,
  //  customerId:CustomerId
  //       };

  //       const res = await DODBActivity(
  //         EnvData?.REACT_APP_APIURL + "/microsite-api-v1/update-cart-info",
  //         JSON.stringify(raw)
  //       );

  //       if (res) {
  //         props.refetch();
  //       }
  //     } catch (error) {
  //       // Handle the error if needed
  //     } finally {
  //       setLoading(false);
  //     }
  //   }
  // };

  const updateCart = async (productId: any, action: string) => {
    try {
      setLoading(true); // Start loading

      var raw = {
        id: productId,
        qty: action === "add" ? count + 1 : count - 1,
        action: "update",
        cartId: 0,
        customerId: CustomerId,
      };

      const res = await DODBActivity(
        EnvData?.REACT_APP_APIURL + "/microsite-api-v1/update-cart-info",
        JSON.stringify(raw)
      );

      if (res) {
        //console.log(res.status, "from response");
        props.refetch();
        // timeouts.push(myTimeout);
      }
    } catch (error) {
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const deleteCart = async (Id: number, partnumber: string) => {
    var raw = {
      id: Id,
      qty: count,
      action: "delete",
      cartId: 0,
    };

    try {
      setLoading(true); // Start loading

      const res = await DODBActivity(
        EnvData?.REACT_APP_APIURL + "/microsite-api-v1/delete-cart-info",
        JSON.stringify(raw)
      );
      //console.log(res, "from delete");
      if (res) {
        setselectDelete(!SelectDelete);
        // const myTimeout =  setTimeout(()=>{
        props.refetch();
        // },1000)
        // timeouts.push(myTimeout);

        deleteItem(partnumber);
        if (props.NavigateHome == true && props.orderSummary == true) {
          // console.log(props.NavigateHome,props.orderSummary,  "from delete");
          clearLocalstorageValues();
          navigate("/");
          setTimeout(() => {
            window.location.reload();
          }, 200);
        } else if (props.NavigateHome == true) {
          // console.log(props.NavigateHome ,"props.NavigateHome")
          setTimeout(() => {
            window.location.reload();
          }, 200);
        }
      }
    } catch (error) {
    } finally {
      setLoading(false); // Stop loading
    }
  };
  let shortdesc = Data.partDescriptionName.slice(0, 60);

  if (loading)
    return (
      <div>
        {" "}
        <ProductLoader />
      </div>
    );

  return (
    <>
      <div className="order-box">
        <div className="order-box-inner">
          <div className="order-box-section">
            <div className="order-box-img-close">
              <div className="order-box-image">
                <img src={Data.imgSrc} alt="images" />
              </div>
            </div>
            <div className="qty-box order-qty-box">
              {/* <span className="qty-label">Qty: </span> */}
              <Button
                onClick={() => {
                  setCount(count - Stdcount);
                  updateCart(Data.pId, "minus");
                }}
                disabled={count === Stdcount}
              >
                <RemoveIcon />
              </Button>

              <DebounceInput
                // className="px-2"
                value={count}
                variant="outlined"
                className="debounce-input"
                debounceTimeout={1500}
                onChange={(e) => handleInputChange(e, Data.pId)}
              />
              <Button
                onClick={() => {
                  setCount(count + Stdcount);
                  updateCart(Data.pId, "add");
                }}
              >
                <AddIcon />
              </Button>
            </div>
            <div className="order-box-close">
              <Typography
                onClick={() => {
                  // console.log(props.NavigateHome,props.orderSummary,  "from delete");
                  deleteCart(Data.pId, Data.partNumber);
                }}
                sx={{ cursor: "pointer" }}
              >
                Remove
              </Typography>
            </div>
          </div>
          <div
            className="order-box-content"
            onClick={() => {
              navigate("/Category/" + Data.partNumber);
              props.closeDraw();
            }}
          >
            <Typography className="order-box-partnumber" variant="body2">
              {Data.partNumber}
            </Typography>
            <Typography className="order-box-title" variant="body2">
              {/* {props.data.partDescriptionName} */}
              <Description
                Fulltext={Data.partDescriptionName}
                slicedtext={shortdesc}
                ordersumm={true}
              />
            </Typography>
            {/* <Typography className="order-box-title" variant='body2'>
                                 Qty : 1
                             </Typography> */}
            <Stack direction="row" spacing={2} mb={1}>
              <Typography variant="body2">
                Unit : $ {Data.unitPrice.toFixed(2)}
              </Typography>
              {Data.corePrice > 0 ? (
                <>
                  {" "}
                  <Divider orientation="vertical" />
                  <Typography variant="body2">
                    Core : $ {Data.corePrice.toFixed(2)}
                  </Typography>
                </>
              ) : (
                <></>
              )}
            </Stack>
            <Typography>
              <b>Total : </b>{" "}
              <Typography
                variant="subtitle2"
                sx={{ color: "primary.main", display: "inline-block" }}
              >
                ${Data.extendTotal.toFixed(2)}
              </Typography>
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ordersummaryproducts;
