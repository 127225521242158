
import { Box, Container, Grid, Paper, Stack, Typography } from "@mui/material";

import breadcrumbsbanner from "../../Images/centralturbos/breadcrumbs-contact-banner.png"
import aboutus1 from "../../Images/centralturbos/aboutus-1.png";
import aboutus2 from "../../Images/centralturbos/aboutus-2.png";
import aboutus3 from "../../Images/centralturbos/aboutus-3.png";


export default function CentralTurbosAboutUs() {
  return(
    <div>
      <Paper elevation={0} sx={{ mt: 0,p: 7,  backgroundImage: `url(${breadcrumbsbanner})`, }} className="central-turbos-breadcrumbs-banner">
          <Container>
              <Box sx={{px:5}}>
                  <Typography className="central-turbos-breadcrumbs-title">About Us</Typography>
                  {/* <Typography className="central-turbos-breadcrumbs-sub-title">subhead</Typography> */}
              </Box>
          </Container>
      </Paper>
      <Paper elevation={0} sx={{mb: 2}} className="central-turbos-aboutus-paper">
          <Container>
            <Grid container spacing={0} alignContent="center" className="central-turbos-aboutus-grid">
                <Grid item xs={12} md={6}>
                  <Box className="central-turbos-aboutus-content">
                      <Typography className="central-turbos-aboutus-subhead">Who We Are</Typography>
                      <Typography>Welcome to Central Turbos, your trusted and leading distributor of turbochargers and diesel parts. With over two decades of industry expertise, we have been proudly serving customers since 2001.
At Central Turbos, we understand the importance of reliable and high-quality in maintaining your equipment and fleets. As specialists in our field, we cater to a wide range of industries and applications, delivering top-quality products that meet and exceed industry standards to ensure that your equipment can stay up and running for as long as possible.
</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="central-turbos-aboutus-img-box">
                    <img src={aboutus3} alt="" />
                  </Box>
                </Grid>
            </Grid>
            <Grid container spacing={0} alignContent="center" className="central-turbos-aboutus-grid">
              <Grid item xs={12} md={6}>
                <Box className="central-turbos-aboutus-img-box-left">
                    <img src={aboutus2} alt="" />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="central-turbos-aboutus-content">
                      <Typography className="central-turbos-aboutus-subhead">Dedicated Distributors</Typography>
                      <Typography>Our experience in the market and commitment to excellence drives us to partner with top OEM suppliers and manufacturers; as a Franchise Distributor for Borg Warner, and trusted master distributors for other major brands such as Holset/Cummins, IHI, ProDiesel, Zeki Turbo, Pure Power, Denso, Mitsubishi, PAI, IPD, McBee, Just Turbo, and Full Tilt, we take pride in our extensive product selection, offering a comprehensive range of turbochargers and diesel parts to meet the unique demands of various vehicles, applications, and equipment.</Typography>
                  </Box>
                </Grid>
            </Grid>
            <Grid container spacing={0} alignContent="center" className="central-turbos-aboutus-grid">
                <Grid item xs={12} md={6}>
                  <Box className="central-turbos-aboutus-content">
                      <Typography className="central-turbos-aboutus-subhead">A Team You Can Trust</Typography>
                      <Typography>It's not just our products that set us apart – it's our dedication to exceptional customer service. Our knowledgeable team is always ready to provide expert guidance, assisting you in finding the perfect turbocharger solution for your needs. When you choose Central Turbos, you can trust in the quality of our products, the expertise of our team, and our commitment to your satisfaction. Experience the difference today!</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="central-turbos-aboutus-img-box">
                    <img src={aboutus1} alt="" />
                  </Box>
                </Grid>
            </Grid>
          </Container>
      </Paper>
    </div>
  )

}
