import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Stack,
  InputLabel,
  OutlinedInput,
  Box,
  Grid,
  RadioGroup,
  Radio,
  Divider,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import RefreshIcon from "@mui/icons-material/Refresh";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import Menu from "@mui/material/Menu";
import { IPartsearchAttributeTypeProps } from "../../Types/MicrositeType";
import RangeFilter from "./RangeFilter";
import {
  addAttribute,
  addAttributeWithValue,
  addfromto,
  clearallAttributeWithValue,
} from "../../Redux/Counterslice";
import { useDispatch, useSelector } from "react-redux";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: "1rem", fontWeight: "bold", color: "#000" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "transparent",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  maxheight: "120px",
  overflow: "auto",
}));

export function AttributeFilter(props: {
  _attributes?: IPartsearchAttributeTypeProps[];
  selectattribute?: any;
  attributeSub?: any;
  trigg?: any;
  //attributesearch:any
}) {
  const [expanded, setExpanded] = React.useState<string | false>("");
  const [selectedValue, setSelectedValue] = React.useState("");
  const [checked, setChecked] = React.useState(false);
  const [expandtrigger, setexpandtrigger] = React.useState(false);
  const [from, setFrom] = React.useState("");
  const [to, setTo] = React.useState("");
  const [PREVKEYNAME, setPREVKEYNAME] = React.useState("");

  const [AttributesFilters, setAttributesFilters] = React.useState<
    IPartsearchAttributeTypeProps[]
  >([]);


  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  const dispatch = useDispatch();
  const Attributevaluedata = (state: any) => state.ATTRIBUTE_FILTER_WITH_VALUE;
  const attributeData = (state: any) => state.ATTRIBUTE_FILTER;
  const attributeFilterData = useSelector(attributeData);
  const attributeValueData = useSelector(Attributevaluedata);

  React.useEffect(() => {
  // console.log("present 4", props.attributeSub);
    // setexpandtrigger(props.attributeSub.length>0)
    setAttributesFilters(props.attributeSub);
  }, [props.attributeSub]);

  const handleChange =
    (panel: string, keyname: any) =>
    (event: React.SyntheticEvent, newExpanded: boolean) => {
      const selectkeyname: any = attributeValueData.items.filter(
        (val: any) => val.AttributeName === keyname.keyName
      );
    // console.log(selectkeyname, "attributeValueData 1");
      if (selectkeyname.length > 0) {
        setSelectedValue(selectkeyname[0].AttributeValue);
      } else {
        setSelectedValue("");
      }

      if (PREVKEYNAME !== keyname.keyName || PREVKEYNAME === "") {
        setPREVKEYNAME(keyname.keyName);
        setAttributesFilters([]);
      }
    // console.log("Trigger  grid select", keyname);
      if (keyname.rangeSearch) {
        // props.selectattribute(keyname.keyName, "");
        let present = attributeValueData.items.find(
          (obj: any) => obj.AttributeName === keyname.keyName
        );
        if (present == true) {
        }
      } else if (keyname.imageSearch) {
        let present = attributeValueData.items.find(
          (obj: any) => obj.AttributeName === keyname.keyName
        );

        if (attributeValueData.items.length > 0 && present !== undefined) {
          setAttributesFilters([present]);
          setChecked(true);
        } else {
          setChecked(false);
        // console.log("Trigger  grid select", keyname);
          props.selectattribute(keyname.keyName, "");
        }
      } else {
        let present = attributeValueData.items.find(
          (obj: any) => obj.AttributeName === keyname.keyName
        );
      // console.log(attributeValueData, "present 0");
      // console.log(present, "present 1");
      // console.log(keyname, "present 2");
        if (attributeValueData.items.length > 0 && present !== undefined) {
          setAttributesFilters([present]);
          setChecked(true);
        } else {
          setChecked(false);
        // console.log("Trigger  grid select");
          props.selectattribute(keyname.keyName, "");
        }
      }

      let type = {
        Attribute: keyname.keyName,
      };
      dispatch(addAttribute(type));

      setExpanded(newExpanded ? panel : false);
    };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // id?: any,
  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name?: any
  ) => {
    setSelectedValue(event.target.value);
    if (attributeFilterData.Attribute != "") {
      let type = {
        AttributeName: attributeFilterData.Attribute,
        AttributeValue: event.target.value,
        Attributeid: name,
        Attributefrom: 0,
        Attributeto: 0,
      };
      dispatch(addAttributeWithValue(type));
      props.trigg();
      setExpanded(false);
    }
  };

  const imagesearch = (value: any, name: any) => {
    setSelectedValue(value);
    if (attributeFilterData.Attribute != "") {
      let type = {
        AttributeName: attributeFilterData.Attribute,
        AttributeValue: value,
        Attributeid: name,
        Attributefrom: 0,
        Attributeto: 0,
      };
      dispatch(addAttributeWithValue(type));
      props.trigg();
      setExpanded(false);
      handleClose();
    }
  };

  function Serchfromto(resfromApi: any) {
    let type = {
      AttributeValue: resfromApi.keyId,
      AttributeName: resfromApi.keyName,
    };
  }

  function clearattribute() {
    // setkeyName("");

    dispatch(clearallAttributeWithValue());
    setSelectedValue("");
    setExpanded("");
    props.trigg();
  }

  function triggergrid() {
    props.trigg();
  }

  return (
    <Box sx={{ mt: 3 }}>
      <Stack mb={1} direction="row" spacing={2}>
        <Typography variant="h6" sx={{ fontWeight: "600" }}>
          Attribute
          <Divider sx={{ bgcolor: "primary.main" }} className="title-divider" />
        </Typography>
        <Button
          variant="text"
          color="error"
          sx={{ marginLeft: "auto!important" }}
          onClick={clearattribute}
        >
          <RefreshIcon /> Reset
        </Button>
      </Stack>
      {props._attributes?.map((res, index) => (
        <React.Fragment key={index}>
          {res.rangeSearch === true ? (
            <Accordion
              expanded={expanded === `panel${index + 1}`} // Use unique panel IDs based on index
              onChange={handleChange(`panel${index + 1}`, res)} // Use unique panel IDs based on index
              key={index}
            >
              <AccordionSummary
                aria-controls={`panel${index + 1}-content`}
                id={`panel${index + 1}-header`}
              >
                <Typography variant="subtitle2">{res.keyName}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup>
                  <RangeFilter props={res} triggersearch={triggergrid} />
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          ) : res.imageSearch === true ? (
            <Accordion
              expanded={expanded === `panel${index + 1}`} // Use unique panel IDs based on index
              onChange={handleChange(`panel${index + 1}`, res)} // Use unique panel IDs based on index
              key={index}
            >
              <AccordionSummary
                aria-controls={`panel${index + 1}-content`}
                id={`panel${index + 1}-header`}
              >
                <Typography variant="subtitle2">{res.keyName}</Typography>
              </AccordionSummary>
              {AttributesFilters.length > 0 ? (
                <AccordionDetails>
                  {checked == true ? (
                    <FormGroup>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={selectedValue}
                        onChange={(e) => handleCheckboxChange(e, e.target.name)}
                      >
                        {AttributesFilters.map((subres: any , id:number) => (
                          <FormControlLabel
                          key={id}
                            name={subres.Attributeid}
                            value={subres.AttributeValue}
                            control={<Radio />}
                            label={subres.AttributeValue}
                            checked={true}
                            disabled={true}
                          />
                        ))}
                      </RadioGroup>
                    </FormGroup>
                  ) : (
                    <FormGroup>
                      <Typography
                        variant="body2"
                        color="secondary"
                        sx={{ textDecoration: "underline" }}
                        aria-controls={open ? "view-all-img" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                      >
                        <PhotoCameraIcon
                          sx={{ verticalAlign: "middle", marginRight: "8px" }}
                        />{" "}
                        View the images
                      </Typography>

                      <Menu
                        id="view-all-img"
                        MenuListProps={{
                          "aria-labelledby": "fade-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        className="view-all-img-popup"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Box sx={{ padding: "20px" }}>
                          <Typography variant="h5" mb={2}>
                            {PREVKEYNAME}
                            <DisabledByDefaultIcon
                              onClick={handleClose}
                              color="primary"
                              sx={{ float: "right", fontSize: "20px" }}
                            />
                          </Typography>

                          <Grid container spacing={2}>
                            {props.attributeSub.map((subres: any , id:number) => (
                              <Grid item xs={12} sm={6} md={2} key={id}> 
                                <div
                                  className="categorybox"
                                  onClick={() =>
                                    imagesearch(subres.keyName, subres.keyId)
                                  }
                                >
                                  <div className="category-inner">
                                    <Typography className="categoryimage">
                                      {subres.imageAzureFlag == true ? (
                                        <img
                                          // src={`${process.env.REACT_APP_MICROSITENAME}\PartSpecificationImages\SmallImages\ ${subres.imageName}`}
                                          src={
                                            EnvData?.REACT_APP_CDNURL +
                                            "\\PartSpecificationImages\\SmallImages\\" +
                                            subres.imageName
                                          }
                                          alt="category1"
                                          loading="lazy"
                                        />
                                      ) : (
                                        <img
                                          // src={`${EnvData?.REACT_APP_MICROSITENAME}\PartSpecificationImages\SmallImages\ ${subres.imageName}`}
                                          src={EnvData?.REACT_APP_NOIMAGEURL}
                                          alt="category1"
                                          loading="lazy"
                                        />
                                      )}
                                    </Typography>

                                    <Typography
                                      className="categorytitle"
                                      variant="body2"
                                    >
                                      {subres.keyName}
                                    </Typography>
                                  </div>
                                </div>
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </Menu>

                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={selectedValue}
                        onChange={(e) => handleCheckboxChange(e, e.target.name)}
                      >
                        {AttributesFilters.map((subres: any , id:number) => (
                          <FormControlLabel
                          key={id}
                            name={subres.keyId}
                            value={subres.keyName}
                            control={<Radio />}
                            label={subres.keyName}
                          />
                        ))}
                      </RadioGroup>
                    </FormGroup>
                  )}
                </AccordionDetails>
              ) : (
                <CircularProgress
                  sx={{
                    textAlign: "center",
                    margin: "0 auto",
                    display: "block",
                  }}
                />
              )}
            </Accordion>
          ) : (
            <Accordion
              expanded={expanded === `panel${index + 1}`} // Use unique panel IDs based on index
              onChange={handleChange(`panel${index + 1}`, res)} // Use unique panel IDs based on index
              key={index}
            >
              <AccordionSummary
                aria-controls={`panel${index + 1}-content`}
                id={`panel${index + 1}-header`}
              >
                <Typography variant="subtitle2">{res.keyName}</Typography>
              </AccordionSummary>
              {AttributesFilters.length > 0 ? (
                <AccordionDetails>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={selectedValue}
                    onChange={(e) => handleCheckboxChange(e, e.target.name)}
                  >
                    {AttributesFilters.map((subres: any , id:number) => (
                      <FormControlLabel
                      key={id}
                        name={checked ? subres.Attributeid : subres.keyId}
                        value={checked ? subres.AttributeValue : subres.keyName}
                        control={<Radio />}
                        label={checked ? subres.AttributeValue : subres.keyName}
                        disabled={checked}
                        checked={checked}
                      />
                    ))}
                  </RadioGroup>
                </AccordionDetails>
              ) : (
                <CircularProgress
                  sx={{
                    textAlign: "center",
                    margin: "0 auto",
                    display: "block",
                  }}
                />
              )}
            </Accordion>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
}
