import { TopHeader } from "./TopHeader";
import { MenuHeader } from "./MenuHeader";
import { IMicrositeProps } from "../../Types/MicrositeType";
import './header.style.css';

export const Header = (props: { _settings: IMicrositeProps, _contact?: string }) => {

  return (
    <div>
      <TopHeader _settings={props._settings} />
      <MenuHeader _settings={props._settings} />
    </div>

  );
    
}