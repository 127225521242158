import { useState, useContext, useEffect } from "react";
import { Typography, Drawer, Divider, IconButton } from "@mui/material";
import cart from "../../Images/cart.png";
import cartwhite from "../../Images/cart_white.png";
import "./my-cart-style.css";
//import { CartContext } from "./CartContext";
import { CartContext } from "./CartContext";
import { Link } from "react-router-dom";
import OrderSummary from "../Checkout/OrderSummary";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import eventEmitter from "../PartSearchTest/eventEmmiter";
// import ClearAllLocalStorage from "../Checkout/ClearLocalstorage";
import { clearLocalstorageValues } from "../../API/GetMicrositeSettings";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export function CartCount(props: { _cartcount: number }) {
  const [isCartOpen, setIsCartOpen] = useState(false);

  const { cartItems } = useContext(CartContext);
  const itemCount = cartItems && cartItems.length > 0 ? cartItems.length : 0;
  //alert(itemCount)
  //console.log('cart items')
  const toggleMyCartOpen = () => {
    itemCount > 0 && setIsCartOpen(isCartOpen ? false : true);
  };

  function CLoseDrawer() {
    setIsCartOpen(false);
  }

  function OpenDrawer() {
    if (isCartOpen == false) {
      setIsCartOpen(true);
    }
  }
  useEffect(() => {
    localStorage.setItem(
      "CartCount",
      (itemCount == 0 ? props._cartcount : itemCount).toString()
    );
  }, [itemCount]);
  useEffect(() => {
    eventEmitter.on("triggerOpenDraw", OpenDrawer);
    // eventEmitter.on("triggersearchfilter",  TriggerAttribute);

    return () => {
      // ResetreduxData()
      eventEmitter.off("triggerOpenDraw", OpenDrawer);
    };
  }, []);

  const location = useLocation();
  const isRootPath = location.pathname === '/';

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  return (
    <div>
      <Typography sx={{ position: "relative" }} onClick={toggleMyCartOpen}>
      
      {EnvData?.REACT_APP_SHOW_CENTRAL_TURBO == "true" ? 
                    (
                      <>
                      {isRootPath == true ? (
                        <>
                        <img src={cartwhite} className="imageicons" alt="logo" loading="lazy" />
                        </> ) : <>
                        <img src={cart} className="imageicons" alt="logo" loading="lazy" />
                        </>}
                     
                      </>
                    ) : <><img src={cart} className="imageicons" alt="logo" loading="lazy" /> </>}
      
      
        {/*  */}
        <Typography
          className="cartCount"
          variant="body2"
          component="span"
          sx={{
            backgroundColor: "primary.main",
            color: "primary.contrastText",
          }}
        >
          {itemCount == 0 ? props._cartcount : itemCount}
          {/* {props._cartcount} */}
        </Typography>
      </Typography>

      <Drawer
        anchor="right"
        open={isCartOpen}
        onClose={() => setIsCartOpen(false)}
        className="cart-right-menu"
      >
        <div className="cart-drawer-header">
          <div className="product-right-border">
            <Typography variant="h4" p={0}>
              YOUR CART
              <Divider
                sx={{ bgcolor: "primary.main" }}
                className="title-divider"
              />
            </Typography>
          </div>
          <IconButton className="brawer-close-button" onClick={CLoseDrawer}>
            <CloseRoundedIcon />
          </IconButton>
        </div>

        <OrderSummary closedrawer={CLoseDrawer} />
      </Drawer>
      {/* {isCartOpen && (
        <div className="mycart-dropdown-container">
          <div className="mycart-item"></div>

          <Button onClick={toggleMyCartOpen} to="/viewcart" component={Link}>
            View Cart
          </Button>
          <Button onClick={toggleMyCartOpen} to="/Checkout" component={Link}>
            Checkout
          </Button>
        </div>
      )} */}
    </div>
  );
}
