import { Typography, Link, Paper, Grid, Box, Divider } from "@mui/material";
import { Container, Stack } from "@mui/system";

import { IBrandProps, IMicrositeProps } from "../../Types/MicrositeType";
import { BRAND_FILTER } from "../../Redux/ReduxConstants";

import { useSelector } from "react-redux";
import { addBrandSelection, addBrandfilter } from "../../Redux/Counterslice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useResetdata } from "../../Redux/Resetdata";

export function Brand(props: {
  _brand: IBrandProps[];
  _seoTitle: string;
  microsite_Information: IMicrositeProps;
}) {
  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  let url = EnvData?.REACT_APP_CDNURL + "/BrandImages/";

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { ResetreduxData } = useResetdata();

  const selectbrand = (brandname: string, brandId: any) => {
    ResetreduxData();
    let Brandtype = {
      type: BRAND_FILTER,
      brandId: brandId,
      brandName: brandname,
    };
    dispatch(addBrandfilter(Brandtype));
    dispatch(addBrandSelection(false));
    navigate("/PartSearch");
  };

  return (
    <div>
      {props.microsite_Information.showBrandNameOrLogo === "Logo" ? (
        <Container>
          <Paper elevation={0} sx={{ py: 5 }} key="PA3">
            <div className="product-right-border">
              <Typography variant="h4">
                {props._seoTitle}
                <Divider
                  sx={{ bgcolor: "primary.main" }}
                  className="title-divider"
                />
              </Typography>
            </div>
            {/* <p style={{ display: "block" }} key="T6">
            Click below brand image to redirect our Business Partners webiste
          </p> */}
            <Box className="brand-img-sec" key="ST3">
              {props._brand.map((elem, index) => (
                <Link
                  className="brand-img-div"
                  key={index}
                  onClick={() => selectbrand(elem.brandName, elem.brandId)}
                  // href={elem.brandUrl !== "" ? elem.brandUrl : ""}
                >
                  <img
                    src={
                      elem.imageAzureFlag === true
                        ? url + elem.imageName
                        : EnvData?.REACT_APP_NOIMAGEURL
                    }
                    alt="Logo"
                  />
                </Link>
              ))}
            </Box>
          </Paper>
        </Container>
      ) : (
        <Container>
          <Paper elevation={0} sx={{ py: 5 }} key="PA4">
            <div className="product-right-border">
              <Typography variant="h4">
                {props._seoTitle}
                <Divider
                  sx={{ bgcolor: "primary.main" }}
                  className="title-divider"
                />
              </Typography>
            </div>
            {/* <p style={{ display: "block" }} key="T6">
            Click below brand image to redirect our Business Partners webiste
          </p> */}
            <Box className="brand-text-sec" key="ST4">
              {props._brand.map((elem, index) => (
                <Link
                  className="brand-text"
                  key={index}
                  // href={elem.brandUrl !== "" ? elem.brandUrl : ""}
                  onClick={() => selectbrand(elem.brandName, elem.brandId)}
                >
                  {elem.brandName}
                </Link>
              ))}
            </Box>
          </Paper>
        </Container>
      )}
    </div>
  );
}
