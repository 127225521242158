import { Typography, Container, Paper, Box, Divider } from "@mui/material";

import { AxiosGetdata, useGetData } from "../APIHooks/useGetData";
import { useEffect, useState } from "react";
import axios from "axios";
import { GetData, Get_Data } from "../API/GetDataFromApi";
import { useSelector } from "react-redux";
export default function TermsAndConditions() {
  const [data1, setData] = useState<IgetTerms[]>([]);

  interface IgetTerms {
    heading: string;
    content: string;
  }

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);

  // Using axios
  var raw = JSON.stringify({
    action: "SearchData",
    parray: [
      "TableName",
      "MicrositePoliciesandTerms",
      "SearchOption",
      "Equals",
      "SearchColumn1Name",
      "MicrositePoliciesandTerms.MicrositeId",
      "SearchColumn1Value",
      EnvData?.REACT_APP_MICROSITEID,
      "SearchColumn2Name",
      "MicrositePoliciesandTerms.Active",
      "SearchColumn2Value",
      "1",
    ],
    pageName: "string",
    methodname: "string",
    readwriteFlag: 0,
    outputparam: "",
  });

  // useEffect(()=>{
  //     axios.post(process.env.REACT_APP_APIURL + '/api/Microsite/getDatas_readOnly',requestOptions1.body , requestOptions1)
  //     .then(
  //         (res)=>{
  //             // let response:IgetTerms[] = res.data.Table
  //             console.log(res.data.Table , "res from axios")
  //             setData(res.data.Table )
  //         }

  //     )

  //     .catch((e)=>{
  //         console.error(e)
  //     })

  // } ,[])

  // const [data1] = AxiosGetdata<IgetTerms[]>(process.env.REACT_APP_APIURL + '/api/Microsite/getDatas_readOnly',requestOptions1.body ,requestOptions1,[])
  useEffect(() => {
    (async () => {
      Get_Data(
        EnvData?.REACT_APP_APIURL +
          "/microsite-api-v1/get-terms-and-conditions",
        raw
      )
        .then((results?) => {
          //   console.log(results.Table ,"reults from tndc")
          setData(results.data);
        })
        .catch((err) => {
          console.error(err);
          //   localStorage.removeItem("user");
        });
    })();
  }, []);

  // console.log(data1, "RES");

  // Axios

  // const [data] = useGetData<IgetTerms[]>(process.env.REACT_APP_APIURL + '/api/Microsite/getDatas_readOnly', requestOptions, []);
  // console.log(data , "data test")

  return (
    <div>
      <Paper elevation={0} sx={{ mt: 2, mb: 1, py: 3 }}>
        <Container>
          {/* <Typography variant="h1" align="center">
             H1 Tag Content
            </Typography>
          <Typography variant="body1" pb={3} align="center" sx={{fontSize:'17px'}}>
                H2 Tag Content
          </Typography> */}
          <div className="product-right-border">
            <Typography variant="h4" pb={3}>
              Terms and Conditions
              <Divider
                sx={{ bgcolor: "primary.main" }}
                className="title-divider"
              />
            </Typography>
          </div>
          {data1 &&
            data1.map((elem, index) => (
              <Box sx={{ mb: 3 }} key={index}>
                <Typography key={index} variant="h5">
                  {elem.heading}
                </Typography>

                <div
                  dangerouslySetInnerHTML={{
                    __html: elem.content,
                  }}
                ></div>
              </Box>
            ))}
        </Container>
      </Paper>
    </div>
  );
}
