import { useState, useContext, memo, useEffect } from "react";
import {
  Grid,
  Box,
  Button,
  Divider,
  Link,
  Typography,
  Tooltip,
  Menu,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import CachedIcon from "@mui/icons-material/Cached";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import "./product.style.css";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useGetData } from "../../APIHooks/useGetData";
import useGetauthHeader from "../../APIHooks/useGetAuthHeader";
import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import LinkIcon from "@mui/icons-material/Link";
import { ICart, ICartContextAction } from "../../Types/CartType";
import { CartContext } from "../Cart/CartContext";
import productnote from "../../Images/notes-icons.png";
import productnote1 from "../../Images/notes-icons1.png";
import {
  IFeatureproductProps,
  ISpecProps,
  ICrossRefProps,
} from "../../Types/MicrositeType";
import {
  GetData,
  GetADDToCartData,
  GetSpecificationData,
  GetCrossreference,
} from "../../API/GetDataFromApi";
import { addPDInfo, clearPDInfo } from "../../Redux/Counterslice";
import { Navigate, Link as RouterLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ConstructionOutlined } from "@mui/icons-material";
import { useResetdata } from "../../Redux/Resetdata";
import eventEmitter from "../PartSearchTest/eventEmmiter";
import ProductLoader from "../ProductLoader";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";

const longText = `
Aliquam eget finibus ante, non facilisis lectus. Sed vitae dignissim est, vel aliquam tellus.
Praesent non nunc mollis, fermentum neque at, semper arcu.
Nullam eget est sed sem iaculis gravida eget vitae justo.
`;

const ProductGrid = (props: {
  _product: IFeatureproductProps[];
  partsearch?: boolean;
  home?: boolean;
  Seo?:boolean
}) => {
  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);
  const selectData = (state: any) => state;
  const data = useSelector(selectData);
  const CustomerId =
    localStorage.getItem("CustomerId") === undefined ||
    localStorage.getItem("CustomerId") === null ||
    localStorage.getItem("CustomerId") === ""
      ? "0"
      : localStorage.getItem("CustomerId");
  const [currentPage, setCurrentPage] = useState(1);
  const [loaded, setLoad] = useState<boolean>(false);
  const [popupPart, setpopupPart] = useState("");
  const [state, setState] = useState({
    extcompetitorID: data.EXTENDER_CROSSREFERENCE_FILTER.CompetitorId,
    extcompetitiorPartNumber:
      data.EXTENDER_CROSSREFERENCE_FILTER.CompetitorpartNumber,
    EnteredText: data.EXTENDER_TEXT_SEARCH.Text,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ResetreduxData } = useResetdata();
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      extcompetitorID: data.EXTENDER_CROSSREFERENCE_FILTER.CompetitorId,
      extcompetitiorPartNumber:
        data.EXTENDER_CROSSREFERENCE_FILTER.CompetitorpartNumber,
      EnteredText: data.EXTENDER_TEXT_SEARCH.Text,
    }));
  }, [
    data.EXTENDER_CROSSREFERENCE_FILTER.CompetitorId,
    data.EXTENDER_CROSSREFERENCE_FILTER.CompetitorpartNumber,
    data.EXTENDER_CROSSREFERENCE_FILTER.CompetitorpartNumber,
    data.EXTENDER_TEXT_SEARCH.Text,
  ]);

  // console.log(props.partsearch , "partsearch ")
  let url = EnvData?.REACT_APP_CDNURL + "/PartImages/LargeImages/";
  const authHeader = useGetauthHeader();

  let partsearch = props.partsearch;

  const rowsPerPage = 20; // Number of rows per page

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const currentPageData = props._product?.slice(startIndex, endIndex);
  //console.log(currentPageData, "current");
  // console.log(currentPage ,Math.ceil(props._product?.length / rowsPerPage) , "page number")
  const [loading, setLoading] = useState(false);
  const { setcartItems } = useContext(CartContext) as ICartContextAction;
  const AddTocart = (
    partid: number,
    partnumber: string,
    description: string,
    price: number,
    url: string
  ) => {
    setLoading(true);
    var cartName = localStorage.getItem("cartName");
    var cartInput = {
      // micrositeId: process.env.REACT_APP_MICROSITEID,
      partid: partid,
      cartName: cartName,
      customerId: CustomerId,
    };
    const requestOptionscart = {
      method: "POST",
      headers: authHeader,
      body: JSON.stringify(cartInput),
    };
    // console.log(JSON.stringify(requestOptionscart))

    return GetADDToCartData(
      EnvData?.REACT_APP_APIURL + "/microsite-api-v1/add-to-cart",
      JSON.stringify(cartInput),
      cartInput
    )
      .then((results) => {
        const output_array = JSON.stringify(results).split("|");
        //console.log(output_array);
        let cartid = output_array[1];
        if (cartid !== "") {
          //alert("Item added to the Cart");

          const newCart: ICart = {
            Partnumber: partnumber,
            description: description,
            price: price,
            imgflag: true,
            imgUrl: url,
          };
          setcartItems(newCart);
        }
      })
      .catch((error) => {
        console.log("Error in " + error);
      })

      .finally(() => {
        setLoading(false);
        // const myTimeout = setTimeout(()=>{
        eventEmitter.emit("triggerOpenDraw", {});
        // },1000)
      });
  };

  const [specification, setSpecification] = useState<ISpecProps[]>([]);
  const getSpecification = (partid: number) => {
    var specInput = {
      action: "ms_GetSpecforPart",
      parray: [
        "PartId",
        partid,
        "SiteName",
        String(EnvData?.REACT_APP_MICROSITENAME),
      ],
      pageName: "",
      methodname: "",
      readwriteFlag: 0,
      outputparam: "Yes",
    };
    // const requestOptionsSpec = {
    //   method: 'POST',
    //   headers: authHeader,
    //   body: JSON.stringify(specInput)
    // }
    let URL = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/get-part-specifications?PartId=${partid}`;

    return (
      GetSpecificationData(URL, partid)
        // .then((response) => {
        //   return response.json()
        // })
        .then((results) => {
          // console.log(results, " res from Specification");
          setSpecification(results.data);
        })
        .catch((error) => {
          console.log("Error in " + error);
        })
    );
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    partid: number,
    partNumber: string
  ) => {
    setpopupPart(partNumber);
    getSpecification(partid);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleSpecClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);

  const [anchorContactAvailabilityEl, setanchorContactAvailabilityEl] =
    useState<null | HTMLElement>(null);

  const contactAvailabilityClick = (event: React.MouseEvent<HTMLElement>) => {
    setanchorContactAvailabilityEl(
      anchorContactAvailabilityEl ? null : event.currentTarget
    );
  };

  const openContact = Boolean(anchorContactAvailabilityEl);

  const [popupEl, setPopupEl] = React.useState<null | HTMLElement>(null);
  const [crossRef, setcrossRef] = useState<ICrossRefProps[]>([]);
  const handleClickOpen = (
    event: React.MouseEvent<HTMLElement>,
    partid: number,
    partNumber: string
  ) => {
    setpopupPart(partNumber);
    setLoad(true);
    setPopupEl(popupEl ? null : event.currentTarget);

    let ExtraParam = "";

    if (state.extcompetitorID != "" || state.extcompetitiorPartNumber != "") {
      if (state.extcompetitorID != "") {
        ExtraParam = "&competitorId=" + state.extcompetitorID;
      }
      if (state.extcompetitiorPartNumber != "") {
        ExtraParam =
          ExtraParam + "&competitorPartNo=" + state.extcompetitiorPartNumber;
      }
    } else {
      if (state.EnteredText != "") {
        ExtraParam = ExtraParam + "&competitorPartNo=" + state.EnteredText;
      }
    }

    let URL = `${EnvData?.REACT_APP_APIURL}/microsite-api-v1/get-cross-references?PartId=${partid}`;
    if (ExtraParam != "") {
      URL = URL + ExtraParam;
    }
    return GetCrossreference(URL, partid)
      .then((results) => {
        setcrossRef(results.data);
        setLoad(false);
      })
      .catch((error) => {
        console.log("Error in " + error);
      });
  };
  const openPopup = Boolean(popupEl);
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setPopupEl(popupEl ? null : event.currentTarget);
  };

  if (loading)
    return (
      <div>
        {" "}
        <ProductLoader />
      </div>
    );

  return (
    <Box>
      {props.partsearch == true ? (
        <div className={EnvData?.REACT_APP_SHOW_CENTRAL_TURBO === "true" ? "current-page-result central-turbos-current-page-result" : "current-page-result"}>
          Page {currentPage} Of{" "}
          {Math.ceil(props._product?.length / rowsPerPage)} Over{" "}
          {props._product?.length} Results
        </div>
      ) : (
        <></>
      )}

      <Grid container spacing={2}>
        {currentPageData?.map((elem, index) => {
          let queryString = elem.partNumber; //
          let querdesp = elem.partDescriptionName; //
          const stringd = `/Category/${queryString}`;
          function pdBreadcrumbs() {
            if (props.home == true) {
              ResetreduxData();
            }
            if (props.partsearch == true) {
              let type = {
                partnumber: queryString,
                partflag: "PartSearch",
              };
              dispatch(addPDInfo(type));
            }
          }
          return (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Box className={EnvData?.REACT_APP_SHOW_CENTRAL_TURBO === "true" ? "product_wrapper central-turbos-product-wrapper" : "product_wrapper"} onClick={pdBreadcrumbs}>
                <div className="product_image_wrapper">
                  <Box
                    sx={{ display: "flex" }}
                    className="product_image_wrapper_icons"
                  >
                

                    {elem.specCount > 0 ? (
                      <>
                        <IconButton
                          aria-label="settings"
                          sx={{ marginLeft: "auto" }}
                          onClick={(e) =>
                            handleClick(e, elem.partId, elem.partNumber)
                          }
                        >
                          <DescriptionIcon />
                        </IconButton>

                        {/* <Menu
                          id="spec-menu"
                          elevation={2}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleSpecClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <Box display="flex">
                            <TableContainer component={Paper} elevation={0}>
                              <Table aria-label="simple table">
                                <TableHead>
                                  <TableRow sx={{ backgroundColor: "#e9e9e9" }}>
                                    <TableCell colSpan={2} sx={{ p: 1 }}>
                                      Specification
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {specification &&
                                    specification.map((elem, index) => (
                                      <TableRow key={index}>
                                        <TableCell>
                                          {elem.partsSpecificationName}
                                        </TableCell>
                                        <TableCell>
                                          {elem.columnValue}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </Menu> */}
                      </>
                    ) : null}
                  </Box>
                  <RouterLink to={stringd} onClick={pdBreadcrumbs}>
                    <Link className="product_imagethumb_link">
                      <img
                        src={elem.imageUrl}
                        alt="product thumb"
                        className="product_imagethumb"
                        loading="lazy"
                      />
                      <span className="water-mark">
                        {sessionStorage.getItem("WaterMark")}
                      </span>
                    </Link>
                  </RouterLink>
                  
                  {partsearch == true && elem.exactMatch == true ? (
                    <div className="product-labels">
                      <Typography>Exact Match</Typography>
                    </div>
                  ) : elem.crossRef > 0 ? (
                    <>
                   <div className="product-labels-xpref" style={{cursor:"pointer"}}  onClick={(e) =>
                            handleClickOpen(e, elem.partId, elem.partNumber)
                          }>
                      <Typography>XRef-Match</Typography>
                    </div>
                     
                    </>
                  ) : null}

                </div>
                <Divider />
                <div className="product_info">
                  <p className="product_number">
                    <RouterLink to={stringd} onClick={pdBreadcrumbs}>
                      {elem.salesPartNumber !== elem.partNumber &&
                      elem.partNumber !== "" &&
                      elem.partNumber !== undefined &&
                      elem.partNumber !== null &&
                      elem.showInventoryPartnumber === true
                        ? elem.partNumber.toUpperCase() +
                          " / " +
                          elem.salesPartNumber.toUpperCase()
                        : elem.salesPartNumber.toUpperCase()}
                    </RouterLink>
                  </p>
                  <Typography className="product_dec">
                  <Tooltip title={elem.partDescriptionName} arrow>
                  <RouterLink to={stringd} onClick={pdBreadcrumbs}>
                      {elem.partDescriptionName.slice(0, 60)}
                      {elem.partDescriptionName.length > 60 ? "..." : ""}
                    </RouterLink>
                    </Tooltip>
                   
                  </Typography>

                  {elem.shoppingCart == true ||
                  (CustomerId !== "0" && elem.catalogueWithPricing === true) ? (
                    <Box className="price-list">
                      {parseFloat(elem.unitPrice.toFixed(2)) > 0 ? (
                        <Typography variant="subtitle1">
                          ${elem.unitPrice.toFixed(2)}
                        </Typography>
                      ) : null}

                      {/* {elem.total < elem.originalPrice &&
                      elem.originalPrice > 0 ? (
                        <Typography
                          color="custom"
                          className="price-line-through"
                        >
                          Price: ${elem.originalPrice}
                        </Typography>
                      ) : null} */}
                      {parseFloat(elem.unitPrice.toFixed(2)) ===
                      0 ? null : elem.alwaysShowReadytoShip === true ||
                        elem.stockInfo === "In Stock" ? (
                        <Typography variant="body1" className="price-ship">
                          <CheckCircleIcon
                            sx={{ fontSize: "14px", marginRight: "5px" }}
                          />{" "}
                          In Stock
                        </Typography>
                      ) : elem.showOutofStock ? (
                        <p className="out-of-stock">
                          <CancelSharpIcon sx={{ marginRight: "5px" }} /> Out of
                          stock
                        </p>
                      ) : null}
                    </Box>
                  ) : null}

                  {/* {elem.disPer > 0 && elem.shoppingCart == true ? (
                      <Box className="price-save">
                        <Typography variant="body2" sx={{ px: 1 }}>
                          
                          {elem.disPer}% OFF
                        </Typography>
                      </Box>
                    ) : null} */}

                  {elem.showPartStatusinSearchPage == "True" &&
                  elem.prnStatusDesc != "Active" &&
                  elem.prnStatusDesc != "Deactive" ? (
                    <div className="stack-disprice-discountinued">
                      <Box className="discountinued-details">
                        <Typography
                          variant="body2"
                          sx={{
                            px: 1,
                            backgroundColor: "primary.main",
                            color: "primary.contrastText",
                          }}
                        >
                          {elem.prnStatusDesc}
                        </Typography>
                      </Box>
                    </div>
                  ) : null}
                  {/* <Divider sx={{mb:1}}/> */}
                  {elem.corePrice > 0 &&
                  (elem.shoppingCart == true ||
                    (CustomerId !== "0" &&
                      elem.catalogueWithPricing === true)) ? (
                    <Box sx={{ mb: 1 }}>
                      <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>
                        + Core Deposit : {elem.corePrice.toFixed(2)}
                      </Typography>
                    </Box>
                  ) : null}
                  {elem.interChangeTypes !== "" &&
                  elem.interChangeTypes !== undefined &&
                  elem.interChangeTypes !== null ? (
                    <Tooltip title={elem.crossRefNotes} arrow>
                      <Typography
                        variant="subtitle2"
                        color="error"
                        sx={{ fontSize: "14px" }}
                        className="interchangetypes"
                      >
                        <IconButton color="error" sx={{ py: 0 }}>
                          {elem.interChangeTypes === "Can Use" ? (
                            <InfoOutlinedIcon />
                          ) : elem.interChangeTypes === "Associated Part" ? (
                            <LinkIcon />
                          ) : elem.interChangeTypes === "Direct Replacement" ? (
                            <CachedIcon />
                          ) : (
                            <></>
                          )}
                        </IconButton>
                        {elem.interChangeTypes}
                      </Typography>
                    </Tooltip>
                  ) : null}

                  {elem.partNotes !== "" &&
                  elem.partNotes !== undefined &&
                  elem.partNotes !== null ? (
                    <Box className="product_notes">
                      <Tooltip title={"Part Notes: " + elem.partNotes} arrow>
                        <Typography variant="body2">
                          <img src={productnote} alt="notes" />{" "}
                          {elem.partNotes.slice(0, 25)}
                          {elem.partNotes.length > 25 ? "..." : ""}
                        </Typography>
                      </Tooltip>
                    </Box>
                  ) : null}
                  {elem.appNotes !== undefined &&
                  elem.appNotes !== null &&
                  elem.appNotes.trim() !== "" ? (
                    <Box className="product_notes">
                      <Tooltip
                        title={
                          <React.Fragment>
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  "Application Notes: " +
                                  elem.appNotes.replaceAll("   ", "<br/>"),
                              }}
                            />
                          </React.Fragment>
                        }
                        arrow
                      >
                        <Typography variant="body2">
                          <img src={productnote1} alt="notes" />{" "}
                          {elem.appNotes.slice(0, 25)}
                          {elem.partNotes.length > 25 ? "..." : ""}
                        </Typography>
                      </Tooltip>
                    </Box>
                  ) : null}

                   {
                    props.Seo ===true? (<> <Button
                      variant="outlined"
                      color="primary"
                      endIcon={<KeyboardArrowDownIcon />}
                      onClick={()=>navigate(stringd)}
                      sx={{ width: "100%" }}
                    >
                     View details
                    </Button></>) :   elem.shoppingCart == true ||
                      (CustomerId !== "0" && elem.catalogueWithPricing === true) ? (
                        <Box className="price-cart">
                          {parseFloat(elem.unitPrice.toFixed(2)) === 0 ? (
                            // ||
                            // (elem.alwaysShowReadytoShip == false &&
                            //   elem.stockInfo == "No Stock")
                            <div
                              className="price-contact"
                              onClick={contactAvailabilityClick}
                              aria-controls={
                                open ? "contact-availability" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                            >
                              <Button
                                variant="outlined"
                                color="primary"
                                endIcon={<KeyboardArrowDownIcon />}
                                sx={{ width: "100%" }}
                              >
                                Contact for Availability{" "}
                              </Button>
                              <Menu
                                id="contact-availability"
                                elevation={2}
                                className="contact-availability-popup"
                                open={openContact}
                                anchorEl={anchorContactAvailabilityEl}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              >
                                <div className="contact-availability">
                                  <Box
                                    sx={{ textAlign: "center", fontSize: "15px" }}
                                  >
                                    Please Contact
                                    <Button
                                      color="secondary"
                                      sx={{ py: 0, fontSize: "15px" }}
                                    >
                                      {elem.phoneNumber}
                                    </Button>{" "}
                                    or
                                    <Button
                                      color="secondary"
                                      sx={{ py: 0, fontSize: "15px" }}
                                    >
                                      {elem.contactEmail}
                                    </Button>
                                    for Availability as the item may not be in
                                    stock.
                                  </Box>
                                </div>
                              </Menu>
                            </div>
                          ) : (elem.shoppingCart == true ||
                              (CustomerId !== "0" &&
                                elem.catalogueWithPricing === true)) &&
                            elem.unitPrice > 0 ? (
                            <Button
                              color="primary"
                              // className="addto-cart-icon"
                              variant="contained"
                              className="central-turbos-cart-button"
                              onClick={() => {
                                AddTocart(
                                  elem.partId,
                                  elem.partNumber,
                                  elem.partDescriptionName,
                                  elem.disPer,
                                  elem.assetName !== ""
                                    ? url + elem.assetName
                                    : (EnvData?.REACT_APP_NOIMAGEURL as string)
                                );
                              }}
                              endIcon={<ShoppingCartOutlinedIcon />}
                            >
                              Add To Cart
                            </Button>
                          ) : null}
                          {/* {elem.shoppingCart == true &&
                          elem.unitPrice > 0 &&
                          (elem.alwaysShowReadytoShip == true ||
                            elem.stockInfo !== "No Stock") ? (
                            <Button
                              color="primary"
                              // className="addto-cart-icon"
                              variant="contained"
                              onClick={() => {
                                AddTocart(
                                  elem.partId,
                                  elem.partNumber,
                                  elem.partDescriptionName,
                                  elem.disPer,
                                  elem.assetName !== ""
                                    ? url + elem.assetName
                                    : (process.env.REACT_APP_NOIMAGEURL as string)
                                );
                              }}
                              endIcon={<ShoppingCartOutlinedIcon />}
                            >
                              Add To Cart
                            </Button>
                          ) : null} */}
                        </Box>
                      ) : null
                   }
                
                </div>
              </Box>
            </Grid>
          );
        })}

        <Dialog
          open={openPopup}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title" className="dialog-title-mobile">
            Cross Reference for {popupPart}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {loaded == false ? (
              <div className="cross-ref">
                <table className="cross-ref-table">
                  <tr>
                    <th>Competitor Partnumber</th>
                    <th>Competitor Name</th>
                    <th>InterChange Type</th>
                    <th>Notes</th>
                  </tr>
                  <tbody>
                    {crossRef &&
                      crossRef?.map((elem, index) => (
                        <tr key={index}>
                          <td>{elem.competitorPartNo}</td>
                          <td>{elem.competitorName}</td>
                          <td>{elem.interchangeTypes}</td>
                          <td>{elem.notes}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <CircularProgress sx={{ textAlign: "center" }} />
            )}
          </DialogContent>
        </Dialog>

        <Dialog
          open={open}
          //anchorEl={anchorEl}
          onClose={handleSpecClose}
          fullWidth={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title" className="dialog-title-mobile">
            Specification for {popupPart}
            <IconButton
              aria-label="close"
              onClick={handleSpecClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TableContainer
              component={Paper}
              elevation={0}
              className="specification-popup-container"
            >
              <Table aria-label="simple table" className="cross-ref-table">
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#e9e9e9" }}>
                    {/* <TableCell colSpan={2} sx={{ p: 1 }}>
                                      Specification
                                    </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {specification &&
                    specification.map((elem, index) => (
                      <TableRow key={index}>
                        <TableCell>{elem.partsSpecificationName}</TableCell>
                        <TableCell>{elem.columnValue}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </Dialog>

        {props._product?.length >= 5 ? (
          <div className="pagination-sec">
            <Pagination
              count={Math.ceil(props._product?.length / rowsPerPage)}
              page={currentPage}
              onChange={(event, value) => setCurrentPage(value)}
            />
          </div>
        ) : (
          <></>
        )}
      </Grid>
    </Box>
  );
};
export default memo(ProductGrid);
