import { configureStore } from "@reduxjs/toolkit";
import {
  PSCategoryFilterreducer,
  PSBrandfilterreducer,
  PSDescfilterreducer,
  extenderCrossReferenceFilterreducer,
  extenderBrandFilterreduceer,
  extnderTextSearch,
  VehiclesearchYearselect,
  VehiclesearchMakeselect,
  VehiclesearchModelselect,
  VehiclesearchEngineselect,
  applicationFiltersreducer,
  attributeFiltersreducer,
  attributeFiltersWithvaluereducer,
  applicationFiltersWithvaluereducer,
  attributeFilterFromto,
  productlistdata,
  ms_Settings,
  trigger_rest,
  extra_filters,
  PartDetailsInfo,
  OrderSummaryInfo,
  OrderHistoryDetailsCapture ,
  CaptureFreightCount ,
  EnvSettings ,
  Paypalgenerated,
  CaptureCaptchaValue

} from "./Counterslice";


export const store = configureStore({
  reducer: {    
    CATEGORY_FILTER: PSCategoryFilterreducer,
    BRAND_FILTER: PSBrandfilterreducer,
    DESCRIPTION_FILTER: PSDescfilterreducer,
    EXTENDER_BRAND_FILTER: extenderBrandFilterreduceer,
    EXTENDER_CROSSREFERENCE_FILTER: extenderCrossReferenceFilterreducer,
    EXTENDER_TEXT_SEARCH: extnderTextSearch,
    YEAR_FILTER :VehiclesearchYearselect,
    MAKE_FILTER :VehiclesearchMakeselect,
    MODEL_FILTER :VehiclesearchModelselect,
    ENGINE_FILTER :VehiclesearchEngineselect,
    APPLICATION_FILTER:applicationFiltersreducer,
    ATTRIBUTE_FILTER:attributeFiltersreducer,
    APPLICATION_FILTER_WITH_VALUE:applicationFiltersWithvaluereducer,
    ATTRIBUTE_FILTER_WITH_VALUE:attributeFiltersWithvaluereducer,
    ATTRIBUTE_FILTER_FROM_TO :attributeFilterFromto,
    PRODUCT_DATA :productlistdata,
    MS_SETTINGS_DATA:ms_Settings,
    RESET_FUNCTION:trigger_rest,
    EXTRA_FILTERS:extra_filters,
    PART_DETAILS_INFO :PartDetailsInfo,
    ORDERSUMMARY_INFO:OrderSummaryInfo,
    ORDERHISTORY_DETAILS:OrderHistoryDetailsCapture,
    FREIGHT_DETAILS:CaptureFreightCount ,
    ENV_SETTINGS :EnvSettings ,
    PAYPALGENERATED:Paypalgenerated,
    CAPTURECAPTCHAVALUE:CaptureCaptchaValue,

  },
  // devTools:true
});

export type RootState = ReturnType<typeof store.getState>;
