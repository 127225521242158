import { Typography, Container, Paper, Box, Grid, List, ListItem, ListItemText, Link, Divider } from "@mui/material";
import featuresimage1 from "../Images/crm.png";
import featuresimage2 from "../Images/4_1.png";
import featuresimage3 from "../Images/production.png";
import featuresimage4 from "../Images/business-intelligence.png";
import featuresimage5 from "../Images/accounting-packages.png";
import featuresimage6 from "../Images/parts-lookup.png";
import featuresimage7 from "../Images/sales-process.png";
import featuresimage8 from "../Images/features.png";
import featuresimage9 from "../Images/parts-search.png";

import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import '../Css/features.style.css';

export default function FeaturesBenefits() {
    return(
        <div>   
            <Paper elevation={0} sx={{mt:2, mb:1, py:3,}}>
                <Container>
                    <Box sx={{mb:1}}>
                    <div className="product-right-border">
                        <Typography variant="h4" sx={{ mb: 1 }}>GO-CADENCE FEATURES & BENEFITS
                        <Divider
                  sx={{ bgcolor: "primary.main" }}
                  className="title-divider"
                />
                </Typography>
                </div>
                    </Box>
                    <Box sx={{mb:3}}>
                       <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <div className="features-box">
                                    <div className="features-box-header">
                                        <div className="features-box-header-text">
                                            <Typography variant="subtitle2" >CRM</Typography>
                                        </div>
                                        <div className="features-box-header-img">
                                            <img src={featuresimage1} alt="crm" loading='lazy' />
                                        </div>
                                    </div>
                                    <div className="features-box-body">
                                        <List className="features-box-body-list">
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"> <KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Customer Dashboard</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"> <KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Contact Management </Link>
                                                </ListItemText>
                                            </ListItem>

                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Customer Note Tracking</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Cadence Sales Process</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Document Library</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Salesman Visitation Module</Link>
                                                </ListItemText>
                                            </ListItem>

                                        </List>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <div className="features-box">
                                    <div className="features-box-header">
                                        <div className="features-box-header-text">
                                            <Typography variant="subtitle2" >INVENTORY</Typography>
                                        </div>
                                        <div className="features-box-header-img">
                                            <img src={featuresimage2} alt="crm" loading='lazy' />
                                        </div>
                                    </div>
                                    <div className="features-box-body">
                                        <List className="features-box-body-list">
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"> <KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Purchase Order Creation</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"> <KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Inventory Management </Link>
                                                </ListItemText>
                                            </ListItem>

                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Vendor Returns</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Inventory Dashboard</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Inventory Core Management</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Part Accessories</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Warehouse Transfers</Link>
                                                </ListItemText>
                                            </ListItem>

                                        </List>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <div className="features-box">
                                    <div className="features-box-header">
                                        <div className="features-box-header-text">
                                            <Typography variant="subtitle2" >PRODUCTION</Typography>
                                        </div>
                                        <div className="features-box-header-img">
                                            <img src={featuresimage3} alt="crm" loading='lazy'/>
                                        </div>
                                    </div>
                                    <div className="features-box-body">
                                        <List className="features-box-body-list">
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"> <KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Work Order</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"> <KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Bill of Materials</Link>
                                                </ListItemText>
                                            </ListItem>

                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>DPF Cleaning and Processing</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>On Vehicle Repair / Vehicle Service Module</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Time and Labor</Link>
                                                </ListItemText>
                                            </ListItem>
                                            

                                        </List>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <div className="features-box">
                                    <div className="features-box-header">
                                        <div className="features-box-header-text">
                                            <Typography variant="subtitle2" >BUSINESS INTELLIGENCE</Typography>
                                        </div>
                                        <div className="features-box-header-img">
                                            <img src={featuresimage4} alt="crm" loading='lazy' />
                                        </div>
                                    </div>
                                    <div className="features-box-body">
                                        <List className="features-box-body-list">
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"> <KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Forecasting</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"> <KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Reporting </Link>
                                                </ListItemText>
                                            </ListItem>

                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Analytical Dashboard for Designing SOP</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Company & Sales Representative Budgeting</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Credit Card Integration</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Multi-Currency</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Printer Integration</Link>
                                                </ListItemText>
                                            </ListItem>

                                        </List>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <div className="features-box">
                                    <div className="features-box-header">
                                        <div className="features-box-header-text">
                                            <Typography variant="subtitle2" >ACCOUNTING PACKAGE</Typography>
                                        </div>
                                        <div className="features-box-header-img">
                                            <img src={featuresimage5} alt="crm" loading='lazy' />
                                        </div>
                                    </div>
                                    <div className="features-box-body">
                                        <List className="features-box-body-list">
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"> <KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Accounts Payable</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"> <KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Accounts Receivable</Link>
                                                </ListItemText>
                                            </ListItem>

                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Company & Sales Rep Budgeting</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Accounting Package Integration - QuickBooks</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Accounting Dashboard</Link>
                                                </ListItemText>
                                            </ListItem>
                                           

                                        </List>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <div className="features-box">
                                    <div className="features-box-header">
                                        <div className="features-box-header-text">
                                            <Typography variant="subtitle2" >PARTS LOOKUP</Typography>
                                        </div>
                                        <div className="features-box-header-img">
                                            <img src={featuresimage6} alt="crm" loading='lazy'/>
                                        </div>
                                    </div>
                                    <div className="features-box-body">
                                        <List className="features-box-body-list">
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"> <KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Parts Cross Reference</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"> <KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Application Lookup by Year, Make, Model</Link>
                                                </ListItemText>
                                            </ListItem>

                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Application Lookup by VIN</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>ACES and PIES Compliant</Link>
                                                </ListItemText>
                                            </ListItem>
                                            

                                        </List>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <div className="features-box">
                                    <div className="features-box-header">
                                        <div className="features-box-header-text">
                                            <Typography variant="subtitle2" >SALES PROCESS</Typography>
                                        </div>
                                        <div className="features-box-header-img">
                                            <img src={featuresimage7} alt="crm" loading='lazy' />
                                        </div>
                                    </div>
                                    <div className="features-box-body">
                                        <List className="features-box-body-list">
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"> <KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Sales Orders</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"> <KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Quotes</Link>
                                                </ListItemText>
                                            </ListItem>

                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Credits</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Backorder Tracking</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Customer Core Tracking</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Dispatch</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Customer B2B Site</Link>
                                                </ListItemText>
                                            </ListItem>
                                        </List>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <div className="features-box">
                                    <div className="features-box-header">
                                        <div className="features-box-header-text">
                                            <Typography variant="subtitle2" >ADDITIONAL FEATURES & BENEFITS</Typography>
                                        </div>
                                        <div className="features-box-header-img">
                                            <img src={featuresimage8} alt="crm" loading='lazy'/>
                                        </div>
                                    </div>
                                    <div className="features-box-body">
                                        <List className="features-box-body-list">
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"> <KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Customized User Dashboards</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"> <KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Customized User Menus</Link>
                                                </ListItemText>
                                            </ListItem>

                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>2nd Level Authentication for Remote Access</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>SAAS Based Module: No Need for Local Servers or Software</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>B2C Integration</Link>
                                                </ListItemText>
                                            </ListItem>
                                           

                                        </List>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <div className="features-box">
                                    <div className="features-box-header">
                                        <div className="features-box-header-text">
                                            <Typography variant="subtitle2" >PARTS SEARCH</Typography>
                                        </div>
                                        <div className="features-box-header-img">
                                            <img src={featuresimage9} alt="crm" loading='lazy' />
                                        </div>
                                    </div>
                                    <div className="features-box-body">
                                        <List className="features-box-body-list">
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"> <KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>ACES and PIES Compliant</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"> <KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Application search via Year, Make, Model</Link>
                                                </ListItemText>
                                            </ListItem>

                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Application search via VIN</Link>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem disablePadding={true}>
                                                <ListItemText>
                                                    <Link className="features-box-body-text"><KeyboardDoubleArrowRightIcon className="features-box-body-arrow"/>Parts Cross Reference</Link>
                                                </ListItemText>
                                            </ListItem>
                                            

                                        </List>
                                    </div>
                                </div>
                            </Grid>
                       </Grid>
                    </Box>
                   
                </Container>
            </Paper>
        </div>
    )
}