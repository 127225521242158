import { Typography, Container, Paper, Box, TextField, Divider, Stack, Button} from "@mui/material";


export default function OopsPage() {

  return(
    <div>
         <Paper elevation={0} sx={{ mt: 3, mb: 1, py: 3 }}>
          <Container>
            <Box sx={{ mb: 1 }}>
              <Divider sx={{
                  "&::before, &::after": {
                    borderColor: "primary.main",
                  },
                }}>
                  <Typography variant="h1" sx={{border:"2px solid", borderColor:'primary.main', padding:'15px' }}>OOPS ! Something went wrong Here</Typography>
              </Divider>
            </Box>
            <Box sx={{ mt: 3, textAlign:"center" }}>
              <Typography variant="h2" >You can go back and use our search to check again. </Typography>
            </Box>
          </Container>
        </Paper>
    </div>
  );
}

