import { Box, Button, Divider } from "@mui/material";
import React, { useState, Component } from "react";
import Slider from "react-slick";
import {
  Avatar,
  Typography,
  Container,
  Paper,
  Link,
  Modal,
  IconButton,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { IVidoesProps, videostateprops } from "../../Types/MicrositeType";
import { useSelector } from "react-redux";
function SampleNextArrow(props: any) {
  const { style, onClick } = props;
  return (
    <Button
      variant="contained"
      style={{
        ...style,
        display: "block",
        padding: "6px",
        top: "50%",
        position: "absolute",
        right: "10px",
        zIndex: "99",
        transform: "translate(0, -50%)",
        minWidth: "auto",
        lineHeight: 1,
      }}
      onClick={onClick}
    >
      <KeyboardArrowRightOutlinedIcon sx={{ lineHeight: 1 }} />
    </Button>
  );
}

function SamplePrevArrow(props: any) {
  const { style, onClick } = props;
  return (
    <Button
      variant="contained"
      style={{
        ...style,
        display: "block",
        padding: "6px",
        top: "50%",
        position: "absolute",
        left: "10px",
        zIndex: "99",
        transform: "translate(0, -50%)",
        minWidth: "auto",
        lineHeight: 1,
      }}
      onClick={onClick}
    >
      <KeyboardArrowLeftOutlinedIcon sx={{ lineHeight: 1 }} />
    </Button>
  );
}

const settings = {
  dots: false,
  infinite: true,
  className: "videoslider",
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: true,
  autoplay: false,
  initialSlide: 1,
  speed: 500,
  autoplaySpeed: 5000,
  cssEase: "linear",
  adaptiveHeight: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 761,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        centerMode: false,
      },
    },
  ],
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  //border: '2px solid #000',
  boxShadow: 5,
  pt: 2,
  px: 4,
  pb: 3,
};

export const Videos = (props: {
  _videos: IVidoesProps[];
  _seoTitle: string;
}) => {
  const [showPopup, setshowPopup] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  let _videoUrl: string = "";

  let env = (state: any) => state.ENV_SETTINGS;
  const EnvData = useSelector(env);


  let url =
    EnvData?.REACT_APP_CDNURL +
    "/Microsite/" +
    EnvData?.REACT_APP_MICROSITENAME +
    "/Snapshot/";
  const openLightbox = (fname: string, vUrl: string) => {
  // console.log(fname);
  // console.log(vUrl);
  // console.log("as");
    if (vUrl !== "") {
      _videoUrl = vUrl;
    } else {
      _videoUrl = url + fname;
    }
    setVideoUrl(_videoUrl);
    setshowPopup(true);
  };

  const closeLightbox = () => {
    setshowPopup(false);
  };

  var raw = JSON.stringify({
    micrositeName: EnvData?.REACT_APP_MICROSITENAME,
    cartName: "",
    assetstyle: "string",
  });

  // const requestOptions = {
  //   method: 'POST',
  //   headers: useGetauthHeader(),
  //   body: raw
  // }

  //const [lividoes] = useGetDatawithMultipleArray<IVidoesProps[]>(process.env.REACT_APP_APIURL + '/api/Microsite/Getmicrositeindexdata', requestOptions, "Table6", []);

  return (
    <Box sx={{ pt: 6, pb: 8 }}>
      <Container maxWidth={"lg"}>
        {props._videos.length > 0 ? (
          <div className="product-right-border">
            <Typography variant="h4" pb={3} color="#000">
              {props._seoTitle}
              <Divider
                sx={{ bgcolor: "primary.main" }}
                className="title-divider"
              />
            </Typography>
          </div>
        ) : null}

        <Slider {...settings}>
          {props._videos.map((elem, index) => (
            <div key={index}>
              <Link
                className="video-img"
                onClick={() =>
                  openLightbox(elem["videoFileName"], elem["videoUrl"])
                }
              >
                <img src={url + elem.snapshot} alt="Logo" loading="lazy" />
              </Link>
            </div>
          ))}
        </Slider>

        <Dialog
          onClose={closeLightbox}
          open={showPopup}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle>
            Videos
            <IconButton
              className="brawerclosebutton"
              onClick={closeLightbox}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseRoundedIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box>
              <iframe
                width="100%"
                height="300"
                src={videoUrl}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              />
            </Box>
          </DialogContent>
        </Dialog>
      </Container>
    </Box>
  );
};
