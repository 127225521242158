import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Stack,
  InputLabel,
  OutlinedInput,
  Box,
  Grid,
  RadioGroup,
  Radio,
  Divider,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import { useDispatch, useSelector } from "react-redux";
import {
  IPartsearchCategoryProps,
  IPartsearchAttributeTypeProps,
} from "../../Types/MicrositeType";
import {
  addAttribute,
  addAttributeWithValue,
  addApplicationWithValue,
  addfromto,
  addApplication,
  clearalllApplicationWithValue,
} from "../../Redux/Counterslice";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "transparent",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  maxHeight: "120px",
  overflow: "auto",
}));

export function ApplicationFilter(props: {
  _application: IPartsearchAttributeTypeProps[];
  selectapplication?: any;
  applicationSub?: any;
  trigg?: any;
}) {
  const [expanded, setExpanded] = React.useState<string | false>("");
  const [selectedValue, setSelectedValue] = React.useState("");
  const [ApplicationFilters, setApplicationFilters] = React.useState<
    IPartsearchAttributeTypeProps[]
  >([]);
  const [PREVKEYNAME, setPREVKEYNAME] = React.useState("");
  //  console.log(props.selectapplication , "selectapp")
  const dispatch = useDispatch();
// console.log(props, "applications");

  const applicationData = (state: any) => state.APPLICATION_FILTER;
  const applicationFilterData = useSelector(applicationData);
  const appDatawithvalue = (state: any) =>
    state.APPLICATION_FILTER_WITH_VALUE.items;
  const appFilterDatawithvalue = useSelector(appDatawithvalue);
  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
  // console.log("present 4", props.applicationSub);
    // setexpandtrigger(props.attributeSub.length>0)
    setApplicationFilters(props.applicationSub);
  }, [props.applicationSub]);
  const handleChange =
    (panel: string, keyname: any) =>
    (event: React.SyntheticEvent, newExpanded: boolean) => {
    // console.log("applicationFilterData", appFilterDatawithvalue);

      if (appFilterDatawithvalue != undefined) {
        const selectkeyname: any = appFilterDatawithvalue.filter(
          (val: any) => val.ApplicationName === keyname.keyName
        );
      // console.log(selectkeyname, "attributeValueData 1");
        if (selectkeyname.length > 0) {
          setSelectedValue(selectkeyname[0].ApplicationValue);
        } else {
          setSelectedValue("");
        }
      }

      //props.selectapplication(keyname.keyName);

      if (PREVKEYNAME !== keyname.keyName || PREVKEYNAME === "") {
      // console.log(PREVKEYNAME, "present 3");
        setPREVKEYNAME(keyname.keyName);
        setApplicationFilters([]);
      }

      let present = appFilterDatawithvalue.find(
        (obj: any) => obj.ApplicationName === keyname.keyName
      );
    // console.log(appFilterDatawithvalue, "present 0");
    // console.log(present, "present 1");
    // console.log(keyname, "present 2");
      if (appFilterDatawithvalue.length > 0 && present !== undefined) {
      // console.log(keyname, "present auto select");
        setApplicationFilters([present]);
        setChecked(true);
      } else {
        setChecked(false);
      // console.log(keyname, "present manul select");
        props.selectapplication(keyname.keyName, "");
      }

      let type = {
        Application: keyname.keyName,
      };
    // console.log("dsipatch attribute", type);
      dispatch(addApplication(type));
      setExpanded(newExpanded ? panel : false);
    };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: any
  ) => {
    setSelectedValue(event.target.value);
  // console.log(applicationFilterData, "fromapplication");
    if (applicationFilterData.Application != "") {
    // console.log("fromapplication 1");
      let type = {
        ApplicationName: applicationFilterData.Application,
        ApplicationValue: event.target.value,
        Applicationid: name,
      };
      dispatch(addApplicationWithValue(type));
      setExpanded(false);
      props.trigg();
    }
  };

  function clearapplication() {
    dispatch(clearalllApplicationWithValue());
    props.trigg();
    setSelectedValue("");
    setExpanded("");
  }

// console.log(appFilterDatawithvalue, "appFilterDatawithvalue");
  return (
    <Box sx={{ mt: 3 }}>
      <Stack mb={1} direction="row" spacing={2}>
        <Typography variant="subtitle1">
          Application
          <Divider sx={{ bgcolor: "primary.main" }} className="title-divider" />
        </Typography>
        <Button
          variant="text"
          color="error"
          sx={{ marginLeft: "auto!important" }}
          onClick={clearapplication}
        >
          <RefreshIcon /> Reset
        </Button>
      </Stack>
      {props._application?.map((res, index) => (
        <Accordion
          expanded={expanded === `panel${index + 1}`} // Use unique panel IDs based on index
          onChange={handleChange(`panel${index + 1}`, res)} // Use unique panel IDs based on index
          key={index} // Add a unique key for each iteration
        >
          <AccordionSummary
            aria-controls={`panel${index + 1}-content`}
            id={`panel${index + 1}-header`}
          >
            <Typography variant="subtitle2">{res.keyName}</Typography>
          </AccordionSummary>
          {ApplicationFilters.length > 0 ? (
            <AccordionDetails>
              {/* <FormGroup> */}
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={selectedValue}
                onChange={(e) => handleCheckboxChange(e, e.target.name)}
              >
                {ApplicationFilters.map((subres: any , id:number) => (
                  // <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                  //   <FormControlLabel control={<Checkbox onChange={(e) => handleCheckboxChange(subres.keyId, e)} name={subres.keyName} />} label={subres.keyName} sx={{ marginRight: 0 }} />
                  // </Stack>
                  <FormControlLabel
                  key={id}
                    name={
                      checked ? subres.Applicationid : subres.keyId.toString()
                    }
                    value={checked ? subres.ApplicationValue : subres.keyName}
                    control={<Radio />}
                    label={checked ? subres.ApplicationValue : subres.keyName}
                    disabled={checked}
                    checked={checked}
                  />
                ))}
              </RadioGroup>
              {/* </FormGroup> */}
            </AccordionDetails>
          ) : (
            <CircularProgress
              sx={{
                textAlign: "center",
                margin: "0 auto",
                display: "block",
              }}
            />
          )}
        </Accordion>
      ))}
    </Box>
  );
}
